function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { default as MuiExpansionPanelSummary } from '@material-ui/core/ExpansionPanelSummary';
import ChevronDown from '@eui/ds-icons/lib/ChevronDown';
import { colorBrandNeutral100, colorBrandNeutral400, colorBrandNeutral500, colorTextPrimary, fontSizeLarge, boxShadowFocusInset } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            color: colorTextPrimary,
            outline: 'none',
            '&$focused': {
                backgroundColor: 'transparent'
            },
            '&$disabled': {
                opacity: 1
            }
        },
        disabled: {
            color: colorBrandNeutral400,
            backgroundColor: colorBrandNeutral100
        },
        focused: {
            boxShadow: boxShadowFocusInset,
            outline: 'none'
        },
        iconButtonRoot: {
            fontSize: fontSizeLarge,
            '&$disabled': {
                color: colorBrandNeutral400
            }
        },
        iconLeftPosition: {
            top: '0.25rem',
            left: '0.5625rem',
            right: 'auto',
            position: 'absolute'
        },
        iconLeftPositionExpanded: {
            '& $iconLeftPosition': {
                top: '0.75rem'
            }
        }
    };
};

/**
 * A component that....
 */
var ExpansionPanelSummary = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        leftIconDisplay = props.leftIconDisplay,
        expandIcon = props.expandIcon,
        expandIconProps = props.expandIconProps,
        rest = _objectWithoutProperties(props, ['classes', 'leftIconDisplay', 'expandIcon', 'expandIconProps']);

    var classOverrides = {
        root: classes.root,
        disabled: classes.disabled,
        focused: classes.focused
    };

    if (leftIconDisplay) {
        classOverrides.expandIcon = classNames(classOverrides.expandIcon, classes.iconLeftPosition, 'eds-expansion-panel-icon-left-position');
        classOverrides.expanded = classes.iconLeftPositionExpanded;
    }

    return React.createElement(MuiExpansionPanelSummary, Object.assign({
        expandIcon: expandIcon ? expandIcon : React.createElement(ChevronDown, Object.assign({ fill: props.disabled ? colorBrandNeutral400 : colorBrandNeutral500 }, expandIconProps)),
        IconButtonProps: { classes: { root: classes.iconButtonRoot } },
        classes: classOverrides,
        ref: ref
    }, rest));
});

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';
ExpansionPanelSummary.displayName = 'ExpansionPanelSummary';

ExpansionPanelSummary.propTypes = {
    /**
     * The content of the expansion panel summary.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * @ignore
     * If `true`, the summary will be displayed in a disabled state.
     */
    disabled: PropTypes.bool,
    /**
     * @ignore
     * If `true`, expands the summary, otherwise collapse it.
     */
    expanded: PropTypes.bool,
    /**
     * **DEPRECATED** This is now handled by the component.
     *
     * The icon to display as the expand indicator.
     */
    expandIcon: PropTypes.node,
    /**
     * Properties applied to the icon element.
     */
    expandIconProps: PropTypes.object,
    /**
     * Displays the expandIcon on the left side of the ExpansionPanel.
     */
    leftIconDisplay: PropTypes.bool,
    /**
     * @ignore
     */
    onChange: PropTypes.func,
    /**
     * @ignore
     */
    onClick: PropTypes.func
};

ExpansionPanelSummary.defaultProps = {
    leftIconDisplay: false
};

export default withStyles(styles)(ExpansionPanelSummary);