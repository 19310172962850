'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            display: 'inline-flex',
            marginRight: _tokens.spacingMedium
        },
        indicator: {
            width: _tokens.sizingSmall,
            height: _tokens.sizingSmall,
            borderRadius: _tokens.borderRadiusCircle,
            marginRight: _tokens.spacingXxSmall,
            alignSelf: 'center'
        }
    };
};

/**
 * A UI element that renders an indicator color and label for the CalendarLegend.
 * @done true
 * @updated false
 * @versionAdded v0.2.1
 * @lab true
 */
var CalendarLegendItem = _react2.default.forwardRef(function (props, ref) {
    var color = props.color,
        classes = props.classes,
        label = props.label,
        className = props.className,
        theme = props.theme,
        rest = _objectWithoutProperties(props, ['color', 'classes', 'label', 'className', 'theme']);
    // default to the cta color if no color it sent for the indicator


    var indicatorColor = color === null || color === undefined ? theme.palette.tertiary.fountain[600] : color;

    return _react2.default.createElement(
        'div',
        Object.assign({ className: (0, _classnames2.default)(classes.root, className) }, rest, { ref: ref }),
        _react2.default.createElement('div', { className: classes.indicator, style: { backgroundColor: indicatorColor } }),
        _react2.default.createElement(
            _Typography2.default,
            { variant: 'body3' },
            label
        )
    );
});

CalendarLegendItem.propTypes = {
    /**
     * The color to match the calendar items in this category.
     */
    color: _propTypes2.default.string,
    /**
     * The label to render next to the indicator.
     */
    label: _propTypes2.default.string.isRequired,
    /**
     * @ignore
     */
    classes: _propTypes2.default.object,
    /**
     * Class name to send to parent `div` of the CalendarLegendItem.
     */
    className: _propTypes2.default.string,
    /**
     * @ignore
     * Material-UI theme object.
     */
    theme: _propTypes2.default.object
};

CalendarLegendItem.displayName = 'CalendarLegendItem';

exports.default = (0, _styles2.default)(styles, { withTheme: true })(CalendarLegendItem);