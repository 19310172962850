'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.styles = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _ChevronRight = require('@eui/ds-icons/lib/ChevronRight');

var _ChevronRight2 = _interopRequireDefault(_ChevronRight);

var _ChevronLeft = require('@eui/ds-icons/lib/ChevronLeft');

var _ChevronLeft2 = _interopRequireDefault(_ChevronLeft);

var _withStyles = require('../../styles/withStyles');

var _withStyles2 = _interopRequireDefault(_withStyles);

var _IconButton = require('../../IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _tokens = require('../../styles/tokens');

var _EDSContext = require('../../EDSContext/EDSContext');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = exports.styles = {
    /* Styles applied to the root element. */
    root: {
        color: 'inherit',
        flexBasis: 'auto',
        alignSelf: 'center',
        margin: '0 2px'
    },
    icon: {
        height: _tokens.spacingSmall,
        width: _tokens.spacingSmall
    },
    scrollLeftIcon: {
        marginRight: _tokens.spacingXSmall
    },
    scrollRightIcon: {
        marginLeft: _tokens.spacingXSmall
    },
    hideScrollButtons: {
        display: 'none'
    }
};

var TabScrollButton = _react2.default.forwardRef(function (props, ref) {
    var _classNames2;

    var classes = props.classes,
        classNameProp = props.className,
        direction = props.direction,
        edsContext = props.edsContext,
        onClick = props.onClick,
        visible = props.visible,
        hideScrollButtons = props.hideScrollButtons,
        other = _objectWithoutProperties(props, ['classes', 'className', 'direction', 'edsContext', 'onClick', 'visible', 'hideScrollButtons']);

    var className = (0, _classnames2.default)('tab-scroll-button', classes.root, _defineProperty({}, classes.hideScrollButtons, hideScrollButtons), classNameProp, (_classNames2 = {}, _defineProperty(_classNames2, classes.scrollLeftIcon, direction === 'left'), _defineProperty(_classNames2, classes.scrollRightIcon, direction === 'right'), _classNames2));

    return (
        // NOTE: This should use the gray version of the icon-only IconButton
        _react2.default.createElement(
            _IconButton2.default,
            Object.assign({
                'aria-label': edsContext.formatMessage('component.Tabs.scrollButtons'),
                title: edsContext.formatMessage('component.Tabs.scrollButtons'),
                className: className,
                onClick: onClick,
                tabIndex: -1,
                color: 'secondary',
                disabled: !visible
            }, other, {
                ref: ref
            }),
            direction === 'left' ? _react2.default.createElement(_ChevronLeft2.default, { className: classes.icon }) : _react2.default.createElement(_ChevronRight2.default, { className: classes.icon })
        )
    );
});

TabScrollButton.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * The direction of icon to render.
     */
    direction: _propTypes2.default.oneOf(['left', 'right']),
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,
    /**
     * Callback to execute for button press.
     */
    onClick: _propTypes2.default.func,
    /**
     * Should the button be present or just consume space.
     */
    visible: _propTypes2.default.bool,
    /**
     * For auto mode, buttons will be hidden if tabs don't need scrolling
     */
    hideScrollButtons: _propTypes2.default.bool
};

TabScrollButton.defaultProps = {
    visible: true
};

TabScrollButton.muiName = 'MuiPrivateTabScrollButton';

exports.default = (0, _EDSContext.withEDSContext)((0, _withStyles2.default)(styles)(TabScrollButton));