'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.i18n = undefined;

var _ar_SA = require('./ar_SA/ar_SA.json');

var _ar_SA2 = _interopRequireDefault(_ar_SA);

var _es = require('./es/es.json');

var _es2 = _interopRequireDefault(_es);

var _en_GB = require('./en_GB/en_GB.json');

var _en_GB2 = _interopRequireDefault(_en_GB);

var _en_US = require('./en_US/en_US.json');

var _en_US2 = _interopRequireDefault(_en_US);

var _fr = require('./fr/fr.json');

var _fr2 = _interopRequireDefault(_fr);

var _fr_CA = require('./fr_CA/fr_CA.json');

var _fr_CA2 = _interopRequireDefault(_fr_CA);

var _pt = require('./pt/pt.json');

var _pt2 = _interopRequireDefault(_pt);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var i18n = exports.i18n = {
    ar_SA: Object.assign({}, _ar_SA2.default),
    en_US: Object.assign({}, _en_US2.default),
    en_GB: Object.assign({}, _en_GB2.default),
    es: Object.assign({}, _es2.default),
    fr: Object.assign({}, _fr2.default),
    fr_CA: Object.assign({}, _fr_CA2.default),
    pt: Object.assign({}, _pt2.default)
};