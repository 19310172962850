'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _tokens = require('../styles/tokens');

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        link: {
            color: theme.palette.ctaColor.base,
            textDecoration: 'underline',
            '&:hover': {
                color: theme.palette.ctaColor.hover,
                textDecoration: 'underline',
                cursor: 'pointer'
            },
            '&:active': {
                color: theme.palette.ctaColor.active,
                textDecoration: 'underline'
            },
            '&:focus': {
                outline: 'none',
                textDecoration: 'underline',
                borderRadius: _tokens.borderRadiusSmall,
                boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + _tokens.colorBorderGlobalFocus
            }
        },
        disabled: {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            textDecoration: 'underline',
            color: _tokens.colorTextNeutral400
        },
        standalone: {
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: _tokens.fontSizeDefault,
            fontWeight: _tokens.fontWeightStrong
        },
        inline: {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit'
        }
    };
};

/**
 * Use `TextLink` to take the user to a new page.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  TextLinkExample
 *  CustomComponent
 */
var TextLink = _react2.default.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        className = props.className,
        Component = props.component,
        disabled = props.disabled,
        text = props.text,
        url = props.url,
        variant = props.variant,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'component', 'disabled', 'text', 'url', 'variant']);

    var classOverrides = {
        stateStyle: (0, _classnames2.default)((_classNames = {}, _defineProperty(_classNames, classes.disabled, disabled), _defineProperty(_classNames, classes.link, !disabled), _defineProperty(_classNames, classes.standalone, variant === 'standalone'), _defineProperty(_classNames, classes.inline, variant === 'inline'), _classNames), className)
    };

    (0, _react.useEffect)(function () {
        if (props.component === 'a' && (0, _isEmpty2.default)(props.url)) {
            console.warn('url prop is required for TextLink component.');
        }
    }, [props.component, props.url]);

    if (disabled) {
        return _react2.default.createElement(
            Component,
            Object.assign({
                'aria-disabled': 'true',
                className: classOverrides.stateStyle
            }, rest),
            text
        );
    } else {
        return _react2.default.createElement(
            Component,
            Object.assign({
                href: url,
                className: classOverrides.stateStyle
            }, rest, {
                ref: ref
            }),
            text
        );
    }
});

TextLink.muiName = 'TextLink';
TextLink.displayName = 'TextLink';

TextLink.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * Custom className for additional styling
     */
    className: _propTypes2.default.string,
    /**
     * The component used for the root node. We recommend using a component or element
     * that supports the `href` prop.
     */
    component: _propTypes2.default.elementType,
    /**
     * Set to true to disable the link
     */
    disabled: _propTypes2.default.bool,
    /**
     * The text that the link will display
     */
    text: _propTypes2.default.string.isRequired,
    /**
     * The redirect URL. This prop is required if replacement `component` prop is not specified.
     */
    url: _propTypes2.default.string,
    /**
     * Style variant of the TextLink to be displayed. <code>inherit</code> will inherit font weight, font size, and font family from surrounding text. <code>standalone</code> is to be used for a <code>TextLink</code> that is not surrounded by other text.
     */
    variant: _propTypes2.default.oneOf(['standalone', 'inherit'])
};

TextLink.defaultProps = {
    component: 'a',
    disabled: false,
    variant: 'standalone'
};

exports.default = (0, _styles2.default)(styles)(TextLink);