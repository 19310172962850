import React from 'react';
import { IconSprite as EdsIconSprite } from '@eui/ds-icons/lib';
import createEDSTheme from '../../core/themes/createEDSTheme';
import Alert from '../../core/Alert';
import Application from '../../core/Application';
import Icon from '../../core/Icon';
import IconButton from '../../core/IconButton';
import NavTabs from '../../core/NavTabs';
import PageLoader from '../../core/PageLoader';
import Snackbar from '../../core/Snackbar';
import Tooltip from '../../core/Tooltip';
import ContentPage from '../ContentPage';
import NavBar from '../NavBar';
import { CtaColors } from '../../core/styles/Tokens';
import { LogType } from '../../../types/LogType';
import { ResultType } from '../../../types/ResultType';
import Constants from '../../../helpers/Constants';
import Format from '../../../helpers/Format';
import LogData from '../../../helpers/LogData';
import Requests from '../../../requests/Layout';
import Actions from '../../../flux/actions/LayoutActions';
import Store from '../../../flux/stores/LayoutStore';
class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.callbackObserver = (mutationRecord) => {
            if (mutationRecord && mutationRecord.length > 0) {
                const mutationType = mutationRecord.find(m => m.attributeName === 'style');
                if (mutationType) {
                    this.setState({
                        alertOverModal: Boolean(mutationType.target.style) && mutationType.target.style.cssText === 'overflow: hidden;'
                    });
                }
            }
        };
        this.resolveGetInitialLoadData = (json) => {
            try {
                const { idMainPage, idModule, idPage, showCart } = this.props;
                const result = Format.toJson(json);
                if (result.status) {
                    const isAuthenticated = result.data.isAuthenticated;
                    if (isAuthenticated && showCart) {
                        Actions.getCountCart();
                    }
                    const displayName = result.data.displayName;
                    const layoutPermissions = result.data.layoutPermissions;
                    let tabs;
                    const menu = result.data.options;
                    const resources = result.data.resources;
                    const layoutResources = result.data.layoutResources;
                    if (resources && layoutResources && resources.lblPageTitle && layoutResources.lblPageTitleGeneral) {
                        resources.lblPageTitle = `${layoutResources.lblPageTitleGeneral}${resources.lblPageTitle}`;
                    }
                    let theme = result.data.theme;
                    let cultures = result.data.cultures;
                    if (menu) {
                        const pageSelected = idMainPage ? `${idMainPage}Id` : `${idPage}Id`;
                        menu.forEach(item => {
                            if (item.id === (`${idModule}Id`)) {
                                tabs = item.options;
                                if (tabs) {
                                    tabs.forEach(tab => {
                                        if (tab.id === pageSelected) {
                                            if (resources && !resources.lblPageTitle) {
                                                if (layoutResources.lblPageTitleGeneral) {
                                                    resources.lblPageTitle = `${layoutResources.lblPageTitleGeneral}${tab.text}`;
                                                }
                                                else {
                                                    resources.lblPageTitle = tab.text;
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                    Store.setAuthMode(result.data.authMode);
                    Store.setHasProfile(result.data.hasProfile);
                    Store.setIsAuthenticated(isAuthenticated);
                    Store.setMenuOptions(menu);
                    Store.setPermissions(result.data.permissions);
                    Store.setResources(result.data.resources);
                    Store.setResourcesLayout(layoutResources);
                    Store.setTheme(theme);
                    if (!Boolean(theme)) {
                        theme = Store.getDefaultTheme();
                    }
                    Store.setCultures(cultures);
                    if (!Boolean(theme)) {
                        cultures = Store.getDefaultCutlure();
                    }
                    const newUITheme = this.getUITheme(theme);
                    let componentTranslations;
                    if (layoutResources && layoutResources.component) {
                        const componentKeys = Object.keys(layoutResources.component);
                        if (componentKeys.length > 0) {
                            componentTranslations = {};
                            let elementKeys;
                            let componentName;
                            let propName;
                            for (const componentKey of componentKeys) {
                                elementKeys = Object.keys(layoutResources.component[componentKey]);
                                for (const elementKey of elementKeys) {
                                    componentName = `${componentKey[0].toUpperCase()}${componentKey.substr(1, componentKey.length - 1)}`;
                                    propName = `component.${componentName}.${elementKey}`;
                                    componentTranslations[propName] = layoutResources.component[componentKey][elementKey];
                                }
                            }
                        }
                    }
                    this.setState({
                        buildVersion: result.data.buildVersion,
                        buildYear: result.data.buildYear,
                        componentTranslations: componentTranslations,
                        displayName: displayName,
                        isAuthenticated: isAuthenticated,
                        layoutPermissions: layoutPermissions,
                        layoutResources: layoutResources,
                        menuOptions: menu,
                        notifications: result.data.notifications,
                        resources: resources,
                        tabsOptions: tabs,
                        themeEds: newUITheme,
                        themeSettings: theme
                    }, () => this.setState({
                        ready: true
                    }, () => Actions.setLayoutReady(true)));
                }
                else if (result.code) {
                    this.redirectError(result.code);
                }
                else if (result.statusText) {
                    this.showError(result.statusText);
                }
                else {
                    this.logError(LogData.badJsonResult(this.resolveGetInitialLoadData.name));
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.resolveGetInitialLoadData.name, e));
            }
        };
        this.onAlertChange = () => {
            const alert = Store.getAlert();
            if (alert) {
                this.setState({
                    alert: alert,
                    loading: false,
                    showMessage: true
                });
            }
        };
        this.onCloseAlert = () => {
            this.setState({
                showMessage: false
            });
        };
        this.onCountCartChange = () => {
            this.setState({
                cartItems: Store.getCartItems(),
                countCart: Store.getCountCart()
            });
        };
        this.onError = () => {
            const error = Store.getError();
            const errorMessage = Store.getErrorMessage();
            if (error && errorMessage) {
                this.logError(LogData.fromMessage(this.onError.name, errorMessage));
            }
        };
        this.onLoadingChange = () => {
            const loading = Store.getLoading();
            this.setState({
                loading: loading
            });
        };
        this.onLogDataChange = () => {
            const { idModule, idPage } = this.props;
            const { layoutResources } = this.state;
            const alert = {
                message: 'There was an error in PowerCampus. Try again.',
                messageType: ResultType.error
            };
            const logData = Store.getLogData();
            if (logData) {
                logData.idModule = idModule;
                logData.idPage = idPage;
                switch (logData.type) {
                    case LogType.genericError:
                        if (layoutResources && layoutResources.lblError) {
                            alert.message = layoutResources.lblError;
                        }
                        break;
                    case LogType.customError:
                        if (logData.userMessage) {
                            alert.message = logData.userMessage;
                        }
                        break;
                }
                Requests.postError(logData);
            }
            this.setState({
                alert: alert,
                loading: false,
                logData: logData,
                showMessage: true
            });
        };
        this.onRedirectCodeChange = () => {
            const redirectCode = Store.getRedirectCode();
            if (redirectCode) {
                this.setState({
                    loading: true,
                    redirectCode: redirectCode
                });
                switch (redirectCode) {
                    case -1:
                        window.location.assign(`${Constants.webUrl}/Errors/Error`);
                        break;
                    case 400:
                        window.location.assign(`${Constants.webUrl}/Errors/Error400`);
                        break;
                    case 401:
                        window.location.assign(`${Constants.webUrl}/Errors/Error401`);
                        break;
                    case 403:
                        window.location.assign(`${Constants.webUrl}/Errors/Error402`);
                        break;
                    case 404:
                        window.location.assign(`${Constants.webUrl}/Errors/Error404`);
                        break;
                    case 500:
                        window.location.assign(`${Constants.webUrl}/Errors/Error500`);
                        break;
                    default:
                        window.location.assign(`${Constants.webUrl}/Errors/Error500`);
                        break;
                }
            }
        };
        this.onReloadThemeChange = () => {
            const theme = Store.getTheme();
            const newUITheme = this.getUITheme(theme);
            this.setState({
                themeEds: newUITheme,
                themeSettings: theme
            });
        };
        document.title = '';
        this.state = this.getInitialState();
        Store.addAlertListener(this.onAlertChange);
        Store.addCountCartListener(this.onCountCartChange);
        Store.addErrorListener(this.onError);
        Store.addLoadingListener(this.onLoadingChange);
        Store.addLogDataListener(this.onLogDataChange);
        Store.addRedirectCodeListener(this.onRedirectCodeChange);
        Store.addReloadThemeListener(this.onReloadThemeChange);
        const target = document.getElementsByTagName('body');
        if (target && target.length > 0) {
            this.observer = new MutationObserver(this.callbackObserver);
            const config = {
                attributeFilter: ['style'],
                attributeOldValue: true,
                attributes: true
            };
            this.observer.observe(target[0], config);
        }
    }
    getInitialState() {
        const themeSettings = Store.getTheme();
        return {
            componentError: false,
            countCart: -1,
            isAuthenticated: Store.getIsAuthenticated(),
            layoutResources: Store.getResourcesLayout(),
            loading: false,
            logData: undefined,
            menuOptions: Store.getMenuOptions(),
            ready: false,
            redirectCode: 0,
            showMessage: false,
            themeEds: themeSettings ? this.getUITheme(themeSettings) : undefined,
            themeSettings: themeSettings
        };
    }
    logError(logData) {
        Actions.setLogData(logData);
    }
    redirectError(code) {
        Actions.setRedirectCode(code);
    }
    showError(message) {
        Actions.setAlert({ message: message, messageType: ResultType.error });
    }
    getUITheme(theme) {
        const oldMetaTheme = document.getElementById('dynamic-theme');
        const oldLinkFavicon = document.getElementById('dynamic-favicon');
        const metaTheme = document.createElement('meta');
        metaTheme.id = 'dynamic-theme';
        metaTheme.name = 'theme-color';
        const linkFavicon = document.createElement('link');
        linkFavicon.id = 'dynamic-favicon';
        linkFavicon.rel = 'icon';
        const newUITheme = createEDSTheme('light');
        if (theme && theme.actionColor && theme.primaryColor && theme.secondaryColor) {
            newUITheme.palette.ctaColor = CtaColors[theme.actionColor];
            newUITheme.palette.primary.main = theme.primaryColor;
            newUITheme.palette.secondary.main = theme.secondaryColor;
            metaTheme.content = theme.primaryColor;
            linkFavicon.href = theme.faviconUrl;
        }
        else {
            metaTheme.content = Constants.defaultThemeColor;
            linkFavicon.href = Constants.faviconCDN;
        }
        if (oldMetaTheme) {
            document.head.removeChild(oldMetaTheme);
        }
        if (oldLinkFavicon) {
            document.head.removeChild(oldLinkFavicon);
        }
        document.head.appendChild(metaTheme);
        document.head.appendChild(linkFavicon);
        return newUITheme;
    }
    componentDidMount() {
        try {
            const { idModule, idPage } = this.props;
            Actions.setLoading(true);
            Requests.getInitialLoadData(idModule, idPage, this.resolveGetInitialLoadData, this.logError);
        }
        catch (e) {
            this.logError(LogData.fromException(this.componentDidMount.name, e));
        }
    }
    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
        Store.removeAlertListener(this.onAlertChange);
        Store.removeCountCartListener(this.onCountCartChange);
        Store.removeErrorListener(this.onError);
        Store.removeLoadingListener(this.onLoadingChange);
        Store.removeLogDataListener(this.onLogDataChange);
        Store.removeRedirectCodeListener(this.onRedirectCodeChange);
    }
    componentDidCatch(error, info) {
        this.logError(LogData.fromComponentException(this.componentDidCatch.name, error, info));
        this.setState({
            componentError: true
        }, () => this.redirectError(500));
    }
    render() {
        const { children, idMainModule, idMainPage, idModule, idPage, hideHeader, showCart, showNavTabs, showNotifications, withBackgroundImage, withFooter } = this.props;
        const { alert, alertOverModal, buildVersion, buildYear, cartItems, componentError, componentTranslations, countCart, displayName, isAuthenticated, layoutPermissions, loading, menuOptions, notifications, ready, resources, layoutResources, showMessage, tabsOptions, themeEds, themeSettings } = this.state;
        let pageAlert;
        if (alert) {
            if (alert.snackbar) {
                pageAlert = (React.createElement(Snackbar, { action: layoutResources ? (React.createElement(Tooltip, { title: layoutResources.lblClose },
                        React.createElement(IconButton, { color: "white", id: "btnClosePageMessage", "aria-label": layoutResources.lblClose, onClick: this.onCloseAlert },
                            React.createElement(Icon, { name: "close" })))) : undefined, ContentProps: {
                        'aria-describedby': 'pageMessage'
                    }, message: (React.createElement("span", { id: "pageMessage" }, alert.message ? alert.message :
                        (layoutResources ?
                            layoutResources.lblError :
                            'There was an error in PowerCampus. Try again.'))), open: showMessage, type: alert.message ? alert.messageType : ResultType.error, onClose: this.onCloseAlert }));
            }
            else {
                pageAlert = (React.createElement(Alert, { autoHideDuration: 30000, disableWindowBlurListener: true, id: "pageAlert", open: showMessage, overModal: alertOverModal, text: alert.message ? alert.message :
                        (layoutResources ?
                            layoutResources.lblError :
                            'There was an error in PowerCampus. Try again.'), type: alert.message ? alert.messageType : ResultType.error, variant: "page", onClose: this.onCloseAlert }));
            }
        }
        const pageSelected = idMainPage ? `${idMainPage}Id` : `${idPage}Id`;
        let headerTitle;
        let navTabs;
        if (ready) {
            if (!hideHeader) {
                if (resources && resources.lblHeaderTitle) {
                    headerTitle = resources.lblHeaderTitle;
                }
                else if (tabsOptions && idPage) {
                    tabsOptions.forEach(item => {
                        if (item.id === pageSelected) {
                            headerTitle = item.text;
                        }
                    });
                }
            }
            if (tabsOptions && idPage) {
                navTabs = showNavTabs ? (React.createElement(NavTabs, { id: "navTabs", options: tabsOptions, valueSelected: pageSelected })) : React.createElement("br", null);
            }
        }
        if (!componentError) {
            if (themeEds && themeSettings) {
                return (React.createElement(Application, { labelOverrides: componentTranslations, theme: themeEds },
                    React.createElement(EdsIconSprite, null),
                    React.createElement(NavBar, { cartItems: cartItems, countCart: countCart, displayName: displayName, homeUrl: `${Constants.webUrl}/Home`, isAuthenticated: isAuthenticated, layoutPermissions: layoutPermissions, logoAlternateText: themeSettings.logoAlternateText, logOutUrl: `${Constants.webUrl}/Home/LogOut`, logoUrl: themeSettings.logoUrl, menuOptions: menuOptions, notifications: notifications, profileUrl: `${Constants.webUrl}/Account/Profile`, showCart: showCart, showNotifications: showNotifications, resources: layoutResources ? layoutResources.navBar : undefined, valueSelected: idMainModule ? `${idMainModule}Id` : `${idModule}Id` }),
                    React.createElement("div", { className: "ss-app" },
                        React.createElement(ContentPage, { backgroundImageUrl: themeSettings.backgroundUrl, buildVersion: buildVersion, buildYear: buildYear, contentPage: children, headerTitle: headerTitle, isAuthenticated: isAuthenticated, navTabs: navTabs, withBackgroundImage: withBackgroundImage, withFooter: withFooter })),
                    React.createElement(PageLoader, { "aria-valuetext": layoutResources ? layoutResources.lblLoading : undefined, open: loading }),
                    pageAlert));
            }
            else {
                return (React.createElement(Application, { labelOverrides: componentTranslations, theme: themeEds },
                    React.createElement(EdsIconSprite, null),
                    React.createElement(PageLoader, { isDefault: true, open: loading }),
                    pageAlert));
            }
        }
        else {
            return (React.createElement(React.Fragment, null));
        }
    }
}
export default Layout;
