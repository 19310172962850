export var LayoutEventStatus;
(function (LayoutEventStatus) {
    LayoutEventStatus["changeAlert"] = "changeAlert";
    LayoutEventStatus["changeCountCart"] = "changeCountCart";
    LayoutEventStatus["changeLayoutReady"] = "changeLayoutReady";
    LayoutEventStatus["changeLoading"] = "changeLoading";
    LayoutEventStatus["changeLogData"] = "changeLogData";
    LayoutEventStatus["changeRedirectCode"] = "changeRedirectCode";
    LayoutEventStatus["changeReloadTheme"] = "changeReloadTheme";
    LayoutEventStatus["error"] = "error";
})(LayoutEventStatus || (LayoutEventStatus = {}));
