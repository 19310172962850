var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { withEDSContext } from './../EDSContext/EDSContext';
import { Stepper as MuiStepper, StepConnector } from '@material-ui/core';
import { STEP_LABEL_CLASSNAME, STEP_PROGRESS_STEP_CLASSNAME, STEP_LABEL_ERROR_CLASSNAME, STEP_LABEL_HAS_ERROR_CLASSNAME, ACTIVE_STEP_SCREEN_READER_TEXT } from './internal/constants.js';

var styles = function styles(theme) {
    return {
        root: {
            background: 'transparent',
        },
        hidden: {
            width: 0,
            height: 0,
            color: 'transparent'
        }
    };
};

/**
 * Use `StepProgress` to display a user's progress through numbered steps.
 * @done true
 * @updated false
 * @versionAdded v0.0.14
 * @examples
 *  LinearHorizontalExample
 *  NonLinearExample
 *  AlternativeLabelExample
 *  VerticalExample
 *  ErrorStateExample
 *  MobileStepperExample
 *  NonLinearDisabledExample
 */
var StepProgress = React.forwardRef(function (props, ref) {
    var _useState = useState(''),
        _useState2 = _slicedToArray(_useState, 2),
        stepLabel = _useState2[0],
        setStepLabel = _useState2[1];

    var _useState3 = useState(false),
        _useState4 = _slicedToArray(_useState3, 2),
        hasStepError = _useState4[0],
        setHasStepError = _useState4[1];

    var _useState5 = useState(''),
        _useState6 = _slicedToArray(_useState5, 2),
        errorLabel = _useState6[0],
        setErrorLabel = _useState6[1];

    var localRef = ref || useRef();

    var classes = props.classes,
        edsContext = props.edsContext,
        rest = _objectWithoutProperties(props, ['classes', 'edsContext']);

    var classOverrides = {
        root: classes.root
    };

    // This effect sets the `aria-label` to say `<step-label>, <step-number> / <total-steps>
    // by the screen reader by extracting all the step labels
    useEffect(function () {
        var stepLabels = localRef.current.querySelectorAll('.' + STEP_LABEL_CLASSNAME);

        stepLabels.forEach(function (localStepLabel, i) {
            localStepLabel.setAttribute('aria-label', edsContext.formatMessage('component.StepProgress.a11yLabel', {
                label: localStepLabel.textContent.replace(/^[0-9]/, ''),
                step: i + 1,
                totalSteps: stepLabels.length
            }));
        });
    }, [localRef, edsContext]);

    // This effect sets the div with current step content with error message if there is one.
    useEffect(function () {
        try {
            var stepLabels = localRef.current.querySelectorAll('.' + STEP_LABEL_CLASSNAME);
            var activeStep = stepLabels[props.activeStep];
            var localStepLabel = localRef.current.querySelectorAll('.' + STEP_PROGRESS_STEP_CLASSNAME)[props.activeStep];
            var localErrorLabel = localStepLabel && localStepLabel.querySelector('.' + STEP_LABEL_ERROR_CLASSNAME);
            var secondaryLabel = localErrorLabel ? localErrorLabel.textContent : '';
            setHasStepError(activeStep ? activeStep.classList.contains(STEP_LABEL_HAS_ERROR_CLASSNAME) : '');
            setErrorLabel(secondaryLabel);
            setStepLabel(props.activeStep + 1 > stepLabels.length ? edsContext.formatMessage('component.MobileStepper.allStepsCompleted') : activeStep && activeStep.getAttribute('aria-label'));
        } catch (error) {
            console.log(error);
        }
    }, [props.activeStep, localRef, edsContext]);

    var getText = function getText() {
        var errorMessage = edsContext.formatMessage('component.StepProgress.errorMessage', {
            errorMessage: errorLabel
        });
        var text = stepLabel;

        text += hasStepError ? ', ' + edsContext.formatMessage('component.StepProgress.errorMessageTitle') : '';
        text += errorLabel.length ? ', ' + errorMessage : '';

        return text;
    };

    return React.createElement(
        React.Fragment,
        null,
        React.createElement(MuiStepper, Object.assign({}, rest, {
            classes: classOverrides,
            ref: localRef
        })),
        React.createElement(
            'div',
            { 'aria-live': 'assertive', className: classes.hidden + ' ' + ACTIVE_STEP_SCREEN_READER_TEXT },
            getText()
        )
    );
});

StepProgress.muiName = 'Stepper';
StepProgress.displayName = 'StepProgress';

StepProgress.propTypes = {
    /**
     * Set the active step (zero based index).
     */
    activeStep: PropTypes.number,
    /**
     * If set to 'true' and orientation is `horizontal`,
     * then the step label will be positioned under the icon.
     */
    alternativeLabel: PropTypes.bool,
    /**
     * Two or more `<Step />` components.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * A component to be placed between each step.
     */
    connector: PropTypes.element,
    /**
     * If set the `StepProgress` will not assist in controlling steps for linear flow.
     */
    nonLinear: PropTypes.bool,
    /**
     * The stepper orientation (layout flow direction).
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

StepProgress.defaultProps = {
    activeStep: 0,
    alternativeLabel: false,
    connector: React.createElement(StepConnector, null),
    nonLinear: false,
    orientation: 'horizontal'
};

export default withEDSContext(withStyles(styles)(StepProgress));