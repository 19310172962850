"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getDefaultMaxDate = getDefaultMaxDate;
exports.getDefaultMinDate = getDefaultMinDate;
exports.areSameTime = areSameTime;
var Months = exports.Months = {
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11
};

function getDefaultMaxDate() {
    var date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    date.setMonth(Months.DECEMBER, 31);
    return date;
}

function getDefaultMinDate() {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 20);
    date.setMonth(Months.JANUARY, 1);
    return date;
}

function areSameTime(date1, date2) {
    return date1 != null && date2 != null && date1.getHours() === date2.getHours() && date1.getMinutes() === date2.getMinutes() && date1.getSeconds() === date2.getSeconds() && date1.getMilliseconds() === date2.getMilliseconds();
}