import React from 'react';

var SvgBookmark = function SvgBookmark(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                d: "M47.375 0h-33.75A5.625 5.625 0 0 0 8 5.625V60l22.5-13.125L53 60V5.625A5.625 5.625 0 0 0 47.375 0z",
                fill: "currentColor"
            }),
            React.createElement("path", {
                d: "M49.25 53.471L30.5 42.534 11.75 53.47V5.625c0-1.034.841-1.875 1.875-1.875h33.75c1.034 0 1.875.841 1.875 1.875v47.846z",
                fill: "#FFF",
                opacity: 0.88
            }),
            React.createElement("path", {
                d: "M47.375 0A5.625 5.625 0 0 1 53 5.625V60L30.5 46.875 8 60V5.625A5.625 5.625 0 0 1 13.625 0h33.75zm1.875 53.471V5.625a1.877 1.877 0 0 0-1.875-1.875h-33.75a1.877 1.877 0 0 0-1.875 1.875v47.846L30.5 42.534 49.25 53.47z",
                fill: "currentColor"
            })
        )
    );
};

export default SvgBookmark;