'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _classNames = require('react-day-picker/lib/src/classNames');

var _classNames2 = _interopRequireDefault(_classNames);

var _core = require('@hedtech/react-design-system/core');

var _styles = require('@hedtech/react-design-system/core/styles');

var _ChevronRight = require('@eui/ds-icons/lib/ChevronRight');

var _ChevronRight2 = _interopRequireDefault(_ChevronRight);

var _ChevronLeft = require('@eui/ds-icons/lib/ChevronLeft');

var _ChevronLeft2 = _interopRequireDefault(_ChevronLeft);

var _tokens = require('../../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var iconButtonAlignmentSpace = '0.375rem';

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0
        },
        left: {
            marginRight: 'auto',
            marginLeft: iconButtonAlignmentSpace,
            width: _tokens.sizingMedium,
            height: _tokens.sizingMedium,
            '& svg': {
                height: _tokens.spacingSmall,
                width: _tokens.spacingSmall
            }
        },
        right: {
            marginRight: iconButtonAlignmentSpace,
            marginLeft: 'auto',
            width: _tokens.sizingMedium,
            height: _tokens.sizingMedium,
            '& svg': {
                height: _tokens.spacingSmall,
                width: _tokens.spacingSmall
            }
        }
    };
};

function Navbar(props) {
    var _cn, _cn2;

    var onPreviousClick = props.onPreviousClick,
        onNextClick = props.onNextClick,
        className = props.className,
        dir = props.dir,
        labels = props.labels,
        classes = props.classes,
        showPreviousButton = props.showPreviousButton,
        showNextButton = props.showNextButton;


    var isRtl = dir === 'rtl';

    var previousButton = _react2.default.createElement(
        _core.IconButton,
        {
            key: 'hedtech-datcepicker-prev-btn',
            color: 'gray',
            className: (0, _classnames2.default)((_cn = {}, _defineProperty(_cn, classes.left, !isRtl), _defineProperty(_cn, classes.right, isRtl), _cn)),
            'aria-label': labels.previousMonth,
            onClick: function onClick() {
                return onPreviousClick();
            },
            disabled: !showPreviousButton
        },
        !isRtl ? _react2.default.createElement(_ChevronLeft2.default, null) : _react2.default.createElement(_ChevronRight2.default, null)
    );

    var nextButton = _react2.default.createElement(
        _core.IconButton,
        {
            key: 'hedtech-datepicker-next-btn',
            color: 'gray',
            className: (0, _classnames2.default)((_cn2 = {}, _defineProperty(_cn2, classes.right, !isRtl), _defineProperty(_cn2, classes.left, isRtl), _cn2)),
            'aria-label': labels.nextMonth,
            onClick: function onClick() {
                return onNextClick();
            },
            disabled: !showNextButton
        },
        !isRtl ? _react2.default.createElement(_ChevronRight2.default, null) : _react2.default.createElement(_ChevronLeft2.default, null)
    );

    return _react2.default.createElement(
        'div',
        { className: (0, _classnames2.default)(className, classes.root) },
        isRtl ? [nextButton, previousButton] : [previousButton, nextButton]
    );
}

Navbar.propTypes = {
    classNames: _propTypes2.default.shape({
        navBar: _propTypes2.default.string.isRequired,
        navButtonPrev: _propTypes2.default.string.isRequired,
        navButtonNext: _propTypes2.default.string.isRequired
    }),
    className: _propTypes2.default.string,
    showPreviousButton: _propTypes2.default.bool,
    showNextButton: _propTypes2.default.bool,
    onPreviousClick: _propTypes2.default.func,
    onNextClick: _propTypes2.default.func,
    dir: _propTypes2.default.string,
    labels: _propTypes2.default.shape({
        previousMonth: _propTypes2.default.string,
        nextMonth: _propTypes2.default.string
    }),
    classes: _propTypes2.default.object
};

Navbar.defaultProps = {
    classNames: _classNames2.default,
    dir: 'ltr',
    labels: {},
    showPreviousButton: true,
    showNextButton: true
};

exports.default = (0, _styles.withStyles)(styles)(Navbar);