'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.styles = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = exports.styles = function styles(theme) {
    return {
        root: {
            '& button': {
                margin: '0 8px',
                marginBottom: '8px'
            }
        }
    };
};

/**
 * Use a `ButtonGroup` for two or more buttons that are grouped together.
 */
var ButtonGroup = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        className = props.className,
        children = props.children,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'children']);

    // showcase all class overrides


    var buttonGroupClasses = (0, _classnames2.default)(classes.root, className);

    return _react2.default.createElement(
        'div',
        Object.assign({ className: buttonGroupClasses }, rest, { ref: ref }),
        children
    );
});

ButtonGroup.displayName = 'ButtonGroup';

ButtonGroup.propTypes = {
    /**
     * Class name to assign the component.
     */
    className: _propTypes2.default.string,
    /**
     * Classes used to extend original style object.
     */
    classes: _propTypes2.default.object,
    /**
     * The buttons that are passed in as children within ButtonGroups.
     */
    children: _propTypes2.default.node.isRequired
};

exports.default = (0, _styles2.default)(styles)(ButtonGroup);