'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _TableBody = require('@material-ui/core/TableBody');

var _TableBody2 = _interopRequireDefault(_TableBody);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: {}
};

/**
 * The body of the table
 */
var TableBody = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: classes.root
    };

    return _react2.default.createElement(_TableBody2.default, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

TableBody.muiName = 'TableBody';
TableBody.displayName = 'TableBody';

TableBody.propTypes = {
    /**
     * The content of the component, normally `TableRow`.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object])

};

// default props
TableBody.defaultProps = {
    component: 'tbody'
};

exports.default = (0, _styles2.default)(styles)(TableBody);