function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiCardContent } from '@material-ui/core/CardContent';
import { CardContext } from './Card';
import { spacingLarge, spacingMedium, spacingSmall, spacingXSmall, spacingXSmall2 } from '../styles/tokens';
import classNames from 'classnames';

var styles = function styles(theme) {
    var _responsive;

    return {
        root: {},
        standard: {
            padding: spacingSmall + ' ' + spacingLarge,
            '&:last-child': {
                padding: spacingSmall + ' ' + spacingLarge
            }
        },
        dense: {
            padding: spacingXSmall2 + ' ' + spacingMedium,
            '&:last-child': {
                padding: spacingXSmall2 + ' ' + spacingMedium
            }
        },
        none: {
            padding: 0,
            '&:last-child': {
                padding: 0
            }
        },
        responsive: (_responsive = {}, _defineProperty(_responsive, theme.breakpoints.only('xs'), {
            padding: spacingXSmall + ' ' + spacingSmall
        }), _defineProperty(_responsive, '&:last-child', _defineProperty({}, theme.breakpoints.only('xs'), {
            padding: spacingXSmall + ' ' + spacingSmall
        })), _responsive)
    };
};

/**
 * CardContent component for displaying content on a Card component
 */
var CardContent = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var _useContext = useContext(CardContext),
        padding = _useContext.padding,
        responsive = _useContext.responsive;

    var classOverrides = {
        root: classNames((_classNames = {}, _defineProperty(_classNames, classes.standard, padding === 'standard'), _defineProperty(_classNames, classes.dense, padding === 'dense'), _defineProperty(_classNames, classes.none, padding === 'none'), _defineProperty(_classNames, classes.responsive, responsive && ['standard', 'dense'].indexOf(padding) !== -1), _classNames), classes.root)
    };

    return React.createElement(MuiCardContent, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

CardContent.muiName = 'CardContent';
CardContent.displayName = 'CardContent';

CardContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

// If default props are needed, they must be set here
CardContent.defaultProps = {
    component: 'div'
};

export default withStyles(styles)(CardContent);