'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('@material-ui/styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
    return {
        '@global': {
            html: {
                WebkitFontSmoothing: 'antialiased', // Antialiasing.
                MozOsxFontSmoothing: 'grayscale', // Antialiasing.
                // Change from `box-sizing: content-box` so that `width`
                // is not affected by `padding` or `border`.
                boxSizing: 'border-box'
            },
            '*, *::before, *::after': {
                boxSizing: 'inherit'
            },
            body: {
                margin: 0, // Remove the margin in all browsers.
                backgroundColor: theme.palette.background.default,
                '@media print': {
                    // Save printer ink.
                    backgroundColor: theme.palette.common.white
                }
            }
        }
    };
}, { name: 'CssBaseline' });

function CssBaseline(props) {
    var _props$children = props.children,
        children = _props$children === undefined ? null : _props$children;

    useStyles();
    return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        children
    );
}

CssBaseline.propTypes = {
    /**
     * You can wrap a node.
     */
    children: _propTypes2.default.node
};

exports.default = CssBaseline;