function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import { withEDSContext } from '../EDSContext/EDSContext';

/**
 * A component that displays the current step of the total steps
 */
var MobileStepperCounter = React.forwardRef(function (props, ref) {
  var activeStep = props.activeStep,
      steps = props.steps,
      edsContext = props.edsContext,
      rest = _objectWithoutProperties(props, ['activeStep', 'steps', 'edsContext']);

  return React.createElement(
    Typography,
    Object.assign({ variant: 'body2' }, rest, { ref: ref }),
    edsContext.formatMessage('component.MobileStepper.step', {
      step: activeStep + 1 >= steps ? steps : activeStep + 1,
      count: steps
    })
  );
});

MobileStepperCounter.propTypes = {
  /**
   * Set the active step (zero based index). This should be the same value that is being sent to the <MobileStepper> component.
   */
  activeStep: PropTypes.number,
  /**
   * The total steps. This should be the same value that is being sent to the <MobileStepper> component.
   */
  steps: PropTypes.number.isRequired,
  /**
   * Props will be passed into the rendered `<Typography>` component.
   */
  props: PropTypes.object,
  /**
   * @ignore
   */
  edsContext: PropTypes.object
};

MobileStepperCounter.displayName = 'MobileStepperCounter';

export default withEDSContext(MobileStepperCounter);