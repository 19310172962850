'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ = require('../');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Use `ConfirmationDialog` to require user action before closing a dialog.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  ConfirmationDialogExample
 *  FormDialogExample
 */
var ConfirmationDialog = _react2.default.forwardRef(function (props, ref) {
  var content = props.content,
      contentText = props.contentText,
      DialogProps = props.DialogProps,
      DialogActionsProps = props.DialogActionsProps,
      DialogContentProps = props.DialogContentProps,
      DialogContentTextProps = props.DialogContentTextProps,
      DialogTitleProps = props.DialogTitleProps,
      open = props.open,
      primaryActionOnClick = props.primaryActionOnClick,
      primaryActionProps = props.primaryActionProps,
      primaryActionText = props.primaryActionText,
      secondaryActionOnClick = props.secondaryActionOnClick,
      secondaryActionProps = props.secondaryActionProps,
      secondaryActionText = props.secondaryActionText,
      title = props.title;

  /**
   * Override `disableBackdropClick` and `disableEscapeKeyDown` to true
   * Pass `open` for convenience
   */

  DialogProps['disableBackdropClick'] = true;
  DialogProps['disableEscapeKeyDown'] = true;
  DialogProps['maxWidth'] = false;
  DialogProps['open'] = open;
  DialogProps['showTitleBarClose'] = false;

  // Override color for primary action
  primaryActionProps['color'] = 'primary';
  primaryActionProps['onClick'] = primaryActionOnClick;

  // Override color for secondary action
  secondaryActionProps['color'] = 'secondary';
  secondaryActionProps['onClick'] = secondaryActionOnClick;

  return _react2.default.createElement(
    _.Dialog,
    Object.assign({}, DialogProps, { ref: ref }),
    _react2.default.createElement(
      _.DialogTitle,
      DialogTitleProps,
      title
    ),
    _react2.default.createElement(
      _.DialogContent,
      DialogContentProps,
      _react2.default.createElement(
        _.DialogContentText,
        DialogContentTextProps,
        contentText
      ),
      content
    ),
    _react2.default.createElement(
      _.DialogActions,
      DialogActionsProps,
      _react2.default.createElement(
        _.Button,
        secondaryActionProps,
        secondaryActionText
      ),
      _react2.default.createElement(
        _.Button,
        primaryActionProps,
        primaryActionText
      )
    )
  );
});

ConfirmationDialog.muiName = 'ConfirmationDialog';
ConfirmationDialog.displayName = 'ConfirmationDialog';

ConfirmationDialog.propTypes = {
  /**
   * Content displayed by the `DialogContent` element.
   */
  content: _propTypes2.default.node,
  /**
   * Text displayed by the `DialogContentText` element.
   */
  contentText: _propTypes2.default.string,
  /**
   * Props that will be applied to the `Dialog` element.
   */
  DialogProps: _propTypes2.default.object,
  /**
   * Props that will be applied to the `DialogActions` element.
   */
  DialogActionsProps: _propTypes2.default.object,
  /**
   * Props that will be applied to the `DialogContent` element.
   */
  DialogContentProps: _propTypes2.default.object,
  /**
   * Props that will be applied to the `DialogContentText` element.
   */
  DialogContentTextProps: _propTypes2.default.object,
  /**
   * Props that will be applied to the `DialogTitle` element.
   */
  DialogTitleProps: _propTypes2.default.object,
  /**
   * If `true`, the Dialog is open.
   */
  open: _propTypes2.default.bool.isRequired,
  /**
   * onClick applied to the primary action Button element.
   */
  primaryActionOnClick: _propTypes2.default.func.isRequired,
  /**
   * Props applied to the primary action Button element.
   */
  primaryActionProps: _propTypes2.default.object.isRequired,
  /**
   * Text applied to the primary action Button element.
   */
  primaryActionText: _propTypes2.default.string.isRequired,
  /**
   * onClick applied to the secondary action Button element.
   */
  secondaryActionOnClick: _propTypes2.default.func.isRequired,
  /**
   * Props applied to the secondary action Button element.
   */
  secondaryActionProps: _propTypes2.default.object.isRequired,
  /**
   * Text applied to the secondary action Button element.
   */
  secondaryActionText: _propTypes2.default.string.isRequired,
  /**
   * Title displayed by the DialogTitle element.
   */
  title: _propTypes2.default.string.isRequired
};

ConfirmationDialog.defaultProps = {
  DialogProps: {},
  DialogActionsProps: {},
  DialogContentProps: {},
  DialogContentTextProps: {},
  DialogTitleProps: {},
  primaryActionProps: {},
  secondaryActionProps: {}
};

exports.default = ConfirmationDialog;