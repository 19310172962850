export var LayoutStatus;
(function (LayoutStatus) {
    LayoutStatus["errorGetCountCart"] = "errorGetCountCart";
    LayoutStatus["fetchGetCountCart"] = "fetchGetCountCart";
    LayoutStatus["successGetCountCart"] = "successGetCountCart";
    LayoutStatus["successHidePageLoader"] = "successHidePageLoader";
    LayoutStatus["successSetAlert"] = "successSetAlert";
    LayoutStatus["successSetCountCart"] = "successSetCountCart";
    LayoutStatus["successSetLayoutReady"] = "successSetLayoutReady";
    LayoutStatus["successSetLoading"] = "successSetLoading";
    LayoutStatus["successSetLogData"] = "successSetLogData";
    LayoutStatus["successSetRedirectCode"] = "successSetRedirectCode";
    LayoutStatus["successSetReloadTheme"] = "successSetReloadTheme";
    LayoutStatus["successShowPageLoader"] = "successShowPageLoader";
})(LayoutStatus || (LayoutStatus = {}));
