"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgLightbulb = function SvgLightbulb(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", {
                fill: "currentColor",
                d: "M30 58.192l-6.09-.674-1.621-3.143V43.333L15.29 32.435 10.917 22.5l1.81-10.5 6.736-7.367 8.78-3.063 9.018 1.358L46.273 10l1.954 10-.946 8.333-6.52 8.334-3.5 5v9.657l-1.517 4.926z"
            }),
            _react2.default.createElement("path", {
                fill: "#FFF",
                opacity: 0.88,
                d: "M30 58.192l-6.09-.674-1.621-3.143V43.333L15.29 32.435 10.917 22.5l1.81-10.5 6.736-7.367 8.78-3.063 9.018 1.358L46.273 10l1.954 10-.946 8.333-6.52 8.334-3.5 5v9.657l-1.517 4.926z"
            }),
            _react2.default.createElement("path", {
                d: "M29.5 9.375a1.87 1.87 0 0 1-1.864 1.875c-4.11 0-7.454 3.364-7.454 7.5a1.87 1.87 0 0 1-1.864 1.875 1.87 1.87 0 0 1-1.863-1.875c0-6.203 5.016-11.25 11.181-11.25 1.03 0 1.864.84 1.864 1.875zM50 20.625c0 11.922-6.022 10.727-10.543 22.579a2.782 2.782 0 0 1-.639.98v10.191h-.002a2.822 2.822 0 0 1-.61 1.708l-2.276 2.861A2.79 2.79 0 0 1 33.747 60h-8.494a2.79 2.79 0 0 1-2.183-1.056l-2.276-2.861a2.821 2.821 0 0 1-.61-1.708h-.002V44.18a2.832 2.832 0 0 1-.645-.993C14.997 31.304 8.949 32.55 9 20.473 9.048 9.142 18.237 0 29.5 0 40.821 0 50 9.235 50 20.625zM35.09 52.5H23.91v1.497l1.79 2.253h7.6l1.79-2.253V52.5zm0-7.5H23.91v3.75h11.18V45zm11.183-24.375c0-9.327-7.504-16.875-16.773-16.875-9.27 0-16.773 7.55-16.773 16.875 0 10.251 5.142 8.31 10.058 20.625h13.43c4.907-12.293 10.058-10.404 10.058-20.625z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgLightbulb;