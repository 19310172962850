function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiListSubheader } from '@material-ui/core/ListSubheader';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = function styles(theme) {
  return {
    // Necessary style overrides are set here
    root: {}
  };
};

/**
 * A component that....
 */
var ListSubheader = function ListSubheader(props) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    // See Material UI documentation for available CSS classes can be passed through to override
    root: classes.root
  };

  return React.createElement(MuiListSubheader, Object.assign({}, rest, {
    classes: classOverrides
  }));
};

ListSubheader.muiName = 'ListSubheader';

ListSubheader.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(['default', 'primary', 'inherit']),
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  /**
   * If `true`, the List Subheader will not stick to the top during scroll.
   */
  disableSticky: PropTypes.bool,
  /**
   * If `true`, the List Subheader will be indented.
   */
  inset: PropTypes.bool
};

ListSubheader.defaultProps = {
  color: 'default',
  component: 'li',
  disableSticky: false,
  inset: false
};

export default withStyles(styles)(ListSubheader);