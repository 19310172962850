function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiTableSortLabel } from '@material-ui/core/TableSortLabel';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: {}
};

/**
 * Table component for the label of a column sort
 */
var TableSortLabel = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: classes.root
    };

    return React.createElement(MuiTableSortLabel, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

TableSortLabel.muiName = 'TableSortLabel';

TableSortLabel.propTypes = {
    /**
     * If true, the table row will have the selected shading.
     */
    active: PropTypes.bool,
    /**
     * Label contents, the arrow will be appended automatically.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The current sort direction.
     */
    direction: PropTypes.oneOf(['asc', 'desc'])

};

TableSortLabel.displayName = 'TableSortLabel';

// If default props are needed, they must be set here
TableSortLabel.defaultProps = {
    active: false,
    direction: 'desc'
};

export default withStyles(styles)(TableSortLabel);