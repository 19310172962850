import React from 'react';

var SvgLink = function SvgLink(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("rect", {
                fill: "currentColor",
                transform: "rotate(-45 29.92 30.305)",
                x: -2.58,
                y: 18.805,
                width: 65,
                height: 23,
                rx: 11.5
            }),
            React.createElement("rect", {
                fill: "#FFF",
                opacity: 0.88,
                transform: "rotate(-45 29.92 30.305)",
                x: -2.58,
                y: 18.805,
                width: 65,
                height: 23,
                rx: 11.5
            }),
            React.createElement("path", {
                d: "M35.339 46.19l-9.263 9.245c-5.94 5.93-15.56 5.933-21.502 0-5.941-5.928-5.944-15.53 0-21.46l9.262-9.245c.135-.135.273-.268.413-.397.883-.818 2.315-.234 2.364.967.022.56.07 1.121.142 1.679.056.434-.087.87-.397 1.178L7.55 36.946a10.953 10.953 0 0 0 0 15.518c4.299 4.288 11.25 4.288 15.548 0l9.263-9.245.042-.042c4.246-4.281 4.227-11.215-.043-15.476a10.925 10.925 0 0 0-3.226-2.231 1.406 1.406 0 0 1-.835-1.362 7.248 7.248 0 0 1 .33-1.782 1.393 1.393 0 0 1 1.863-.85 15.115 15.115 0 0 1 4.846 3.254c5.928 5.917 5.927 15.545 0 21.46zM24.778 35.27a15.115 15.115 0 0 0 4.846 3.253 1.394 1.394 0 0 0 1.862-.85 7.248 7.248 0 0 0 .33-1.78 1.406 1.406 0 0 0-.834-1.363 10.92 10.92 0 0 1-3.226-2.23c-4.27-4.262-4.29-11.196-.043-15.477l.042-.042 9.263-9.245c4.298-4.288 11.25-4.289 15.548 0a10.953 10.953 0 0 1 0 15.518c-.018.019-6.879 6.866-8.807 8.788-.31.31-.453.745-.397 1.179.072.557.12 1.118.142 1.68.049 1.2 1.48 1.784 2.364.966.14-.13.278-.262.413-.397l9.262-9.245c5.944-5.93 5.941-15.533 0-21.46-5.942-5.933-15.563-5.93-21.502 0l-9.263 9.244c-5.927 5.916-5.928 15.544 0 21.461z",
                fill: "currentColor"
            })
        )
    );
};

export default SvgLink;