import React from 'react';
import EdsGrid from '@hedtech/react-design-system/core/es/Grid';
const defaultProps = {
    component: 'div',
    container: false,
    item: false
};
const defaultPropsContainer = Object.assign({ alignContent: 'stretch', alignItems: 'stretch', direction: 'row', justify: 'flex-start', wrap: 'wrap' }, defaultProps);
const defaultPropsItem = Object.assign({ lg: false, md: false, sm: false, xl: false, xs: false, zeroMinWidth: false }, defaultProps);
const Grid = (props) => {
    const { container, item } = props;
    let defaultPropsGrid = {};
    if (container) {
        defaultPropsGrid = defaultPropsContainer;
    }
    else if (item) {
        defaultPropsGrid = defaultPropsItem;
    }
    else {
        defaultPropsGrid = defaultProps;
    }
    return (React.createElement(EdsGrid, Object.assign({}, defaultPropsGrid, props)));
};
export default Grid;
