'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.styles = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Checkbox = require('@material-ui/core/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _CheckBoxOutlineBlank = require('@material-ui/core/internal/svg-icons/CheckBoxOutlineBlank');

var _CheckBoxOutlineBlank2 = _interopRequireDefault(_CheckBoxOutlineBlank);

var _CheckBox = require('@material-ui/core/internal/svg-icons/CheckBox');

var _CheckBox2 = _interopRequireDefault(_CheckBox);

var _IndeterminateCheckBox = require('@material-ui/core/internal/svg-icons/IndeterminateCheckBox');

var _IndeterminateCheckBox2 = _interopRequireDefault(_IndeterminateCheckBox);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = exports.styles = function styles(theme) {
    return {
        root: {
            color: theme.palette.grey['500'],
            margin: _tokens.spacingSmall,
            padding: 0,
            width: _tokens.sizingMedium // size of the icon
        },
        focused: {
            borderRadius: _tokens.borderRadiusSmall,
            boxShadow: 'inset 0 0 0 2px ' + _tokens.colorBrandSecondary,
            color: theme.palette.grey['500'],
            margin: _tokens.spacingSmall,
            padding: 0,
            width: _tokens.sizingMedium // size of the icon
        },
        checked: {},
        disabled: {},
        colorPrimary: {
            '&$checked': {
                color: theme.palette.ctaColor.base
            },
            '&$disabled': {
                color: theme.palette.disabled.background
            }
        },
        size: {
            width: _tokens.sizingMedium,
            height: _tokens.sizingMedium
        }
    };
};

/**
 * Use `Checkbox` to allow a user to select one or more values from a small set of options.
 * @done true
 * @updated false
 * @versionAdded v0.0.5
 * @examples
 *  LabeledCheckboxes
 *  UnlabeledCheckboxes
 */

var Checkbox = function (_React$Component) {
    _inherits(Checkbox, _React$Component);

    function Checkbox(props) {
        _classCallCheck(this, Checkbox);

        var _this = _possibleConstructorReturn(this, (Checkbox.__proto__ || Object.getPrototypeOf(Checkbox)).call(this, props));

        _this.state = {
            isFocused: false
        };
        _this.handleFocus = _this.handleFocus.bind(_this);
        _this.handleBlur = _this.handleBlur.bind(_this);
        return _this;
    }

    _createClass(Checkbox, [{
        key: 'handleFocus',
        value: function handleFocus(e) {
            if (this.props.onFocus) {
                this.props.onFocus(e);
            }
            this.setState(function (prevState) {
                return {
                    isFocused: true
                };
            });
        }
    }, {
        key: 'handleBlur',
        value: function handleBlur(e) {
            if (this.props.onBlur) {
                this.props.onBlur(e);
            }
            this.setState(function (prevState) {
                return {
                    isFocused: false
                };
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var isFocused = this.state.isFocused;

            var _props = this.props,
                classes = _props.classes,
                inputPropsTemp = _props.inputProps,
                other = _objectWithoutProperties(_props, ['classes', 'inputProps']);

            var inputProps = Object.assign({}, inputPropsTemp);

            // Updating aria-checked attribute when indeterminate flag is true.
            if (this.props.indeterminate) {
                inputProps['aria-checked'] = 'mixed';
            }

            return _react2.default.createElement(_Checkbox2.default, Object.assign({
                classes: {
                    root: isFocused ? classes.focused : classes.root,
                    checked: classes.checked,
                    disabled: classes.disabled,
                    colorPrimary: classes.colorPrimary
                },
                onFocus: this.handleFocus,
                onBlur: this.handleBlur,
                disableRipple: true,
                color: 'primary',
                inputProps: inputProps
            }, other));
        }
    }]);

    return Checkbox;
}(_react2.default.Component);

;

Checkbox.muiName = 'Checkbox';

Checkbox.propTypes = {
    /**
     * If `true`, the component is checked.
     */
    checked: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.string]),
    /**
     * The icon to display when the component is checked.
     */
    checkedIcon: _propTypes2.default.node,
    /**
     * Useful to extend the style applied to components.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * If `true`, the switch will be disabled.
     */
    disabled: _propTypes2.default.bool,
    /**
     * If `true`, the ripple effect will be disabled.
     */
    disableRipple: _propTypes2.default.bool,
    /**
     * The icon to display when the component is unchecked.
     */
    icon: _propTypes2.default.node,
    /**
     * The id of the `input` element.
     */
    id: _propTypes2.default.string,
    /**
     * If `true`, the component appears indeterminate.
     */
    indeterminate: _propTypes2.default.bool,
    /**
     * The icon to display when the component is indeterminate.
     */
    indeterminateIcon: _propTypes2.default.node,
    /**
     * Properties applied to the `input` element.
     */
    inputProps: _propTypes2.default.object,
    /**
     * Use that property to pass a ref callback to the native input component.
     */
    inputRef: _propTypes2.default.func,
    /**
     * Callback fired when the state is changed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.checked`.
     * @param {boolean} checked The `checked` value of the switch
     */
    onChange: _propTypes2.default.func,
    /**
     * @ignore
     */
    onBlur: _propTypes2.default.func,
    /**
     * @ignore
     */
    onFocus: _propTypes2.default.func,
    /**
     * The input component property `type`.
     */
    type: _propTypes2.default.string,
    /**
     * The value of the component.
     */
    value: _propTypes2.default.string
};

Checkbox.defaultProps = {
    checkedIcon: _react2.default.createElement(_CheckBox2.default, null),
    icon: _react2.default.createElement(_CheckBoxOutlineBlank2.default, null),
    indeterminate: false,
    indeterminateIcon: _react2.default.createElement(_IndeterminateCheckBox2.default, null)
};

exports.default = (0, _styles2.default)(styles)(Checkbox);