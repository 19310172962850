const hdnBaseAddress = document.getElementById('hdnBaseAddress');
const webUrl = hdnBaseAddress ? hdnBaseAddress.value : '';
const siteConfigurationMissing = document.getElementById('siteConfigurationMissing');
const Constants = {
    defaultActionColor: 'blue',
    defaultThemeColor: '#783084',
    faviconCDN: 'https://cdn.elluciancloud.com/assets/EDS2/0.0.8/favicon/favicon.ico',
    headersRequestsJson: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    imagesCDN: 'https://cdn.elluciancloud.com/assets/EDS2/3.8.0/img',
    redirectToError403: () => {
        if (hdnBaseAddress) {
            window.location.assign(`${webUrl}/Errors/Error403`);
        }
    },
    redirectToError500: () => {
        if (hdnBaseAddress) {
            window.location.assign(`${webUrl}/Errors/Error500`);
        }
    },
    redirectToLogin: () => {
        if (hdnBaseAddress) {
            window.location.assign(`${webUrl}/Home/Login`);
        }
    },
    siteConfigurationMissing: siteConfigurationMissing ? (siteConfigurationMissing.value === 'true') : false,
    webUrl: webUrl
};
export default Constants;
