'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _core = require('@material-ui/core');

var _constants = require('./internal/constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            borderColor: theme.palette.ctaColor.base
        }
    };
};

/**
 * A single step in a stepper
 */
var Step = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        root: classes.root
    };

    return (
        /* These marker class names will be used by its parent component StepProgress */
        _react2.default.createElement(_core.Step, Object.assign({}, rest, {
            className: _constants.STEP_PROGRESS_STEP_CLASSNAME,
            classes: classOverrides,
            ref: ref
        }))
    );
});

Step.muiName = 'Step';
Step.displayName = 'Step';

Step.propTypes = {
    /**
     * Sets the step as active. Is passed to child components.
     */
    active: _propTypes2.default.bool,
    /**
     * Should be `Step` sub-components such as `StepLabel`, `StepContent`.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: _propTypes2.default.bool,
    /**
     * Mark the step as disabled, will also disable the button if
     * `StepButton` is a child of `Step`. Is passed to child components.
     */
    disabled: _propTypes2.default.bool
};

// NOTE: we are very intentionally NOT providing defaults for these props; if any
// value is assigned to the active, completed, or disabled props, those values will
// supercede the directives of the parent StepProgress; ie, if the stepper tries to
// set a Step to active, any defaults we declare here will override those settings

exports.default = (0, _styles2.default)(styles)(Step);