'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        menuRoot: {
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[300]
        },
        menuItemRoot: {
            fontSize: _tokens.fontSizeDefault,
            borderBottom: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[300],
            paddingTop: '.75rem',
            paddingBottom: '.75rem',
            '&:focus, &:hover': {
                backgroundColor: theme.palette.grey[250],
                color: theme.palette.grey[600]
            },
            '&:last-child': {
                borderBottom: 'none'
            },
            '&$menuItemSelected': {
                backgroundColor: theme.palette.ctaColor.base,
                color: theme.palette.grey[100],
                '&:focus': {
                    backgroundColor: theme.palette.ctaColor.hover,
                    color: theme.palette.grey[100]
                },
                '&:hover': {
                    backgroundColor: theme.palette.ctaColor.hover,
                    color: theme.palette.grey[100]
                }
            }
        },
        menuItemSelected: {
            backgroundColor: theme.palette.ctaColor.base,
            color: theme.palette.grey[100],
            '&:focus:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                color: theme.palette.grey[100]
            },
            '&:focus': {
                backgroundColor: theme.palette.ctaColor.hover,
                color: theme.palette.grey[100]
            },
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                color: theme.palette.grey[100]
            }
        }
    };
};

var SelectionMenuItem = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        children = props.children,
        disabled = props.disabled,
        rest = _objectWithoutProperties(props, ['classes', 'children', 'disabled']);

    return _react2.default.createElement(
        _MenuItem2.default,
        Object.assign({
            classes: {
                root: classes.menuItemRoot,
                selected: classes.menuItemSelected
            },
            disabled: disabled,
            'aria-disabled': disabled ? true : false,
            ref: ref
        }, rest),
        children
    );
});

// necessary to add an explicit display name, since docgen can't seem to
// extract one from a forwardRef'd component
SelectionMenuItem.displayName = 'SelectionMenuItem';

SelectionMenuItem.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * The text of the item.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * If `true`, the item will be disabled.
     */
    disabled: _propTypes2.default.bool
};

exports.default = (0, _styles2.default)(styles, { name: 'SelectionMenuItem' })(SelectionMenuItem);