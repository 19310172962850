function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiCardHeader } from '@material-ui/core/CardHeader';
import { CardContext } from './Card';
import { spacingLarge, spacingMedium, spacingSmall, spacingXSmall, spacingXSmall2 } from '../styles/tokens';
import classNames from 'classnames';

var styles = function styles(theme) {
    return {
        root: {},
        standard: {
            padding: spacingLarge + ' ' + spacingLarge + ' ' + spacingSmall
        },
        dense: {
            padding: spacingMedium + ' ' + spacingMedium + ' ' + spacingXSmall2
        },
        none: {
            padding: 0
        },
        action: {
            marginRight: 0,
            marginTop: 0
        },
        responsive: _defineProperty({}, theme.breakpoints.only('xs'), {
            padding: spacingSmall + ' ' + spacingSmall + ' ' + spacingXSmall
        })
    };
};

/**
 * CardHeader component for displaying header on a Card component
 */
var CardHeader = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var _useContext = useContext(CardContext),
        padding = _useContext.padding,
        responsive = _useContext.responsive;

    var classOverrides = {
        root: classNames((_classNames = {}, _defineProperty(_classNames, classes.standard, padding === 'standard'), _defineProperty(_classNames, classes.dense, padding === 'dense'), _defineProperty(_classNames, classes.none, padding === 'none'), _defineProperty(_classNames, classes.responsive, responsive && ['standard', 'dense'].indexOf(padding) !== -1), _classNames), classes.root),
        action: classes.action
    };

    return React.createElement(MuiCardHeader, Object.assign({
        classes: classOverrides,
        titleTypographyProps: {
            variant: 'h2'
        }
    }, rest, {
        ref: ref
    }));
});

CardHeader.muiName = 'CardHeader';
CardHeader.displayName = 'CardHeader';

CardHeader.propTypes = {
    /**
     * The action to display in the card header.
     */
    action: PropTypes.node,
    /**
     * The Avatar for the Card Header.
     */
    avatar: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * If true, the children won't be wrapped by a Typography component.
     * This can be useful to render an alternative Typography variant by
     * wrapping the title text, and optional subheader text with the
     * Typography component.
     */
    disableTypography: PropTypes.bool,
    /**
     * The content of the component.
     */
    subheader: PropTypes.node,
    /**
     * These props will be forwarded to the subheader (as
     * long as disableTypography is not true).
     */
    subheaderTypographyProps: PropTypes.object,
    /**
     * The content of the Card Title.
     */
    title: PropTypes.node,
    /**
     * These props will be forwarded to the title (as
     * long as disableTypography is not `true`).
     */
    titleTypographyProps: PropTypes.object
};

// If default props are needed, they must be set here
CardHeader.defaultProps = {
    component: 'div',
    disableTypography: false
};

export default withStyles(styles)(CardHeader);