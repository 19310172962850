import config from './edsconfig.js';

var cdnBaseUrlWithVersion = '' + config.cdnBaseUrl + config.appVersion;
var getLargeIllustrationsUrl = function getLargeIllustrationsUrl(assetName) {
    return cdnBaseUrlWithVersion + '/img/illustrations/large/' + assetName;
};

var getFontsUrl = function getFontsUrl() {
    return cdnBaseUrlWithVersion + '/fonts/fonts.css';
};

var getBackgroundImageUrl = function getBackgroundImageUrl(assetName) {
    return cdnBaseUrlWithVersion + '/img/bg/' + assetName;
};

var getFavIconUrl = function getFavIconUrl(assetName) {
    return assetName ? cdnBaseUrlWithVersion + '/img/favicons/' + assetName : cdnBaseUrlWithVersion + '/img/favicons/favicon.svg';
};

var cdn = {
    getLargeIllustrationsUrl: getLargeIllustrationsUrl,
    getFontsUrl: getFontsUrl,
    getBackgroundImageUrl: getBackgroundImageUrl,
    getFavIconUrl: getFavIconUrl
};

export default cdn;