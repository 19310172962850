'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _ExpansionPanelSummary = require('@material-ui/core/ExpansionPanelSummary');

var _ExpansionPanelSummary2 = _interopRequireDefault(_ExpansionPanelSummary);

var _ChevronDown = require('@eui/ds-icons/lib/ChevronDown');

var _ChevronDown2 = _interopRequireDefault(_ChevronDown);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            color: _tokens.colorTextPrimary,
            outline: 'none',
            '&$focused': {
                backgroundColor: 'transparent'
            },
            '&$disabled': {
                opacity: 1
            }
        },
        disabled: {
            color: _tokens.colorBrandNeutral400,
            backgroundColor: _tokens.colorBrandNeutral100
        },
        focused: {
            boxShadow: _tokens.boxShadowFocusInset,
            outline: 'none'
        },
        iconButtonRoot: {
            fontSize: _tokens.fontSizeLarge,
            '&$disabled': {
                color: _tokens.colorBrandNeutral400
            }
        },
        iconLeftPosition: {
            top: '0.25rem',
            left: '0.5625rem',
            right: 'auto',
            position: 'absolute'
        },
        iconLeftPositionExpanded: {
            '& $iconLeftPosition': {
                top: '0.75rem'
            }
        }
    };
};

/**
 * A component that....
 */
var ExpansionPanelSummary = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        leftIconDisplay = props.leftIconDisplay,
        expandIcon = props.expandIcon,
        expandIconProps = props.expandIconProps,
        rest = _objectWithoutProperties(props, ['classes', 'leftIconDisplay', 'expandIcon', 'expandIconProps']);

    var classOverrides = {
        root: classes.root,
        disabled: classes.disabled,
        focused: classes.focused
    };

    if (leftIconDisplay) {
        classOverrides.expandIcon = (0, _classnames2.default)(classOverrides.expandIcon, classes.iconLeftPosition, 'eds-expansion-panel-icon-left-position');
        classOverrides.expanded = classes.iconLeftPositionExpanded;
    }

    return _react2.default.createElement(_ExpansionPanelSummary2.default, Object.assign({
        expandIcon: expandIcon ? expandIcon : _react2.default.createElement(_ChevronDown2.default, Object.assign({ fill: props.disabled ? _tokens.colorBrandNeutral400 : _tokens.colorBrandNeutral500 }, expandIconProps)),
        IconButtonProps: { classes: { root: classes.iconButtonRoot } },
        classes: classOverrides,
        ref: ref
    }, rest));
});

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';
ExpansionPanelSummary.displayName = 'ExpansionPanelSummary';

ExpansionPanelSummary.propTypes = {
    /**
     * The content of the expansion panel summary.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * @ignore
     * If `true`, the summary will be displayed in a disabled state.
     */
    disabled: _propTypes2.default.bool,
    /**
     * @ignore
     * If `true`, expands the summary, otherwise collapse it.
     */
    expanded: _propTypes2.default.bool,
    /**
     * **DEPRECATED** This is now handled by the component.
     *
     * The icon to display as the expand indicator.
     */
    expandIcon: _propTypes2.default.node,
    /**
     * Properties applied to the icon element.
     */
    expandIconProps: _propTypes2.default.object,
    /**
     * Displays the expandIcon on the left side of the ExpansionPanel.
     */
    leftIconDisplay: _propTypes2.default.bool,
    /**
     * @ignore
     */
    onChange: _propTypes2.default.func,
    /**
     * @ignore
     */
    onClick: _propTypes2.default.func
};

ExpansionPanelSummary.defaultProps = {
    leftIconDisplay: false
};

exports.default = (0, _styles2.default)(styles)(ExpansionPanelSummary);