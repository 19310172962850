'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _RadioGroup = require('@material-ui/core/RadioGroup');

var _RadioGroup2 = _interopRequireDefault(_RadioGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 *  A component for grouping multiple radios
 */
var RadioGroup = _react2.default.forwardRef(function (props, ref) {
  return _react2.default.createElement(_RadioGroup2.default, Object.assign({}, props, { ref: ref }));
});
// TODO: Update this after the update of material-ui itself


RadioGroup.muiName = 'RadioGroup';
RadioGroup.displayName = 'RadioGroup';

RadioGroup.propTypes = {
  /**
   * The content of the component.
   */
  children: _propTypes2.default.node,
  /**
   * The name used to reference the value of the control.
   */
  name: _propTypes2.default.string,
  /**
   * @ignore
   */
  onBlur: _propTypes2.default.func,
  /**
   * Callback fired when a radio button is selected.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`.
   * @param {string} value The `value` of the selected radio button
   */
  onChange: _propTypes2.default.func,
  /**
   * @ignore
   */
  onKeyDown: _propTypes2.default.func,
  /**
   * Value of the selected radio button.
   */
  value: _propTypes2.default.string
};

exports.default = RadioGroup;