function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import HeaderBar from './../HeaderBar';
import Logo from './../Logo';
import { withEDSContext } from '../EDSContext/EDSContext';
import { withStyles } from '../styles';

import MaintenancePage from './internal/MaintenancePage';
import BadRequest400Page from './internal/BadRequest400Page';
import Unauthorized401Page from './internal/Unauthorized401Page';
import Forbidden403Page from './internal/Forbidden403Page';
import PageNotFound404Page from './internal/PageNotFound404Page';
import MethodNotAllowed405Page from './internal/MethodNotAllowed405Page';
import InternalServerError500Page from './internal/InternalServerError500Page';
import ServiceUnavailable503Page from './internal/ServiceUnavailable503Page';

import { spacingXSmall, sizingXxLarge1 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            height: 'calc(100vh)',
            backgroundColor: theme.palette.grey[200],
            '& .headerbar-logo': {
                maxWidth: spacingXSmall
            }
        },
        noHeader: {
            height: 'calc(100vh - ' + sizingXxLarge1 + ')'
        },
        errorTemplate: {
            display: 'flex',
            justifyContent: 'center'
        }
    };
};

export var ERROR_PAGES = {
    maintenance: MaintenancePage,
    badRequest: BadRequest400Page,
    unauthorized: Unauthorized401Page,
    forbidden: Forbidden403Page,
    pageNotFound: PageNotFound404Page,
    methodNotAllowed: MethodNotAllowed405Page,
    internalServerError: InternalServerError500Page,
    serviceUnavailable: ServiceUnavailable503Page,
    '400': BadRequest400Page,
    '401': Unauthorized401Page,
    '403': Forbidden403Page,
    '404': PageNotFound404Page,
    '405': MethodNotAllowed405Page,
    '500': InternalServerError500Page,
    '503': ServiceUnavailable503Page
};

/**
 * A template for creating error/maintenance pages
 * @done true
 * @updated false
 * @versionAdded v3.0.0
 * @template true
 * @examples
 *  ErrorPageExample
 *  ErrorPageCustomizationExamples
 */
var ErrorPage = React.forwardRef(function (props, ref) {
    var className = props.className,
        classes = props.classes,
        noHeader = props.noHeader,
        logo = props.logo;

    var ErrorPageTemplate = ERROR_PAGES[props.errorType];

    return ErrorPageTemplate ? React.createElement(
        'div',
        { ref: ref, className: classNames(className, classes.root, _defineProperty({}, classes.noHeader, noHeader)) },
        !noHeader && React.createElement(HeaderBar, { logo: logo }),
        React.createElement(
            'div',
            { className: classes.errorTemplate },
            React.createElement(ErrorPageTemplate, props)
        )
    ) : null;
});

ErrorPage.muiName = 'ErrorPage';
ErrorPage.displayName = 'ErrorPage';

ErrorPage.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Custom error message
     */
    message: PropTypes.string,
    /**
     * Link for correction of action. It can just be a URL or a Link, NavLink or any other router component.
     * For example:
     * If error is 401, then provide URL to sign in if you just want redirect the page.
     * If you want to just use NavLink then pass the NavLink component.
     */
    action: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    /**
     * Error codes. You pass error codes directly or use the meaningful names.
     * default - Maintenance page
     * 400 - Bad Request
     * 401 - Unauthorized
     * 403 - Forbidden
     * 404 - Page Not Found
     * 405 - Method Not Allowed
     * 500 - Internal Server Error
     * 503 - Service Unavailable
     */
    errorType: PropTypes.oneOf(['maintenance', // Maintenance page
    'badRequest', // 400
    'unauthorized', // 401
    'forbidden', // 403
    'pageNotFound', // 404
    'methodNotAllowed', // 405
    'internalServerError', // 500
    'serviceUnavailable', //503
    '400', '401', '403', '404', '405', '500', '503']).isRequired,
    /**
     * Use this prop to pass cutom url to override the default image for error pages.
     */
    imageSrc: PropTypes.string,
    /**
     * Set this prop if you don't want to render the header.
     */
    noHeader: PropTypes.bool,
    /**
     * The cutom logo to use in the header bar.
     */
    logo: PropTypes.node
};

ErrorPage.defaultProps = {
    noHeader: false,
    logo: React.createElement(Logo, null)
};

export default withEDSContext(withStyles(styles)(ErrorPage));