'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _core = require('@material-ui/core');

var _Warning = require('@eui/ds-icons/lib/Warning');

var _Warning2 = _interopRequireDefault(_Warning);

var _tokens = require('../styles/tokens');

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _constants = require('./internal/constants.js');

var _StepEdit = require('./internal/StepEdit');

var _StepEdit2 = _interopRequireDefault(_StepEdit);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        labelRoot: {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.palette.grey[600]
        },
        labelError: {
            color: theme.palette.status.error.fill
        },
        stepIconRoot: {
            color: theme.palette.grey[100],
            borderRadius: '100%',
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.ctaColor.base,
            '&$stepIconActive': {
                border: 'none',
                color: theme.palette.ctaColor.base,
                '&> text': {
                    color: theme.palette.grey[100],
                    fill: theme.palette.grey[100]
                }
            }
        },
        stepIconText: {
            fill: theme.palette.ctaColor.base
        },
        stepIconActive: {
            color: theme.palette.ctaColor.base
        },
        stepIconCustomRoot: {
            border: 'none',
            width: _tokens.sizingMedium,
            height: _tokens.sizingMedium,
            verticalAlign: 'middle'
        },
        stepIconError: {
            color: theme.palette.status.error.fill,
            fill: theme.palette.status.error.fill
        },
        stepIconCompleted: {
            color: theme.palette.ctaColor.base,
            fill: theme.palette.ctaColor.base
        },
        stepIconDisabled: {
            '& svg': {
                border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
                '&> text': {
                    color: theme.palette.grey[400],
                    fill: theme.palette.grey[400]
                }
            }
        },
        stepLabelDisabled: {
            color: theme.palette.grey[400]
        }
    };
};

/**
 * A component that displays progress through numbered steps.
 */
var StepLabel = _react2.default.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        children = props.children,
        error = props.error,
        completed = props.completed,
        optional = props.optional,
        disabled = props.disabled,
        StepIconProps = props.StepIconProps,
        rest = _objectWithoutProperties(props, ['classes', 'children', 'error', 'completed', 'optional', 'disabled', 'StepIconProps']);

    var defaultOverrides = {
        StepIconProps: Object.assign({
            classes: {
                root: classes.stepIconRoot,
                text: classes.stepIconText,
                active: classes.stepIconActive,
                completed: classes.stepIconActive,
                error: classes.stepIconError
            }
        }, StepIconProps)
    };

    if (optional) {
        defaultOverrides.optional = _react2.default.createElement(
            _Typography2.default,
            {
                variant: 'body3',
                color: 'textSecondary',
                align: props.alternativeLabel ? 'center' : 'left'
            },
            optional
        );
    }

    if (error) {
        // Assign new error icon
        defaultOverrides.StepIconProps.icon = _react2.default.createElement(_Warning2.default, { className: (0, _classnames2.default)(classes.stepIconError, classes.stepIconCustomRoot) });

        // if error is a string make the optional prop print the error message
        if (error.length > 0) {
            /* These marker class names will be used by its parent component StepProgress */
            defaultOverrides.optional = _react2.default.createElement(
                _Typography2.default,
                {
                    variant: 'body3',
                    className: (0, _classnames2.default)(classes.labelError, _constants.STEP_LABEL_ERROR_CLASSNAME),
                    align: props.alternativeLabel ? 'center' : 'left'
                },
                error
            );
        }
    }

    if (props.active) {
        defaultOverrides.StepIconProps.icon = _react2.default.createElement(_StepEdit2.default, { className: classes.stepIconActive });
    }

    // NOTE: This icon is not in the @eui/ds-icons package
    // if (completed) {
    //     // Assign new check icon
    //     defaultOverrides.StepIconProps.icon = (
    //         <svg aria-hidden="true" className={classNames(classes.stepIconCustomRoot, classes.stepIconCompleted)} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>
    //     );
    // }

    return _react2.default.createElement(
        _core.StepLabel,
        Object.assign({}, defaultOverrides, {
            classes: {
                disabled: !error && disabled && classes.stepIconDisabled
            },
            disabled: disabled,
            completed: completed
        }, rest, {
            ref: ref
        }),
        _react2.default.createElement(
            'span',
            { className: (0, _classnames2.default)(classes.labelRoot, '' + _constants.STEP_LABEL_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, classes.labelError, error), _defineProperty(_classNames, _constants.STEP_LABEL_HAS_ERROR_CLASSNAME, error), _defineProperty(_classNames, classes.stepLabelDisabled, disabled && !error), _classNames)) },
            children
        )
    );
});

StepLabel.muiName = 'StepLabel';
StepLabel.displayName = 'StepLabel';

StepLabel.propTypes = {
    /**
     * @ignore
     * Sets the step as active. Is passed to child components.
     */
    active: _propTypes2.default.bool,
    /**
     * @ignore
     * Set internally by Stepper when it's supplied with the alternativeLabel property.
     */
    alternativeLabel: _propTypes2.default.bool,
    /**
     * In most cases will simply be a string containing a title for the label.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * @ignore
     * Mark the step as completed. Is passed to child components.
     */
    completed: _propTypes2.default.bool,
    /**
     * Mark the step as disabled, will also disable the button if
     * `StepLabelButton` is a child of `StepLabel`. Is passed to child components.
     */
    disabled: _propTypes2.default.bool,
    /**
     * Used to mark the step in an error state and provide an error message.
     */
    error: _propTypes2.default.any,
    /**
     * Override the default icon.
     */
    icon: _propTypes2.default.node,
    /**
     * The optional text to display.
     */
    optional: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.object])
};

StepLabel.defaultProps = {
    error: false
};

exports.default = (0, _styles2.default)(styles)(StepLabel);