'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.navigate = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }(); // Default imports


// Internal components and functions


// DS Icons imports


// Utilities


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Button = require('../Button');

var _Button2 = _interopRequireDefault(_Button);

var _DatePicker = require('../DatePicker');

var _DatePicker2 = _interopRequireDefault(_DatePicker);

var _Divider = require('../Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _Dropdown = require('../Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _DropdownItem = require('../Dropdown/DropdownItem');

var _DropdownItem2 = _interopRequireDefault(_DropdownItem);

var _FormControl = require('../FormControl');

var _FormControl2 = _interopRequireDefault(_FormControl);

var _Grid = require('../Grid');

var _Grid2 = _interopRequireDefault(_Grid);

var _IconButton = require('../IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _Calendar = require('./Calendar');

var _EDSContext = require('../EDSContext/EDSContext');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _tokens = require('../styles/tokens');

var _Calendar2 = require('@eui/ds-icons/lib/Calendar');

var _Calendar3 = _interopRequireDefault(_Calendar2);

var _ChevronLeft = require('@eui/ds-icons/lib/ChevronLeft');

var _ChevronLeft2 = _interopRequireDefault(_ChevronLeft);

var _ChevronRight = require('@eui/ds-icons/lib/ChevronRight');

var _ChevronRight2 = _interopRequireDefault(_ChevronRight);

var _Popper = require('@material-ui/core/Popper');

var _Popper2 = _interopRequireDefault(_Popper);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// create constants for navigation used in react-big-calendar
var navigate = exports.navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};

var styles = function styles(theme) {
    return {
        alignLabel: {
            alignSelf: 'center'
        },
        iconRoot: {
            height: _tokens.sizingSmall,
            width: _tokens.sizingSmall
        },
        toolbar: {
            minWidth: '6.25rem'
        },
        datePickerPosition: {
            display: 'flex'
        },
        gridMarginOverride: {
            margin: 0,
            width: _tokens.widthFluid
        },
        popperIndex: {
            zIndex: 5
        },
        todayButton: {
            flex: 1
        }
    };
};

var CalendarToolbar = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        localizer = props.localizer,
        label = props.label,
        edsContext = props.edsContext,
        onNavigate = props.onNavigate,
        onView = props.onView,
        views = props.views;

    // Initialize states using useState hook

    var _useState = (0, _react.useState)(null),
        _useState2 = _slicedToArray(_useState, 2),
        anchorEl = _useState2[0],
        setAnchorEl = _useState2[1];

    var _useState3 = (0, _react.useState)(false),
        _useState4 = _slicedToArray(_useState3, 2),
        focused = _useState4[0],
        setFocused = _useState4[1];

    var _useState5 = (0, _react.useState)(false),
        _useState6 = _slicedToArray(_useState5, 2),
        open = _useState6[0],
        setOpen = _useState6[1];

    var messages = localizer.messages;

    var _useContext = (0, _react.useContext)(_Calendar.CalendarContext),
        date = _useContext.date,
        setDate = _useContext.setDate,
        showViewNames = _useContext.showViewNames,
        selectedView = _useContext.selectedView,
        setSelectedView = _useContext.setSelectedView,
        width = _useContext.width;

    var handleClick = function handleClick(event) {
        var currentTarget = event.currentTarget;

        setAnchorEl(currentTarget);
        setOpen(true);
    };

    var handleClickAway = function handleClickAway() {
        setOpen(false);
    };

    var handleDateChange = function handleDateChange(selectedDate) {
        setOpen(false);
        setDate(selectedDate.toDate());
    };

    var handleFocusChange = function handleFocusChange() {
        setFocused(true);
    };

    var handleNavigation = function handleNavigation(action) {
        onNavigate(action);
    };

    var handleViewChange = function handleViewChange(event) {
        var value = event.target.value;

        onView(value);
        setSelectedView(value);
    };

    var sentenceCase = function sentenceCase(message) {
        return message.toLowerCase().split(' ').map(function (word, index) {
            return word.replace(word[0], index > 0 ? word[0].toLowerCase() : word[0].toUpperCase());
        }).join(' ');
    };

    var dropdownControl = views.length >= 1 && _react2.default.createElement(
        _Grid2.default,
        { className: 'rbc-btn-group', item: true },
        _react2.default.createElement(
            _FormControl2.default,
            { id: 'calendar-views', className: classes.toolbar },
            _react2.default.createElement(
                _Dropdown2.default,
                {
                    id: 'calendar-views-dropdown',
                    label: '',
                    onChange: handleViewChange,
                    value: selectedView,
                    size: 'small'
                },
                views.map(function (name) {
                    return _react2.default.createElement(_DropdownItem2.default, {
                        key: name,
                        label: sentenceCase(messages[name]),
                        value: name
                    });
                })
            )
        )
    );

    var datePickerControl = _react2.default.createElement(
        _Grid2.default,
        { item: true },
        _react2.default.createElement(
            _Grid2.default,
            {
                classes: {
                    'spacing-xs-2': classes.gridMarginOverride,
                    'spacing-xs-3': classes.gridMarginOverride
                },
                container: true,
                direction: 'row',
                justify: 'flex-end',
                spacing: 0
            },
            _react2.default.createElement(
                _Grid2.default,
                { item: true },
                _react2.default.createElement(
                    _IconButton2.default,
                    {
                        'aria-label': edsContext.formatMessage('component.CalendarToolBar.openDatePicker'),
                        color: 'gray',
                        onClick: handleClick,
                        title: edsContext.formatMessage('component.CalendarToolBar.openDatePicker'),
                        'aria-haspopup': 'true'
                    },
                    _react2.default.createElement(_Calendar3.default, null)
                ),
                _react2.default.createElement(
                    _Popper2.default,
                    {
                        anchorEl: anchorEl,
                        className: classes.popperIndex,
                        disablePortal: true,
                        open: open,
                        placement: 'bottom-end'
                    },
                    _react2.default.createElement(_DatePicker2.default, {
                        date: (0, _moment2.default)(date),
                        focused: focused,
                        id: 'CalendarToolbar__StandaloneDatePicker',
                        onDateChange: handleDateChange,
                        onFocusChange: handleFocusChange,
                        onOutsideClick: handleClickAway,
                        variant: 'standalone'
                    })
                )
            )
        )
    );

    var labelNavigation = _react2.default.createElement(
        _Grid2.default,
        { item: true },
        _react2.default.createElement(
            _Grid2.default,
            {
                classes: {
                    'spacing-xs-2': classes.gridMarginOverride,
                    'spacing-xs-3': classes.gridMarginOverride
                },
                container: true,
                direction: 'row',
                justify: 'flex-start',
                spacing: 0,
                wrap: 'nowrap'
            },
            _react2.default.createElement(
                _Grid2.default,
                { item: true },
                _react2.default.createElement(
                    _IconButton2.default,
                    {
                        color: 'gray',
                        onClick: function onClick() {
                            return handleNavigation(navigate.PREVIOUS);
                        },
                        'aria-label': edsContext.formatMessage('component.Calendar.previous'),
                        title: edsContext.formatMessage('component.Calendar.previous')
                    },
                    _react2.default.createElement(_ChevronLeft2.default, { className: classes.iconRoot })
                )
            ),
            _react2.default.createElement(
                _Grid2.default,
                { className: classes.alignLabel, item: true },
                _react2.default.createElement(
                    _Typography2.default,
                    { variant: 'h4' },
                    label
                )
            ),
            _react2.default.createElement(
                _Grid2.default,
                { item: true },
                _react2.default.createElement(
                    _IconButton2.default,
                    {
                        color: 'gray',
                        onClick: function onClick() {
                            return handleNavigation(navigate.NEXT);
                        },
                        'aria-label': edsContext.formatMessage('component.Calendar.next'),
                        title: edsContext.formatMessage('component.Calendar.next')
                    },
                    _react2.default.createElement(_ChevronRight2.default, { className: classes.iconRoot })
                )
            )
        )
    );

    var todayButton = _react2.default.createElement(
        _Grid2.default,
        {
            className: (0, _classnames2.default)(classes.alignLabel, classes.todayButton),
            item: true
        },
        _react2.default.createElement(
            _Button2.default,
            {
                onClick: function onClick() {
                    return handleNavigation(navigate.TODAY);
                },
                variant: 'text'
            },
            messages.today
        )
    );

    return _react2.default.createElement(
        _Grid2.default,
        {
            classes: {
                'spacing-xs-2': classes.gridMarginOverride,
                'spacing-xs-3': classes.gridMarginOverride
            },
            className: 'calendar-toolbar',
            container: true,
            direction: 'column',
            spacing: 0,
            wrap: 'nowrap',
            ref: ref
        },
        _react2.default.createElement(
            _Grid2.default,
            { item: true },
            _react2.default.createElement(
                _Grid2.default,
                {
                    classes: {
                        'spacing-xs-2': classes.gridMarginOverride,
                        'spacing-xs-3': classes.gridMarginOverride
                    },
                    container: true,
                    direction: 'row',
                    justify: showViewNames ? 'space-between' : 'flex-end',
                    spacing: 2
                },
                todayButton,
                showViewNames && width !== 'xs' && dropdownControl,
                datePickerControl
            )
        ),
        width === 'xs' && _react2.default.createElement(
            _Grid2.default,
            { item: true },
            _react2.default.createElement(
                _Grid2.default,
                {
                    classes: {
                        'spacing-xs-2': classes.gridMarginOverride,
                        'spacing-xs-3': classes.gridMarginOverride
                    },
                    container: true,
                    justify: 'center'
                },
                showViewNames && width === 'xs' && dropdownControl
            )
        ),
        _react2.default.createElement(
            _Grid2.default,
            { item: true },
            _react2.default.createElement(_Divider2.default, {
                className: classes.divider,
                style: { margin: '0 1rem' },
                variant: 'middle'
            })
        ),
        _react2.default.createElement(
            _Grid2.default,
            { item: true },
            _react2.default.createElement(
                _Grid2.default,
                {
                    classes: {
                        'spacing-xs-2': classes.gridMarginOverride,
                        'spacing-xs-3': classes.gridMarginOverride
                    },
                    container: true,
                    justify: 'center',
                    spacing: 2
                },
                labelNavigation
            )
        )
    );
});

CalendarToolbar.propTypes = {
    /**
     * JSS classes object.
     */
    classes: _propTypes2.default.object,
    /**
     * The active view to display.
     */
    view: _propTypes2.default.string.isRequired,
    /**
     * An array of views that is sent by react-big-calendar.
     */
    views: _propTypes2.default.arrayOf(_propTypes2.default.string).isRequired,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,
    /**
     * Callback function for updating the current Date based on the selection
     * from DatePicker
     */
    onDateChange: _propTypes2.default.func,
    /**
     * The node to render the date range label.
     */
    label: _propTypes2.default.node.isRequired,
    /**
     * Internal localizer function used by react-big-calendar.
     */
    localizer: _propTypes2.default.object,
    /**
     * @ignore
     */
    onNavigate: _propTypes2.default.func.isRequired,
    /**
     * @ignore
     */
    onView: _propTypes2.default.func.isRequired
};

CalendarToolbar.displayName = 'CalendarToolbar';

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(CalendarToolbar));