'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _ListItemAvatar = require('@material-ui/core/ListItemAvatar');

var _ListItemAvatar2 = _interopRequireDefault(_ListItemAvatar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        // Necessary style overrides are set here
        root: {}
    };
};

/**
 * A simple wrapper component to apply avavtar styles to ListItems.
 */
var ListItemAvatar = function ListItemAvatar(props) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        // See Material UI documentation for available CSS classes can be passed through to override
        root: classes.root
    };

    return _react2.default.createElement(_ListItemAvatar2.default, Object.assign({}, rest, {
        classes: classOverrides
    }));
};

ListItemAvatar.muiName = 'ListItemAvatar';

ListItemAvatar.propTypes = {
    /**
     * The content of the component – normally `Avatar`.
     */
    children: _propTypes2.default.element.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object
};

// If default props are needed, they must be set here
// ListItemAvatar.defaultProps = { };

exports.default = (0, _styles2.default)(styles)(ListItemAvatar);