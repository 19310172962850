'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.icons = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('./Icon.css');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

/**
 * Ellucian-provided icons: grid-view, mega-menu, platform-component
 */
var icons = exports.icons = ['add', 'address-card', 'align-center', 'align-justify', 'align-left', 'align-right', 'applications-alt', 'applications', 'approve', 'arrow-double-left', 'arrow-double-right', 'arrow-down', 'arrow-end-left', 'arrow-end-right', 'arrow-left', 'arrow-right', 'arrow-up', 'bar-chart', 'bold', 'bookmark-solid', 'bookmark', 'calculator', 'calendar-add', 'calendar-check', 'calendar-delete', 'calendar-grid', 'calendar-minus', 'calendar', 'caret-circle-right', 'cart', 'check-circle', 'check-feedback', 'check', 'chevron-down', 'chevron-left', 'chevron-right', 'chevron-up', 'circle', 'clipboard-check', 'clipboard-list', 'clock', 'close', 'cloud', 'code-branch', 'color', 'compass', 'completion', 'configuration', 'copy', 'course', 'daily-work', 'dashboard', 'deny', 'download', 'drag', 'edit', 'email', 'employment', 'error', 'external-link', 'facebook', 'favorite', 'file-export', 'file-image', 'file-text', 'filter', 'finances', 'flip', 'folder-open', 'folder', 'forward', 'globe', 'graduation', 'grid-view', 'help', 'hide', 'history', 'home', 'hour-glass', 'id', 'image', 'indent', 'info', 'italic', 'label', 'laptop', 'lightbulb', 'line-chart', 'line-columns', 'link', 'list-ol', 'list-view', 'location', 'lock', 'menu', 'mobile', 'more-horizontal', 'more-vertical', 'mouse-pointer', 'move', 'note', 'notification', 'partial', 'phone', 'pie-chart', 'pin', 'platform-components', 'plus-circle', 'print', 'privacy', 'rating', 'refresh', 'reply', 'requirements', 'save', 'search', 'send', 'settings', 'share', 'show', 'sign-out', 'sort', 'spinner', 'stopwatch', 'strikethrough', 'subtract', 'text', 'trash', 'twitter', 'underline', 'undo', 'unlock', 'upload', 'user-plus', 'user', 'warning'];

/**
 * Display an icon by name.
 * @param {string} name
 */
var Icon = _react2.default.forwardRef(function (props, ref) {
    var large = props.large,
        spin = props.spin,
        name = props.name,
        rest = _objectWithoutProperties(props, ['large', 'spin', 'name']);

    var iconClasses = (0, _classnames2.default)('ds-icon', 'ds-' + name, {
        'ds-icon--large': large,
        'ds-icon--spin': spin
    }, props.className);

    return _react2.default.createElement('svg', Object.assign({ ref: ref }, rest, { className: iconClasses,
        dangerouslySetInnerHTML: { __html: '<use xlink:href="#ds-icon-' + name + '"></use>' } }));
});

Icon.propTypes = {
    /**
     * The identifier for which icon to display
     */
    name: _propTypes2.default.string.isRequired,
    /**
     * Increases to larger size if {true}
     */
    large: _propTypes2.default.bool,
    /**
     * Causes the icon to spin for loaders, gear, etc if {true}
     */
    spin: _propTypes2.default.bool,
    /**
     * Add additional classname to style icon
     */
    className: _propTypes2.default.string
};

Icon.defaultProps = {
    large: false,
    spin: false
};

exports.default = Icon;