import React from 'react';

var SvgBookmark = function SvgBookmark(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                d: "M59.1602083,28.7404167 L48.1510417,12.2264583 C47.2236527,10.8355052 45.662495,10 43.9907292,10 L16.0092708,10 C14.3375279,10 12.7763922,10.8354932 11.8490625,12.2264583 L0.839791667,28.7404167 C0.292195871,29.5617153 0,30.5267409 0,31.5138542 L0,45 C0,47.7614583 2.23854167,50 5,50 L55,50 C57.7614583,50 60,47.7614583 60,45 L60,31.5138542 C60,30.5267409 59.7078041,29.5617153 59.1602083,28.7404167 Z M16.90125,16.6666667 L43.0988542,16.6666667 L51.9877083,30 L39.1666667,30 L35.8333333,36.6666667 L24.1666667,36.6666667 L20.8333333,30 L8.01229167,30 L16.90125,16.6666667 Z",
                id: "Theme",
                fill: "currentColor"
            }),
            React.createElement("path", {
                d: "M59.1602083,28.7404167 L48.1510417,12.2264583 C47.2236527,10.8355052 45.662495,10 43.9907292,10 L16.0092708,10 C14.3375279,10 12.7763922,10.8354932 11.8490625,12.2264583 L0.839791667,28.7404167 C0.292195871,29.5617153 0,30.5267409 0,31.5138542 L0,45 C0,47.7614583 2.23854167,50 5,50 L55,50 C57.7614583,50 60,47.7614583 60,45 L60,31.5138542 C60,30.5267409 59.7078041,29.5617153 59.1602083,28.7404167 Z M16.90125,16.6666667 L43.0988542,16.6666667 L51.9877083,30 L39.1666667,30 L35.8333333,36.6666667 L24.1666667,36.6666667 L20.8333333,30 L8.01229167,30 L16.90125,16.6666667 Z",
                id: "Lighten",
                fill: "#FFFFFF",
                opacity: "0.88"
            }),
            React.createElement("path", {
                d: "M59.0436458,27.0184375 L48.1642708,12.0591667 C47.2233622,10.7654293 45.7202287,10 44.1205208,10 L15.8794792,10 C14.2797958,10 12.7766863,10.7654192 11.8358333,12.0591667 L0.956354167,27.0184375 C0.334792685,27.8730147 0,28.9025585 0,29.9592708 L0,45 C0,47.7614583 2.23854167,50 5,50 L55,50 C57.7614583,50 60,47.7614583 60,45 L60,29.9592708 C60,28.9025585 59.6652073,27.8730147 59.0436458,27.0184375 Z M14.5315625,14.0196875 C14.845744,13.5890855 15.3464431,13.3341314 15.8794792,13.3333333 L44.120625,13.3333333 C44.6520833,13.3333333 45.1559375,13.5898958 45.4685417,14.0196875 L54.66625,26.6666667 L40.4398958,26.6666667 L37.1065625,33.3333333 L22.8934375,33.3333333 L19.5601042,26.6666667 L5.33375,26.6666667 L14.5315625,14.0196875 Z M56.6666667,31.6666667 L56.6666667,45 C56.6666667,45.9190625 55.9189583,46.6666667 55,46.6666667 L5,46.6666667 C4.08104167,46.6666667 3.33333333,45.9190625 3.33333333,45 L3.33333333,31.6666667 C3.33333333,30.7461458 4.07947917,30 5,30 L17.5,30 L20.8333333,36.6666667 L39.1666667,36.6666667 L42.5,30 L55,30 C55.9205208,30 56.6666667,30.7461458 56.6666667,31.6666667 Z",
                id: "Shape",
                fill: "currentColor"
            })
        )
    );
};

export default SvgBookmark;