'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Safari on iOs don't respect the `overflow: hidden` style
 * and hence we can scroll the background with backdrop or dropdown etc.
 * This class is an attempt to prevent that scrolling.
 * TODO: Keep validating this against the latest Safari on iOs,
 *   if the issue is fixed natively then get rid of this class
 *   and associated code in other files.
 */
var IOSBodyScroll = function () {
    function IOSBodyScroll() {
        var _this = this;

        _classCallCheck(this, IOSBodyScroll);

        this.preventBodyScroll = function (e) {
            if (!e.target.closest(_this.selector)) {
                e.preventDefault();
            }
        };

        this.html = document.querySelector('html');
        this.body = document.body;
        this.selector = '';
        this.isIOS = /(iPhone|iPad|iPod)/.test(navigator.platform);
    }

    _createClass(IOSBodyScroll, [{
        key: 'lock',
        value: function lock(boundarySelector) {
            this.selector = boundarySelector;
            // Setting the passive option on your touch and wheel event listeners
            // can improve scrolling performance. if passive option is set to `true` then
            // `e.preventDefault()` will have no effect. Hence setting it to false.
            // For more details go here https://medium.com/@devlucky/about-passive-event-listeners-224ff620e68c
            this.isIOS && document.addEventListener('touchmove', this.preventBodyScroll, { passive: false });
        }
    }, {
        key: 'unlock',
        value: function unlock() {
            this.isIOS && document.removeEventListener('touchmove', this.preventBodyScroll, { passive: false });
        }
    }]);

    return IOSBodyScroll;
}();

exports.default = new IOSBodyScroll();