'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _DialogActions = require('@material-ui/core/DialogActions');

var _DialogActions2 = _interopRequireDefault(_DialogActions);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            display: 'flex',
            flexDirection: 'column-reverse',
            margin: 0,
            padding: 0
        }, theme.breakpoints.up('sm'), {
            justifyContent: 'flex-end',
            flexDirection: 'row'
        }),

        spacing: {
            '& > button ': _defineProperty({
                margin: _tokens.spacingXSmall + ' 0',
                width: '100%'

            }, theme.breakpoints.up('sm'), {
                margin: '0 ' + _tokens.spacingXSmall,
                width: 'auto'
            })
        }

    };
};

/**
 * DialogActions are controls that live within a Dialog.
 */
var DialogActions = _react2.default.forwardRef(function (props, ref) {
    var children = props.children,
        classesProp = props.classes,
        rest = _objectWithoutProperties(props, ['children', 'classes']);

    var classes = {
        root: classesProp.root,
        spacing: classesProp.spacing
    };

    return _react2.default.createElement(
        _DialogActions2.default,
        Object.assign({ classes: classes }, rest, { ref: ref }),
        children
    );
});

DialogActions.muiName = 'DialogActions';
DialogActions.displayName = 'DialogActions';

DialogActions.propTypes = {
    /**
     * The content of the component.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * If `true`, the dialog actions do not have additional margin.
     */
    disableSpacing: _propTypes2.default.bool
};

DialogActions.defaultProps = {
    disableSpacing: false
};

exports.default = (0, _styles2.default)(styles, { index: 0 })(DialogActions);