"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var SvgChevronDown = function SvgChevronDown(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return _react2.default.createElement(
    "svg",
    Object.assign({ viewBox: "0 0 448 512", width: "1em", height: "1em", ref: svgRef }, props),
    _react2.default.createElement("path", { d: "M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z" })
  );
};

var ForwardRef = _react2.default.forwardRef(function (props, ref) {
  return _react2.default.createElement(SvgChevronDown, Object.assign({ svgRef: ref }, props));
});
exports.default = ForwardRef;