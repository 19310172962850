"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgIdBadge = function SvgIdBadge(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", { fill: "currentColor", d: "M2 12h56v37H2z" }),
            _react2.default.createElement("path", { fill: "#FFF", opacity: 0.88, d: "M2 12h56v37H2z" }),
            _react2.default.createElement("path", {
                d: "M54.375 7H5.625A5.625 5.625 0 0 0 0 12.625v33.75A5.625 5.625 0 0 0 5.625 52h48.75A5.625 5.625 0 0 0 60 46.375v-33.75A5.625 5.625 0 0 0 54.375 7zm1.875 39.375c0 1.036-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V14.5h52.5v31.875zm-5.156-5.625H35.156c-.776 0-1.406-.63-1.406-1.406v-.938c0-.776.63-1.406 1.406-1.406h15.938c.776 0 1.406.63 1.406 1.406v.938c0 .776-.63 1.406-1.406 1.406zm0-7.5H35.156c-.776 0-1.406-.63-1.406-1.406v-.938c0-.776.63-1.406 1.406-1.406h15.938c.776 0 1.406.63 1.406 1.406v.938c0 .776-.63 1.406-1.406 1.406zm0-7.5H35.156c-.776 0-1.406-.63-1.406-1.406v-.938c0-.776.63-1.406 1.406-1.406h15.938c.776 0 1.406.63 1.406 1.406v.938c0 .776-.63 1.406-1.406 1.406zm-22.606 8.08a7.544 7.544 0 0 0-2.729-2.236A8.199 8.199 0 0 0 27 27.25C27 22.7 23.3 19 18.75 19s-8.25 3.7-8.25 8.25a8.199 8.199 0 0 0 1.24 4.344 7.546 7.546 0 0 0-2.728 2.236A7.545 7.545 0 0 0 7.5 38.346v1.279a4.88 4.88 0 0 0 4.875 4.875h12.75A4.88 4.88 0 0 0 30 39.625v-1.279c0-1.619-.537-3.223-1.512-4.516zM18.75 22.75a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9zm7.5 16.875a1.125 1.125 0 0 1-1.125 1.125h-12.75a1.125 1.125 0 0 1-1.125-1.125v-1.279a3.75 3.75 0 0 1 2.72-3.605l.845-.242A8.2 8.2 0 0 0 18.75 35.5a8.199 8.199 0 0 0 3.935-1l.845.24a3.75 3.75 0 0 1 2.72 3.606v1.279z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgIdBadge;