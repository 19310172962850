import React from 'react';
import PropTypes from 'prop-types';
import ErrorTemplateBody from './ErrorTemplateBody';

var PageNotFound404Page = React.forwardRef(function (props, ref) {
    var edsContext = props.edsContext;

    var errorTitle = edsContext.formatMessage('component.ErrorPage.404');
    var errorMessage = edsContext.formatMessage('component.ErrorPage.404.message');
    var goBackText = edsContext.formatMessage('component.ErrorPage.goBackText');

    return React.createElement(ErrorTemplateBody, Object.assign({
        largeIllustrationName: '404.png',
        errorTitle: errorTitle,
        errorMessage: errorMessage,
        text: goBackText,
        hasAction: true
    }, props, {
        ref: ref
    }));
});

PageNotFound404Page.propTypes = {
    classes: PropTypes.object,
    edsContext: PropTypes.object,
    message: PropTypes.string,
    imageSrc: PropTypes.string,
    action: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default PageNotFound404Page;