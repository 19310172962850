'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _NativeSelect = require('@material-ui/core/NativeSelect');

var _NativeSelect2 = _interopRequireDefault(_NativeSelect);

var _Typography = require('../../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _ChevronDown = require('@eui/ds-icons/lib/ChevronDown');

var _ChevronDown2 = _interopRequireDefault(_ChevronDown);

var _styles = require('@hedtech/react-design-system/core/styles');

var _tokens = require('../../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
    return {
        caption: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: _tokens.spacingXxSmall + ' ' + _tokens.spacingMedium + ' 0'
        },
        monthYearText: {
            flexGrow: 1,
            flexShrink: 1,
            textAlign: 'center',
            justifyContent: 'center'
        },
        monthSelect: {
            minWidth: '7.25rem' // 116px
        },
        yearSelect: {
            minWidth: _tokens.sizingXxLarge1 // 64px
        },
        select: {
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeDefault,
            fontWeight: _tokens.fontWeightStrong,
            width: 'calc(100% - ' + _tokens.spacingXSmall + ')',
            padding: _tokens.spacingXxSmall,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.grey[200]
            }
        },
        icon: {
            top: 'calc(50% - ' + _tokens.spacingXxSmall + ')',
            right: _tokens.spacingXxSmall,
            position: 'absolute',
            pointerEvents: 'none',
            height: _tokens.spacingXSmall2,
            width: _tokens.spacingXSmall2
        }
    };
};

// TODO: remove the month year select options from here, no current need for them
function Caption(_ref) {
    var withMonthYearSelect = _ref.withMonthYearSelect,
        classes = _ref.classes,
        classNames = _ref.classNames,
        date = _ref.date,
        months = _ref.months,
        locale = _ref.locale,
        localeUtils = _ref.localeUtils,
        onClick = _ref.onClick,
        onChange = _ref.onChange,
        monthYearSelectOptions = _ref.monthYearSelectOptions,
        dir = _ref.dir;

    var captionMonths = localeUtils.getMonths(locale);
    var years = [];
    var isRtl = dir === 'rtl';

    var buildYearsOptions = function buildYearsOptions() {
        var yearOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
            min: 4,
            max: 4
        };

        var currentYear = new Date().getFullYear();
        var fromMonth = new Date(currentYear - yearOptions.min, 0);
        var toMonth = new Date(currentYear + yearOptions.max, 11);

        for (var i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
            years.push(i);
        }
    };

    if (monthYearSelectOptions) {
        var year = monthYearSelectOptions.year;

        buildYearsOptions(year);
    } else {
        buildYearsOptions();
    }

    var handleChange = function handleChange(e) {
        var _e$target$form = e.target.form,
            year = _e$target$form.year,
            month = _e$target$form.month;

        onChange(new Date(year.value, month.value));
    };

    var monthSelect = _react2.default.createElement(
        _NativeSelect2.default,
        {
            key: 'hedtech-datepicker-month-select',
            onChange: handleChange,
            IconComponent: _ChevronDown2.default,
            value: date.getMonth(),
            name: 'month',
            classes: {
                root: classes.monthSelect,
                select: classes.select,
                icon: classes.icon
            },
            disableUnderline: true
        },
        captionMonths.map(function (month, i) {
            return _react2.default.createElement(
                'option',
                { key: month, value: i },
                month
            );
        })
    );

    var yearSelect = _react2.default.createElement(
        _NativeSelect2.default,
        {
            key: 'hedtech-datepicker-year-select',
            IconComponent: _ChevronDown2.default,
            onChange: handleChange,
            value: date.getFullYear(),
            name: 'year',
            classes: {
                root: classes.yearSelect,
                select: classes.select,
                icon: classes.icon
            },
            disableUnderline: true
        },
        years.map(function (year) {
            return _react2.default.createElement(
                'option',
                { key: year, value: year },
                year
            );
        })
    );

    return _react2.default.createElement(
        'div',
        { className: 'DayPicker-Caption' },
        _react2.default.createElement(
            'div',
            { className: classes.caption },
            withMonthYearSelect ? _react2.default.createElement(
                'form',
                null,
                isRtl ? [yearSelect, monthSelect] : [monthSelect, yearSelect]
            ) : _react2.default.createElement(
                _Typography2.default,
                {
                    variant: 'h3',
                    className: classes.monthYearText,
                    component: 'div',
                    align: 'center'
                },
                localeUtils.formatMonthTitle(date, locale)
            )
        )
    );
}

Caption.propTypes = {
    /**
     * @ignore
     */
    classes: _propTypes2.default.object,
    withMonthYearSelect: _propTypes2.default.bool,
    classNames: _propTypes2.default.object,
    date: _propTypes2.default.instanceOf(Date),
    months: _propTypes2.default.arrayOf(_propTypes2.default.string),
    locale: _propTypes2.default.string,
    localeUtils: _propTypes2.default.object,
    onClick: _propTypes2.default.func,
    onChange: _propTypes2.default.func,
    monthYearSelectOptions: _propTypes2.default.object,
    dir: _propTypes2.default.string
};

exports.default = (0, _styles.withStyles)(styles)(Caption);