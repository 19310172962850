'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _core = require('@material-ui/core');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = exports.styles = function styles(theme) {
  return {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      // For correct alignment with the text.
      verticalAlign: 'middle',
      // Remove gray highlight
      WebkitTapHighlightColor: 'transparent',
      marginLeft: -2 * theme.spacing(1),
      marginRight: theme.spacing(2), // used for row presentation of radio/checkbox
      '&$disabled': {
        cursor: 'default'
      }
    },
    disabled: {},
    label: {
      fontSize: _tokens.fontSizeLarge,
      '&$disabled': {
        color: theme.palette.text.disabled
      }
    }
  };
};

/**
 * A drop-in replacement for the <Radio>, <Switch> and <Checkbox> Component.
 * Use this component if you want to display an extra label. You will be able to use the
 * state to change the label text from within the context of the FormControl.
 */
var FormControlLabel = _react2.default.forwardRef(function (props, ref) {
  return _react2.default.createElement(_core.FormControlLabel, Object.assign({}, props, { ref: ref }));
});

FormControlLabel.muiName = 'FormControlLabel';
FormControlLabel.displayName = 'FormControlLabel';

FormControlLabel.propTypes = {
  /**
   * If `true`, the component appears selected.
   */
  checked: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.string]),
  /**
   * Useful to extend the style applied to components.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * A control element. For instance, it can be be a `Radio`, a `Switch` or a `Checkbox`.
   */
  control: _propTypes2.default.element.isRequired,
  /**
   * If `true`, the control will be disabled.
   */
  disabled: _propTypes2.default.bool,
  /**
   * Use that property to pass a ref callback to the native input component.
   */
  inputRef: _propTypes2.default.func,
  /**
   * The text to be used in an enclosing label element.
   */
  label: _propTypes2.default.node,
  /*
   * @ignore
   */
  name: _propTypes2.default.string,
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.checked`.
   * @param {boolean} checked The `checked` value of the switch
   */
  onChange: _propTypes2.default.func,
  /**
   * The value of the component.
   */
  value: _propTypes2.default.string
};

FormControlLabel.contextTypes = {
  muiFormControl: _propTypes2.default.object
};

exports.default = (0, _styles2.default)(styles)(FormControlLabel);