var _toolbar;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import colorPalette from '../common/palette';
import { colorBrandCtaActive, colorBrandCtaBase, colorBrandCtaHover, colorBrandNeutral100, colorBrandNeutral200, colorBrandNeutral250, colorBrandNeutral300, colorBrandNeutral400, colorBrandNeutral500, colorBrandNeutral600, fontSizeHeader1, fontSizeHeader1Small, fontSizeHeader2, fontSizeHeader2Small, fontSizeHeader3, fontSizeHeader4, fontSizeLarge, fontSizeDefault, fontSizeSmall, fontFamilyDefault, fontFamilyHeader, fontWeightNormal, fontWeightStrong, lineHeightHeader1, lineHeightHeader2Small, lineHeightHeader2, lineHeightHeader3, lineHeightHeader4, lineHeightParagraphLarge, lineHeightParagraphMedium, lineHeightParagraphSmall, mqXSmall, sizingXxLarge1, zIndexTextFieldLabel, zIndexDropdown, zIndexHeaderBarDropdown, zIndexMegaMenu, zIndexInlineAlert, zIndexPageAlert, zIndexActionMenu, zIndexHeaderBar, zIndexModal } from '@hedtech/react-design-system/core/styles/tokens';

// Breakpoints must be defined in ascending order of viewport size. These breakpoints are used to calculate the size of the viewport in useWidth() hook.
// If values are "NOT" in ascending order then useWidth() hook will fail to return correct value.
var breakpoints = createBreakpoints({
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }
});

var lightThemeOptions = {
    props: {
        MuiButtonBase: {
            disableRipple: true // Disable ripple effect on components
        }
    },
    breakpoints: breakpoints,
    mixins: {
        toolbar: (_toolbar = {
            minHeight: sizingXxLarge1
        }, _defineProperty(_toolbar, breakpoints.up('xs') + ' and (orientation: landscape)', {
            minHeight: sizingXxLarge1
        }), _defineProperty(_toolbar, breakpoints.up('sm'), {
            minHeight: sizingXxLarge1
        }), _toolbar)
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: colorBrandNeutral500,
                '&$disabled:hover': {
                    cursor: 'not-allowed'
                },
                '&$error': {
                    color: colorBrandNeutral500
                },
                '&$focused': {
                    color: colorBrandNeutral500
                }
            },
            asterisk: {
                '&$error': {
                    color: colorBrandNeutral500
                }
            }
        },
        MuiInput: {
            formControl: {
                'label + &': {
                    marginTop: 0,
                    width: '100%'
                }
            }
        },
        // Hover and selected states are same across
        MuiMenuItem: {
            root: {
                '&:hover': {
                    color: colorBrandNeutral100
                },
                '&$selected': {
                    color: colorBrandNeutral100
                }
            }
        }
    },
    palette: Object.assign({
        action: {
            active: colorBrandCtaActive,
            disabled: colorBrandNeutral400,
            hover: colorBrandCtaHover,
            selected: colorBrandCtaBase
        },
        grey: {
            100: colorBrandNeutral100,
            200: colorBrandNeutral200,
            250: colorBrandNeutral250,
            300: colorBrandNeutral300,
            400: colorBrandNeutral400,
            500: colorBrandNeutral500,
            600: colorBrandNeutral600
        }
    }, colorPalette),
    spacing: 4,

    typography: {

        fontFamily: fontFamilyDefault, // Open Sans
        fontSize: 14, // fontSizeDefault
        fontWeight: fontWeightNormal, // 400

        // Display headings in Nunito
        h1: _defineProperty({
            fontFamily: fontFamilyHeader,
            fontSize: fontSizeHeader1, // 36px
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightParagraphSmall
        }, '@media ' + mqXSmall, {
            fontSize: fontSizeHeader1Small, // 30px
            lineHeight: lineHeightHeader1
        }),
        h2: _defineProperty({
            fontFamily: fontFamilyHeader,
            fontSize: fontSizeHeader2, // 26px
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightHeader2Small }, '@media ' + mqXSmall, {
            fontSize: fontSizeHeader2Small, // 24px
            lineHeight: lineHeightHeader2 // 1.23
        }),
        h3: {
            fontFamily: fontFamilyHeader,
            fontSize: fontSizeHeader3, // 21px
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightHeader3
        },
        h4: {
            fontFamily: fontFamilyHeader,
            fontSize: fontSizeHeader4, // 18px
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightHeader4
        },
        h5: {
            fontFamily: fontFamilyHeader,
            fontWeight: fontWeightNormal
        },
        h6: {
            fontFamily: fontFamilyHeader,
            fontWeight: fontWeightNormal
        },
        subtitle1: { // h6
            fontFamily: fontFamilyHeader,
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightHeader3
        },
        subtitle2: { // h6
            fontFamily: fontFamilyHeader,
            fontWeight: fontWeightNormal,
            lineHeight: lineHeightHeader3
        },
        // Standard (usually serif) body text
        body1: {
            fontSize: fontSizeLarge, // 16px
            lineHeight: lineHeightParagraphLarge // 1.375
        },
        // Compact (usually sans-serif) body text
        body2: {
            fontSize: fontSizeDefault, // 14px
            lineHeight: lineHeightParagraphMedium, // 1.43
            fontWeight: fontWeightNormal
        },
        button: {
            fontWeight: fontWeightStrong,
            fontSize: fontSizeLarge, // 16px
            fontFamily: fontFamilyHeader
        },
        // Small caption text
        caption: {
            fontSize: fontSizeSmall, //  12px
            lineHeight: lineHeightParagraphSmall, // 1.5
            color: colorBrandNeutral600
        }
    },
    zIndex: {
        textFieldLabel: zIndexTextFieldLabel,
        dropdown: zIndexDropdown,
        headerBarDropdown: zIndexHeaderBarDropdown,
        actionMenu: zIndexActionMenu,
        megaMenu: zIndexMegaMenu,
        inlineAlert: zIndexInlineAlert,
        pageAlert: zIndexPageAlert,
        headerBar: zIndexHeaderBar,
        modal: zIndexModal
    }
};

var lightTheme = createMuiTheme(lightThemeOptions);

export { lightTheme as default, lightThemeOptions };