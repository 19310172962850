'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Slider = require('@material-ui/core/Slider');

var _Slider2 = _interopRequireDefault(_Slider);

var _EDSContext = require('../EDSContext/EDSContext');

var _Circle = require('@eui/ds-icons/lib/Circle');

var _Circle2 = _interopRequireDefault(_Circle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Necessary design tokens are imported here
// import { } from '../styles/tokens';

var styles = exports.styles = function styles(theme) {
  return {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: {},
    sliderThumb: {
      fill: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2.5rem'
    }
  };
};

/**
 * A tool to select a value within a range.
 * @done false
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  SliderExample
 */
var Slider = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      edsContext = props.edsContext,
      rest = _objectWithoutProperties(props, ['classes', 'edsContext']);

  var classOverrides = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: classes.root
  };

  return _react2.default.createElement(_Slider2.default, Object.assign({}, rest, {
    thumb: _react2.default.createElement(_Circle2.default, { 'aria-label': edsContext.formatMessage('component.Slider.thumb'), className: classes.sliderThumb }),
    classes: classOverrides,
    ref: ref
  }));
});

Slider.muiName = 'Slider';
Slider.displayName = 'Slider';

Slider.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
  /**
   * If `true`, the slider will be disabled.
   */
  disabled: _propTypes2.default.bool,
  /**
   * @ignore
   */
  edsContext: _propTypes2.default.object,
  /**
   * The maximum allowed value of the slider.
   * Should not be equal to min.
   */
  max: _propTypes2.default.number,
  /**
   * The minimum allowed value of the slider.
   * Should not be equal to max.
   */
  min: _propTypes2.default.number,
  /**
   * Callback function that is fired when the slider's value changed.
   */
  onChange: _propTypes2.default.func,
  /**
   * The granularity the slider can step through values.
   */
  step: _propTypes2.default.number,
  /**
   * @ignore
   */
  theme: _propTypes2.default.object,
  /**
   * The value of the slider.
   */
  value: _propTypes2.default.number.isRequired,
  /**
   * The slider orientation.
   */
  orientation: _propTypes2.default.oneOf(['horizontal', 'vertical'])
};

// If default props are needed, they must be set here
Slider.defaultProps = {
  orientation: 'horizontal'
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(Slider));