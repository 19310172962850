function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// MUI imports
import MenuItem from '@material-ui/core/MenuItem';

// Design System imports
import Checkbox from '../Checkbox';
import { ListItemText } from '../List';
import withStyles from '../styles';
import { borderWidthThin, lineHeightParagraphMedium, sizingMedium } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            backgroundColor: theme.palette.grey['100'],
            // note - can't use borderBottom for this because of an Edge bug that makes
            // bottom borders disappear: https://stackoverflow.com/questions/32313551/border-bottom-bug-in-microsoft-edge
            borderTop: borderWidthThin + ' solid ' + theme.palette.grey['300'],
            color: theme.palette.grey['600'],
            display: 'flex',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            justifyContent: 'space-between',
            lineHeight: lineHeightParagraphMedium,
            minHeight: sizingMedium,
            whiteSpace: 'inherit',

            // updates to restore the MUI 3.x padding settings for dropdown items
            padding: '11px 16px',
            height: '24px',
            boxSizing: 'content-box',

            '&:hover, &:focus': {
                backgroundColor: theme.palette.grey['250'],
                color: theme.palette.grey['600']
            },
            '&:active': {
                backgroundColor: theme.palette.ctaColor.active,
                color: theme.palette.grey['100'],
                '& > svg': {
                    fill: theme.palette.grey['100']
                }
            },
            '& > svg': {
                fill: theme.palette.grey['500']
            }

        },
        singleSelected: {
            '&$selected': {
                backgroundColor: theme.palette.ctaColor.base,
                color: theme.palette.grey['100'],
                '&:hover, &:hover&:focus': {
                    backgroundColor: theme.palette.ctaColor.hover,
                    color: theme.palette.grey['100']
                },
                '&:active, &:hover&:active': {
                    backgroundColor: theme.palette.ctaColor.active,
                    color: theme.palette.grey['100']
                },
                '& > svg': {
                    fill: theme.palette.grey['100']
                }
            }
        },
        multipleSelected: {
            '&$selected': {
                backgroundColor: theme.palette.grey['100'],
                color: theme.palette.grey['600'],
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.grey['250'],
                    color: theme.palette.grey['600']
                }
            }
        },
        multipleActive: {
            '&:active': {
                backgroundColor: theme.palette.grey['250'],
                color: theme.palette.grey['600']
            }
        },
        textRoot: {
            paddingLeft: '16px',
            paddingRight: '16px',

            // only apply left/right padding if the menu text is *not* the first item in the menu
            // item; this will give us the correct padding when the item has icons at the
            // start/end
            '&:first-child': {
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        textPrimary: {
            color: 'inherit',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            '&:hover, &:active': {
                color: 'inherit'
            }
        },
        selected: {}
    };
};

var DropdownItem = React.forwardRef(function (props, ref) {
    var _classNames;

    var children = props.children,
        classes = props.classes,
        label = props.label,
        LeftIconComponent = props.LeftIconComponent,
        RightIconComponent = props.RightIconComponent,
        multiple = props.multiple,
        selectAll = props.selectAll,
        selected = props.selected,
        other = _objectWithoutProperties(props, ['children', 'classes', 'label', 'LeftIconComponent', 'RightIconComponent', 'multiple', 'selectAll', 'selected']);

    return React.createElement(
        MenuItem,
        Object.assign({
            className: classNames(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.multipleActive, multiple), _defineProperty(_classNames, classes.singleSelected, !multiple && selected), _defineProperty(_classNames, classes.multipleSelected, multiple && selected), _classNames), classes.selected),
            disableRipple: true,
            disableTouchRipple: true,
            selected: selected,
            ref: ref
        }, other),
        LeftIconComponent,
        React.createElement(ListItemText, {
            classes: {
                root: classes.textRoot,
                primary: classes.textPrimary
            },
            primary: label
        }),
        children,
        multiple && !selectAll && React.createElement(Checkbox, { checked: selected }),
        RightIconComponent
    );
});

DropdownItem.muiName = 'DropdownItem';

// necessary to add an explicit display name, since docgen can't seem to
// extract one from a forwardRef'd component
DropdownItem.displayName = 'DropdownItem';

DropdownItem.propTypes = {
    /**
     * Menu item contents.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See Classes API below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, the left and right padding is removed.
     */
    disableGutters: PropTypes.bool,
    /**
     * Label of the DropdownItem to be displayed
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    /**
     * Icon to be displayed to the the left of the text
     */
    LeftIconComponent: PropTypes.node,
    /**
     * @ignore
     */
    multiple: PropTypes.bool,
    /**
     * Icon to be displayed to the the right of the text
     */
    RightIconComponent: PropTypes.node,
    /**
     * @ignore
     */
    role: PropTypes.string,
    /**
     * Renders a DropdownItem for toggling SelectAll status
     * @ignore
     */
    selectAll: PropTypes.bool,
    /**
     * @ignore
     */
    selected: PropTypes.bool
};

DropdownItem.defaultProps = {
    disableGutters: false,
    LeftIconComponent: null,
    RightIconComponent: null,
    role: 'menuitem',
    selectAll: false
};

export default withStyles(styles, { name: 'DropdownItem' })(DropdownItem);