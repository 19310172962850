'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Badge = require('@material-ui/core/Badge');

var _Badge2 = _interopRequireDefault(_Badge);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        badge: {
            color: theme.palette.status.default.text,
            background: theme.palette.grey[100],
            minWidth: _tokens.sizingXLarge,
            width: 'auto',
            height: _tokens.sizingLarge,
            fontSize: theme.typography.body2.fontSize,
            borderRadius: _tokens.borderRadiusLarge,
            position: 'initial',
            marginLeft: _tokens.spacingSmall,
            marginTop: '-' + _tokens.spacingXxSmall,
            padding: '0 ' + _tokens.spacingXSmall2,
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
            transform: 'none'
        }
    };
};

/**
 * Use `Badge` to indicate a numerical value or count.
 * @done true
 * @updated false
 * @versionAdded v0.0.6
 * @examples
 *  BadgeExamples
 *  NotificationBadgeExamples
 */
var Badge = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        maxvalue = props.maxvalue,
        rest = _objectWithoutProperties(props, ['classes', 'maxvalue']);

    var classOverrides = {
        badge: classes.badge
    };

    return _react2.default.createElement(_Badge2.default, Object.assign({}, rest, {
        max: maxvalue,
        classes: classOverrides,
        ref: ref
    }));
});

Badge.muiName = 'Badge';

Badge.propTypes = {
    /**
     * The content rendered within the badge.
     */
    badgeContent: _propTypes2.default.number.isRequired,
    /**
     * The badge will be added relative to this node.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOf(['string', 'func']),
    /**
     * Max value of content rendered within the badge.
     */
    maxvalue: _propTypes2.default.number
};

Badge.displayName = 'Badge';

Badge.defaultProps = {
    maxvalue: 999
};

exports.default = (0, _styles2.default)(styles)(Badge);