import React from 'react';

var StepEdit = function StepEdit(props) {
    return React.createElement(
        "svg",
        Object.assign({}, props, { width: "24px", height: "24px", viewBox: "0 0 32 32", xmlns: "http://www.w3.org/2000/svg" }),
        React.createElement(
            "defs",
            null,
            React.createElement("path", { d: "M15.3627681,2.30077663 L13.6796131,0.61830983 C12.8553012,-0.206032963 11.5209897,-0.206157963 10.6964903,0.61827858 L0.778435041,10.4715172 L0.0102793522,15.1276367 C-0.0733143368,15.6343549 0.365529093,16.0732919 0.872372473,15.9897295 L5.52846282,15.2215429 L15.3823931,5.3034919 C16.205205,4.4806491 16.2130488,3.15102564 15.3627681,2.30077663 Z M3.77012036,9.4374871 L9.09071022,4.15139948 L11.848552,6.90924003 L6.56249332,12.2298276 L6.56249332,10.9374854 L5.06249441,10.9374854 L5.06249441,9.4374871 L3.77012036,9.4374871 Z M2.56662124,14.3166064 L1.68340313,13.4333887 L2.06277785,11.1337976 L2.63777743,10.5624858 L3.93749523,10.5624858 L3.93749523,12.0624858 L5.43749414,12.0624858 L5.43749414,13.36217 L4.86618206,13.9372006 L2.56662124,14.3166064 L2.56662124,14.3166064 Z M14.4098938,4.33121177 L14.4083001,4.33280552 L14.4067063,4.33439927 L12.9057699,5.84514752 L10.1548344,3.09421321 L11.6655833,1.59327745 L11.6671458,1.59171495 L11.6687083,1.59015245 C11.9545519,1.30434028 12.4180203,1.30080904 12.7073326,1.5901212 L14.3902689,3.27305675 C14.6989561,3.58171264 14.7008624,4.04021211 14.4098938,4.33121177 L14.4098938,4.33121177 Z", id: "path-1" })
        ),
        React.createElement(
            "g",
            { id: "Components", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement(
                "g",
                { id: "Step-Progress", transform: "translate(-288.000000, -636.000000)" },
                React.createElement(
                    "g",
                    { id: "Steps-[16h]", transform: "translate(69.000000, 636.000000)" },
                    React.createElement(
                        "g",
                        { id: "Step", transform: "translate(219.000000, 0.000000)" },
                        React.createElement(
                            "g",
                            { id: "Group" },
                            React.createElement(
                                "g",
                                { id: "Misc/Pagination/Button/Outline" },
                                React.createElement("circle", { id: "Circle", stroke: "currentColor", strokeWidth: "1", fill: "#FFFFFF", fillRule: "evenodd", cx: "16", cy: "16", r: "15.5" }),
                                React.createElement("g", { id: "Buttons/zFocused/Off" }),
                                React.createElement(
                                    "g",
                                    { id: "1)-Main-Use/edit", transform: "translate(8.000000, 8.000000)" },
                                    React.createElement(
                                        "mask",
                                        { id: "mask-2", fill: "white" },
                                        React.createElement("use", { xlinkHref: "#path-1" })
                                    ),
                                    React.createElement("use", { id: "1---Main-Use/edit", fill: "currentColor", fillRule: "evenodd", xlinkHref: "#path-1" })
                                )
                            )
                        )
                    )
                )
            )
        )
    );
};

export default StepEdit;