'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Divider = require('@material-ui/core/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            backgroundColor: theme.palette.grey[400],
            marginTop: _tokens.spacingSmall,
            marginBottom: _tokens.spacingSmall
        },
        middle: {
            marginLeft: _tokens.spacingSmall,
            marginRight: _tokens.spacingSmall
        }
    };
};

/**
 * Use `Divider` to group and separate content within lists and page layouts.
 * @done true
 * @updated false
 * @versionAdded v0.0.11
 * @examples
 *  FullBleedDividerExample
 *  MiddleDividerExample
 */
var Divider = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        variant = props.variant,
        rest = _objectWithoutProperties(props, ['classes', 'variant']);

    var classOverrides = {
        root: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.middle, variant === 'middle'))
    };

    return _react2.default.createElement(_Divider2.default, Object.assign({}, rest, { classes: classOverrides, ref: ref }));
});

Divider.muiName = 'Divider';
Divider.displayName = 'Divider';

Divider.propTypes = {
    absolute: _propTypes2.default.bool,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
    /**
     * Specifies fullbleed or middle Divider styling. Default is fullbleed.
     */
    variant: _propTypes2.default.oneOf(['fullbleed', 'middle'])
};

Divider.defaultProps = {
    variant: 'fullbleed'
};

exports.default = (0, _styles2.default)(styles)(Divider);