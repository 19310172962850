'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CardContext = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('@material-ui/core/Card');

var _Card2 = _interopRequireDefault(_Card);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            borderRadius: _tokens.borderRadiusMedium,
            overflow: 'inherit'
        },
        accent: {
            borderTopWidth: _tokens.sizingSmall,
            borderTopStyle: 'solid',
            paddingTop: 0
        },
        accentPrimary: {
            borderTopColor: theme.palette.primary.main
        },
        accentSecondary: {
            borderTopColor: theme.palette.secondary.main
        }
    };
};

var CardContext = (0, _react.createContext)();

/**
 * Use `Card` to display a group of similar content on a page.
 * @done true
 * @updated false
 * @versionAdded v0.0.3
 * @examples
 *  SimpleCard
 *  VisuallyAccentedCard
 *  ComplexCard
 *  CommunicationHistory
 */
var Card = _react2.default.forwardRef(function (props, ref) {
    var _classNames;

    var accent = props.accent,
        classes = props.classes,
        padding = props.padding,
        responsive = props.responsive,
        rest = _objectWithoutProperties(props, ['accent', 'classes', 'padding', 'responsive']);

    var classOverrides = (0, _classnames2.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.accent, ['primary', 'secondary'].indexOf(accent) !== -1), _defineProperty(_classNames, classes.accentPrimary, accent === 'primary'), _defineProperty(_classNames, classes.accentSecondary, accent === 'secondary'), _classNames));

    return _react2.default.createElement(
        CardContext.Provider,
        {
            value: {
                padding: padding,
                responsive: responsive
            }
        },
        _react2.default.createElement(_Card2.default, Object.assign({}, rest, {
            classes: {
                root: classOverrides
            },
            ref: ref
        }))
    );
});

Card.muiName = 'Card';
Card.displayName = 'Card';

Card.propTypes = {
    /**
     * Customize the color of accent border that is added to the card.
     * The options are `primary` and `secondary`. The `Card` will render with an
     * accented top border color that corresponds to the theme's primary and secondary
     * color (i.e. `primary` will render a `Card` with a `primary` accent border).
     */
    accent: _propTypes2.default.oneOf(['primary', 'secondary']),
    /**
     * If `true`, the card will use raised visual styling
     */
    raised: _propTypes2.default.bool,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * @ignore
     */
    elevation: _propTypes2.default.number,
    /**
     * Customize the spacing for Card's subcomponents. Standard, dense, and none
     * apply consistent padding across all viewports, per subcomponent.
     */
    padding: _propTypes2.default.oneOf(['standard', 'dense', 'none']),
    /**
     * If `true`, `responsive` combines with `padding` to provide a responsive
     * spacing solution, if padding is set to either `standard` or `dense`.
     */
    responsive: _propTypes2.default.bool

};

Card.defaultProps = {
    elevation: 5,
    padding: 'standard',
    responsive: true
};

exports.CardContext = CardContext;
exports.default = (0, _styles2.default)(styles)(Card);