import React from 'react';

var SvgNews = function SvgNews(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M8.814 14.95H2l.469 32.929h56.008V12H8.814z"
            }),
            React.createElement("path", {
                fill: "#FFF",
                opacity: 0.88,
                d: "M8.814 14.95H2l.469 32.929h56.008V12H8.814z"
            }),
            React.createElement("path", {
                d: "M57.5 10H9.167a2.503 2.503 0 0 0-2.5 2.5v.833H2.5a2.5 2.5 0 0 0-2.5 2.5V45a5 5 0 0 0 5 5h52.5c1.378 0 2.5-1.122 2.5-2.5v-35c0-1.378-1.122-2.5-2.5-2.5zM3.333 45V16.667h3.334V45c0 .919-.748 1.667-1.667 1.667A1.669 1.669 0 0 1 3.333 45zm53.334 1.667H9.714A4.99 4.99 0 0 0 10 45V13.333h46.667v33.334zm-42.084-10h17.5c.69 0 1.25-.56 1.25-1.25v-17.5c0-.69-.56-1.25-1.25-1.25h-17.5c-.69 0-1.25.56-1.25 1.25v17.5c0 .69.56 1.25 1.25 1.25zM16.667 20H30v13.333H16.667V20zm-3.334 22.083v-.833c0-.69.56-1.25 1.25-1.25h17.5c.69 0 1.25.56 1.25 1.25v.833c0 .69-.56 1.25-1.25 1.25h-17.5c-.69 0-1.25-.56-1.25-1.25zm23.334 0v-.833c0-.69.56-1.25 1.25-1.25h14.166c.69 0 1.25.56 1.25 1.25v.833c0 .69-.56 1.25-1.25 1.25H37.917c-.69 0-1.25-.56-1.25-1.25zm0-6.666v-.834c0-.69.56-1.25 1.25-1.25h14.166c.69 0 1.25.56 1.25 1.25v.834c0 .69-.56 1.25-1.25 1.25H37.917c-.69 0-1.25-.56-1.25-1.25zm0-13.334v-.833c0-.69.56-1.25 1.25-1.25h14.166c.69 0 1.25.56 1.25 1.25v.833c0 .69-.56 1.25-1.25 1.25H37.917c-.69 0-1.25-.56-1.25-1.25zm0 6.667v-.833c0-.69.56-1.25 1.25-1.25h14.166c.69 0 1.25.56 1.25 1.25v.833c0 .69-.56 1.25-1.25 1.25H37.917c-.69 0-1.25-.56-1.25-1.25z",
                fill: "currentColor"
            })
        )
    );
};

export default SvgNews;