function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { StepButton as MuiStepButton } from '@material-ui/core';
import { borderWidthThick, colorBrandSecondary } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            outline: 'none',
            '&:focus': {
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBrandSecondary
            }
        }
    };
};

/**
 * A component that displays progress through numbered steps.
 */
var StepButton = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        root: classes.root
    };

    return React.createElement(MuiStepButton, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

StepButton.muiName = 'StepButton';
StepButton.displayName = 'StepButton';

StepButton.propTypes = {
    /**
     * Can be a `StepLabel` or a node to place inside `StepLabel` as children.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The icon displayed by the step label.
     */
    icon: PropTypes.node,
    /**
     * The optional node to display.
     */
    optional: PropTypes.node
};

export default withStyles(styles)(StepButton);