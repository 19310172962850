'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    var _pageHeader;

    return {
        pageHeader: (_pageHeader = {
            paddingTop: _tokens.spacingLarge,
            paddingBottom: _tokens.spacingMedium,
            lineHeight: '2.625rem',
            color: theme.palette.grey[600]
        }, _defineProperty(_pageHeader, theme.breakpoints.up('lg'), {
            fontSize: _tokens.fontSizeHeader1
        }), _defineProperty(_pageHeader, theme.breakpoints.down('md'), {
            fontSize: _tokens.fontSizeHeader1Small
        }), _pageHeader)
    };
};

/**
 * Use `PageHeader` to display a page title at the top of a page.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  PageHeaderExample
 */
var PageHeader = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        text = props.text,
        rest = _objectWithoutProperties(props, ['classes', 'text']);

    var classOverrides = {
        h1: classes.pageHeader
    };

    return _react2.default.createElement(
        _Typography2.default,
        Object.assign({}, rest, { classes: classOverrides, variant: 'h1', ref: ref }),
        text
    );
});

PageHeader.muiName = 'PageHeader';
PageHeader.displayName = 'PageHeader';

PageHeader.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The content to be displayed as the page header.
     */
    text: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node])
};

exports.default = (0, _styles2.default)(styles)(PageHeader);