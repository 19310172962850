import AppDispatcher from '../AppDispatcher';
import { LayoutStatus } from '../../types/LayoutStatus';
import Requests from '../../requests/Layout';
const LayoutActions = {
    getCountCart() {
        AppDispatcher.dispatchAsync(Requests.getCountCart(), {
            failure: LayoutStatus.errorGetCountCart,
            request: LayoutStatus.fetchGetCountCart,
            success: LayoutStatus.successGetCountCart
        }, {});
    },
    hidePageLoader() {
        AppDispatcher.dispatch({ type: LayoutStatus.successHidePageLoader });
    },
    setAlert(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.successSetAlert, payload: { value } });
    },
    setCountCart(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.successSetCountCart, payload: { value } });
    },
    setLayoutReady(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.successSetLayoutReady, payload: { value } });
    },
    setLoading(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.successSetLoading, payload: { value } });
    },
    setLogData(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.successSetLogData, payload: { value } });
    },
    setRedirectCode(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.successSetRedirectCode, payload: { value } });
    },
    setReloadTheme(value) {
        AppDispatcher.dispatch({ type: LayoutStatus.successSetReloadTheme, payload: { value } });
    },
    showPageLoader() {
        AppDispatcher.dispatch({ type: LayoutStatus.successShowPageLoader });
    }
};
export default LayoutActions;
