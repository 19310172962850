"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgNoMessages = function SvgNoMessages(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 156, height: 183, viewBox: "0 0 156 183" }, props),
        _react2.default.createElement(
            "defs",
            null,
            _react2.default.createElement(
                "linearGradient",
                {
                    x1: "50%",
                    y1: "100%",
                    x2: "50%",
                    y2: "0%",
                    id: "NoMessages_svg__a"
                },
                _react2.default.createElement("stop", { stopColor: "#EBEBEB", stopOpacity: 0, offset: "0%" }),
                _react2.default.createElement("stop", { stopColor: "#EBEBEB", offset: "33.339%" }),
                _react2.default.createElement("stop", { stopColor: "#EBEBEB", offset: "100%" })
            ),
            _react2.default.createElement(
                "linearGradient",
                {
                    x1: "31.773%",
                    y1: "21.985%",
                    x2: "61.51%",
                    y2: "82.063%",
                    id: "NoMessages_svg__b"
                },
                _react2.default.createElement("stop", { stopColor: "#F6F6F6", offset: "0%" }),
                _react2.default.createElement("stop", { stopColor: "#D1D1D1", offset: "38.942%" }),
                _react2.default.createElement("stop", { stopColor: "#F4F4F4", offset: "100%" })
            )
        ),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", {
                fill: "url(#NoMessages_svg__a)",
                d: "M96.543 93.138h15.785v90.022H96.543z"
            }),
            _react2.default.createElement("path", {
                d: "M123.768 82.13l.083.191-.083.04v.11l-.115-.053-88.996 43.798L.99 44.976 88.753 1.451l.128.296A15.2 15.2 0 0 1 95.858.062c2.182 0 4.337.468 6.323 1.371a36.849 36.849 0 0 1 21.587 33.54V82.13z",
                transform: "translate(32.118 .332)",
                fill: "url(#NoMessages_svg__b)"
            }),
            _react2.default.createElement("path", {
                d: "M24.334 106.366l-.216-.097v-46.97c0-8.51 6.899-15.41 15.409-15.41 2.177 0 4.33.462 6.316 1.355A36.819 36.819 0 0 1 67.57 78.826v46.723l.214.096L46.606 158.5c-7.4 11.48-22.078 15.833-34.54 10.243C1.779 164.13-2.82 152.05 1.795 141.763c.42-.938.91-1.842 1.467-2.705l21.072-32.692z",
                fill: "currentColor"
            }),
            _react2.default.createElement("path", {
                fill: "#FFF",
                opacity: 0.2,
                d: "M24 105.853l43.569-21.001v40.945z"
            }),
            _react2.default.createElement("path", {
                d: "M12.066 168.743C1.779 164.13-2.82 152.05 1.795 141.763c.42-.938.91-1.842 1.467-2.705l21.177-32.855 43.344 19.442L46.606 158.5c-7.4 11.48-22.078 15.833-34.54 10.243z",
                fill: "#FFF",
                opacity: 0.85
            }),
            _react2.default.createElement("path", {
                d: "M45.843 45.244A36.819 36.819 0 0 1 67.57 78.826v46.97L24.118 106.27v-46.97c0-8.51 6.899-15.41 15.409-15.41 2.177 0 4.33.462 6.316 1.355z",
                fill: "#FFF",
                opacity: 0.3
            }),
            _react2.default.createElement("path", {
                d: "M146.187 57.3v7.424L138.54 68.4l.142-7.442-51.17 24.945.149-7.78 58.677-28.605-.15 7.78z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgNoMessages;