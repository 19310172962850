'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    root: {
        padding: '0 ' + _tokens.spacingSmall,
        alignItems: 'center',
        margin: 'inherit',
        height: _tokens.sizingXxLarge1,
        '& svg': {
            height: _tokens.sizingXxLarge1
        }
    }
};

var HeaderBarLogo = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        children = props.children;


    return _react2.default.createElement(
        'div',
        { className: classes.root, ref: ref },
        children
    );
});

HeaderBarLogo.displayName = 'HeaderBarLogo';

HeaderBarLogo.propTypes = {
    /**  The content of the component. This component's children might be an image or brand name. */
    children: _propTypes2.default.node.isRequired,

    /** Useful to extend the style applied to components. */
    classes: _propTypes2.default.object.isRequired
};

exports.default = (0, _styles2.default)(styles)(HeaderBarLogo);