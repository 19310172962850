var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import React, { forwardRef } from 'react';
import EdsAlert from '@hedtech/react-design-system/core/es/Alert';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = (theme) => createStyles({
    overModal: {
        top: 0,
        zIndex: Number(Tokens.zIndexModal) + 1
    },
    pageTop: {
        [theme.breakpoints.only('xs')]: {
            left: 0,
            right: 0
        }
    }
});
const Alert = (props, ref) => {
    const { children, classes, overModal, type } = props, rest = __rest(props, ["children", "classes", "overModal", "type"]);
    const classOverrides = omit(classes, ['overModal']);
    if (overModal) {
        classOverrides.pageTop = classnames(classes.pageTop, classes.overModal);
    }
    return (React.createElement(EdsAlert, Object.assign({ alertType: type, classes: classOverrides, ref: ref }, rest), children));
};
const AlertFRC = forwardRef(Alert);
AlertFRC.displayName = 'AlertFRC';
AlertFRC.defaultProps = {
    id: '',
    userDismissable: false
};
export default withStyles(styles, { name: 'AlertWS' })(AlertFRC);
