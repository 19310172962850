function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import { default as MuiBadge } from '@material-ui/core/Badge';
import { default as withStyles } from '../styles';
import PropTypes from 'prop-types';
import { spacingXxSmall, spacingSmall, spacingXSmall2, sizingLarge, sizingXLarge, borderRadiusLarge, borderWidthThin } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        badge: {
            color: theme.palette.status.default.text,
            background: theme.palette.grey[100],
            minWidth: sizingXLarge,
            width: 'auto',
            height: sizingLarge,
            fontSize: theme.typography.body2.fontSize,
            borderRadius: borderRadiusLarge,
            position: 'initial',
            marginLeft: spacingSmall,
            marginTop: '-' + spacingXxSmall,
            padding: '0 ' + spacingXSmall2,
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            transform: 'none'
        }
    };
};

/**
 * Use `Badge` to indicate a numerical value or count.
 * @done true
 * @updated false
 * @versionAdded v0.0.6
 * @examples
 *  BadgeExamples
 *  NotificationBadgeExamples
 */
var Badge = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        maxvalue = props.maxvalue,
        rest = _objectWithoutProperties(props, ['classes', 'maxvalue']);

    var classOverrides = {
        badge: classes.badge
    };

    return React.createElement(MuiBadge, Object.assign({}, rest, {
        max: maxvalue,
        classes: classOverrides,
        ref: ref
    }));
});

Badge.muiName = 'Badge';

Badge.propTypes = {
    /**
     * The content rendered within the badge.
     */
    badgeContent: PropTypes.number.isRequired,
    /**
     * The badge will be added relative to this node.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOf(['string', 'func']),
    /**
     * Max value of content rendered within the badge.
     */
    maxvalue: PropTypes.number
};

Badge.displayName = 'Badge';

Badge.defaultProps = {
    maxvalue: 999
};

export default withStyles(styles)(Badge);