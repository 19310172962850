"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgPencil = function SvgPencil(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", {
                fill: "currentColor",
                d: "M2 56.254V44.891L46.889 2l7.817 3.218 2.913 8.79-42.5 43.673z"
            }),
            _react2.default.createElement("path", {
                fill: "#FFF",
                opacity: 0.88,
                d: "M2 56.254V44.891L46.889 2l7.817 3.218 2.913 8.79-42.5 43.673z"
            }),
            _react2.default.createElement("path", {
                d: "M57.803 6.577L53.41 2.193a7.51 7.51 0 0 0-10.607 0L1.504 43.41.018 56.766a2.81 2.81 0 0 0 3.106 3.1l13.38-1.484 41.3-41.219a7.475 7.475 0 0 0 0-10.586zM19.3 40.621c.732.731 1.92.731 2.652 0L43.125 19.49l3.523 3.515L20.39 49.21v-4.064h-5.625v-5.614h-4.072L36.95 13.326l3.522 3.516L19.3 37.975a1.868 1.868 0 0 0 0 2.646zm-4.516 14.186l-6.914.766-3.551-3.544.768-6.9 2.327-2.322h4.071v5.614h5.625v4.064l-2.326 2.322zm40.369-40.29l-5.853 5.84-9.697-9.677 5.853-5.841a3.753 3.753 0 0 1 5.303 0l4.394 4.385a3.736 3.736 0 0 1 0 5.293z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgPencil;