import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel as MuiFormLabel } from '@material-ui/core';
import { default as withStyles } from '../styles';

export var styles = function styles(theme) {
    return {
        root: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.secondary,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1,
            padding: 0,
            '&:focused': {
                color: theme.palette.primary[theme.palette.type === 'light' ? 'dark' : 'light']
            },
            '&:disabled': {
                color: theme.palette.text.disabled
            },
            '&:error': {
                color: theme.palette.error.main
            }
        },
        focused: {},
        disabled: {},
        error: {},
        asterisk: {
            '&:error': {
                color: theme.palette.error.main
            }
        }
    };
};

/**
 *  A wrapper for a label in a form with standard disabled/error/filled/focused/required props,
 * each of which can be accompanied by standardized, customized styling.  It also supports passing
 * full children.
 */
var FormLabel = React.forwardRef(function (props, ref) {
    return React.createElement(MuiFormLabel, Object.assign({}, props, { ref: ref }));
});

FormLabel.muiName = 'FormLabel';
FormLabel.displayName = 'FormLabel';

FormLabel.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * If `true`, the label should be displayed in a disabled state.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, the label should be displayed in an error state.
     */
    error: PropTypes.bool,
    /**
     * If `true`, the label should use filled classes key.
     */
    filled: PropTypes.bool,
    /**
     * If `true`, the input of this label is focused (used by `FormGroup` components).
     */
    focused: PropTypes.bool,
    /**
     * If `true`, the label will indicate that the input is required.
     */
    required: PropTypes.bool
};

FormLabel.defaultProps = {
    component: 'label'
};

FormLabel.contextTypes = {
    muiFormLabel: PropTypes.object
};

export default withStyles(styles)(FormLabel);