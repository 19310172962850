'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/* eslint-disable no-underscore-dangle */

// note: this was taken, more or less verbatim, from MUI's generator script:
//  https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createGenerateClassName.js

var escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g;

function safePrefix(classNamePrefix) {
    var prefix = String(classNamePrefix);
    if (prefix.length >= 256) {
        console.warn('Material-UI: the class name prefix is too long: ' + prefix + '.');
    }
    // Sanitize the string as will be used to prefix the generated class name.
    return prefix.replace(escapeRegex, '-');
}

// Returns a function which generates unique class names based on counters.
// When new generator function is created, rule counter is reset.
// We need to reset the rule counter for SSR for each request.
//
// It's inspired by
// https://github.com/cssinjs/jss/blob/4e6a05dd3f7b6572fdd3ab216861d9e446c20331/src/utils/createGenerateClassName.js
var classGenerator = function createGenerateClassName() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _options$dangerouslyU = options.dangerouslyUseGlobalCSS,
        dangerouslyUseGlobalCSS = _options$dangerouslyU === undefined ? false : _options$dangerouslyU,
        _options$productionPr = options.productionPrefix,
        productionPrefix = _options$productionPr === undefined ? 'jss' : _options$productionPr,
        _options$seed = options.seed,
        seed = _options$seed === undefined ? '' : _options$seed;

    var ruleCounter = 0;

    return function (rule, styleSheet) {

        // default name
        var name = rule.key + '-' + seed + ruleCounter;

        ruleCounter += 1;

        if (ruleCounter > 1e10) {
            console.warn(['Material-UI: you might have a memory leak.', 'The ruleCounter is not supposed to grow that much.'].join(''));
        };

        // Code branch the whole block at the expense of more code.
        if (dangerouslyUseGlobalCSS) {

            if (styleSheet) {
                if (styleSheet.options.name) {
                    name = styleSheet.options.name + '-' + rule.key;
                } else if (styleSheet.options.classNamePrefix && process.env.NODE_ENV !== 'production') {
                    var prefix = safePrefix(styleSheet.options.classNamePrefix);
                    name = prefix + '-' + rule.key + '-' + seed + ruleCounter;
                }
            } else if (process.env.NODE_ENV === 'production') {
                name = '' + productionPrefix + seed + ruleCounter;
            }
        } else if (process.env.NODE_ENV === 'production') {
            name = '' + productionPrefix + seed + ruleCounter;
        } else if (styleSheet && styleSheet.options.classNamePrefix) {
            var _prefix = safePrefix(styleSheet.options.classNamePrefix);

            name = _prefix + '-' + rule.key + '-' + seed + ruleCounter;
        }

        return name;
    };
};

exports.default = classGenerator();