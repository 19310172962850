/* Copyright 2019 Ellucian Company L.P. and its affiliates.
 * File: SignIn.tsx
 * Type: Container component */

// #region Imports
import URLSearchParams from '@ungap/url-search-params';
import React from 'react';

// Core components
import Button from '@powercampus/design-system/react/core/Button';
import Card, { CardContent } from '@powercampus/design-system/react/core/Card';
import Grid from '@powercampus/design-system/react/core/Grid';
import Modal from '@powercampus/design-system/react/core/Modal';
import Paragraph from '@powercampus/design-system/react/core/Paragraph';
import Text from '@powercampus/design-system/react/core/Text';
import TextField from '@powercampus/design-system/react/core/TextField';

// Types
import { IAlert } from '@powercampus/design-system/types/IAlert';
import { IJsonResult } from '@powercampus/design-system/types/IJsonResult';
import { ILogData } from '@powercampus/design-system/types/ILogData';
import { ResultType } from '@powercampus/design-system/types/ResultType';
import { IAuthResponse } from '../../Types/Generic/IAuthResponse';
import { ISignInResources } from '../../Types/Resources/Generic/ISignInResources';

// Helpers
import Constants from '@powercampus/design-system/helpers/Constants';
import LogData from '@powercampus/design-system/helpers/LogData';
import Tokens from '@powercampus/design-system/react/core/styles/Tokens';
import { createStyles, withStyles, WithStyles } from '@powercampus/design-system/react/core/styles/withStyles';

// Requests
import RequestsLayout from '@powercampus/design-system/requests/Layout';
import Requests from '../../Requests/Generic/SignIn';

// State Management
import LayoutActions from '@powercampus/design-system/flux/actions/LayoutActions';
import LayoutStore from '@powercampus/design-system/flux/stores/LayoutStore';
// #endregion Imports

// #region Types
export interface ISignInProps {
    className?: string;
    open?: boolean;
    userName?: string;
    onAfterSignIn?: () => void;
    onClose?: () => void;
    onGoSignUp?: () => void;
}

interface ISignInState {
    authMode?: number;
    componentError: boolean;
    hasStore: boolean;
    isLoadingNext: boolean;
    isLoadingSignIn: boolean;
    password: string;
    passwordResetURL: string;
    resources?: ISignInResources;
    showForgotPassword: boolean;
    userExists: boolean;
    username: string;
}

const styles = createStyles({
    loginText: {
        marginTop: `${Tokens.spacingSmall}!important`
    },
    spacingButtons: {
        paddingTop: Tokens.spacingSmall
    },
    spacingInstructions: {
        paddingTop: Tokens.spacingSmall
    },
    spacingWelcome: {
        marginBottom: Tokens.spacingSmall
    }
});

type PropsWithStyles = ISignInProps & WithStyles<typeof styles>;
// #endregion Types

// #region Component
class SignIn extends React.Component<PropsWithStyles, ISignInState> {
    private idModule: string;
    private idPage: string;

    public readonly state: Readonly<ISignInState>;

    public constructor(props) {
        super(props);

        // #region Initialize Variables and State
        this.idModule = 'Generic';
        this.idPage = 'SignIn';
        this.state = this.getInitialState(this.props.userName);
        // #endregion Initialize Variables and State
    }

    private getInitialState(userName?: string): ISignInState {
        let passwordResetURL: string = '';
        let resources: ISignInResources | undefined;
        let showForgotPassword: boolean = false;

        if (this.state) {
            passwordResetURL = this.state.passwordResetURL;
            resources = this.state.resources;
            showForgotPassword = this.state.showForgotPassword;
        }
        return {
            authMode: undefined,
            componentError: false,
            hasStore: false,
            isLoadingNext: false,
            isLoadingSignIn: false,
            password: '',
            passwordResetURL: passwordResetURL,
            resources: resources,
            showForgotPassword: showForgotPassword,
            userExists: false,
            username: userName || ''
        };
    }

    // #region Events
    private onChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            password: event.target.value
        });
    };

    private onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            username: event.target.value
        });
    };

    private onCloseModal = (): void => {
        try {
            const {
                onClose
            } = this.props;
            const {
                isLoadingNext,
                isLoadingSignIn
            } = this.state;

            if (onClose && !isLoadingNext && !isLoadingSignIn) {
                this.setState(this.getInitialState());
                onClose();
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onCloseModal.name, e));
        }
    };

    private onGoSignUpModal = (): void => {
        try {
            const {
                onGoSignUp
            } = this.props;

            const {
                isLoadingNext,
                isLoadingSignIn
            } = this.state;

            if (onGoSignUp && !isLoadingNext && !isLoadingSignIn) {
                this.setState(this.getInitialState());
                onGoSignUp();
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onGoSignUpModal.name, e));
        }
    };

    private onNext = (): void => {
        try {
            const {
                isLoadingNext,
                username
            } = this.state;

            if (!isLoadingNext) {
                this.showLoaderNext();
                Requests.getAuthenticationMode(username, this.resolveGetAuthenticationMode, this.logError);
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onNext.name, e));
        }
    };

    private onSignIn = (): void => {
        try {
            const {
                authMode,
                isLoadingSignIn,
                password,
                username
            } = this.state;

            if (authMode && !isLoadingSignIn) {
                this.showLoaderSignIn();
                if (authMode === 4) {
                    window.location.assign(`${Constants.webUrl}/Sso/SAML`);
                }
                else {
                    Requests.postAuthenticateUser(username, password, this.resolvePostAuthenticateUser, this.logError);
                }
            }
            else {
                this.showError();
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onSignIn.name, e));
        }
    };

    private onClickForgotPassword = (): void => {
        try {
            const {
                isLoadingNext,
                isLoadingSignIn,
                passwordResetURL
            } = this.state;

            if (passwordResetURL && !isLoadingNext && !isLoadingSignIn) {
                window.location.assign(passwordResetURL);
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onClickForgotPassword.name, e));
        }
    };

    private onUseAnotherAccount = (): void => {
        try {
            const {
                isLoadingNext,
                isLoadingSignIn
            } = this.state;

            if (!isLoadingNext && !isLoadingSignIn) {
                this.setState({
                    authMode: undefined,
                    password: '',
                    username: ''
                });
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.onUseAnotherAccount.name, e));
        }
    };
    // #endregion Events

    // #region Loader Functions
    private hideAllLoaders = (): void => {
        this.setState({
            isLoadingNext: false,
            isLoadingSignIn: false
        });
    };

    private hideLoaderNext = (): void => {
        this.setState({
            isLoadingNext: false
        });
    };

    private hideLoaderSignIn = (): void => {
        this.setState({
            isLoadingSignIn: false
        });
    };

    private showLoaderNext = (): void => {
        this.setState({
            isLoadingNext: true
        });
    };

    private showLoaderSignIn = (): void => {
        this.setState({
            isLoadingSignIn: true
        });
    };
    // #endregion Loader Functions

    // #region Error Functions
    private logError(logData: ILogData): void {
        this.hideAllLoaders();
        LayoutActions.setLogData(logData);
    }

    private redirectError(code: number): void {
        this.hideAllLoaders();
        LayoutActions.setRedirectCode(code);
    }

    private showError(message?: string): void {
        this.hideAllLoaders();
        LayoutActions.setAlert({ message: message, messageType: ResultType.error } as IAlert);
    }
    // #endregion Error Functions

    // #region Resolvers
    private resolveGetResources = (json: string): void => {
        try {
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                this.setState({
                    resources: result.data
                });
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolveGetResources.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolveGetResources.name, e));
        }
    };

    private resolveGetShowForgotPassword = (json: string): void => {
        try {
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                this.setState({
                    passwordResetURL: result.data.passwordResetURL,
                    showForgotPassword: result.data.enablePasswordReset
                });
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolveGetShowForgotPassword.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolveGetShowForgotPassword.name, e));
        }
    };

    private resolveGetAuthenticationMode = (json: string): void => {
        try {
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                const {
                    resources
                } = this.state;
                if (resources) {
                    this.setState({
                        authMode: result.data.mode,
                        hasStore: result.data.hasStore,
                        userExists: result.data.userExists
                    });

                    let message: string | undefined;

                    if (!result.data.userExists) {
                        message = resources.lblUserExists;
                    }
                    else if (!result.data.hasStore) {
                        message = resources.lblHasStore;
                    }
                    const alert: IAlert = {
                        message: message,
                        messageType: ResultType.error
                    };

                    if (message) {
                        LayoutActions.setAlert(alert);
                    }

                    if (result.data.mode == 3) {
                        window.location.assign(`${Constants.webUrl}/ADFS/Authentication`);
                    }
                    if (result.data.mode == 4) {
                        window.location.assign(`${Constants.webUrl}/SSO/SAML`);
                    }
                }
                this.hideLoaderNext();
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolvePostAuthenticateUser.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolvePostAuthenticateUser.name, e));
        }
    };

    private resolvePostAuthenticateUser = (json: string): void => {
        try {
            const result: IJsonResult = JSON.parse(json);
            if (result.status) {
                const authResponse: IAuthResponse = result.data;
                if (authResponse && authResponse.success) {
                    LayoutStore.setIsAuthenticated(true);
                    LayoutStore.setMenuOptions(undefined);

                    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
                    const returnUrl: string | null = urlParams.get('ReturnUrl');

                    const {
                        onAfterSignIn
                    } = this.props;

                    if (onAfterSignIn) {
                        onAfterSignIn();
                    }
                    else {
                        if (returnUrl && !returnUrl.includes('LogOut')) {
                            window.location.href = `${returnUrl}`;
                        }
                        else {
                            window.location.href = `${Constants.webUrl}`;
                        }
                    }
                }
                else {
                    const {
                        resources
                    } = this.state;

                    if (resources) {
                        let message: string = '';
                        if (authResponse.status === 0) {
                            message = resources.lblNone;
                        }
                        else if (authResponse.status === 1) {
                            message = resources.lblSuccess;
                        }
                        else if (authResponse.status === 2) {
                            message = resources.lblInvalidCredentials;
                        }
                        else if (authResponse.status === 3) {
                            message = resources.lblInvalidPassword;
                        }
                        else if (authResponse.status === 4) {
                            message = resources.lblNoIdentity;
                        }
                        else if (authResponse.status === 6) {
                            message = resources.lblUnknown;
                        }

                        const alert: IAlert = {
                            message: message,
                            messageType: ResultType.error
                        };

                        LayoutActions.setAlert(alert);
                        this.hideLoaderSignIn();
                    }
                }
            }
            else if (result.code) {
                this.redirectError(result.code);
            }
            else if (result.log) {
                this.showError();
            }
            else {
                this.logError(LogData.badJsonResult(this.resolvePostAuthenticateUser.name));
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.resolvePostAuthenticateUser.name, e));
        }
    };
    // #endregion Resolvers

    // #region Lifecycle
    public componentDidMount(): void {
        try {
            RequestsLayout.getResources(this.idModule, this.idPage,
                this.resolveGetResources,
                this.logError);
            Requests.getShowForgotPassword(this.resolveGetShowForgotPassword, this.logError);

            const {
                username
            } = this.state;
            if (username) {
                this.onNext();
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.componentDidMount.name, e));
        }
    }

    public componentDidCatch(error, info): void {
        this.setState({
            componentError: true
        }, () => {
            this.logError(LogData.fromComponentException(this.componentDidCatch.name, error, info));
            this.redirectError(500);
        });
    }
    // #endregion Lifecycle

    public render(): JSX.Element {
        const {
            classes,
            className,
            open,
            onClose,
            onGoSignUp
        } = this.props;

        const {
            authMode,
            componentError,
            isLoadingNext,
            isLoadingSignIn,
            password,
            resources,
            showForgotPassword,
            username
        } = this.state;

        let contentPage: JSX.Element | undefined;
        if (!componentError && resources) {
            let forgotPassword: JSX.Element | undefined;
            if (showForgotPassword) {
                forgotPassword = (
                    <Text weight="strong">
                        <Button
                            id="btnForgotPassword"
                            align="right"
                            textVariantStyling="inline"
                            variant="text"
                            onClick={this.onClickForgotPassword}
                        >
                            {resources.btnForgot}
                        </Button>
                    </Text>
                );
            }
            const stepNumber: number = !authMode ? 1 : (authMode && (authMode === 1 || authMode === 2) ? 2 : 0);

            let title: JSX.Element | undefined;
            let loginContent: JSX.Element | JSX.Element[] | undefined;
            let button: JSX.Element | undefined;

            switch (stepNumber) {
                case 0:
                    title = (
                        <Text size="h2" align="center">
                            {resources.lblLogInTitle}
                        </Text>
                    );
                    break;
                case 1:
                    title = (
                        <>
                            <Text size="h2" align="center">
                                {resources.lblLogInTitle}
                            </Text>
                            {onGoSignUp && (
                                <Paragraph
                                    align="center"
                                    className={classes.spacingInstructions}
                                    id="prgSignUp"
                                    text={resources.lblInstructions}
                                    events={[this.onGoSignUpModal]}
                                />
                            )}
                        </>
                    );
                    button = (
                        <Button
                            id="btnNext"
                            loading={isLoadingNext}
                            onClick={this.onNext}
                        >
                            {resources.btnNext}
                        </Button>
                    );
                    loginContent = (
                        <>
                            <Grid container>
                                <Grid item xs={12}>
                                    {!onClose && (
                                        <>
                                            {title}
                                        </>
                                    )}
                                    <TextField
                                        className={classes.loginText}
                                        disabled={authMode || isLoadingNext}
                                        id="txtUsername"
                                        label={resources.lblUserName}
                                        value={username || ''}
                                        onChange={this.onChangeUsername}
                                        onEnterPress={this.onNext}
                                    />
                                </Grid>
                            </Grid>
                            {!onClose && (
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    className={classes.spacingButtons}
                                    direction="column"
                                >
                                    <Grid item>
                                        {button}
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    );
                    break;
                case 2:
                    title = (
                        <>
                            <Text
                                align="center"
                                className={classes.spacingWelcome}
                                size="h1"
                            >
                                {resources.lblWelcome}
                            </Text>
                            <Text align="center" size="h2">
                                {username}
                            </Text>
                            <Grid container justify="center">
                                <Grid item>
                                    <Text weight="strong">
                                        <Button
                                            disabled={isLoadingSignIn}
                                            id="btnAnotherAccount"
                                            textVariantStyling="inline"
                                            variant="text"
                                            onClick={this.onUseAnotherAccount}
                                        >
                                            {resources.lblUseAnotherAccount}
                                        </Button>
                                    </Text>
                                </Grid>
                            </Grid>
                        </>
                    );
                    button = (
                        <Button
                            id="btnSignIn"
                            loading={isLoadingSignIn}
                            onClick={this.onSignIn}
                        >
                            {resources.btnSingIn}
                        </Button>
                    );
                    loginContent = (
                        <>
                            <Grid container>
                                <Grid item xs={12}>
                                    {!onClose && (
                                        <>
                                            {title}
                                        </>
                                    )}
                                    <TextField
                                        className={classes.loginText}
                                        disabled={isLoadingSignIn}
                                        id="txtPassword"
                                        label={resources.lblPassword}
                                        passwordToggle
                                        type="password"
                                        value={password || ''}
                                        onChange={this.onChangePassword}
                                        onEnterPress={this.onSignIn}
                                    />
                                </Grid>
                            </Grid>
                            {!onClose && (
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    className={classes.spacingButtons}
                                    direction="column"
                                >
                                    {forgotPassword && (
                                        <Grid item>
                                            {forgotPassword}
                                        </Grid>
                                    )}
                                    <Grid item>
                                        {button}
                                    </Grid>
                                </Grid>
                            )}
                            {onClose && (
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    className={classes.spacingButtons}
                                    direction="column"
                                >
                                    {forgotPassword && (
                                        <Grid item>
                                            {forgotPassword}
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </>
                    );
                    break;
            }

            if (onClose) {
                contentPage = (
                    <Modal
                        disableBackdropClick={isLoadingNext || isLoadingSignIn}
                        disableEscapeKeyDown={isLoadingNext || isLoadingSignIn}
                        disableHeaderTypography
                        id="signInModal"
                        footer={button}
                        header={title}
                        maxWidth="md"
                        open={open}
                        onClose={this.onCloseModal}
                    >
                        {loginContent}
                    </Modal>
                );
            }
            else {
                contentPage = (
                    <Card className={className}>
                        <CardContent>
                            {loginContent}
                        </CardContent>
                    </Card>
                );
            }
        }

        return (
            <>
                {contentPage}
            </>
        );
    }
}
// #endregion Component

// Export: Component
export default withStyles(styles)(SignIn);