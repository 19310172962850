'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ErrorTemplateBody = require('./ErrorTemplateBody');

var _ErrorTemplateBody2 = _interopRequireDefault(_ErrorTemplateBody);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PageNotFound404Page = _react2.default.forwardRef(function (props, ref) {
    var edsContext = props.edsContext;

    var errorTitle = edsContext.formatMessage('component.ErrorPage.404');
    var errorMessage = edsContext.formatMessage('component.ErrorPage.404.message');
    var goBackText = edsContext.formatMessage('component.ErrorPage.goBackText');

    return _react2.default.createElement(_ErrorTemplateBody2.default, Object.assign({
        largeIllustrationName: '404.png',
        errorTitle: errorTitle,
        errorMessage: errorMessage,
        text: goBackText,
        hasAction: true
    }, props, {
        ref: ref
    }));
});

PageNotFound404Page.propTypes = {
    classes: _propTypes2.default.object,
    edsContext: _propTypes2.default.object,
    message: _propTypes2.default.string,
    imageSrc: _propTypes2.default.string,
    action: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.string])
};

exports.default = PageNotFound404Page;