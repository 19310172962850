function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiTableRow } from '@material-ui/core/TableRow';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: {
            height: '3rem',
            '&$hover:hover': {
                backgroundColor: theme.palette.grey[250]
            }
        },
        hover: {}
    };
};

/**
 * Table component for displaying a row
 */
var TableRow = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: classes.root,
        hover: classes.hover
    };

    return React.createElement(MuiTableRow, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

TableRow.muiName = 'TableRow';
TableRow.displayName = 'TableRow';

TableRow.propTypes = {
    /**
     * Should be valid `<tr>`children such as `TableCell`.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * If `true`, the table row will shade on hover.
     */
    hover: PropTypes.bool,
    /**
     * If `true`, the table row will have the selected shading.
     */
    selected: PropTypes.bool

};

// If default props are needed, they must be set here
TableRow.defaultProps = {
    component: 'tr',
    hover: false,
    selected: false
};

export default withStyles(styles)(TableRow);