import { createMuiTheme } from '@material-ui/core/styles';
import lightTheme, { lightThemeOptions } from './light/light-theme.js';
import darkTheme, { darkThemeOptions } from './dark/dark-theme.js';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

/**
 * Creates an Ethos theme, based on one of our standard themes.
 *
 * @example <caption>Create a theme that overrides the large breakpoint value</caption>
 *
 * createEDSTheme('light', {
 *     breakpoints: {
 *         values: {
 *             lg: '1500',
 *         }
 *     }
 * })
 *
 * @since v0.0.13
 *
 * @param {theme} baseTheme    The theme to use as the basis of new new theme. Valid values: 'light', 'dark'.
 * @param {object} [overrides] Overrides to the base theme
 * @return {object}            The Ethos theme
 */
function createEDSTheme(baseTheme, overrides) {

    var theme = cloneDeep(lightTheme);
    var themeOptions = cloneDeep(lightThemeOptions);

    // grab the baseline theme
    switch (baseTheme) {
        case 'light':
            break;
        case 'dark':
            theme = cloneDeep(darkTheme);
            themeOptions = cloneDeep(darkThemeOptions);
            break;
        default:
            console.warn('createEDSTheme: Invalid base theme specified: ' + baseTheme + '. Defaulting to light theme.');
            break;

    }

    // apply any overrides
    if (overrides) {
        theme = createMuiTheme(merge(themeOptions, overrides));
    }

    return theme;
}

export default createEDSTheme;