'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _styles = require('@material-ui/core/styles');

var _useMediaQuery = require('../useMediaQuery');

var _useMediaQuery2 = _interopRequireDefault(_useMediaQuery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 * https://material-ui.com/components/use-media-query/#migrating-from-withwidth
 */

/**
 * This method returns the size of the viewport on which an app is running.
 * Return value would be one of 'xs', 'sm', 'md', 'lg', 'xl'.
 */
var useWidth = function useWidth() {
    var theme = (0, _styles.useTheme)();
    var keys = [].concat(_toConsumableArray(theme.breakpoints.keys)).reverse();

    return keys.reduce(function (output, key) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var matches = (0, _useMediaQuery2.default)(theme.breakpoints.up(key));
        return !output && matches ? key : output;
    }, null) || 'xs';
};

exports.default = useWidth;