'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _TableSortLabel = require('@material-ui/core/TableSortLabel');

var _TableSortLabel2 = _interopRequireDefault(_TableSortLabel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: {}
};

/**
 * Table component for the label of a column sort
 */
var TableSortLabel = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: classes.root
    };

    return _react2.default.createElement(_TableSortLabel2.default, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

TableSortLabel.muiName = 'TableSortLabel';

TableSortLabel.propTypes = {
    /**
     * If true, the table row will have the selected shading.
     */
    active: _propTypes2.default.bool,
    /**
     * Label contents, the arrow will be appended automatically.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The current sort direction.
     */
    direction: _propTypes2.default.oneOf(['asc', 'desc'])

};

TableSortLabel.displayName = 'TableSortLabel';

// If default props are needed, they must be set here
TableSortLabel.defaultProps = {
    active: false,
    direction: 'desc'
};

exports.default = (0, _styles2.default)(styles)(TableSortLabel);