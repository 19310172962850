'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

/**
 * This is a custom hook to determine if the dialog consists of a `Datepicker` component.
 * If so the `enforceFocusFlag` is set to `true` momentarily to allow the control to go out of a `Dialog`.
 * TODO: This is a dirty hack and get rid of this code once we switch the date picker with some other lib.
 */
function useEnforceFocusFlag() {
    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        disableEnforceFocusFlag = _useState2[0],
        setEnforceFocusFlag = _useState2[1];

    var onFocus = function onFocus(e) {
        if (e.target.querySelector('.DateInput_input')) {
            setEnforceFocusFlag(true);
        }
    };

    var onBlur = function onBlur(e) {
        if (e.target.getAttribute && e.target.getAttribute('tabindex') === '0') {
            setEnforceFocusFlag(false);
        }
    };

    return [disableEnforceFocusFlag, onFocus, onBlur];
}

exports.default = useEnforceFocusFlag;