'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.lightThemeOptions = exports.default = undefined;

var _toolbar;

var _styles = require('@material-ui/core/styles');

var _createBreakpoints = require('@material-ui/core/styles/createBreakpoints');

var _createBreakpoints2 = _interopRequireDefault(_createBreakpoints);

var _palette = require('../common/palette');

var _palette2 = _interopRequireDefault(_palette);

var _tokens = require('@hedtech/react-design-system/core/styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Breakpoints must be defined in ascending order of viewport size. These breakpoints are used to calculate the size of the viewport in useWidth() hook.
// If values are "NOT" in ascending order then useWidth() hook will fail to return correct value.
var breakpoints = (0, _createBreakpoints2.default)({
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }
});

var lightThemeOptions = {
    props: {
        MuiButtonBase: {
            disableRipple: true // Disable ripple effect on components
        }
    },
    breakpoints: breakpoints,
    mixins: {
        toolbar: (_toolbar = {
            minHeight: _tokens.sizingXxLarge1
        }, _defineProperty(_toolbar, breakpoints.up('xs') + ' and (orientation: landscape)', {
            minHeight: _tokens.sizingXxLarge1
        }), _defineProperty(_toolbar, breakpoints.up('sm'), {
            minHeight: _tokens.sizingXxLarge1
        }), _toolbar)
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: _tokens.colorBrandNeutral500,
                '&$disabled:hover': {
                    cursor: 'not-allowed'
                },
                '&$error': {
                    color: _tokens.colorBrandNeutral500
                },
                '&$focused': {
                    color: _tokens.colorBrandNeutral500
                }
            },
            asterisk: {
                '&$error': {
                    color: _tokens.colorBrandNeutral500
                }
            }
        },
        MuiInput: {
            formControl: {
                'label + &': {
                    marginTop: 0,
                    width: '100%'
                }
            }
        },
        // Hover and selected states are same across
        MuiMenuItem: {
            root: {
                '&:hover': {
                    color: _tokens.colorBrandNeutral100
                },
                '&$selected': {
                    color: _tokens.colorBrandNeutral100
                }
            }
        }
    },
    palette: Object.assign({
        action: {
            active: _tokens.colorBrandCtaActive,
            disabled: _tokens.colorBrandNeutral400,
            hover: _tokens.colorBrandCtaHover,
            selected: _tokens.colorBrandCtaBase
        },
        grey: {
            100: _tokens.colorBrandNeutral100,
            200: _tokens.colorBrandNeutral200,
            250: _tokens.colorBrandNeutral250,
            300: _tokens.colorBrandNeutral300,
            400: _tokens.colorBrandNeutral400,
            500: _tokens.colorBrandNeutral500,
            600: _tokens.colorBrandNeutral600
        }
    }, _palette2.default),
    spacing: 4,

    typography: {

        fontFamily: _tokens.fontFamilyDefault, // Open Sans
        fontSize: 14, // fontSizeDefault
        fontWeight: _tokens.fontWeightNormal, // 400

        // Display headings in Nunito
        h1: _defineProperty({
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeHeader1, // 36px
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightParagraphSmall
        }, '@media ' + _tokens.mqXSmall, {
            fontSize: _tokens.fontSizeHeader1Small, // 30px
            lineHeight: _tokens.lineHeightHeader1
        }),
        h2: _defineProperty({
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeHeader2, // 26px
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightHeader2Small }, '@media ' + _tokens.mqXSmall, {
            fontSize: _tokens.fontSizeHeader2Small, // 24px
            lineHeight: _tokens.lineHeightHeader2 // 1.23
        }),
        h3: {
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeHeader3, // 21px
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightHeader3
        },
        h4: {
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeHeader4, // 18px
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightHeader4
        },
        h5: {
            fontFamily: _tokens.fontFamilyHeader,
            fontWeight: _tokens.fontWeightNormal
        },
        h6: {
            fontFamily: _tokens.fontFamilyHeader,
            fontWeight: _tokens.fontWeightNormal
        },
        subtitle1: { // h6
            fontFamily: _tokens.fontFamilyHeader,
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightHeader3
        },
        subtitle2: { // h6
            fontFamily: _tokens.fontFamilyHeader,
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightHeader3
        },
        // Standard (usually serif) body text
        body1: {
            fontSize: _tokens.fontSizeLarge, // 16px
            lineHeight: _tokens.lineHeightParagraphLarge // 1.375
        },
        // Compact (usually sans-serif) body text
        body2: {
            fontSize: _tokens.fontSizeDefault, // 14px
            lineHeight: _tokens.lineHeightParagraphMedium, // 1.43
            fontWeight: _tokens.fontWeightNormal
        },
        button: {
            fontWeight: _tokens.fontWeightStrong,
            fontSize: _tokens.fontSizeLarge, // 16px
            fontFamily: _tokens.fontFamilyHeader
        },
        // Small caption text
        caption: {
            fontSize: _tokens.fontSizeSmall, //  12px
            lineHeight: _tokens.lineHeightParagraphSmall, // 1.5
            color: _tokens.colorBrandNeutral600
        }
    },
    zIndex: {
        textFieldLabel: _tokens.zIndexTextFieldLabel,
        dropdown: _tokens.zIndexDropdown,
        headerBarDropdown: _tokens.zIndexHeaderBarDropdown,
        actionMenu: _tokens.zIndexActionMenu,
        megaMenu: _tokens.zIndexMegaMenu,
        inlineAlert: _tokens.zIndexInlineAlert,
        pageAlert: _tokens.zIndexPageAlert,
        headerBar: _tokens.zIndexHeaderBar,
        modal: _tokens.zIndexModal
    }
};

var lightTheme = (0, _styles.createMuiTheme)(lightThemeOptions);

exports.default = lightTheme;
exports.lightThemeOptions = lightThemeOptions;