'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var STEP_PROGRESS_STEP_CLASSNAME = exports.STEP_PROGRESS_STEP_CLASSNAME = 'eds-step-progress-step';
var MOBILE_STEPPER_LABEL_CLASSNAME = exports.MOBILE_STEPPER_LABEL_CLASSNAME = 'eds-mobile-stepper-label';
var STEP_LABEL_CLASSNAME = exports.STEP_LABEL_CLASSNAME = 'eds-step-label';
var STEP_LABEL_ERROR_CLASSNAME = exports.STEP_LABEL_ERROR_CLASSNAME = 'eds-step-label-error';
var STEP_LABEL_HAS_ERROR_CLASSNAME = exports.STEP_LABEL_HAS_ERROR_CLASSNAME = 'eds-step-label-has-error';
var ACTIVE_STEP_SCREEN_READER_TEXT = exports.ACTIVE_STEP_SCREEN_READER_TEXT = 'eds-active-step-screen-reader-text';