'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ErrorTemplateBody = require('./ErrorTemplateBody');

var _ErrorTemplateBody2 = _interopRequireDefault(_ErrorTemplateBody);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MaintenancePage = _react2.default.forwardRef(function (props, ref) {
    var edsContext = props.edsContext;

    var errorTitle = edsContext.formatMessage('component.ErrorPage.maintenance');
    var errorMessage = edsContext.formatMessage('component.ErrorPage.maintenance.message');

    return _react2.default.createElement(_ErrorTemplateBody2.default, Object.assign({
        largeIllustrationName: '503.png',
        errorTitle: errorTitle,
        errorMessage: errorMessage
    }, props, {
        ref: ref
    }));
});

MaintenancePage.propTypes = {
    edsContext: _propTypes2.default.object
};

exports.default = MaintenancePage;