'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Popover = require('@material-ui/core/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
  // Necessary style overrides are set here
  // See Material UI documentation for available CSS classes to override
  paperRoot: {}
};

/**
 * Use `Popover` to display additional content.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  PopoverExamples
 */
var Popover = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  // This component passes through to an internal paper element to style the pertinent area
  // By default it passes the paper class above, but that can be overridden by downstream
  // NOTE! There appears to be a problem preventing styling currently, see the expected behavior here: https://codesandbox.io/s/wq78039yol


  var PaperProps = {
    classes: {
      root: classes.paperRoot
    }
  };

  return _react2.default.createElement(_Popover2.default, Object.assign({}, rest, {
    PaperProps: PaperProps,
    ref: ref
  }));
});

Popover.muiName = 'Popover';
Popover.displayName = 'Popover';

Popover.propTypes = {
  /**
   * This is callback property. It's called by the component on mount.
   * This is useful when you want to trigger an action programmatically.
   * It currently only supports updatePosition() action.
   *
   * @param {object} actions This object contains all posible actions
   * that can be triggered programmatically.
   */
  action: _propTypes2.default.func,
  /**
   * This is the DOM element, or a function that returns the DOM element,
   * that may be used to set the position of the popover.
   */
  anchorEl: _propTypes2.default.oneOfType([_propTypes2.default.object, _propTypes2.default.func]),
  /**
   * This is the point on the anchor where the popover's
   * `anchorEl` will attach to. This is not used when the
   * anchorReference is 'anchorPosition'.
   *
   * Options:
   * vertical: [top, center, bottom];
   * horizontal: [left, center, right].
   */
  anchorOrigin: _propTypes2.default.shape({
    horizontal: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.oneOf(['left', 'center', 'right'])]),
    vertical: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.oneOf(['top', 'center', 'bottom'])])
  }),
  /**
   * This is the position that may be used
   * to set the position of the popover.
   * The coordinates are relative to
   * the application's client area.
   */
  anchorPosition: _propTypes2.default.shape({
    top: _propTypes2.default.number,
    left: _propTypes2.default.number
  }),
  /*
   * This determines which anchor prop to refer to to set
   * the position of the popover.
   */
  anchorReference: _propTypes2.default.oneOf(['anchorEl', 'anchorPosition', 'none']),
  /**
   * Supported props for the Backdrop of Popover:
   *
   * children: The content of the component.
   *
   * classes: Override or extend the styles applied to the component.
   *
   * invisible: If true, the backdrop is invisible.
   *
   * open: If true, the backdrop is open.
   *
   * tranistionDuration: The duration for the transition, in milliseconds. You may specify a single timeout for all transitions, or individually with an object.
   *
   * See more detailed information here: https://material-ui.com/api/backdrop/
   */
  BackdropProps: _propTypes2.default.object,
  /**
   * The content of the component.
   */
  children: _propTypes2.default.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * A node, component instance, or function that returns either.
   * The `container` will passed to the Modal component.
   * By default, it uses the body of the anchorEl's top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: _propTypes2.default.oneOfType([_propTypes2.default.object, _propTypes2.default.func]),
  /**
   * The elevation of the popover.
   */
  elevation: _propTypes2.default.number,
  /**
   * This function is called in order to retrieve the content anchor element.
   * It's the opposite of the `anchorEl` property.
   * The content anchor element should be an element inside the popover.
   * It's used to correctly scroll and set the position of the popover.
   * The positioning strategy tries to make the content anchor element just above the
   * anchor element.
   */
  getContentAnchorEl: _propTypes2.default.func,
  /**
   * Specifies how close to the edge of the window the popover can appear.
   */
  marginThreshold: _propTypes2.default.number,
  /**
   * Callback fired when the component requests to be closed.
   *
   * @param {object} event The event source of the callback.
   */
  onClose: _propTypes2.default.func,
  /**
   * Callback fired before the component is entering.
   */
  onEnter: _propTypes2.default.func,
  /**
   * Callback fired when the component has entered.
   */
  onEntered: _propTypes2.default.func,
  /**
   * Callback fired when the component is entering.
   */
  onEntering: _propTypes2.default.func,
  /**
   * Callback fired before the component is exiting.
   */
  onExit: _propTypes2.default.func,
  /**
   * Callback fired when the component has exited.
   */
  onExited: _propTypes2.default.func,
  /**
   * Callback fired when the component is exiting.
   */
  onExiting: _propTypes2.default.func,
  /**
   * If `true`, the popover is visible.
   */
  open: _propTypes2.default.bool.isRequired,
  /**
   * Properties applied to the `Paper` element.
   */
  PaperProps: _propTypes2.default.object,
  /**
   * @ignore
   */
  role: _propTypes2.default.string,
  /**
   * This is the point on the popover which
   * will attach to the anchor's origin.
   *
   * Options:
   * vertical: [top, center, bottom, x(px)];
   * horizontal: [left, center, right, x(px)].
   */
  transformOrigin: _propTypes2.default.shape({
    horizontal: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.oneOf(['left', 'center', 'right'])]),
    vertical: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.oneOf(['top', 'center', 'bottom'])])
  }),
  /**
   * Transition component.
   */
  TransitionComponent: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
  /**
   * Set to 'auto' to automatically calculate transition time based on height.
   */
  transitionDuration: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.shape({ enter: _propTypes2.default.number, exit: _propTypes2.default.number }), _propTypes2.default.oneOf(['auto'])]),
  /**
   * Properties applied to the `Transition` element.
   */
  TransitionProps: _propTypes2.default.object
};

// If default props are needed, they must be set here
// Popover.defaultProps = { };

exports.default = (0, _styles2.default)(styles)(Popover);