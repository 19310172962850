import Constants from '../helpers/Constants';
import Request from '../helpers/Request';
const LayoutRequests = {
    getCountCart() {
        return fetch(`${Constants.webUrl}/Cart/Count`, {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: Constants.headersRequestsJson,
            method: 'get'
        })
            .then(response => {
            if (response && response.ok) {
                return response.json();
            }
            else {
                throw new DOMException('Error in getCountCart');
            }
        })
            .catch(error => console.error(error));
    },
    getInitialLoadData(idModule, idPage, resolver, resolveError) {
        const data = { idModule: encodeURI(idModule), idPage: encodeURI(idPage) };
        Request.post(this.getInitialLoadData.name, '/Layout/InitialLoadData', data, resolver, resolveError);
    },
    getResources(idModule, idPage, resolver, resolveError, signal) {
        Request.get(this.getInitialLoadData.name, `/Layout/Resources/${encodeURI(idModule)}/${encodeURI(idPage)}`, resolver, resolveError, signal);
    },
    getPermissions(idModule, idPage, resolver, resolveError) {
        Request.get(this.getInitialLoadData.name, `/Layout/Permissions/${encodeURI(idModule)}/${encodeURI(idPage)}`, resolver, resolveError);
    },
    postError(logData) {
        try {
            const data = { logData };
            fetch(`${Constants.webUrl}/Logging/Error`, {
                body: JSON.stringify(data),
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: Constants.headersRequestsJson,
                method: 'post'
            })
                .then(response => {
                if (!(response && response.ok)) {
                    throw new Error('Failed to register the error.');
                }
            })
                .catch(e => {
                throw e;
            });
        }
        catch (e) {
            throw e;
        }
    }
};
export default LayoutRequests;
