"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgBookmark = function SvgBookmark(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement(
                "g",
                { id: "1)-Main-Use/search", transform: "translate(1.000000, 1.000000)" },
                _react2.default.createElement("path", {
                    d: "M57.6007031,53.1149846 L43.8490308,39.3633123 C43.5884967,39.1027782 43.2486695,38.9668473 42.8861872,38.9668473 L41.3909477,38.9668473 C44.9591329,34.8322836 47.1226991,29.4516869 47.1226991,23.5613495 C47.1226991,10.5459694 36.5767297,0 23.5613495,0 C10.5459694,0 0,10.5459694 0,23.5613495 C0,36.5767297 10.5459694,47.1226991 23.5613495,47.1226991 C29.4516869,47.1226991 34.8322836,44.9591329 38.9668473,41.3909477 L38.9668473,42.8861872 C38.9668473,43.2486695 39.1141058,43.5884967 39.3633123,43.8490308 L53.1149846,57.6007031 C53.6473805,58.133099 54.508276,58.133099 55.0406718,57.6007031 L57.6007031,55.0406718 C58.133099,54.508276 58.133099,53.6473805 57.6007031,53.1149846 Z",
                    id: "Path",
                    fill: "currentColor"
                }),
                _react2.default.createElement("path", {
                    d: "M23.5,42 C13.27875,42 5,33.72125 5,23.5 C5,13.27875 13.27875,5 23.5,5 C33.72125,5 42,13.27875 42,23.5 C42,33.72125 33.72125,42 23.5,42 Z",
                    id: "Path",
                    fill: "#FFFFFF",
                    opacity: "0.88"
                })
            )
        )
    );
};

exports.default = SvgBookmark;