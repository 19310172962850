'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _DialogContentText = require('@material-ui/core/DialogContentText');

var _DialogContentText2 = _interopRequireDefault(_DialogContentText);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.fontWeight,
            lineHeight: theme.typography.body2.lineHeight,
            marginBottom: _tokens.spacingSmall
        }, theme.breakpoints.up('sm'), {
            marginBottom: _tokens.spacingSmall
        })
    };
};

/**
 * DialogContentText is the text of the main content within a Dialog.
 */
var DialogContentText = _react2.default.forwardRef(function (props, ref) {
    var children = props.children,
        classesProp = props.classes,
        rest = _objectWithoutProperties(props, ['children', 'classes']);

    var classes = {
        root: classesProp.root
    };

    return _react2.default.createElement(
        _DialogContentText2.default,
        Object.assign({ classes: classes }, rest, { ref: ref }),
        children
    );
});

DialogContentText.muiName = 'DialogContentText';
DialogContentText.displayName = 'DialogContentText';

DialogContentText.propTypes = {
    /**
     * The content of the component.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string
};

exports.default = (0, _styles2.default)(styles)(DialogContentText);