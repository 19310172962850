'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
  return {
    // Necessary style overrides are set here
    root: {},
    colorError: {
      color: theme.palette.status.error.text
    },
    colorPrimary: {},
    colorSecondary: {},
    colorTextPrimary: {},
    colorTextSecondary: {},
    h4: {},
    h1: {},
    h2: {},
    h3: {},
    body2: {},
    body1: {},
    caption: {}
  };
};

/**
 * Use `Typography` to create visual hierarchy, organize content, and guide users through interfaces.
 * @done true
 * @updated false
 * @versionAdded v0.0.12
 * @examples
 *  TypographyExample
 */
var Typography = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      children = props.children,
      variant = props.variant,
      rest = _objectWithoutProperties(props, ['classes', 'children', 'variant']);

  var currentVariant = variant;

  var classOverrides = Object.assign({
    // See Material UI documentation for available CSS classes can be passed through to override
    root: classes.root,
    colorError: classes.colorError
  }, classes);

  if (currentVariant === 'body3') {
    currentVariant = 'caption';
  }

  return _react2.default.createElement(
    _Typography2.default,
    Object.assign({}, rest, {
      variant: currentVariant,
      classes: classOverrides,
      ref: ref
    }),
    children
  );
});

Typography.muiName = 'Typography';
Typography.displayName = 'Typography';

Typography.propTypes = {
  /**
   * Set the text-align on the component.
   */
  align: _propTypes2.default.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  /**
   * The content of the component.
   */
  children: _propTypes2.default.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: _propTypes2.default.oneOf(['initial', 'error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary']),
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   * By default, it maps the variant to a good default headline component.
   */
  component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
  /**
   * Controls the display type
   */
  display: _propTypes2.default.oneOf(['initial', 'block', 'inline']),
  /**
   * If `true`, the text will have a bottom margin.
   */
  gutterBottom: _propTypes2.default.bool,
  /**
   * We are empirically mapping the variant property to a range of different DOM element types.
   * For instance, h1 to h6. If you wish to change that mapping, you can provide your own.
   * Alternatively, you can use the `component` property.
   */
  variantMapping: _propTypes2.default.object,
  /**
   * If `true`, the text will not wrap, but instead will truncate with an ellipsis.
   */
  noWrap: _propTypes2.default.bool,
  /**
   * If `true`, the text will have a bottom margin.
   */
  paragraph: _propTypes2.default.bool,
  /**
   * Applies the theme typography styles for the given variant.
   *
   */
  variant: _propTypes2.default.oneOf(['h1', 'h2', 'h3', 'h4', 'body1', 'body2', 'body3', 'inherit'])
};

Typography.defaultProps = {
  align: 'inherit',
  color: 'initial',
  display: 'block',
  gutterBottom: false,
  noWrap: false,
  paragraph: false,
  variant: 'body2'
};

exports.default = (0, _styles.withStyles)(styles)(Typography);