'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        // Necessary style overrides are set here
        root: {
            display: 'inline-block',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.button.fontWeight,
            padding: _tokens.spacingXxSmall + ' ' + _tokens.spacingXSmall
        },
        default: {
            backgroundColor: theme.palette.status.default.background,
            color: theme.palette.status.default.text
        },
        draft: {
            backgroundColor: theme.palette.status.warning.background,
            color: theme.palette.status.warning.text
        },
        error: {
            backgroundColor: theme.palette.status.error.background,
            color: theme.palette.status.error.text
        },
        pending: {
            backgroundColor: theme.palette.status.neutral.background,
            color: theme.palette.status.neutral.text
        },
        success: {
            backgroundColor: theme.palette.status.success.background,
            color: theme.palette.status.success.text
        }
    };
};

/**
 * Use a `StatusLabel` to indicate the status or category of an item.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  StatusLabelExample
 *  StatusLabelTableExample
 *  StatusLabelText
 */
var StatusLabel = _react2.default.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        className = props.className,
        textProp = props.text,
        typeProp = props.type,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'text', 'type']);

    var text = textProp.toUpperCase();
    var type = typeProp.toLowerCase();

    var classOverrides = {
        //CSS classes to override from Material UI Typography CSS API
        root: (0, _classnames2.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.default, type === 'default'), _defineProperty(_classNames, classes.draft, type === 'draft'), _defineProperty(_classNames, classes.error, type === 'error'), _defineProperty(_classNames, classes.pending, type === 'pending'), _defineProperty(_classNames, classes.success, type === 'success'), _classNames), className)
    };

    return _react2.default.createElement(
        _Typography2.default,
        Object.assign({ component: 'span',
            classes: classOverrides,
            ref: ref
        }, rest),
        text
    );
});

StatusLabel.muiName = 'StatusLabel';

StatusLabel.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * Text for the component to display
     */
    text: _propTypes2.default.string.isRequired,
    /**
     * Type of status to indicate (default, draft, error, pending, success)
     */
    type: _propTypes2.default.oneOf(['default', 'draft', 'error', 'pending', 'success'])
};

StatusLabel.displayName = 'StatusLabel';

StatusLabel.defaultProps = {
    type: 'default'
};

exports.default = (0, _styles2.default)(styles)(StatusLabel);