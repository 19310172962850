'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Tooltip = require('@material-ui/core/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    tooltip: _defineProperty({
      paddingLeft: _tokens.spacingSmall,
      paddingRight: _tokens.spacingSmall,
      fontSize: theme.typography.body2.fontSize,
      maxWidth: '18.750rem',
      lineHeight: theme.typography.caption.lineHeight,
      textAlign: 'center',
      backgroundColor: theme.palette.grey[500]
    }, theme.breakpoints.up('sm'), {
      fontSize: theme.typography.caption.fontSize,
      padding: _tokens.spacingXxSmall
    })
  };
};

/**
 * Use `Tooltip` to display a small amount of informative text.
 * @done true
 * @updated false
 * @versionAdded v0.0.9
 * @examples
 *  TooltipExample
 *  TooltipPositionedExample
 *  TooltipDisabledButton
 */
var Tooltip = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    tooltip: classes.tooltip
  };

  return _react2.default.createElement(_Tooltip2.default, Object.assign({
    classes: classOverrides,
    ref: ref
  }, rest));
});

Tooltip.muiName = 'Tooltip';
Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  /**
   * Tooltip reference element.
   */
  children: _propTypes2.default.element.isRequired,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * Do not respond to focus events.
   */
  disableFocusListener: _propTypes2.default.bool,
  /**
   * Do not respond to hover events.
   */
  disableHoverListener: _propTypes2.default.bool,
  /**
   * Do not respond to long press touch events.
   */
  disableTouchListener: _propTypes2.default.bool,
  /**
   * The number of milliseconds to wait before showing the tooltip.
   * This property won't impact the enter touch delay (`enterTouchDelay`).
   */
  enterDelay: _propTypes2.default.number,
  /**
   * The number of milliseconds a user must touch the element before showing the tooltip.
   */
  enterTouchDelay: _propTypes2.default.number,
  /**
   * The relationship between the tooltip and the wrapper component is not clear from the DOM.
   * By providing this property, we can use aria-describedby to solve the accessibility issue.
   */
  id: _propTypes2.default.string,
  /**
   * The number of milliseconds to wait before hiding the tooltip.
   * This property won't impact the leave touch delay (`leaveTouchDelay`).
   */
  leaveDelay: _propTypes2.default.number,
  /**
   * The number of milliseconds after the user stops touching an element before hiding the tooltip.
   */
  leaveTouchDelay: _propTypes2.default.number,
  /**
   * Callback fired when the tooltip requests to be closed.
   *
   * @param {object} event The event source of the callback
   */
  onClose: _propTypes2.default.func,
  /**
   * Callback fired when the tooltip requests to be open.
   *
   * @param {object} event The event source of the callback
   */
  onOpen: _propTypes2.default.func,
  /**
   * If `true`, the tooltip is shown.
   */
  open: _propTypes2.default.bool,
  /**
   * Tooltip placement
   */
  placement: _propTypes2.default.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
  /**
   * Properties applied to the `Popper` element.
   */
  PopperProps: _propTypes2.default.object,
  /**
   * @ignore
   */
  theme: _propTypes2.default.object,
  /**
   * Tooltip title. Zero-length titles string are never displayed.
   */
  title: _propTypes2.default.node.isRequired
};

// If default props are needed, they must be set here
// Tooltip.defaultProps = { };

exports.default = (0, _styles2.default)(styles)(Tooltip);