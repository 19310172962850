import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

var useStyles = makeStyles(function (theme) {
    return {
        '@global': {
            html: {
                WebkitFontSmoothing: 'antialiased', // Antialiasing.
                MozOsxFontSmoothing: 'grayscale', // Antialiasing.
                // Change from `box-sizing: content-box` so that `width`
                // is not affected by `padding` or `border`.
                boxSizing: 'border-box'
            },
            '*, *::before, *::after': {
                boxSizing: 'inherit'
            },
            body: {
                margin: 0, // Remove the margin in all browsers.
                backgroundColor: theme.palette.background.default,
                '@media print': {
                    // Save printer ink.
                    backgroundColor: theme.palette.common.white
                }
            }
        }
    };
}, { name: 'CssBaseline' });

function CssBaseline(props) {
    var _props$children = props.children,
        children = _props$children === undefined ? null : _props$children;

    useStyles();
    return React.createElement(
        React.Fragment,
        null,
        children
    );
}

CssBaseline.propTypes = {
    /**
     * You can wrap a node.
     */
    children: PropTypes.node
};

export default CssBaseline;