function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { StepLabel as MuiStepLabel } from '@material-ui/core';
import Warning from '@eui/ds-icons/lib/Warning';
import { sizingMedium, borderWidthThin } from '../styles/tokens';
import Typography from '../Typography';
import { STEP_LABEL_ERROR_CLASSNAME, STEP_LABEL_HAS_ERROR_CLASSNAME, STEP_LABEL_CLASSNAME } from './internal/constants.js';
import StepEdit from './internal/StepEdit';

var styles = function styles(theme) {
    return {
        labelRoot: {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.palette.grey[600]
        },
        labelError: {
            color: theme.palette.status.error.fill
        },
        stepIconRoot: {
            color: theme.palette.grey[100],
            borderRadius: '100%',
            border: borderWidthThin + ' solid ' + theme.palette.ctaColor.base,
            '&$stepIconActive': {
                border: 'none',
                color: theme.palette.ctaColor.base,
                '&> text': {
                    color: theme.palette.grey[100],
                    fill: theme.palette.grey[100]
                }
            }
        },
        stepIconText: {
            fill: theme.palette.ctaColor.base
        },
        stepIconActive: {
            color: theme.palette.ctaColor.base
        },
        stepIconCustomRoot: {
            border: 'none',
            width: sizingMedium,
            height: sizingMedium,
            verticalAlign: 'middle'
        },
        stepIconError: {
            color: theme.palette.status.error.fill,
            fill: theme.palette.status.error.fill
        },
        stepIconCompleted: {
            color: theme.palette.ctaColor.base,
            fill: theme.palette.ctaColor.base
        },
        stepIconDisabled: {
            '& svg': {
                border: borderWidthThin + ' solid ' + theme.palette.grey[400],
                '&> text': {
                    color: theme.palette.grey[400],
                    fill: theme.palette.grey[400]
                }
            }
        },
        stepLabelDisabled: {
            color: theme.palette.grey[400]
        }
    };
};

/**
 * A component that displays progress through numbered steps.
 */
var StepLabel = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        children = props.children,
        error = props.error,
        completed = props.completed,
        optional = props.optional,
        disabled = props.disabled,
        StepIconProps = props.StepIconProps,
        rest = _objectWithoutProperties(props, ['classes', 'children', 'error', 'completed', 'optional', 'disabled', 'StepIconProps']);

    var defaultOverrides = {
        StepIconProps: Object.assign({
            classes: {
                root: classes.stepIconRoot,
                text: classes.stepIconText,
                active: classes.stepIconActive,
                completed: classes.stepIconActive,
                error: classes.stepIconError
            }
        }, StepIconProps)
    };

    if (optional) {
        defaultOverrides.optional = React.createElement(
            Typography,
            {
                variant: 'body3',
                color: 'textSecondary',
                align: props.alternativeLabel ? 'center' : 'left'
            },
            optional
        );
    }

    if (error) {
        // Assign new error icon
        defaultOverrides.StepIconProps.icon = React.createElement(Warning, { className: classNames(classes.stepIconError, classes.stepIconCustomRoot) });

        // if error is a string make the optional prop print the error message
        if (error.length > 0) {
            /* These marker class names will be used by its parent component StepProgress */
            defaultOverrides.optional = React.createElement(
                Typography,
                {
                    variant: 'body3',
                    className: classNames(classes.labelError, STEP_LABEL_ERROR_CLASSNAME),
                    align: props.alternativeLabel ? 'center' : 'left'
                },
                error
            );
        }
    }

    if (props.active) {
        defaultOverrides.StepIconProps.icon = React.createElement(StepEdit, { className: classes.stepIconActive });
    }

    // NOTE: This icon is not in the @eui/ds-icons package
    // if (completed) {
    //     // Assign new check icon
    //     defaultOverrides.StepIconProps.icon = (
    //         <svg aria-hidden="true" className={classNames(classes.stepIconCustomRoot, classes.stepIconCompleted)} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>
    //     );
    // }

    return React.createElement(
        MuiStepLabel,
        Object.assign({}, defaultOverrides, {
            classes: {
                disabled: !error && disabled && classes.stepIconDisabled
            },
            disabled: disabled,
            completed: completed
        }, rest, {
            ref: ref
        }),
        React.createElement(
            'span',
            { className: classNames(classes.labelRoot, '' + STEP_LABEL_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, classes.labelError, error), _defineProperty(_classNames, STEP_LABEL_HAS_ERROR_CLASSNAME, error), _defineProperty(_classNames, classes.stepLabelDisabled, disabled && !error), _classNames)) },
            children
        )
    );
});

StepLabel.muiName = 'StepLabel';
StepLabel.displayName = 'StepLabel';

StepLabel.propTypes = {
    /**
     * @ignore
     * Sets the step as active. Is passed to child components.
     */
    active: PropTypes.bool,
    /**
     * @ignore
     * Set internally by Stepper when it's supplied with the alternativeLabel property.
     */
    alternativeLabel: PropTypes.bool,
    /**
     * In most cases will simply be a string containing a title for the label.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * @ignore
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * Mark the step as disabled, will also disable the button if
     * `StepLabelButton` is a child of `StepLabel`. Is passed to child components.
     */
    disabled: PropTypes.bool,
    /**
     * Used to mark the step in an error state and provide an error message.
     */
    error: PropTypes.any,
    /**
     * Override the default icon.
     */
    icon: PropTypes.node,
    /**
     * The optional text to display.
     */
    optional: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

StepLabel.defaultProps = {
    error: false
};

export default withStyles(styles)(StepLabel);