'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ERROR_PAGES = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _HeaderBar = require('./../HeaderBar');

var _HeaderBar2 = _interopRequireDefault(_HeaderBar);

var _Logo = require('./../Logo');

var _Logo2 = _interopRequireDefault(_Logo);

var _EDSContext = require('../EDSContext/EDSContext');

var _styles = require('../styles');

var _MaintenancePage = require('./internal/MaintenancePage');

var _MaintenancePage2 = _interopRequireDefault(_MaintenancePage);

var _BadRequest400Page = require('./internal/BadRequest400Page');

var _BadRequest400Page2 = _interopRequireDefault(_BadRequest400Page);

var _Unauthorized401Page = require('./internal/Unauthorized401Page');

var _Unauthorized401Page2 = _interopRequireDefault(_Unauthorized401Page);

var _Forbidden403Page = require('./internal/Forbidden403Page');

var _Forbidden403Page2 = _interopRequireDefault(_Forbidden403Page);

var _PageNotFound404Page = require('./internal/PageNotFound404Page');

var _PageNotFound404Page2 = _interopRequireDefault(_PageNotFound404Page);

var _MethodNotAllowed405Page = require('./internal/MethodNotAllowed405Page');

var _MethodNotAllowed405Page2 = _interopRequireDefault(_MethodNotAllowed405Page);

var _InternalServerError500Page = require('./internal/InternalServerError500Page');

var _InternalServerError500Page2 = _interopRequireDefault(_InternalServerError500Page);

var _ServiceUnavailable503Page = require('./internal/ServiceUnavailable503Page');

var _ServiceUnavailable503Page2 = _interopRequireDefault(_ServiceUnavailable503Page);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: {
            height: 'calc(100vh)',
            backgroundColor: theme.palette.grey[200],
            '& .headerbar-logo': {
                maxWidth: _tokens.spacingXSmall
            }
        },
        noHeader: {
            height: 'calc(100vh - ' + _tokens.sizingXxLarge1 + ')'
        },
        errorTemplate: {
            display: 'flex',
            justifyContent: 'center'
        }
    };
};

var ERROR_PAGES = exports.ERROR_PAGES = {
    maintenance: _MaintenancePage2.default,
    badRequest: _BadRequest400Page2.default,
    unauthorized: _Unauthorized401Page2.default,
    forbidden: _Forbidden403Page2.default,
    pageNotFound: _PageNotFound404Page2.default,
    methodNotAllowed: _MethodNotAllowed405Page2.default,
    internalServerError: _InternalServerError500Page2.default,
    serviceUnavailable: _ServiceUnavailable503Page2.default,
    '400': _BadRequest400Page2.default,
    '401': _Unauthorized401Page2.default,
    '403': _Forbidden403Page2.default,
    '404': _PageNotFound404Page2.default,
    '405': _MethodNotAllowed405Page2.default,
    '500': _InternalServerError500Page2.default,
    '503': _ServiceUnavailable503Page2.default
};

/**
 * A template for creating error/maintenance pages
 * @done true
 * @updated false
 * @versionAdded v3.0.0
 * @template true
 * @examples
 *  ErrorPageExample
 *  ErrorPageCustomizationExamples
 */
var ErrorPage = _react2.default.forwardRef(function (props, ref) {
    var className = props.className,
        classes = props.classes,
        noHeader = props.noHeader,
        logo = props.logo;

    var ErrorPageTemplate = ERROR_PAGES[props.errorType];

    return ErrorPageTemplate ? _react2.default.createElement(
        'div',
        { ref: ref, className: (0, _classnames2.default)(className, classes.root, _defineProperty({}, classes.noHeader, noHeader)) },
        !noHeader && _react2.default.createElement(_HeaderBar2.default, { logo: logo }),
        _react2.default.createElement(
            'div',
            { className: classes.errorTemplate },
            _react2.default.createElement(ErrorPageTemplate, props)
        )
    ) : null;
});

ErrorPage.muiName = 'ErrorPage';
ErrorPage.displayName = 'ErrorPage';

ErrorPage.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * Custom error message
     */
    message: _propTypes2.default.string,
    /**
     * Link for correction of action. It can just be a URL or a Link, NavLink or any other router component.
     * For example:
     * If error is 401, then provide URL to sign in if you just want redirect the page.
     * If you want to just use NavLink then pass the NavLink component.
     */
    action: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.string]),
    /**
     * Error codes. You pass error codes directly or use the meaningful names.
     * default - Maintenance page
     * 400 - Bad Request
     * 401 - Unauthorized
     * 403 - Forbidden
     * 404 - Page Not Found
     * 405 - Method Not Allowed
     * 500 - Internal Server Error
     * 503 - Service Unavailable
     */
    errorType: _propTypes2.default.oneOf(['maintenance', // Maintenance page
    'badRequest', // 400
    'unauthorized', // 401
    'forbidden', // 403
    'pageNotFound', // 404
    'methodNotAllowed', // 405
    'internalServerError', // 500
    'serviceUnavailable', //503
    '400', '401', '403', '404', '405', '500', '503']).isRequired,
    /**
     * Use this prop to pass cutom url to override the default image for error pages.
     */
    imageSrc: _propTypes2.default.string,
    /**
     * Set this prop if you don't want to render the header.
     */
    noHeader: _propTypes2.default.bool,
    /**
     * The cutom logo to use in the header bar.
     */
    logo: _propTypes2.default.node
};

ErrorPage.defaultProps = {
    noHeader: false,
    logo: _react2.default.createElement(_Logo2.default, null)
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles.withStyles)(styles)(ErrorPage));