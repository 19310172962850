function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiIconButton } from '@material-ui/core/IconButton';
import { borderRadiusSmall, sizingXLarge, opacity2, opacity4, boxShadowFocus } from '../styles/tokens';
import classNames from 'classnames';

export var styles = function styles(theme) {
    return {
        root: {
            borderRadius: borderRadiusSmall,
            width: sizingXLarge,
            height: sizingXLarge,
            padding: 0
        },
        colorPrimary: {
            backgroundColor: theme.palette.ctaColor.base,
            color: theme.palette.text.white,
            fill: theme.palette.text.white,
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                color: theme.palette.text.white,
                fill: theme.palette.text.white
            },
            '&:active': {
                backgroundColor: theme.palette.ctaColor.active,
                color: theme.palette.text.white,
                fill: theme.palette.text.white
            },
            '&:focus': {
                boxShadow: boxShadowFocus
            },
            '&$disabled': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.disabled.background
            }
        },
        colorSecondary: {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[500],
            fill: theme.palette.grey[500],
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                color: theme.palette.text.white,
                fill: theme.palette.text.white
            },
            '&:active': {
                backgroundColor: theme.palette.ctaColor.active,
                color: theme.palette.text.white,
                fill: theme.palette.text.white
            },
            '&:focus': {
                boxShadow: boxShadowFocus
            },
            '&$disabled': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.disabled.background
            }
        },
        gray: {
            backgroundColor: 'transparent',
            color: theme.palette.grey[500],
            fill: theme.palette.grey[500],
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.ctaColor.hover,
                fill: theme.palette.ctaColor.hover
            },
            '&:active': {
                color: theme.palette.ctaColor.active,
                fill: theme.palette.ctaColor.active
            },
            '&:focus': {
                boxShadow: boxShadowFocus
            },
            '&$disabled': {
                color: theme.palette.grey[200]
            }
        },
        white: {
            background: 'transparent',
            color: theme.palette.text.white,
            fill: theme.palette.text.white,
            '&:hover': {
                background: 'rgb(21, 22, 24, ' + opacity2 + ')',
                color: theme.palette.text.white,
                fill: theme.palette.text.white
            },
            '&:active': {
                background: 'rgb(21, 22, 24, ' + opacity4 + ')',
                color: theme.palette.text.white,
                fill: theme.palette.text.white
            },
            '&:focus': {
                boxShadow: boxShadowFocus
            },
            '&$disabled': {
                backgroundColor: 'transparent',
                color: theme.palette.disabled.background
            }
        },
        disabled: {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.disabled.background,
            fill: theme.palette.disabled.background
        },
        disabledIconOnly: {
            backgroundColor: 'transparent',
            color: theme.palette.disabled.background,
            fill: theme.palette.disabled.background
        }
    };
};

/**
 * Use `IconButton` to indicate a user action that can be conveyed with an icon.
 * @done true
 * @updated false
 * @versionAdded v0.0.7
 * @examples
 *  BasicExample
 *  OnClickHandler
 */
var IconButton = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        color = props.color,
        children = props.children,
        rest = _objectWithoutProperties(props, ['classes', 'color', 'children']);

    var classOverrides = {
        colorPrimary: classes.colorPrimary,
        colorSecondary: classes.colorSecondary,
        disabled: classes.disabled
    };
    var propOverrides = {
        disableRipple: true
    };

    if (color === 'primary' || color === 'secondary') {
        propOverrides.color = color;
        classOverrides.root = classes.root;
    }
    if (color === 'gray' || color === 'white') {
        var _classNames;

        classOverrides.root = classNames(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.white, color === 'white'), _defineProperty(_classNames, classes.gray, color === 'gray'), _classNames));
        classOverrides.disabled = classes.disabledIconOnly;
    }

    return React.createElement(
        MuiIconButton,
        Object.assign({
            classes: classOverrides
        }, rest, propOverrides, {
            ref: ref
        }),
        children
    );
});

// necessary to add an explicit display name, since docgen can't seem to
// extract one from a forwardRef'd component
IconButton.displayName = 'IconButton';

IconButton.propTypes = {
    /**
     * Class name to assign the component.
     */
    className: PropTypes.string,
    /**
     * Classes used to extend original style object.
     */
    classes: PropTypes.object,
    /**
     * The icon element. This is generally an &lt;Icon&gt; component.
     */
    children: PropTypes.node.isRequired,
    /**
     * The color of the component. 'primary' and 'secondary' will give an IconButton with a background. 'gray' and 'white' will give an IconButton with no background.
     */
    color: PropTypes.oneOf(['primary', 'secondary', 'gray', 'white']),
    /**
     * If `true`, the icon button will be disabled.
     */
    disabled: PropTypes.bool
};

IconButton.defaultProps = {
    color: 'primary'
};

export default withStyles(styles, { name: 'IconButton', index: 0 })(IconButton);