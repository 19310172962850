'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _core = require('@material-ui/core');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = exports.styles = function styles(theme) {
  return {
    root: {
      color: theme.palette.text.secondary,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(12),
      '&$error': {
        color: theme.palette.status.error.text
      },
      '&$disabled': {
        color: theme.palette.text.disabled
      }
    },
    error: {},
    disabled: {},
    marginDense: {
      marginTop: theme.spacing(0.5)
    }
  };
};

/**
 *  A wrapper for standardized states for text near inputs in a form, disabled/error.
 */
var FormHelperText = _react2.default.forwardRef(function (props, ref) {
  return _react2.default.createElement(_core.FormHelperText, Object.assign({}, props, { ref: ref }));
});

FormHelperText.muiName = 'FormHelperText';
FormHelperText.displayName = 'FormHelperText';

FormHelperText.propTypes = {
  /**
   * The content of the component.
   */
  children: _propTypes2.default.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
  /**
   * If `true`, the helper text should be displayed in a disabled state.
   */
  disabled: _propTypes2.default.bool,
  /**
   * If `true`, helper text should be displayed in an error state.
   */
  error: _propTypes2.default.bool,
  /**
   * If `true`, the helper text should use filled classes key.
   */
  filled: _propTypes2.default.bool,
  /**
   * If `true`, the helper text should use focused classes key.
   */
  focused: _propTypes2.default.bool,
  /**
   * If `dense`, will adjust vertical spacing. This is normally obtained via context from
   * FormControl.
   */
  margin: _propTypes2.default.oneOf(['dense']),
  /**
   * If `true`, the helper text should use required classes key.
   */
  required: _propTypes2.default.bool
};

FormHelperText.defaultProps = {
  component: 'p'
};

FormHelperText.contextTypes = {
  muiFormControl: _propTypes2.default.object
};

exports.default = (0, _styles2.default)(styles)(FormHelperText);