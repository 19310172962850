import React from 'react';

var SvgCheckmark = function SvgCheckmark(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("circle", { fill: "currentColor", cx: 30, cy: 30, r: 28 }),
            React.createElement("circle", { fill: "#FFF", opacity: 0.88, cx: 30, cy: 30, r: 28 }),
            React.createElement("path", {
                d: "M30 0C13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30 16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0zm0 56.129C15.645 56.129 3.871 44.509 3.871 30 3.871 15.646 15.491 3.871 30 3.871c14.355 0 26.129 11.62 26.129 26.129 0 14.354-11.62 26.129-26.129 26.129zm17.133-33.261L25.3 44.525c-.569.564-1.488.56-2.052-.009L12.859 34.044a1.452 1.452 0 0 1 .008-2.053l1.031-1.022a1.452 1.452 0 0 1 2.053.008l8.344 8.411 19.77-19.612a1.452 1.452 0 0 1 2.054.008l1.022 1.03c.565.57.56 1.49-.008 2.054z",
                fill: "currentColor"
            })
        )
    );
};

export default SvgCheckmark;