'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Switch = require('@material-ui/core/Switch');

var _Switch2 = _interopRequireDefault(_Switch);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
    return {
        root: {
            width: '3.75rem',
            height: '3rem',
            padding: '0.9375rem 0.5rem 0.75rem 0.75rem'
        },
        switchBase: {
            '&$checked': {
                color: theme.palette.grey[100],
                '& + $barBase': {
                    opacity: 1,
                    backgroundColor: theme.palette.ctaColor.base,
                    border: 'none'
                },
                '& $iconBase': {
                    boxShadow: 'none',
                    border: 'none !important'
                },
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            '&:hover': {
                backgroundColor: 'transparent'
            },
            top: '8px',
            left: '5px'
        },
        checked: {
            left: '58px !important',
            display: 'block'
        },
        barBase: {
            borderRadius: '0.75rem',
            width: _tokens.sizingXLarge,
            height: '1.25rem',
            backgroundColor: theme.palette.grey[100],
            opacity: 1,
            transition: 'none'
        },
        bar: {
            '& + $barBase': {
                border: _tokens.borderWidthThick + ' solid ' + theme.palette.grey[400]
            }
        },
        barFocused: {
            '& + $barBase': {
                border: _tokens.borderWidthThick + ' solid ' + theme.palette.grey[400],
                boxShadow: _tokens.boxShadowFocus
            },
            '&$checked': {
                '& + $barBase': {
                    boxShadow: _tokens.boxShadowFocus
                }
            }
        },
        disabledOff: {
            '& + $barBase': {
                opacity: 1,
                backgroundColor: theme.palette.grey[300]
            },
            '&$switchBase': {
                color: theme.palette.grey[300], //disabled off circle
                boxShadow: 'none',
                '& + $barBase': {
                    opacity: 1,
                    backgroundColor: theme.palette.grey[300], //disabled off background
                    border: _tokens.borderWidthThick + ' solid ' + theme.palette.grey[400] //disabled off border
                }
            }
        },
        disabledOn: {
            '& + $barBase': {
                opacity: 1,
                backgroundColor: theme.palette.grey[300]
            },
            '&$switchBase': {
                color: theme.palette.grey[300], //disabled on circle
                boxShadow: 'none',
                '& + $barBase': {
                    opacity: 1,
                    backgroundColor: theme.palette.grey[400], //disabled on background
                    border: _tokens.borderWidthThick + ' solid ' + theme.palette.grey[400] //disabled on border
                }
            }
        },
        iconBase: {
            width: _tokens.sizingSmall,
            height: _tokens.sizingSmall
        },
        iconOn: {
            boxShadow: 'none',
            border: 'none !important'
        },
        iconOnDisabled: {
            boxShadow: 'none',
            border: 'none !important',
            color: theme.palette.grey[300]
        },
        iconOff: {
            color: theme.palette.grey[100],
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
            boxShadow: 'none !important' //MUI applies a very stubborn box-shadow when a border is added
        },
        iconOffDisabled: {
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
            boxShadow: 'none !important' //MUI applies a very stubborn box-shadow when a border is added
        }
    };
};

/**
 * Use `Switch` to select one of two options, typically a "yes/no" or an "on/off" selection.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  SwitchExamples
 *  ControlledExample
 *  SwitchFormGroup
 *  SwitchLabels
 */

var Switch = function (_React$Component) {
    _inherits(Switch, _React$Component);

    function Switch(props) {
        _classCallCheck(this, Switch);

        var _this = _possibleConstructorReturn(this, (Switch.__proto__ || Object.getPrototypeOf(Switch)).call(this, props));

        _this.state = {
            isFocused: false
        };
        _this.handleFocus = _this.handleFocus.bind(_this);
        _this.handleBlur = _this.handleBlur.bind(_this);
        return _this;
    }

    _createClass(Switch, [{
        key: 'handleFocus',
        value: function handleFocus(e) {
            if (this.props.onFocus) {
                this.props.onFocus(e);
            }
            this.setState(function (prevState) {
                return {
                    isFocused: true
                };
            });
        }
    }, {
        key: 'handleBlur',
        value: function handleBlur(e) {
            if (this.props.onBlur) {
                this.props.onBlur(e);
            }
            this.setState(function (prevState) {
                return {
                    isFocused: false
                };
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var isFocused = this.state.isFocused;

            var _props = this.props,
                classes = _props.classes,
                checked = _props.checked,
                disabled = _props.disabled,
                rest = _objectWithoutProperties(_props, ['classes', 'checked', 'disabled']);

            var classOverrides = {
                switchBase: (0, _classnames2.default)(classes.switchBase, isFocused ? classes.barFocused : classes.bar),
                track: classes.barBase,
                root: classes.root,
                disabled: checked ? classes.disabledOn : classes.disabledOff,
                thumb: (0, _classnames2.default)(classes.iconBase, disabled ? classes.iconOffDisabled : classes.iconOff),
                checked: classes.checked
            };

            return _react2.default.createElement(_Switch2.default, Object.assign({
                onFocus: this.handleFocus,
                onBlur: this.handleBlur,
                color: 'secondary',
                disableRipple: true,
                classes: classOverrides,
                checked: checked,
                disabled: disabled
            }, rest));
        }
    }]);

    return Switch;
}(_react2.default.Component);

Switch.muiName = 'Switch';
Switch.displayName = 'Switch';

Switch.propTypes = {
    /**
     * If `true`, the component is checked.
     */
    checked: _propTypes2.default.bool,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * If true, the switch will be disabled.
     */
    disabled: _propTypes2.default.bool,
    /**
     * The id of the input element.
     */
    id: _propTypes2.default.string,
    /**
     * Attributes applied to the input element.
     */
    inputProps: _propTypes2.default.object,
    /**
     * Use this property to pass a ref callback to the native input component.
     */
    inputRef: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.object]),
    /**
     * Callback fired when the state is changed.
     */
    onChange: _propTypes2.default.func,
    /**
     * @ignore
     */
    onBlur: _propTypes2.default.func,
    /**
     * @ignore
     */
    onFocus: _propTypes2.default.func
};

exports.default = (0, _styles2.default)(styles)(Switch);