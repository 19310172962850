import React from 'react';
import PropTypes from 'prop-types';
import NativeSelect from '@material-ui/core/NativeSelect';

import Typography from '../../Typography';
import ChevronDown from '@eui/ds-icons/lib/ChevronDown';
import { withStyles } from '@hedtech/react-design-system/core/styles';

import { fontSizeDefault, spacingMedium, spacingXSmall, spacingXxSmall, spacingXSmall2, sizingXxLarge1, fontFamilyHeader, fontWeightStrong } from '../../styles/tokens';

var styles = function styles(theme) {
    return {
        caption: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: spacingXxSmall + ' ' + spacingMedium + ' 0'
        },
        monthYearText: {
            flexGrow: 1,
            flexShrink: 1,
            textAlign: 'center',
            justifyContent: 'center'
        },
        monthSelect: {
            minWidth: '7.25rem' // 116px
        },
        yearSelect: {
            minWidth: sizingXxLarge1 // 64px
        },
        select: {
            fontFamily: fontFamilyHeader,
            fontSize: fontSizeDefault,
            fontWeight: fontWeightStrong,
            width: 'calc(100% - ' + spacingXSmall + ')',
            padding: spacingXxSmall,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.grey[200]
            }
        },
        icon: {
            top: 'calc(50% - ' + spacingXxSmall + ')',
            right: spacingXxSmall,
            position: 'absolute',
            pointerEvents: 'none',
            height: spacingXSmall2,
            width: spacingXSmall2
        }
    };
};

// TODO: remove the month year select options from here, no current need for them
function Caption(_ref) {
    var withMonthYearSelect = _ref.withMonthYearSelect,
        classes = _ref.classes,
        classNames = _ref.classNames,
        date = _ref.date,
        months = _ref.months,
        locale = _ref.locale,
        localeUtils = _ref.localeUtils,
        onClick = _ref.onClick,
        onChange = _ref.onChange,
        monthYearSelectOptions = _ref.monthYearSelectOptions,
        dir = _ref.dir;

    var captionMonths = localeUtils.getMonths(locale);
    var years = [];
    var isRtl = dir === 'rtl';

    var buildYearsOptions = function buildYearsOptions() {
        var yearOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
            min: 4,
            max: 4
        };

        var currentYear = new Date().getFullYear();
        var fromMonth = new Date(currentYear - yearOptions.min, 0);
        var toMonth = new Date(currentYear + yearOptions.max, 11);

        for (var i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
            years.push(i);
        }
    };

    if (monthYearSelectOptions) {
        var year = monthYearSelectOptions.year;

        buildYearsOptions(year);
    } else {
        buildYearsOptions();
    }

    var handleChange = function handleChange(e) {
        var _e$target$form = e.target.form,
            year = _e$target$form.year,
            month = _e$target$form.month;

        onChange(new Date(year.value, month.value));
    };

    var monthSelect = React.createElement(
        NativeSelect,
        {
            key: 'hedtech-datepicker-month-select',
            onChange: handleChange,
            IconComponent: ChevronDown,
            value: date.getMonth(),
            name: 'month',
            classes: {
                root: classes.monthSelect,
                select: classes.select,
                icon: classes.icon
            },
            disableUnderline: true
        },
        captionMonths.map(function (month, i) {
            return React.createElement(
                'option',
                { key: month, value: i },
                month
            );
        })
    );

    var yearSelect = React.createElement(
        NativeSelect,
        {
            key: 'hedtech-datepicker-year-select',
            IconComponent: ChevronDown,
            onChange: handleChange,
            value: date.getFullYear(),
            name: 'year',
            classes: {
                root: classes.yearSelect,
                select: classes.select,
                icon: classes.icon
            },
            disableUnderline: true
        },
        years.map(function (year) {
            return React.createElement(
                'option',
                { key: year, value: year },
                year
            );
        })
    );

    return React.createElement(
        'div',
        { className: 'DayPicker-Caption' },
        React.createElement(
            'div',
            { className: classes.caption },
            withMonthYearSelect ? React.createElement(
                'form',
                null,
                isRtl ? [yearSelect, monthSelect] : [monthSelect, yearSelect]
            ) : React.createElement(
                Typography,
                {
                    variant: 'h3',
                    className: classes.monthYearText,
                    component: 'div',
                    align: 'center'
                },
                localeUtils.formatMonthTitle(date, locale)
            )
        )
    );
}

Caption.propTypes = {
    /**
     * @ignore
     */
    classes: PropTypes.object,
    withMonthYearSelect: PropTypes.bool,
    classNames: PropTypes.object,
    date: PropTypes.instanceOf(Date),
    months: PropTypes.arrayOf(PropTypes.string),
    locale: PropTypes.string,
    localeUtils: PropTypes.object,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    monthYearSelectOptions: PropTypes.object,
    dir: PropTypes.string
};

export default withStyles(styles)(Caption);