import { withStyles as MuiWithStyles } from '@material-ui/core/styles';

/**
 * Wraps MUI withStyles and injects an index of 1 for internal team use to
 * override the MUI base styles.
 * @param {Object} styles Contains JSS classnames and styles
 * @param {Object} options Configuration options
 */
var withStyles = function withStyles(styles, options) {
  var defaultOptions = Object.assign({ index: 1 }, options);
  return MuiWithStyles(styles, defaultOptions);
};

export default withStyles;