import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { StylesProvider } from '@material-ui/styles';
import { createEDSTheme } from '@hedtech/react-design-system/core/themes';
import { classNameGenerator } from '../styles/';
import { EDSContext } from '../EDSContext/EDSContext';
import CssBaseline from './internal/CssBaseline';
import config from './internal/edsconfig.js';
import cdn from './internal/urls';
import moment from 'moment';

var DEFAULT_LOCALE = 'en_US';

/**
 * `EDSApplication` is the top-level component of an EDS application. All apps must be wrapped in this component.
 *
 * @done true
 * @updated false
 * @versionAdded v0.0.13
 * @examples
 *  EDSApplicationExample
 */
var EDSApplication = function EDSApplication(props) {

    useEffect(function () {
        // if the caller wants us to automatically set the global moment locale, do so
        if (props.locale && props.autosetMomentLocale) {
            moment.locale(props.locale);
        }
    }, [props.locale]); // eslint-disable-line react-hooks/exhaustive-deps

    return React.createElement(
        EDSContext.Provider,
        { value: {
                locale: props.locale,
                labelOverrides: props.labelOverrides
            } },
        React.createElement(
            StylesProvider,
            { generateClassName: classNameGenerator },
            React.createElement(
                MuiThemeProvider,
                { theme: props.theme },
                React.createElement(CssBaseline, null),
                props.children
            )
        )
    );
};

EDSApplication.muiName = 'EDSApplication';
EDSApplication.displayName = 'EDSApplication';

EDSApplication.propTypes = {

    /**
     * The nodes comprising the wrapped application
     */
    children: PropTypes.node.isRequired,
    /**
     * The application's theme.
     */
    theme: PropTypes.object,
    /**
     * Overrides for the translated labels.
     */
    labelOverrides: PropTypes.object,
    /**
     * Locale to use for translations
     */
    locale: PropTypes.string,
    /**
     * Whether to set the global moment context to the locale specified by the `locale` prop.
     */
    autosetMomentLocale: PropTypes.bool

};

// If default props are needed, they must be set here
EDSApplication.defaultProps = {
    theme: createEDSTheme('light'),
    labelOverrides: {},
    locale: DEFAULT_LOCALE,
    autosetMomentLocale: true
};

export { config, cdn };
export default EDSApplication;