'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.INFO_POSITION_AFTER = exports.INFO_POSITION_BEFORE = exports.INFO_POSITION_BOTTOM = exports.INFO_POSITION_TOP = exports.ICON_AFTER_POSITION = exports.ICON_BEFORE_POSITION = exports.VERTICAL_ORIENTATION = exports.HORIZONTAL_ORIENTATION = exports.OPEN_UP = exports.OPEN_DOWN = exports.ANCHOR_RIGHT = exports.ANCHOR_LEFT = exports.MONTH_YEAR_SELECTION = exports.INCREMENTAL_SELECTION = exports.WITH_TEXT_FIELD = exports.STANDALONE = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _omit = require('lodash/omit');

var _omit2 = _interopRequireDefault(_omit);

var _isNil = require('lodash/isNil');

var _isNil2 = _interopRequireDefault(_isNil);

var _range = require('lodash/range');

var _range2 = _interopRequireDefault(_range);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('react-dates/initialize');

var _reactDates = require('react-dates');

var _FormControl = require('../FormControl/FormControl');

var _FormControl2 = _interopRequireDefault(_FormControl);

var _FormHelperText = require('../FormControl/FormHelperText');

var _FormHelperText2 = _interopRequireDefault(_FormHelperText);

var _InputLabel = require('@material-ui/core/InputLabel');

var _InputLabel2 = _interopRequireDefault(_InputLabel);

var _Select = require('@material-ui/core/Select');

var _Select2 = _interopRequireDefault(_Select);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Input = require('@material-ui/core/Input');

var _Input2 = _interopRequireDefault(_Input);

var _getPhrasePropTypes = require('react-dates/lib/utils/getPhrasePropTypes');

var _getPhrasePropTypes2 = _interopRequireDefault(_getPhrasePropTypes);

var _defaultPhrases = require('react-dates/lib/defaultPhrases');

require('react-dates/lib/css/_datepicker.css');

var _Calendar = require('@eui/ds-icons/lib/Calendar');

var _Calendar2 = _interopRequireDefault(_Calendar);

var _ChevronRight = require('@eui/ds-icons/lib/ChevronRight');

var _ChevronRight2 = _interopRequireDefault(_ChevronRight);

var _ChevronLeft = require('@eui/ds-icons/lib/ChevronLeft');

var _ChevronLeft2 = _interopRequireDefault(_ChevronLeft);

var _ChevronDown = require('@eui/ds-icons/lib/ChevronDown');

var _ChevronDown2 = _interopRequireDefault(_ChevronDown);

var _uuid = require('uuid');

var _uuid2 = _interopRequireDefault(_uuid);

var _EDSContext = require('../EDSContext/EDSContext');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _ClickAwayListener = require('@material-ui/core/ClickAwayListener');

var _ClickAwayListener2 = _interopRequireDefault(_ClickAwayListener);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } // AirBnb DatePicker

var MONTH_YEAR_CALENDAR_WIDTH = '284px';

var AUTOPOSITION_CALENDAR_HEIGHT = 276; // (measured in pixels)
var AUTOPOSITION_CALENDAR_WIDTH = 284; // (measured in pixels)

var DEFAULT_PLACEHOLDER = 'Date';

var styles = function styles(theme) {
    return {

        // global styles that apply to all instances of DatePicker -- including the
        // detached calendar that's displayed when the appendToBody option is enabled
        '@global': {

            // the standalone version of the month/year pickers will take up the full width
            // of its container unless you constrain it; this has something to do with the
            // fact that we're using the vertically-oriented datepicker for the month/year
            // variant of DatePicker (to get around other rendering issues)
            '.monthYearPickerFix': {

                '&.SingleDatePicker_picker': {
                    width: MONTH_YEAR_CALENDAR_WIDTH
                },

                '& .DayPicker': {
                    width: MONTH_YEAR_CALENDAR_WIDTH,
                    '& .DayPicker_transitionContainer': {
                        // left to its own devices, the vertically-oriented date picker will
                        // allocate enough room for *two*, stacked montly calendars. constrain
                        // the height, and add a little padding at the padding, to compensate
                        height: 'auto !important',
                        paddingBottom: _tokens.spacingMedium
                    },

                    // hide the "second" (offscreen) month entirely when we're showing the
                    // month/year selection version of datepicker, so that it doesn't take up
                    // any space on the page
                    '& .CalendarMonthGrid_month__hidden': {
                        display: 'none'
                    }

                }
            },

            '.DayPicker': {
                fontFamily: _tokens.fontFamilyDefault,
                borderRadius: _tokens.borderRadiusMedium,
                '& .DayPicker_transitionContainer': {
                    borderRadius: _tokens.borderRadiusMedium
                },
                '& .DayPicker_transitionContainer__horizontal': {
                    transition: 'height 100ms ease-in-out',
                    '& .CalendarMonthGrid': {
                        background: 'transparent'
                    }
                }
            },
            '.SingleDatePicker': {
                '& .SingleDatePickerInput__disabled': {
                    backgroundColor: theme.palette.grey[200],
                    '&:hover': {
                        cursor: 'not-allowed'
                    }
                }
            },
            '.SingleDatePicker_picker': {
                backgroundColor: 'transparent',
                zIndex: _tokens.zIndexDatePicker
            },
            '.SingleDatePickerInput': {
                width: _tokens.widthInputFields,
                height: _tokens.heightInputFields, // 54px to match TextField & Dropdown
                borderRadius: _tokens.borderRadiusMedium,
                border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
                '& .SingleDatePickerInput_calendarIcon': {
                    fill: theme.palette.grey[500],
                    borderRadius: _tokens.borderRadiusMedium,
                    margin: 0,
                    marginLeft: '1.25rem',
                    '&:focus': {
                        outline: 'none',
                        boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + theme.palette.focus
                    },
                    '&::-moz-focus-inner': {
                        border: 0
                    },
                    '&:disabled:hover': {
                        cursor: 'not-allowed'
                    }
                },
                '& .DateInput': {
                    width: 'calc(100% - 3.75rem)', // Specific to not overlap with the calendar icon button
                    backgroundColor: 'transparent',
                    '& .DateInput_input': {
                        padding: _tokens.paddingTextField,
                        backgroundColor: 'transparent',
                        color: theme.palette.grey[600],
                        fontFamily: _tokens.fontFamilyDefault,
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: _tokens.lineHeightHeader2Small,
                        fontWeight: theme.typography.fontWeight,
                        '&:focus': {
                            outline: 0
                        },
                        '&::placeholder': {
                            opacity: 0,
                            color: theme.palette.grey[500],
                            fontSize: theme.typography.body1.fontSize
                        },
                        '&::-webkit-input-placeholder': {
                            opacity: 0,
                            color: theme.palette.grey[500],
                            fontSize: theme.typography.body1.fontSize
                        },
                        '&::-moz-placeholder': {
                            opacity: 0,
                            color: theme.palette.grey[500],
                            fontSize: theme.typography.body1.fontSize
                        },
                        '&:-ms-input-placeholder': {
                            opacity: 0,
                            color: theme.palette.grey[500],
                            fontSize: theme.typography.body1.fontSize
                        },
                        '&:-moz-placeholder': {
                            opacity: 0,
                            color: theme.palette.grey[500],
                            fontSize: theme.typography.body1.fontSize
                        },
                        // Disable FF specific invalid input box-shadow
                        '&:not(output):-moz-ui-invalid': {
                            boxShadow: 'none'
                        }
                    },
                    '& .DateInput_input__focused': {
                        borderColor: 'transparent',
                        backgroundColor: 'transparent'
                    },
                    '& .DateInput_input__disabled': {
                        fontStyle: 'normal',
                        '&:hover': {
                            cursor: 'not-allowed'
                        }
                    }
                },
                // Hide input fang icon
                '& .DateInput_fang': {
                    display: 'none'
                }
            },
            '.DayPicker__withBorder': {
                border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
                boxShadow: '0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12), 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14), 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.21)'
            },
            '.DayPickerKeyboardShortcuts_show__bottomRight': {
                borderRightColor: theme.palette.ctaColor.base
            },
            '.CalendarMonth_caption': {
                fontFamily: _tokens.fontFamilyHeader,
                fontSize: theme.typography.h3.fontSize,
                '& > strong': {
                    fontWeight: theme.typography.h3.fontWeight
                }
            },
            // calendar days
            '.CalendarDay': {
                // Open days
                '&.CalendarDay__default': {
                    color: theme.palette.grey[600],
                    borderColor: 'transparent',
                    border: 'none',
                    borderRadius: _tokens.borderRadiusCircle,
                    transition: theme.transitions.create(['background-color', 'color'], {
                        duration: 50,
                        easing: theme.transitions.easing.sharp
                    }),
                    '&:hover': {
                        backgroundColor: theme.palette.grey[500],
                        color: theme.palette.grey[100],
                        border: 'none'
                    },
                    '&:focus': {
                        outline: 'none',
                        border: 'none',
                        backgroundColor: theme.palette.grey[500],
                        color: theme.palette.grey[100]
                    }
                },

                // Selected Day
                '&.CalendarDay__selected.CalendarDay__default': {
                    backgroundColor: theme.palette.ctaColor.base,
                    color: theme.palette.grey[100],
                    '&:hover': {
                        backgroundColor: theme.palette.ctaColor.hover
                    },
                    '&:active': {
                        backgroundColor: theme.palette.ctaColor.active
                    }
                },

                // Disabled Days
                // Out of range
                '&.CalendarDay__blocked_out_of_range': {
                    borderRadius: 0,
                    color: theme.palette.grey[500],
                    backgroundColor: theme.palette.grey[200],
                    '&:hover': {
                        borderRadius: 0,
                        color: theme.palette.grey[500] + ' !important',
                        backgroundColor: theme.palette.grey[200] + ' !important',
                        cursor: 'not-allowed'
                    },
                    '&:focus': {
                        outline: 'none'
                    }
                },
                // Days outside of current month
                '&.CalendarDay__outside': {
                    color: theme.palette.grey[500],
                    borderRadius: _tokens.borderRadiusCircle,
                    '&:hover': {
                        borderRadius: _tokens.borderRadiusCircle
                    },
                    '&:focus': {
                        outline: 'none',
                        borderRadius: _tokens.borderRadiusCircle
                    }
                },
                // User defined blocked
                '&.CalendarDay__blocked_calendar': {
                    borderRadius: 0,
                    color: theme.palette.grey[500],
                    backgroundColor: theme.palette.grey[200],
                    '&:hover': {
                        borderRadius: 0,
                        color: theme.palette.grey[500] + ' !important',
                        backgroundColor: theme.palette.grey[200] + ' !important',
                        cursor: 'not-allowed'
                    },
                    '&:focus': {
                        outline: 'none',
                        borderRadius: 0,
                        color: theme.palette.grey[500] + ' !important',
                        backgroundColor: theme.palette.grey[200] + ' !important'
                    },
                    '&.CalendarDay__outside': {
                        color: theme.palette.grey[500],
                        borderRadius: 0,
                        '&:hover': {
                            borderRadius: 0,
                            color: theme.palette.grey[500] + ' !important',
                            backgroundColor: theme.palette.grey[200] + ' !important'
                        },
                        '&:focus': {
                            outline: 'none',
                            borderRadius: 0,
                            color: theme.palette.grey[500] + ' !important',
                            backgroundColor: theme.palette.grey[200] + ' !important'
                        }
                    }
                }
            },
            '.DayPicker_weekHeader_ul': {
                textTransform: 'uppercase',
                fontSize: theme.typography.caption.fontSize,
                color: theme.palette.grey[500]
            },
            // DatePicker prev + nav buttons
            '.DayPickerNavigation': {
                '& div[role="button"]': {
                    position: 'absolute',
                    '&:hover': {
                        backgroundColor: theme.palette.ctaColor.tint
                    },
                    '&:focus, &:active': {
                        backgroundColor: theme.palette.ctaColor.tint
                    },
                    '&::-moz-focus-inner': {
                        border: 0
                    }
                },
                '& div:first-of-type': {
                    left: '1.375rem'
                },
                '& div:last-of-type': {
                    right: '3.125rem'
                },
                '& svg': {
                    border: 'none',
                    height: _tokens.spacingSmall,
                    width: _tokens.spacingSmall,
                    '&:focus, &:active': {
                        outline: 'none',
                        border: 0
                    },
                    '&::-moz-focus-inner': {
                        border: 0
                    }
                }
            },

            '.SingleDatePicker_picker__rtl': {
                '& .DayPickerNavigation': {
                    '& div[role="button"]': {
                        position: 'absolute',
                        '&:hover': {
                            backgroundColor: theme.palette.ctaColor.tint
                        },
                        '&:focus, &:active': {
                            backgroundColor: theme.palette.ctaColor.tint
                        },
                        '&::-moz-focus-inner': {
                            border: 0
                        }
                    },
                    '& div:first-of-type': {
                        left: 'auto',
                        right: '1.5rem'
                    },
                    '& div:last-of-type': {
                        right: 'auto',
                        left: '3.125rem'
                    },
                    '& svg': {
                        border: 'none',
                        height: _tokens.spacingSmall,
                        width: _tokens.spacingSmall,
                        '&:focus, &:active': {
                            outline: 'none',
                            border: 0
                        },
                        '&::-moz-focus-inner': {
                            border: 0
                        }
                    }
                }
            },

            // detached (appendToBody) calendar are rendered with position 'absolute' by
            // default; but this doesn't work in all scenarios, since they'll still
            // be positioned absolutely relative to their parent container

            // override the default to be fixed, intead; this seems to work in all
            // situations
            '.SingleDatePicker_picker[style*="transform: translate3d"]': {
                position: 'fixed'
            },

            '.hedtech-bring-forward': {
                zIndex: theme.zIndex.modal
            },

            // Fix for DatePicker calendar positioning when appendToBody props is set
            '.SingleDatePicker_picker_small': {
                top: '2.875rem !important',
                left: '0.875rem !important'
            }

        },

        // month picker fix for DatePicker components that are NOT appended to the body
        monthYearPickerFix: {

            '& .SingleDatePicker_picker': {
                width: MONTH_YEAR_CALENDAR_WIDTH
            },

            '& .DayPicker': {
                width: MONTH_YEAR_CALENDAR_WIDTH,
                '& .DayPicker_transitionContainer': {
                    // left to its own devices, the vertically-oriented date picker will
                    // allocate enough room for *two*, stacked montly calendars. constrain
                    // the height, and add a little padding at the padding, to compensate
                    height: 'auto !important',
                    paddingBottom: _tokens.spacingMedium
                },

                // hide the "second" (offscreen) month entirely when we're showing the
                // month/year selection version of datepicker, so that it doesn't take up
                // any space on the page
                '& .CalendarMonthGrid_month__hidden': {
                    display: 'none'
                }

            }
        },

        rootRTL: {
            '& .SingleDatePickerInput': {
                '& .SingleDatePickerInput_calendarIcon': {
                    margin: 0,
                    marginRight: _tokens.spacingSmall
                }
            }

        },
        standalone: {
            position: 'relative',
            left: -2,
            zIndex: _tokens.zIndexDatePicker,
            '& .DayPicker__withBorder': {
                border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
                boxShadow: 'none'
            },
            '& .DayPicker .DayPicker_transitionContainer': {
                borderRight: 'none',
                backgroundColor: 'transparent'
            },
            '& .CalendarMonthGrid': {
                backgroundColor: 'transparent'
            }
        },
        standaloneRTL: {
            '& .CalendarMonthGrid_month__hidden': {
                visibility: 'inherit'
            }
        },
        fullWidth: {
            '& .SingleDatePicker': {
                width: _tokens.widthFluid
            },
            '& .SingleDatePickerInput': {
                width: _tokens.widthFluid
            }
        },

        // Apply the focused outline around the input field
        focused: {
            '& .SingleDatePickerInput': {
                boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + theme.palette.focus,
                '& .SingleDatePickerInput_calendarIcon': {
                    fill: theme.palette.grey[500],
                    borderRadius: _tokens.borderRadiusMedium,
                    '&:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 0
                    },
                    '&::-moz-focus-inner': {
                        border: 0
                    }
                }
            }
        },

        // small size variant DatePicker
        smallDatePicker: {
            '& .div_small': {
                height: _tokens.heightInputFieldsSmall,
                '& .SingleDatePickerInput': {
                    height: _tokens.heightInputFieldsSmall,
                    top: '-0.125rem',
                    fontSize: _tokens.fontSizeDefault,
                    '& .SingleDatePickerInput_calendarIcon': {
                        height: '2.25rem',
                        width: '2.25rem',
                        padding: _tokens.spacingXSmall,
                        marginLeft: _tokens.spacingMedium
                    },
                    '& .DateInput': {
                        height: '2.25rem',
                        '& .DateInput_input': {
                            height: '2.25rem',
                            padding: _tokens.spacingXSmall,
                            borderBottom: 'none',
                            fontSize: _tokens.fontSizeDefault
                        }
                    }
                },
                // For positioning the calendar when not used in a Table
                '& .SingleDatePicker_picker': {
                    top: '2.875rem !important'
                }
            }
        },

        // Show the placeholder if no label is provided
        noLabel: {
            '& .SingleDatePickerInput': {
                '& .DateInput': {
                    '& .DateInput_input': {
                        '&::placeholder': {
                            opacity: 1
                        },
                        '&::-webkit-input-placeholder': {
                            opacity: 1
                        },
                        '&::-moz-placeholder': {
                            opacity: 1
                        },
                        '&:-ms-input-placeholder': {
                            opacity: 1
                        },
                        '&:-moz-placeholder': {
                            opacity: 1
                        }
                    }
                }
            }
        },
        noLabelMedium: {
            '& .SingleDatePickerInput': {
                '& .DateInput': {
                    '& .DateInput_input': {
                        padding: _tokens.spacingSmall
                    }
                }
            }
        },
        noLabelSmall: {
            '& .SingleDatePickerInput': {
                '& .DateInput': {
                    '& .DateInput_input': {
                        padding: _tokens.spacingXSmall,
                        fontSize: _tokens.fontSizeDefault,
                        height: _tokens.heightInputFieldsSmall,
                        borderBottom: 'none'
                    }
                }
            }
        },

        // Error styling
        error: {
            '& .SingleDatePickerInput': {
                border: _tokens.borderWidthThick + ' solid ' + theme.palette.status.error.fill
            }
        },
        focusedError: {
            '& .SingleDatePickerInput': {
                boxShadow: '0 0 0 ' + _tokens.borderWidthThin + ' ' + theme.palette.status.error.fill,
                '-webkit-box-shadow': '0 0 0 ' + _tokens.borderWidthThin + ' ' + theme.palette.status.error.fill,
                '-moz-box-shadow:': '0 0 0 ' + _tokens.borderWidthThin + ' ' + theme.palette.status.error.fill
            }
        },

        // Styles to show the placeholder when input is
        // focused / DatePicker is open (with label)
        showPlaceholder: {
            '& .SingleDatePickerInput': {
                '& .DateInput': {
                    '& .DateInput_input': {
                        '&::placeholder': {
                            opacity: 1
                        },
                        '&::-webkit-input-placeholder': {
                            opacity: 1
                        },
                        '&::-moz-placeholder': {
                            opacity: 1
                        },
                        '&:-ms-input-placeholder': {
                            opacity: 1
                        },
                        '&:-moz-placeholder': {
                            opacity: 1
                        }
                    }
                }
            }
        },

        // Calendar Icon Button screen reader only styles
        srOnly: {
            position: 'absolute',
            width: 1,
            height: 1,
            padding: 0,
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0,0,0,0)',
            border: 0
        },

        // NOTE: The following styles could be moved into their
        // own files in the FormControl directory
        // InputLabel
        inputLabelFormControl: {
            zIndex: _tokens.zIndexTextFieldLabel,
            left: _tokens.spacingSmall,
            transform: 'translate(0, 1.125rem) scale(1)'
        },
        inputLabelShrink: {
            fontSize: _tokens.fontSizeDefault,
            transform: 'translate(0, .5rem) scale(0.75)'
        },

        // month/year selection styling
        monthYearSelector: {
            display: 'flex',
            padding: '0 10px',
            justifyContent: 'space-between',
            '& svg': {
                width: _tokens.spacingSmall,
                height: _tokens.spacingSmall,
                marginLeft: '0.3125rem',
                fill: _tokens.colorTextNeutral500
            }
        },

        monthYearSelectInput: {
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: theme.typography.h3.fontSize
        },

        monthYearSelectInputInput: {
            padding: '0 1.3125rem 0 0'
        },

        monthYearSelectIcon: {
            top: 'calc(50% - 8px)'
        },

        monthYearSelectMenuItem: {
            backgroundColor: theme.palette.grey['100'],
            // note - can't use borderBottom for this because of an Edge bug that makes
            // bottom borders disappear: https://stackoverflow.com/questions/32313551/border-bottom-bug-in-microsoft-edge
            borderTop: _tokens.borderWidthThin + ' solid ' + theme.palette.grey['300'],
            color: theme.palette.grey['600'],
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            lineHeight: _tokens.lineHeightParagraphMedium,
            minHeight: _tokens.sizingMedium,
            whiteSpace: 'inherit',
            '&:hover, &:focus': {
                backgroundColor: theme.palette.grey['200'],
                color: theme.palette.grey['600']
            }
        },

        monthYearSelectMenuItemSelected: {
            backgroundColor: theme.palette.ctaColor.active + ' !important',
            color: theme.palette.grey['100'] + ' !important'
        },

        monthYearSelectMenu: {
            padding: 0
        },
        inputLabelSmall: {
            top: '-0.35rem',
            left: '0.625rem',
            fontSize: _tokens.fontSizeDefault,
            '&[data-shrink=true]': {
                display: 'none'
            }
        }

    };
};

// CONSTANTS
var STANDALONE = exports.STANDALONE = 'standalone';
var WITH_TEXT_FIELD = exports.WITH_TEXT_FIELD = 'with-text-field';

var INCREMENTAL_SELECTION = exports.INCREMENTAL_SELECTION = 'incremental';
var MONTH_YEAR_SELECTION = exports.MONTH_YEAR_SELECTION = 'month-year';

var ANCHOR_LEFT = exports.ANCHOR_LEFT = 'left';
var ANCHOR_RIGHT = exports.ANCHOR_RIGHT = 'right';

var OPEN_DOWN = exports.OPEN_DOWN = 'down';
var OPEN_UP = exports.OPEN_UP = 'up';

var HORIZONTAL_ORIENTATION = exports.HORIZONTAL_ORIENTATION = 'horizontal';
var VERTICAL_ORIENTATION = exports.VERTICAL_ORIENTATION = 'vertical';

var ICON_BEFORE_POSITION = exports.ICON_BEFORE_POSITION = 'before';
var ICON_AFTER_POSITION = exports.ICON_AFTER_POSITION = 'after';

var INFO_POSITION_TOP = exports.INFO_POSITION_TOP = 'top';
var INFO_POSITION_BOTTOM = exports.INFO_POSITION_BOTTOM = 'bottom';
var INFO_POSITION_BEFORE = exports.INFO_POSITION_BEFORE = 'before';
var INFO_POSITION_AFTER = exports.INFO_POSITION_AFTER = 'after';

var DEFAULT_DISPLAY_FORMAT = 'MMM D, YYYY';
var DEFAULT_WEEK_DAY_FORMAT = 'dd';
var DEFAULT_DAY_SIZE = 34;

var INPUT_SPECIFIC_PROPS = ['anchorDirection', 'aria-label', 'customCloseIcon', 'customInputIcon', 'disableScroll', 'displayFormat', 'id', 'inputIconPosition', 'openDirection', 'placeholder', 'screenReaderInputMessage'];

var navStyles = function navStyles(theme) {
    return {
        root: {
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: _tokens.borderRadiusSmall,
            color: theme.palette.grey[500],
            cursor: 'pointer',
            padding: _tokens.spacingXxSmall + ' 0.375rem',
            position: 'absolute',
            outline: 'none',
            top: '1.375rem',
            userSelect: 'none',
            '&:hover, &:focus, &:active': {
                backgroundColor: theme.palette.ctaColor.tint
            }
        }
    };
};

// This is a util component that gives the Navigation buttons back
//   their tabIndex value. Airbnb removed the tabIndex on custom
//   navigation buttons.
var _DatePickerNavigationButton = function _DatePickerNavigationButton(_ref) {
    var classes = _ref.classes,
        classSrOnly = _ref.className,
        children = _ref.children,
        phrase = _ref.phrase;

    return _react2.default.createElement(
        'span',
        {
            className: classes.root,
            role: 'button',
            tabIndex: '0',
            title: phrase,
            'aria-label': phrase
        },
        _react2.default.createElement(
            'span',
            { className: classSrOnly },
            phrase
        ),
        children
    );
};

_DatePickerNavigationButton.propTypes = {
    classes: _propTypes2.default.object.isRequired,
    className: _propTypes2.default.string.isRequired,
    children: _propTypes2.default.node.isRequired,
    phrase: _propTypes2.default.string.isRequired
};

var DatePickerNavigationButton = (0, _styles2.default)(navStyles)(_DatePickerNavigationButton);

// This implementation is based on MUI's TextField
// https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/TextField/TextField.js

/**
 * Use `DatePicker` to select a single date.
 * @done true
 * @updated false
 * @versionAdded v0.0.5
 * @examples
 *  ExampleDatePicker
 *  Disabled
 *  FullWidth
 *  DateFormats
 *  DatePickerWithinAForm
 *  InADialog
 *  ExampleDatePickerRTL
 *  Standalone
 *  StandaloneWithToggle
 */

var DatePicker = function (_React$Component) {
    _inherits(DatePicker, _React$Component);

    function DatePicker(props) {
        _classCallCheck(this, DatePicker);

        var _this = _possibleConstructorReturn(this, (DatePicker.__proto__ || Object.getPrototypeOf(DatePicker)).call(this, props));

        _this.state = {
            shrink: false
        };

        _this.handleClose = function () {
            if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                _this.props.onFocusChange({ focused: false });
            }
        };

        _this.selectorId = (0, _uuid2.default)();
        _this.datePickerRef = _react2.default.createRef();
        _this.singleDatePickerId = props.id ? props.id : (0, _uuid2.default)();
        return _this;
    }

    _createClass(DatePicker, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            // if a detached calendar is enabled, remember the current overflow values of the
            // top page elements; we will need this info later
            if (this.props.appendToBody) {
                this.getOverflow();
            }

            if (this.props.variant !== STANDALONE) {
                this.HACK_setConditionalSizeClass(this.props);
            }
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
            var _this2 = this;

            var _props = this.props,
                date = _props.date,
                variant = _props.variant,
                appendToBody = _props.appendToBody,
                monthSelectionVariant = _props.monthSelectionVariant,
                focused = _props.focused;


            if (variant !== STANDALONE) {

                // This accounts for DatePickers when placed on Dialogs
                // The MUI Dialog disables the scroll when open, and we need to save that new overflow state
                if (this.props.appendToBody) {
                    this.getOverflow();
                }

                // when the date picker is rendered with `appendToBody`, the date picker calendar
                // is appended to the top of the DOM hierarchy; this makes it difficult to apply
                // conditional styling to it, since react-dates doesn't give us a way to interact
                // with the calendar specifically

                // the workaround: when we want to apply the month/picker hack to it, we
                // wait for it to appear, then apply the styles *directly* to the calendar
                // div

                // check if (1) something has changed that requires us to take another look at the
                // calendar; and (2) we're dealing with an open, detached calendar
                if ((appendToBody !== prevProps.appendToBody || focused !== prevProps.focused || monthSelectionVariant !== prevProps.monthSelectionVariant) && appendToBody && focused) {

                    // jump out of the event loop, to give the calendar time to render
                    setTimeout(function () {
                        // apply the hack if the we're using the month/year selector variant;
                        // otherwise, remove it
                        _this2.handleMonthPickerHack(monthSelectionVariant === MONTH_YEAR_SELECTION);

                        // Use this hack to adjust the z-index of the DatePicker when being rendered in a Dialog
                        // when the prop appendToBody is true. This gives the DatePicker a higher z-index so it is
                        // not hidden in the background of the Dialog/the Dialog's backdrop.
                        _this2.HACK_handleZIndex();
                    }, 1);
                }

                // add aria info to calendar button
                this.HACK_updateCalendarButtonA11yInfo();

                // if an aria label was provided, apply to input field
                this.HACK_updateAriaLabel();

                var curShrink = this.state.shrink;

                var htmlInput = this.datePickerRef.current.querySelector('.DateInput_input');
                var htmlInputValueLength = htmlInput ? htmlInput.value.length : 0;

                if (date !== null || curShrink && htmlInputValueLength > 0) {
                    return;
                } else if (curShrink && htmlInputValueLength === 0) {
                    this.setState({ shrink: false });
                } else if (!curShrink && htmlInputValueLength > 0) {
                    this.setState({ shrink: true });
                }

                this.HACK_setConditionalSizeClass();
            }
        }

        // Utility function that gets the overflow of the html and body elements

    }, {
        key: 'getOverflow',
        value: function getOverflow() {
            this.htmlOverflowY = document.querySelector('html').style.overflowY;
            this.bodyOverflowY = document.querySelector('body').style.overflowY;
        }

        /**
         * Find the instance of the calendar that's been appeneded to the document body, and
         * add (or remove) the class that patches our issues with the month picker
         *
         * @param {boolean} apply True to apply the class; false to remove it
         */

    }, {
        key: 'handleMonthPickerHack',
        value: function handleMonthPickerHack(apply) {

            // grab the calendar
            var datePickers = document.querySelectorAll('body > div > div.SingleDatePicker_picker');

            // add/remove the month picker hack
            if (datePickers.length > 0) {
                for (var i = 0; i < datePickers.length; i++) {
                    var datePicker = datePickers[i];
                    if (datePicker && datePicker.classList) {
                        if (apply) {
                            if (!datePicker.classList.contains('monthYearPickerFix')) {
                                datePicker.classList.add('monthYearPickerFix');
                            }
                        } else {
                            datePicker.classList.remove('monthYearPickerFix');
                        }
                    }
                }
            }
        }
    }, {
        key: 'HACK_handleZIndex',
        value: function HACK_handleZIndex() {
            // Get DatePicker
            var datePickers = document.querySelectorAll('body > div > div.SingleDatePicker_picker');

            // Assign DatePicker a higher z-index
            if (datePickers.length > 0) {
                for (var i = 0; i < datePickers.length; i++) {
                    var datePicker = datePickers[i];
                    if (datePicker && datePicker.classList) {
                        datePicker.classList.add('hedtech-bring-forward');
                    }
                }
            }
        }

        // add aria info to the calendar popup button

    }, {
        key: 'HACK_updateCalendarButtonA11yInfo',
        value: function HACK_updateCalendarButtonA11yInfo() {

            var calendarButton = this.datePickerRef.current.querySelector('.SingleDatePickerInput_calendarIcon');

            // add a "hasPopup" aria label to the button that pops up the date picker
            if (this.datePickerRef.current) {

                if (!calendarButton.getAttribute('aria-haspopup')) {

                    // note -- we don't have direct access to the button, so need to hack our way in there
                    calendarButton.setAttribute('aria-haspopup', 'true');
                }
            }
        }

        // Need to add specific classes for small size DatePicker

    }, {
        key: 'HACK_setConditionalSizeClass',
        value: function HACK_setConditionalSizeClass() {
            // Need to add a class to this basic <div> so we can target it specifically
            var htmlInput = this.datePickerRef.current.querySelector('.DateInput_input');

            if (this.props.size === 'small' && htmlInput && htmlInput.classList && htmlInput.parentElement.parentElement.parentElement) {
                htmlInput.parentElement.parentElement.parentElement.classList.add('div_small');
            }

            // DatePicker with appendToBody doesn't respect calendar's top positioning because of where it's rendered
            // Need to be more aggressive setting it
            var datePickers = document.querySelectorAll('body > div > div.SingleDatePicker_picker');

            if (datePickers.length > 0) {
                for (var i = 0; i < datePickers.length; i++) {
                    var datePicker = datePickers[i];
                    if (datePicker && datePicker.classList && !datePicker.classList.contains('SingleDatePicker_picker_small')) {
                        datePicker.classList.add('SingleDatePicker_picker_small');
                    }
                }
            }
        }

        // add the correct aria-label to the datepicker input field

    }, {
        key: 'HACK_updateAriaLabel',
        value: function HACK_updateAriaLabel() {

            // add a "hasPopup" aria attribute to the button that pops up the date picker
            if (this.props['aria-label']) {

                var htmlInput = this.datePickerRef.current.querySelector('.DateInput_input');

                if (htmlInput.getAttribute('aria-label') !== this.props['aria-label']) {

                    // note -- we need to hack this in, because the placeholder text always overrides
                    // whatever you pass in for ariaLabel, effectively making it impossible to have
                    // different placeholder and aria-label text
                    htmlInput.setAttribute('aria-label', this.props['aria-label']);
                }
            }
        }

        // builds a month selection dropdown

    }, {
        key: 'buildSelector',
        value: function buildSelector(menuItems, callback, value) {

            var classes = this.props.classes;

            return _react2.default.createElement(
                _Select2.default,
                {
                    IconComponent: _ChevronDown2.default,
                    onChange: callback,
                    MenuProps: {
                        id: this.selectorId,
                        MenuListProps: {
                            classes: {
                                root: classes.monthYearSelectMenu
                            }
                        }
                    },
                    classes: {
                        icon: classes.monthYearSelectIcon
                    },
                    value: value,
                    input: _react2.default.createElement(_Input2.default, { classes: {
                            root: classes.monthYearSelectInput,
                            input: classes.monthYearSelectInputInput }, disableUnderline: true })
                },
                menuItems
            );
        }

        // Method will close the calender if user clicks outside of the calendar container.

    }, {
        key: 'render',
        value: function render() {
            var _this3 = this,
                _cn,
                _cn2;

            var _props2 = this.props,
                classes = _props2.classes,
                error = _props2.error,
                variant = _props2.variant,
                className = _props2.className,
                helperText = _props2.helperText,
                fullWidth = _props2.fullWidth,
                edsContext = _props2.edsContext,
                FormControlProps = _props2.FormControlProps,
                FormHelperTextProps = _props2.FormHelperTextProps,
                InputLabelProps = _props2.InputLabelProps,
                labelProp = _props2.label,
                startYear = _props2.startYear,
                endYear = _props2.endYear,
                autoPosition = _props2.autoPosition,
                screenReaderInputMessageProp = _props2.screenReaderInputMessage,
                placeholderProp = _props2.placeholder,
                onFocusChange = _props2.onFocusChange,
                monthSelectionVariant = _props2.monthSelectionVariant,
                isOutsideRange = _props2.isOutsideRange,
                ariaLabel = _props2['aria-label'],
                size = _props2.size,
                props = _objectWithoutProperties(_props2, ['classes', 'error', 'variant', 'className', 'helperText', 'fullWidth', 'edsContext', 'FormControlProps', 'FormHelperTextProps', 'InputLabelProps', 'label', 'startYear', 'endYear', 'autoPosition', 'screenReaderInputMessage', 'placeholder', 'onFocusChange', 'monthSelectionVariant', 'isOutsideRange', 'aria-label', 'size']);

            var shrink = this.state.shrink;


            var label = labelProp;
            var hasLabel = !(0, _isNil2.default)(labelProp);
            var helperTextId = helperText && props.id ? props.id + '-helper-text' : undefined;
            var shouldShrink = props.focused || props.date !== null || shrink;

            // translate placeholder text if we're displaying the default
            var placeholder = placeholderProp === DEFAULT_PLACEHOLDER ? edsContext.formatMessage('component.DatePicker.dateLabel') : placeholderProp;

            // Add asterisk to label or placeholder if required
            if (props.required) {
                if (hasLabel) {
                    label = labelProp + ' *';
                } else {
                    placeholder = placeholder + ' *';
                }
            }

            // set up the default screen reader input message
            var screenReaderInputMessage = screenReaderInputMessageProp || edsContext.formatMessage('component.DatePicker.a11y.inputDescription', {
                dateFormat: this.props.displayFormat
            });

            var showMonthSelectors = this.props.monthSelectionVariant === MONTH_YEAR_SELECTION;

            // Month/Year Calendar Hack
            //
            // The month/year variant of the calendar has an issue where the transitions from one month
            // to another gets 'stuck' when using the month selector; you wind up with the calendar
            // half-shifted out of the viewport. This only happens when you put a MUI select in the month
            // header; something about it throws off the fragile timing of the transition in the bowels
            // of react-dates.
            //
            // The most consistent/least intrusive way we've found to solve this is by making the month/
            // year calendar *vertically*-oriented. This appears to satisfy the transition that is
            // causing us so many problems, without any visual impact to the component (since we only
            // show one calendar at a time).
            //
            // We had to add a few CSS tweaks to make this work (see the monthYearPickerFix class, above).
            // They are only applied for the month/year version of the calendar.

            var DatePickerPropOverrides = {
                inputIconPosition: ICON_AFTER_POSITION,
                orientation: monthSelectionVariant === MONTH_YEAR_SELECTION ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION,
                customInputIcon: _react2.default.createElement(
                    _react2.default.Fragment,
                    null,
                    _react2.default.createElement(
                        'span',
                        { className: classes.srOnly },
                        edsContext.formatMessage('component.DatePicker.inputCalendarIconButton')
                    ),
                    _react2.default.createElement(_Calendar2.default, null)
                ),
                numberOfMonths: 1,
                displayFormat: DEFAULT_DISPLAY_FORMAT,
                weekDayFormat: DEFAULT_WEEK_DAY_FORMAT,
                anchorDirection: this.variants === props.isRTL ? ANCHOR_RIGHT : ANCHOR_LEFT,
                screenReaderInputMessage: screenReaderInputMessage,
                placeholder: placeholder,
                navPrev: showMonthSelectors ? _react2.default.createElement('span', null) : props.isRTL ? _react2.default.createElement(
                    DatePickerNavigationButton,
                    {
                        className: classes.srOnly,
                        phrase: props.phrases.jumpToNextMonth
                    },
                    _react2.default.createElement(_ChevronRight2.default, null)
                ) : _react2.default.createElement(
                    DatePickerNavigationButton,
                    {
                        className: classes.srOnly,
                        phrase: props.phrases.jumpToPrevMonth
                    },
                    _react2.default.createElement(_ChevronLeft2.default, null)
                ),
                navNext: showMonthSelectors ? _react2.default.createElement('span', null) : props.isRTL ? _react2.default.createElement(
                    DatePickerNavigationButton,
                    {
                        className: classes.srOnly,
                        phrase: props.phrases.jumpToPrevMonth
                    },
                    _react2.default.createElement(_ChevronLeft2.default, null)
                ) : _react2.default.createElement(
                    DatePickerNavigationButton,
                    {
                        className: classes.srOnly,
                        phrase: props.phrases.jumpToNextMonth
                    },
                    _react2.default.createElement(_ChevronRight2.default, null)
                ),

                onFocusChange: function onFocusChange(focused) {

                    // this is to get past react-dates' stubborn insistence on closing whenever you click
                    // outside the calendar; selecting a dropdown item is considered an outside click, so
                    // without this code the calendar would close (in non-standalone mode) whenever you
                    // select a new date/year
                    if (focused.focused || !showMonthSelectors || variant === STANDALONE) {
                        _this3.props.onFocusChange(focused);
                    } else {

                        // if the month/year selection menu is visible, abort the close
                        if (document.getElementById(_this3.selectorId)) {
                            _this3.props.onFocusChange({ focused: true });
                        } else {
                            _this3.props.onFocusChange(focused);
                        }
                    }
                },

                onClose: function onClose() {

                    // if the appendToBody prop is specified, users might run afoul of a react-date bug
                    // where, if you invoke a date picker calendar while another one is already open,
                    // react-date's scroll-disabling code gets confused, and turns off scrolling forever
                    // (or until the page refreshes, whichever comes first)

                    // work around this by explicitly setting overflow-y back to its previous value,
                    // whenever a calendar closes

                    if (_this3.props.appendToBody) {

                        setTimeout(function () {

                            // both html & body tags might have their overflows disabled (it appears to be
                            // browser-dependent); so check both, and reset them if necessary
                            var html = document.querySelector('html');
                            var body = document.querySelector('body');

                            if (html.style.overflowY === 'hidden' && _this3.htmlOverflowY !== 'hidden') {
                                html.style.overflowY = _this3.htmlOverflowY;
                            }

                            if (body.style.overflowY === 'hidden' && _this3.bodyOverflowY !== 'hidden') {
                                body.style.overflowY = _this3.bodyOverflowY;
                            }
                        }, 1);
                    }

                    // if the caller provided their own onClose, call it
                    if (_this3.props.onClose) {
                        _this3.props.onClose();
                    }
                },

                // if we're showing month selectors, don't allow selections of years outside of the
                // given year range
                isOutsideRange: this.props.isOutsideRange ? this.props.isOutsideRange : showMonthSelectors ? function (day) {
                    return day.year() < startYear || day.year() > endYear;
                } : function () {
                    return false;
                },

                renderMonthElement: showMonthSelectors ? function (_ref2) {
                    var month = _ref2.month,
                        onMonthSelect = _ref2.onMonthSelect,
                        onYearSelect = _ref2.onYearSelect;
                    return _react2.default.createElement(
                        'div',
                        { className: classes.monthYearSelector },
                        _react2.default.createElement(
                            'div',
                            null,
                            _this3.buildSelector(_moment2.default.months().map(function (monthLabel, value) {
                                return _react2.default.createElement(
                                    _MenuItem2.default,
                                    {
                                        classes: {
                                            root: classes.monthYearSelectMenuItem,
                                            selected: classes.monthYearSelectMenuItemSelected
                                        },
                                        key: value,
                                        value: value,
                                        disableRipple: true,
                                        disableTouchRipple: true
                                    },
                                    monthLabel
                                );
                            }), function (e) {
                                onMonthSelect(month, e.target.value);
                            }, month.month())
                        ),
                        _react2.default.createElement(
                            'div',
                            null,
                            _this3.buildSelector((0, _range2.default)(startYear, endYear + 1, 1).map(function (year) {
                                return _react2.default.createElement(
                                    _MenuItem2.default,
                                    {
                                        classes: {
                                            root: classes.monthYearSelectMenuItem,
                                            selected: classes.monthYearSelectMenuItemSelected
                                        },
                                        key: year,
                                        value: year,
                                        disableRipple: true,
                                        disableTouchRipple: true
                                    },
                                    (0, _moment2.default)(year, 'YYYY').format('YYYY')
                                );
                            }), function (e) {
                                onYearSelect(month, e.target.value);
                            }, month.year())
                        )
                    );
                } : null,
                enableOutsideDays: true,
                daySize: DEFAULT_DAY_SIZE,
                hideKeyboardShortcutsPanel: true,
                transitionDuration: 0
            };

            var autoPositionOverrides = {};

            if (autoPosition && this.datePickerRef.current && this.props.focused) {

                var rect = this.datePickerRef.current.getBoundingClientRect();

                // determine if the datepicker will fit in the available vertical space, and if not
                // if it makes more sense to open it *above* the field
                var verticalFit = rect.bottom + AUTOPOSITION_CALENDAR_HEIGHT <= document.documentElement.clientHeight;
                var bottomDelta = document.documentElement.clientHeight - rect.bottom;
                var topDelta = rect.top;
                var openDirection = !verticalFit && bottomDelta <= topDelta ? OPEN_UP : OPEN_DOWN;

                // determine if the datepicker will fit in the available horizontal space, and if not
                // if it makes more sense to anchor it to the *right* of the field
                var horizontalFit = rect.left + AUTOPOSITION_CALENDAR_WIDTH <= document.documentElement.clientWidth;
                var leftDelta = document.documentElement.clientWidth - rect.left;
                var rightDelta = rect.right;
                var anchorDirection = !horizontalFit && leftDelta <= rightDelta ? ANCHOR_RIGHT : ANCHOR_LEFT;

                // add positioning overrides
                autoPositionOverrides = {
                    openDirection: openDirection,
                    anchorDirection: anchorDirection
                };
            }

            var datePicker = variant === STANDALONE ?
            // Standalone
            _react2.default.createElement(
                'div',
                {
                    className: (0, _classnames2.default)(classes.root, classes.standalone, (_cn = {}, _defineProperty(_cn, classes.monthYearPickerFix, showMonthSelectors), _defineProperty(_cn, classes.rootRTL, props.isRTL), _defineProperty(_cn, classes.standaloneRTL, props.isRTL), _cn), className)
                },
                _react2.default.createElement(_reactDates.DayPickerSingleDateController, Object.assign({}, (0, _omit2.default)(DatePickerPropOverrides, INPUT_SPECIFIC_PROPS), (0, _omit2.default)(props, INPUT_SPECIFIC_PROPS)))
            ) :
            // With input
            _react2.default.createElement(
                _FormControl2.default,
                Object.assign({
                    className: (0, _classnames2.default)(classes.root, (_cn2 = {}, _defineProperty(_cn2, classes.focused, props.focused), _defineProperty(_cn2, classes.monthYearPickerFix, showMonthSelectors), _defineProperty(_cn2, classes.showPlaceholder, props.focused), _defineProperty(_cn2, classes.rootRTL, props.isRTL), _defineProperty(_cn2, classes.fullWidth, fullWidth), _defineProperty(_cn2, (0, _classnames2.default)(classes.noLabel, classes.noLabelMedium), !hasLabel && size !== 'small'), _defineProperty(_cn2, (0, _classnames2.default)(classes.noLabel, classes.noLabelSmall), !hasLabel && size === 'small'), _defineProperty(_cn2, classes.error, error), _defineProperty(_cn2, classes.focusedError, error && props.focused), _defineProperty(_cn2, classes.smallDatePicker, size === 'small'), _cn2), className),
                    fullWidth: fullWidth,
                    error: error
                }, FormControlProps),
                hasLabel && _react2.default.createElement(
                    _InputLabel2.default,
                    Object.assign({
                        htmlFor: props.id,
                        classes: {
                            formControl: classes.inputLabelFormControl,
                            shrink: classes.inputLabelShrink
                        },
                        className: (0, _classnames2.default)(_defineProperty({}, classes.inputLabelSmall, size === 'small')),
                        shrink: shouldShrink,
                        error: error
                    }, InputLabelProps),
                    label
                ),
                _react2.default.createElement(
                    _ClickAwayListener2.default,
                    { onClickAway: this.handleClose },
                    _react2.default.createElement(_reactDates.SingleDatePicker, Object.assign({
                        id: this.singleDatePickerId
                    }, DatePickerPropOverrides, props, autoPositionOverrides))
                ),
                helperText && _react2.default.createElement(
                    _FormHelperText2.default,
                    Object.assign({ id: helperTextId }, FormHelperTextProps),
                    helperText
                )
            );

            // if we're autopositioning, wrap the datepicker in a span so that
            // it can be referenced by the autopositioning algorithm
            datePicker = _react2.default.createElement(
                'span',
                { ref: this.datePickerRef },
                datePicker
            );

            return datePicker;
        }
    }]);

    return DatePicker;
}(_react2.default.Component);

DatePicker.defaultProps = {
    placeholder: DEFAULT_PLACEHOLDER,
    anchorDirection: ANCHOR_LEFT,
    disableScroll: false,
    displayFormat: DEFAULT_DISPLAY_FORMAT,
    enableOutsideDays: true,
    firstDayOfWeek: 0,
    inputIconPosition: ICON_AFTER_POSITION,
    isDayBlocked: function isDayBlocked() {
        return false;
    },
    isDayHighlighted: function isDayHighlighted() {
        return false;
    },
    isOutsideRange: null,
    isRTL: false,
    keepOpenOnDateSelect: false,
    openDirection: OPEN_DOWN,
    variant: WITH_TEXT_FIELD,
    weekDayFormat: DEFAULT_WEEK_DAY_FORMAT,
    phrases: _defaultPhrases.SingleDatePickerPhrases,
    monthSelectionVariant: INCREMENTAL_SELECTION,
    startYear: (0, _moment2.default)().year() - 3,
    endYear: (0, _moment2.default)().year() + 3,
    autoPosition: false,
    size: 'medium'
};

DatePicker.propTypes = {
    /**
     * @ignore
     * Added to allow for global JSS styling (and theme usage).
     */
    classes: _propTypes2.default.object,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,

    /****
     * FormControl, Input
     ****/
    /**
     * Custom class name to be applied on the `div` when `variant="standalone"` or the `FormControl`
     * when `variant="with-text-field"` (default).
     */
    className: _propTypes2.default.string,
    /**
     * Mark the `FormControl`, `FormHelperText` and `Input` in an error state.
     */
    error: _propTypes2.default.bool,
    /**
     * The helper text content.
     */
    helperText: _propTypes2.default.node,
    /**
     * Input label text. This is rendered as the child within the `InputLabel` component.
     */
    label: _propTypes2.default.string,
    /**
     * Properties applied to the [`FormControl`](#/components/FormControl) element.
     */
    FormControlProps: _propTypes2.default.object,
    /**
     * Properties applied to the [`FormHelperText`](#/components/FormControl) element.
     */
    FormHelperTextProps: _propTypes2.default.object,
    /**
     * Render the input field full width of the parent container.
     */
    fullWidth: _propTypes2.default.bool,
    /**
     * Properties applied to the [`InputLabel`](#/components/FormControl) element.
     */
    InputLabelProps: _propTypes2.default.object,
    /**
     * Render the `DatePicker` with input field or as a standalone calendar UI.
     *
     * Options are `WITH_TEXT_FIELD` ('with-text-field') or `STANDALONE` ('standalone') and must be imported from `@hedtech/react-design-system/core/DatePicker`.
     */
    variant: _propTypes2.default.oneOf([WITH_TEXT_FIELD, STANDALONE]),

    /****
     * Required props for a function interactive SingleDatePicker
     ****/
    /**
     * The selected date to display in the `DatePicker` input field.
     *
     * This prop expects a `moment` object.
     */
    date: _reactMomentProptypes2.default.momentObj,
    /**
     * Function that handles setting the date.
     * This function receives a `date` parameter.
     *
     * i.e. `date => this.setState({ date })`
     */
    onDateChange: _propTypes2.default.func.isRequired,
    /**
     * Handles the focused state of the component.
     */
    focused: _propTypes2.default.bool,
    /**
     * Function to set whether the `DatePicker` has a focused state or not.
     *
     * i.e. `({ focused }) => this.setState({ focused })`
     */
    onFocusChange: _propTypes2.default.func.isRequired,

    /**
     * How months are selected; incrementally, or by month/year:
     *   * `INCREMENTAL_SELECTION`: Shows next/previous navigation arrows on the date picker
     *   * `MONTH_YEAR_SELECTION`: Shows month/year selection dropdowns on the date picker
     *
     * Note: If month/year selection is enabled, the calendar will not allow selection of dates
     * before the start year, or after the end year.
     */
    monthSelectionVariant: _propTypes2.default.oneOf([INCREMENTAL_SELECTION, MONTH_YEAR_SELECTION]),

    /**
     * The first year in the year selection dropdown. Only applies when
     * `monthSelectionVariant` === `MONTH_YEAR_SELECTION`.
     *
     * Defaults to three years before current year.
     */
    startYear: _propTypes2.default.number,

    /**
     * The last year in the year selection dropdown. Only applies when
     * `monthSelectionVariant` === `MONTH_YEAR_SELECTION`.
     *
     * Defaults to three years after current year.
     */
    endYear: _propTypes2.default.number,

    /****
     * Input related props
     ****/
    /**
     * A unique identifier of the `DatePicker` to assist with ARIA attribute.
     */
    id: _propTypes2.default.string,
    /**
     * Placeholder text in the input field.
     */
    placeholder: _propTypes2.default.string,
    /**
     * @ignore
     */
    'aria-label': _propTypes2.default.string,
    /**
     * ARIA label for the HTML input field.
     *
     * **DEPRECATED** Use `aria-label` instead.
     */
    ariaLabel: _propTypes2.default.string,
    /**
     * Set the input field to disabled.
     */
    disabled: _propTypes2.default.bool,
    /**
     * Set the input field to required.
     */
    required: _propTypes2.default.bool,
    /**
     * Set the input to `readOnly`. This prevents the users from being able to type into the input field. The date can only be selected from the `DatePicker` UI.
     */
    readOnly: _propTypes2.default.bool,
    /**
     * Inform screen reader users of the date format, minimum days, blocked out dates, etc.
     */
    screenReaderInputMessage: _propTypes2.default.string,

    // NOTE: Hidden until needed
    // /**
    //  * If a date is selected, the Calendar icon will be swapped out with a Clear icon.
    //  */
    // showClearDate: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Render a custom close icon. `showClearDate` has to be `true`.
    //  */
    // customCloseIcon: PropTypes.node,
    // NOTE: Hidden until needed
    // /**
    //  * Show the default input icon.
    //  */
    // showDefaultInputIcon: PropTypes.bool,

    /**
     * Render the icon before the input, or after.
     *
     * Options are `ICON_BEFORE_POSITION` ('before') or `ICON_AFTER_POSITION` ('after') and must be imported from `@hedtech/react-design-system/core/DatePicker`.
     */
    inputIconPosition: _propTypes2.default.oneOf([ICON_BEFORE_POSITION, ICON_AFTER_POSITION]),

    // NOTE: Hidden until needed
    // /**
    //  * Render a custom input icon. `showDefaultInputIcon` has to be `true`.
    //  */
    // customInputIcon: PropTypes.node,
    // NOTE: Hidden until needed
    // /**
    //  * Render the `DatePicker` with no border.
    //  */
    // noBorder: PropTypes.bool,

    /**
     * Render the `DatePicker` with `display` set to `'block'`.
     */
    block: _propTypes2.default.bool,

    // NOTE: Hidden until needed
    // /**
    //  * Render `DatePicker` with size small.
    //  */
    // small: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Render `DatePicker` with size regular.
    //  */
    // regular: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Set the vertical spacing between the input field and the calendar UI.
    //  */
    // verticalSpacing: PropTypes.number,
    // NOTE: Hidden until needed
    // /**
    //  * Keep focus on the `input` after selecting a date.
    //  */
    // keepFocusOnInput: PropTypes.bool,

    /****
     * Calendar presentation and interaction related props
     ****/
    /**
     * Customize the month text rendered in the `DatePicker` UI.
     *
     * i.e. month => momentJalaali(month).format('jMMMM jYYYY') or 'MM YY'
     */
    renderMonthText: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]), // (month) => PropTypes.string,

    // NOTE: Hidden until needed
    // /**
    //  * Render custom month calendar.
    //  *
    //  * See the airbnb 'single month, custom caption' DayPickerSingleDateController example.
    //  *
    //  * i.e. `({ month, onMonthSelect, onYearSelect }) => <CustomMonthComponent />`
    //  */
    // renderMonthElement: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    // NOTE: Hidden until needed
    // /**
    //  * Define the orientation of the `DatePicker` calendar UI.
    //  */
    // orientation: PropTypes.oneOf([HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION]),

    /**
     * Set the direction of the calendar UI to be anchored to the left or the right of the input.
     *
     * Options are `ANCHOR_LEFT` ('left') or `ANCHOR_RIGHT` ('right') and must be imported from `@hedtech/react-design-system/core/DatePicker`.
     */
    anchorDirection: _propTypes2.default.oneOf([ANCHOR_LEFT, ANCHOR_RIGHT]),
    /**
     * Open the `DatePicker` from the top or bottom of the input. This is useful to use when the `DatePicker` selection is near the bottom of the screen.
     *
     * Options are `OPEN_DOWN` ('down') or `OPEN_UP` ('up') and must be imported from `@hedtech/react-design-system/core/DatePicker`.
     */
    openDirection: _propTypes2.default.oneOf([OPEN_DOWN, OPEN_UP]),

    // NOTE: Hidden until needed
    // horizontalMargin: PropTypes.number,
    // withPortal: PropTypes.bool,
    // withFullScreenPortal: PropTypes.bool,

    /**
     * Render the `DatePicker` as the last child in the HTML `body` element.
     *
     * This is particularly useful when rendering a `DatePicker` in a `Dialog`.
     */
    appendToBody: _propTypes2.default.bool,
    /**
     * Disable the scrolling behavior of the parent container when the `DatePicker` is open.
     */
    disableScroll: _propTypes2.default.bool,
    /**
     * Function to set whether the initial month shown.
     *
     * i.e. This function will force the `DatePicker` to open 10 months from today's month: `() => moment().add(10, 'months')`
     */
    initialVisibleMonth: _propTypes2.default.func,
    /**
     * Set the first day of the week.
     *
     * Options are `0`, `1`, `2`, `3`, `4`, `5`, or `6`.
     */
    firstDayOfWeek: _propTypes2.default.oneOf([0, 1, 2, 3, 4, 5, 6]),
    /**
     * Positions the DatePicker so that it doesn't go off the screen.
     *
     * If this is specified, the `openDirection` and `anchorDirection` props are ignored.
     */
    autoPosition: _propTypes2.default.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Set the number of months visible when the DatePicker is open.
    //  */
    // numberOfMonths: PropTypes.number,

    /**
     * Keep the `DatePicker` open after selecting a date.
     */
    keepOpenOnDateSelect: _propTypes2.default.bool,

    // NOTE: Hidden until needed
    // /**
    //  * Open the `DatePicker` back up when the date is cleared.
    //  */
    // reopenPickerOnClearDate: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Render custom calendar information.
    //  */
    // renderCalendarInfo: PropTypes.func,
    // NOTE: Hidden until needed
    // /**
    //  * Define where to render the additional calendar info. Use with `renderCalendarInfo`.
    //  */
    // calendarInfoPosition: PropTypes.oneOf([
    //     INFO_POSITION_TOP,
    //     INFO_POSITION_BOTTOM,
    //     INFO_POSITION_BEFORE,
    //     INFO_POSITION_AFTER,
    // ]),
    // NOTE: Hidden until needed
    // /**
    //  * Hide the keyboard shortcuts panel.
    //  */
    // hideKeyboardShortcutsPanel: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Customize the size of the days. This number should be a non-negative integer.
    //  */
    // daySize: PropTypes.number,

    /**
     * Change the reading direction of the `DatePicker`.
     */
    isRTL: _propTypes2.default.bool,

    // NOTE: Hidden until needed
    // /**
    //  * Change the duration of the month transition, measured in `milliseconds`.
    //  */
    // verticalHeight: PropTypes.number,
    // /**
    //  * Change the duration of the month transition, measured in `milliseconds`.
    //  */
    // transitionDuration: PropTypes.number,
    // /**
    //  * Change the horizontal month padding.
    //  */
    // horizontalMonthPadding: PropTypes.number,

    /****
     * Navigation related props
     ****/
    // NOTE: Hidden until needed
    // /**
    //  * Render a custom element in place of the `previous` chevron button.
    //  */
    // navPrev: PropTypes.node,
    // /**
    //  * Render a custom element in place of the `next` chevron button.
    //  */
    // navNext: PropTypes.node,

    /**
     * Event handler called when the `previous` chevron button is clicked.
     */
    onPrevMonthClick: _propTypes2.default.func,
    /**
     * Event handler called when the `next` chevron button is clicked.
     */
    onNextMonthClick: _propTypes2.default.func,
    /**
     * Event handler called when the `DatePicker` is closed.
     */
    onClose: _propTypes2.default.func,

    /****
     * Day presentation and interaction props
     ****/
    // NOTE: Hidden until needed
    // /**
    //  * Customize the contents of the Calendar day elements.
    //  *
    //  * See https://github.com/airbnb/react-dates/blob/master/stories/DateRangePicker_day.js#L136 and
    //  * https://github.com/airbnb/react-dates/blob/master/src/components/CustomizableCalendarDay.jsx.
    //  */
    // renderCalendarDay: PropTypes.func,

    // NOTE: We do not want consumers to customize this prop
    // it should be removed in an upcoming release.
    /**
     * Toggles if days outside of the shown month are visible.
     *
     * i.e. When March 2019 is shown, the overlapping dates in February and April will show.
     */
    enableOutsideDays: _propTypes2.default.bool,
    /**
     * A function that blocks specific days.
     * This function receives a `day` (a `moment` object) parameter.
     *
     * i.e. This function disables all Fridays: `day => moment.weekdays(day.weekday()) === 'Friday'`
     */
    isDayBlocked: _propTypes2.default.func,
    /**
     * A function that blocks a range of dates.
     * This function receives a `day` (a `moment` object) parameter.
     *
     * Note: `isInclusivelyAfterDay` is a utility function provided by `react-dates/utils`.
     *
     * i.e. This function allows for the next two weeks to be available: `day => !isInclusivelyAfterDay(day, moment()) || isInclusivelyAfterDay(day, moment().add(2, 'weeks'))`
     */
    isOutsideRange: _propTypes2.default.func,
    /**
     * A function that highlights a specific dates.
     * This function receives a `day` (a `moment` object) parameter.
     *
     * Note: `isSameDay` is a utility function provided by `react-dates/utils`.
     *
     * i.e. This function highlights dates that match a `moment` date stored in an `Array`: `day1 => datesList.some(day2 => isSameDay(day1, day2))`
     */
    isDayHighlighted: _propTypes2.default.func,

    /****
     * Internationalization props
     ****/

    /**
     * Date format of the date displayed in the input.
     *
     * See https://momentjs.com/docs/#/displaying/format/ for more information.
     *
     * i.e. `() => moment.localeData().longDateFormat('L')` or `'MM DD, YYYY'`
     */
    displayFormat: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
    /**
     * Date format of the month displayed.
     *
     * See https://momentjs.com/docs/#/displaying/format/ for more information.
     *
     * i.e. `'MMM'`
     */
    monthFormat: _propTypes2.default.string,
    /**
     * Date format of weekday headers.
     *
     * See https://momentjs.com/docs/#/displaying/format/ for more information.
     *
     * i.e. `'dd'`
     */
    weekDayFormat: _propTypes2.default.string,
    /**
     * Override default component phrases.
     *
     * See https://github.com/airbnb/react-dates/blob/master/src/defaultPhrases.js for more information.
     */
    phrases: _propTypes2.default.shape((0, _getPhrasePropTypes2.default)(_defaultPhrases.SingleDatePickerPhrases)),
    /**
     * ARIA label given to the focus-able days.
     */
    dayAriaLabelFormat: _propTypes2.default.string,
    /**
     * The size of the `DatePicker`. Small size is recommended to be used within the `TableEditableCell` component.
     */
    size: _propTypes2.default.oneOf(['small', 'medium'])
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(DatePicker));