import { withStyles as MuiWithStyles } from '@material-ui/core/styles';

/**
 * Wraps MUI withStyles and injects an index of 2 for consuming teams to
 * override the EDS 2 base styles
 * @param {Object} styles Contains JSS classnames and styles
 * @param {Object} options Configuration options
 */
var withStyles = function withStyles(styles, options) {
  var defaultOptions = Object.assign({ index: 2 }, options);
  return MuiWithStyles(styles, defaultOptions);
};

export default withStyles;