'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Avatar = require('@material-ui/core/Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }
// Necessary design tokens are imported here


var styles = {
  // Necessary style overrides are set here
  // See Material UI documentation for available CSS classes to override
  root: {},
  colorDefault: {
    backgroundColor: _tokens.colorBrandNeutral500
  }
};

/**
 * A component that creates a graphical representation of a user.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  AvatarIcon
 *  AvatarImage
 *  AvatarLetter
 */
var Avatar = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: classes.root,
    colorDefault: classes.colorDefault
  };

  return _react2.default.createElement(_Avatar2.default, Object.assign({}, rest, {
    classes: classOverrides,
    ref: ref
  }));
});

Avatar.muiName = 'Avatar';

Avatar.propTypes = {
  /**
   * Used in combination with src or srcSet to provide an alt attribute for the rendered img element.
   */
  alt: _propTypes2.default.string,
  /**
   * Used to render icon or text elements inside the Avatar. src and alt props will not be used and no img will be rendered by default.
   * This can be an element, or just a string.
   */
  children: _propTypes2.default.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object,
  /**
   * The component used for the root node. Either a string to use a DOM element or a component.
   */
  component: _propTypes2.default.oneOf(['string', 'func', 'object']),
  /**
   * Attributes applied to the img element when the component is used to display an image.
   */
  imgProps: _propTypes2.default.object,
  /**
   * The sizes attribute for the img element.
   */
  sizes: _propTypes2.default.string,
  /**
   * The src attribute for the img element.
   */
  src: _propTypes2.default.string,
  /**
   * The srcSet attribute for the img element.
   */
  srcSet: _propTypes2.default.string
};

// necessary to add an explicit display name, since docgen can't seem to
// extract one from a forwardRef'd component
Avatar.displayName = 'Avatar';

// If default props are needed, they must be set here
//Avatar.defaultProps = {
//};

exports.default = (0, _styles2.default)(styles)(Avatar);