'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Paper = require('@material-ui/core/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: {
            flexGrow: 1
        },
        typography: _defineProperty({
            paddingTop: _tokens.spacingMedium,
            paddingBottom: _tokens.spacingMedium,
            lineHeight: _tokens.sizingSmall
        }, theme.breakpoints.down('xs'), {
            paddingLeft: '2.75rem',
            paddingRight: '2.75rem'
        }),
        paperPositionStatic: {
            position: 'static'
        }
    };
};

/**
 * Use `Footer` to display copyright information.
 * @done true
 * @updated false
 * @versionAdded v0.0.7
 * @examples
 *  FooterExample
 */
var Footer = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        footerText = props.footerText,
        rest = _objectWithoutProperties(props, ['classes', 'footerText']);

    var typographyClassOverrides = {
        root: classes.typography
    };

    var paperClassOverrides = {
        root: 'static'
    };

    return _react2.default.createElement(
        _Paper2.default,
        Object.assign({
            square: true,
            component: 'footer',
            elevation: 0,
            classes: paperClassOverrides
        }, rest, {
            ref: ref
        }),
        _react2.default.createElement(
            _Typography2.default,
            { variant: 'body3', classes: typographyClassOverrides, align: 'center' },
            footerText
        )
    );
});

Footer.displayName = 'Footer';

Footer.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The string that the Footer will be displaying.
     */
    footerText: _propTypes2.default.string.isRequired
};

exports.default = (0, _styles2.default)(styles)(Footer);