'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _core = require('@material-ui/core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    root: {}
};

/**
 * A component that displays progress through numbered steps.
 */
var StepContent = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        root: classes.root
    };

    return _react2.default.createElement(_core.StepContent, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

StepContent.muiName = 'StepContent';
StepContent.displayName = 'StepContent';

StepContent.propTypes = {
    /**
     * Step content.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * Collapse component.
     */
    TransitionComponent: _propTypes2.default.elementType,
    /**
     * Adjust the duration of the content expand transition.
     * Passed as a property to the transition component.
     *
     * Set to 'auto' to automatically calculate transition time based on height.
     */
    transitionDuration: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.shape({ enter: _propTypes2.default.number, exit: _propTypes2.default.number }), _propTypes2.default.oneOf(['auto'])]),
    /**
     * Properties applied to the `Transition` element.
     */
    TransitionProps: _propTypes2.default.object
};

StepContent.defaultProps = {
    TransitionComponent: _core.Collapse,
    transitionDuration: 'auto'
};

exports.default = (0, _styles2.default)(styles)(StepContent);