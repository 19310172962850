function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiExpansionPanel } from '@material-ui/core/ExpansionPanel';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
  card: {
    boxShadow: '0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.21)'
  },
  default: {
    boxShadow: '0 19px 20px 0 rgba(0, 0, 0, 0.03), 0 3px 8px 0 rgba(0, 0, 0, 0.06)'
  }
};

/**
 * Use `ExpansionPanel` to organize content into expandable and collapsible sections.
 * @done true
 * @updated true
 * @versionAdded v0.0.13
 * @examples
 *  ExpansionPanelSimple
 *  ExpansionPanelCard
 *  ExpansionPanelControlledAccordion
 *  ExpansionPanelLeftIcon
 *  ExpansionPanelSecondary
 */
var ExpansionPanel = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      variant = props.variant,
      rest = _objectWithoutProperties(props, ['classes', 'variant']);

  var classOverrides = {};
  classOverrides.root = variant === 'card' ? classes.card : classes.default;

  return React.createElement(MuiExpansionPanel, Object.assign({}, rest, {
    classes: classOverrides,
    ref: ref
  }));
});

ExpansionPanel.muiName = 'ExpansionPanel';
ExpansionPanel.displayName = 'ExpansionPanel';

ExpansionPanel.propTypes = {
  /**
   * The content of the expansion panel.
   */
  children: PropTypes.node.isRequired,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Properties applied to the `Collapse` element.
   */
  CollapseProps: PropTypes.object,
  /**
   * If `true`, expands the panel by default.
   */
  defaultExpanded: PropTypes.bool,
  /**
   * If `true`, the panel will be displayed in a disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, expands the panel, otherwise collapse it.
   * Setting this prop enables control over the panel.
   */
  expanded: PropTypes.bool,
  /**
   * Callback fired when the expand/collapse state is changed.
   *
   * @param {object} event The event source of the callback
   * @param {boolean} expanded The `expanded` state of the panel
   */
  onChange: PropTypes.func,
  /**
   * Styles the ExpansionPanel for the context it is on, either 'default' or 'card'.
   */
  variant: PropTypes.oneOf(['default', 'card'])
};

// If default props are needed, they must be set here
ExpansionPanel.defaultProps = {
  variant: 'default',
  defaultExpanded: false,
  disabled: false
};

export default withStyles(styles)(ExpansionPanel);