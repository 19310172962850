import { Dispatcher } from 'Flux';
class AppDispatcher extends Dispatcher {
    constructor() {
        super();
    }
    dispatch(obj) {
        super.dispatch(obj);
    }
    dispatchAsync(promise, types, payload) {
        const { request, success, failure } = types;
        super.dispatch({ type: request, payload: Object.assign({}, payload) });
        promise.then((response) => super.dispatch({
            payload: Object.assign({}, payload, { response }),
            type: success
        }), (error) => super.dispatch({
            payload: Object.assign({}, payload, { error }),
            type: failure
        }));
    }
    register(callback) {
        return super.register(callback);
    }
}
export default new AppDispatcher();
