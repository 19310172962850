'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.cdn = exports.config = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _MuiThemeProvider = require('@material-ui/core/styles/MuiThemeProvider');

var _MuiThemeProvider2 = _interopRequireDefault(_MuiThemeProvider);

var _styles = require('@material-ui/styles');

var _themes = require('@hedtech/react-design-system/core/themes');

var _styles2 = require('../styles/');

var _EDSContext = require('../EDSContext/EDSContext');

var _CssBaseline = require('./internal/CssBaseline');

var _CssBaseline2 = _interopRequireDefault(_CssBaseline);

var _edsconfig = require('./internal/edsconfig.js');

var _edsconfig2 = _interopRequireDefault(_edsconfig);

var _urls = require('./internal/urls');

var _urls2 = _interopRequireDefault(_urls);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_LOCALE = 'en_US';

/**
 * `EDSApplication` is the top-level component of an EDS application. All apps must be wrapped in this component.
 *
 * @done true
 * @updated false
 * @versionAdded v0.0.13
 * @examples
 *  EDSApplicationExample
 */
var EDSApplication = function EDSApplication(props) {

    (0, _react.useEffect)(function () {
        // if the caller wants us to automatically set the global moment locale, do so
        if (props.locale && props.autosetMomentLocale) {
            _moment2.default.locale(props.locale);
        }
    }, [props.locale]); // eslint-disable-line react-hooks/exhaustive-deps

    return _react2.default.createElement(
        _EDSContext.EDSContext.Provider,
        { value: {
                locale: props.locale,
                labelOverrides: props.labelOverrides
            } },
        _react2.default.createElement(
            _styles.StylesProvider,
            { generateClassName: _styles2.classNameGenerator },
            _react2.default.createElement(
                _MuiThemeProvider2.default,
                { theme: props.theme },
                _react2.default.createElement(_CssBaseline2.default, null),
                props.children
            )
        )
    );
};

EDSApplication.muiName = 'EDSApplication';
EDSApplication.displayName = 'EDSApplication';

EDSApplication.propTypes = {

    /**
     * The nodes comprising the wrapped application
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * The application's theme.
     */
    theme: _propTypes2.default.object,
    /**
     * Overrides for the translated labels.
     */
    labelOverrides: _propTypes2.default.object,
    /**
     * Locale to use for translations
     */
    locale: _propTypes2.default.string,
    /**
     * Whether to set the global moment context to the locale specified by the `locale` prop.
     */
    autosetMomentLocale: _propTypes2.default.bool

};

// If default props are needed, they must be set here
EDSApplication.defaultProps = {
    theme: (0, _themes.createEDSTheme)('light'),
    labelOverrides: {},
    locale: DEFAULT_LOCALE,
    autosetMomentLocale: true
};

exports.config = _edsconfig2.default;
exports.cdn = _urls2.default;
exports.default = EDSApplication;