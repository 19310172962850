function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import Typography from '../Typography';
import { spacingXxSmall, spacingXSmall } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        // Necessary style overrides are set here
        root: {
            display: 'inline-block',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.button.fontWeight,
            padding: spacingXxSmall + ' ' + spacingXSmall
        },
        default: {
            backgroundColor: theme.palette.status.default.background,
            color: theme.palette.status.default.text
        },
        draft: {
            backgroundColor: theme.palette.status.warning.background,
            color: theme.palette.status.warning.text
        },
        error: {
            backgroundColor: theme.palette.status.error.background,
            color: theme.palette.status.error.text
        },
        pending: {
            backgroundColor: theme.palette.status.neutral.background,
            color: theme.palette.status.neutral.text
        },
        success: {
            backgroundColor: theme.palette.status.success.background,
            color: theme.palette.status.success.text
        }
    };
};

/**
 * Use a `StatusLabel` to indicate the status or category of an item.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  StatusLabelExample
 *  StatusLabelTableExample
 *  StatusLabelText
 */
var StatusLabel = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        className = props.className,
        textProp = props.text,
        typeProp = props.type,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'text', 'type']);

    var text = textProp.toUpperCase();
    var type = typeProp.toLowerCase();

    var classOverrides = {
        //CSS classes to override from Material UI Typography CSS API
        root: classNames(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.default, type === 'default'), _defineProperty(_classNames, classes.draft, type === 'draft'), _defineProperty(_classNames, classes.error, type === 'error'), _defineProperty(_classNames, classes.pending, type === 'pending'), _defineProperty(_classNames, classes.success, type === 'success'), _classNames), className)
    };

    return React.createElement(
        Typography,
        Object.assign({ component: 'span',
            classes: classOverrides,
            ref: ref
        }, rest),
        text
    );
});

StatusLabel.muiName = 'StatusLabel';

StatusLabel.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Text for the component to display
     */
    text: PropTypes.string.isRequired,
    /**
     * Type of status to indicate (default, draft, error, pending, success)
     */
    type: PropTypes.oneOf(['default', 'draft', 'error', 'pending', 'success'])
};

StatusLabel.displayName = 'StatusLabel';

StatusLabel.defaultProps = {
    type: 'default'
};

export default withStyles(styles)(StatusLabel);