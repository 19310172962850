'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _CircularProgress = require('@material-ui/core/CircularProgress');

var _CircularProgress2 = _interopRequireDefault(_CircularProgress);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
  // Necessary style overrides are set here
  // See Material UI documentation for available CSS classes to override
  root: {}
};

/**
 * Use `CircularProgress` to indicate work is being done "behind the scenes" when a user initiates a call to action (loading, working, etc).
 * @done false
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  CircularProgressIndeterminate
 *  CircularProgressDeterminate
 *  CircularProgressStatic
 */
var CircularProgress = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: classes.root
  };

  return _react2.default.createElement(_CircularProgress2.default, Object.assign({}, rest, {
    classes: classOverrides,
    ref: ref
  }));
});

CircularProgress.muiName = 'CircularProgress';
CircularProgress.displayName = 'CircularProgress';

CircularProgress.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: _propTypes2.default.oneOf(['primary', 'secondary', 'inherit']),
  /**
   * If true, the shrink animation is disabled. This only works if variant is indeterminate.
   */
  disableShrink: _propTypes2.default.bool,
  /**
  * The size of the circle.
  * If using a number, the pixel unit is assumed.
  * If using a string, you need to provide the CSS unit, e.g '3rem'.
  */
  size: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
  /**
   * @ignore
   */
  style: _propTypes2.default.object,
  /**
   * The thickness of the circle.
   */
  thickness: _propTypes2.default.number,
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: _propTypes2.default.number,
  /**
   * The variant to use.
   * Use indeterminate when there is no progress value.
   */
  variant: _propTypes2.default.oneOf(['determinate', 'indeterminate', 'static'])
};

CircularProgress.defaultProps = {
  color: 'primary',
  disableShrink: false,
  size: 40,
  thickness: 3.6,
  value: 0,
  variant: 'indeterminate'
};

exports.default = (0, _styles2.default)(styles)(CircularProgress);