'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _tokens = require('../styles/tokens');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var colorBackgroundHeaderUtilityBar = '#3939B8';
var colorBackgroundHeaderUtilityBarThemed = _tokens.colorBrandNeutral200;

var styles = function styles(theme) {
    return {

        root: {

            display: 'flex',
            height: '' + _tokens.sizingXxLarge1,
            borderBottom: _tokens.spacingXxSmall + ' solid ' + theme.palette.secondary.main,

            backgroundColor: colorBackgroundHeaderUtilityBar,

            '&.themed': {
                backgroundColor: colorBackgroundHeaderUtilityBarThemed
            }
        }

    };
};

/**
 * The utility area of a `HeaderBar`.
 *
 * @done true
 * @updated false
 * @versionAdded v0.2.4
 */

var HeaderUtilityBar = function (_React$Component) {
    _inherits(HeaderUtilityBar, _React$Component);

    function HeaderUtilityBar() {
        _classCallCheck(this, HeaderUtilityBar);

        return _possibleConstructorReturn(this, (HeaderUtilityBar.__proto__ || Object.getPrototypeOf(HeaderUtilityBar)).apply(this, arguments));
    }

    _createClass(HeaderUtilityBar, [{
        key: 'renderChildren',
        value: function renderChildren() {
            var _this2 = this;

            var children = [];

            if (this.props.SearchItem) {
                children.push(this.props.SearchItem);
            }

            children.push(_react2.default.Children.map(this.props.children, function (child) {
                return _react2.default.cloneElement(child, {
                    isUtility: true,
                    responsiveType: child.props.responsiveType || _this2.props.responsiveType
                });
            }));

            return children;
        }
    }, {
        key: 'render',
        value: function render() {
            var _props = this.props,
                classes = _props.classes,
                className = _props.className,
                isThemed = _props.isThemed;


            return _react2.default.createElement(
                'div',
                { className: (0, _classnames2.default)(classes.root, className, { 'themed': isThemed }) },
                this.renderChildren()
            );
        }
    }]);

    return HeaderUtilityBar;
}(_react2.default.Component);

HeaderUtilityBar.propTypes = {

    /**
     * At what breakpoint to hide all utility items on the header bar.
     *
     * Valid values are:
     *
     * - `none` - Never hide anything
     * - `small` - Hide items at the small breakpoint (and above)
     * - `medium` - Hide items at the medium breakpoint (and above)
     * - `large` - Hide items at the large breakpoint (and above)
     */
    responsiveType: _propTypes2.default.string,

    /**
     * The items in the utility bar. Only accepts `HeaderBarItem`s.
     */
    children: function children(props, propName, componentName) {
        var error = void 0;
        var prop = props[propName];

        _react2.default.Children.forEach(prop, function (child) {
            if (child.type.displayName.indexOf('HeaderBarItem') === -1) {
                error = new Error(componentName + ' only accepts children of type HeaderBarItem');
            }
        });

        return error;
    },

    /** @ignore */
    isThemed: _propTypes2.default.bool,

    /** @ignore */
    SearchItem: _propTypes2.default.node,

    /** @ignore */
    classes: _propTypes2.default.object.isRequired,

    /** @ignore */
    className: _propTypes2.default.string

};

HeaderUtilityBar.defaultProps = {
    isThemed: false
};

exports.default = (0, _styles2.default)(styles)(HeaderUtilityBar);