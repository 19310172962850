function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiAvatar } from '@material-ui/core/Avatar';
// Necessary design tokens are imported here
import { colorBrandNeutral500 } from '../styles/tokens';

var styles = {
  // Necessary style overrides are set here
  // See Material UI documentation for available CSS classes to override
  root: {},
  colorDefault: {
    backgroundColor: colorBrandNeutral500
  }
};

/**
 * A component that creates a graphical representation of a user.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  AvatarIcon
 *  AvatarImage
 *  AvatarLetter
 */
var Avatar = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: classes.root,
    colorDefault: classes.colorDefault
  };

  return React.createElement(MuiAvatar, Object.assign({}, rest, {
    classes: classOverrides,
    ref: ref
  }));
});

Avatar.muiName = 'Avatar';

Avatar.propTypes = {
  /**
   * Used in combination with src or srcSet to provide an alt attribute for the rendered img element.
   */
  alt: PropTypes.string,
  /**
   * Used to render icon or text elements inside the Avatar. src and alt props will not be used and no img will be rendered by default.
   * This can be an element, or just a string.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * The component used for the root node. Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOf(['string', 'func', 'object']),
  /**
   * Attributes applied to the img element when the component is used to display an image.
   */
  imgProps: PropTypes.object,
  /**
   * The sizes attribute for the img element.
   */
  sizes: PropTypes.string,
  /**
   * The src attribute for the img element.
   */
  src: PropTypes.string,
  /**
   * The srcSet attribute for the img element.
   */
  srcSet: PropTypes.string
};

// necessary to add an explicit display name, since docgen can't seem to
// extract one from a forwardRef'd component
Avatar.displayName = 'Avatar';

// If default props are needed, they must be set here
//Avatar.defaultProps = {
//};

export default withStyles(styles)(Avatar);