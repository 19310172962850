'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _withWidth = require('./withWidth');

Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_withWidth).default;
  }
});
Object.keys(_withWidth).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _withWidth[key];
    }
  });
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }