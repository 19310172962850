function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import Paper from '@material-ui/core/Paper';
import Typography from '../Typography';
import { sizingSmall, spacingMedium } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            flexGrow: 1
        },
        typography: _defineProperty({
            paddingTop: spacingMedium,
            paddingBottom: spacingMedium,
            lineHeight: sizingSmall
        }, theme.breakpoints.down('xs'), {
            paddingLeft: '2.75rem',
            paddingRight: '2.75rem'
        }),
        paperPositionStatic: {
            position: 'static'
        }
    };
};

/**
 * Use `Footer` to display copyright information.
 * @done true
 * @updated false
 * @versionAdded v0.0.7
 * @examples
 *  FooterExample
 */
var Footer = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        footerText = props.footerText,
        rest = _objectWithoutProperties(props, ['classes', 'footerText']);

    var typographyClassOverrides = {
        root: classes.typography
    };

    var paperClassOverrides = {
        root: 'static'
    };

    return React.createElement(
        Paper,
        Object.assign({
            square: true,
            component: 'footer',
            elevation: 0,
            classes: paperClassOverrides
        }, rest, {
            ref: ref
        }),
        React.createElement(
            Typography,
            { variant: 'body3', classes: typographyClassOverrides, align: 'center' },
            footerText
        )
    );
});

Footer.displayName = 'Footer';

Footer.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The string that the Footer will be displaying.
     */
    footerText: PropTypes.string.isRequired
};

export default withStyles(styles)(Footer);