"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var SvgArrowEndRight = function SvgArrowEndRight(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return _react2.default.createElement(
    "svg",
    Object.assign({ viewBox: "0 0 448 512", width: "1em", height: "1em", ref: svgRef }, props),
    _react2.default.createElement("path", { d: "M372 32h-24c-6.6 0-12 5.4-12 12v183L116.5 39.4C95.9 22.3 64 36.6 64 64v384c0 27.4 31.9 41.8 52.5 24.6L336 283.9V468c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12V44c0-6.6-5.4-12-12-12zM112 413.5V98.4l186.7 157.1-186.7 158z" })
  );
};

var ForwardRef = _react2.default.forwardRef(function (props, ref) {
  return _react2.default.createElement(SvgArrowEndRight, Object.assign({ svgRef: ref }, props));
});
exports.default = ForwardRef;