'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Snackbar = require('@material-ui/core/Snackbar');

var _Snackbar2 = _interopRequireDefault(_Snackbar);

var _useWidth = require('./../useWidth');

var _useWidth2 = _interopRequireDefault(_useWidth);

var _ = require('../');

var _CheckFeedback = require('@eui/ds-icons/lib/CheckFeedback');

var _CheckFeedback2 = _interopRequireDefault(_CheckFeedback);

var _Warning = require('@eui/ds-icons/lib/Warning');

var _Warning2 = _interopRequireDefault(_Warning);

var _Error = require('@eui/ds-icons/lib/Error');

var _Error2 = _interopRequireDefault(_Error);

var _Info = require('@eui/ds-icons/lib/Info');

var _Info2 = _interopRequireDefault(_Info);

var _EDSContext = require('../EDSContext/EDSContext');

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var commonStyles = {
    top: _tokens.sizingXxLarge1,
    margin: _tokens.spacingLarge
};

var commonMobileStyles = {
    margin: _tokens.spacingSmall
};

var styles = function styles(theme) {
    return {
        root: {
            zIndex: _tokens.zIndexSnackbar
        },
        anchorOriginTopRight: Object.assign({}, commonStyles, _defineProperty({
            right: 0
        }, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles))),
        anchorOriginTopCenter: Object.assign({}, commonStyles, _defineProperty({}, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles))),
        anchorOriginTopLeft: Object.assign({}, commonStyles, _defineProperty({}, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles))),
        anchorOriginBottomCenter: _defineProperty({
            margin: _tokens.spacingLarge
        }, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles)),
        anchorOriginBottomLeft: _defineProperty({}, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles)),
        anchorOriginBottomRight: _defineProperty({}, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles)),
        contentPropsStyles: _defineProperty({
            minWidth: '21.5rem',
            backgroundColor: _tokens.colorBrandNeutral500,
            color: _tokens.colorTextNeutral100,
            fontSize: _tokens.fontSizeLarge
        }, theme.breakpoints.between('xs', 'sm'), {
            borderRadius: _tokens.borderRadiusMedium
        }),
        contentMessage: {
            marginRight: _tokens.spacingLarge,
            flex: 1
        },
        message: {
            width: _tokens.widthFluid
        },
        snackbarContent: {
            display: 'flex',
            alignItems: 'center'
        },
        undoAction: {
            '& a, a:hover': {
                color: _tokens.colorTextNeutral100,
                textDecoration: 'underline'
            }
        },
        customAction: {
            marginRight: _tokens.spacingXSmall
        },
        icon: {
            marginRight: _tokens.spacingXSmall,
            height: _tokens.sizingMedium,
            width: _tokens.sizingMedium,
            fill: _tokens.colorTextNeutral100
        }
    };
};

var snackbarVariant = {
    success: _CheckFeedback2.default,
    warning: _Warning2.default,
    error: _Error2.default,
    info: _Info2.default
};

var CustomMessage = _react2.default.forwardRef(function (props, ref) {
    var variant = props.variant,
        message = props.message,
        onUndoClick = props.onUndoClick,
        action = props.action,
        classes = props.classes,
        edsContext = props.edsContext;

    var SnackbarIcon = function SnackbarIcon() {
        return null;
    };
    var undoAction = null;

    if (variant) {
        SnackbarIcon = snackbarVariant[variant];
    }

    if (onUndoClick) {
        undoAction = _react2.default.createElement(
            _.Button,
            {
                variant: 'text',
                withDarkBackground: true,
                onClick: onUndoClick
            },
            edsContext.formatMessage('component.Snackbar.undo')
        );
    }

    return _react2.default.createElement(
        'div',
        { className: classes.snackbarContent },
        _react2.default.createElement(SnackbarIcon, { className: classes.icon, ref: ref }),
        _react2.default.createElement(
            'span',
            { className: classes.contentMessage },
            message
        ),
        action && _react2.default.createElement(
            'span',
            { className: classes.customAction },
            action
        ),
        undoAction && _react2.default.createElement(
            'span',
            { className: classes.undoAction },
            undoAction
        )
    );
});

CustomMessage.propTypes = {
    /**
     * The action to display.
     */
    action: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * The message to display.
     */
    message: _propTypes2.default.node,
    /**
     * Variants of the Snackbar. Choose from these ['success', 'error', 'warning', 'info'].
     */
    variant: _propTypes2.default.oneOf(['success', 'error', 'warning', 'info']),
    /**
     * Callback fired when the undo action is clicked.
     * If this callback is present Snackbar will add Undo button automatically
     */
    onUndoClick: _propTypes2.default.func,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object
};

var DEFAULT_DURATION = 5000;
var ACTIONABLE_DEFAULT_DURATION = 7000;

/**
 * Use `Snackbar` to deliver low-urgency communication to the user.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  SnackbarExamples
 */
var Snackbar = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        ContentProps = props.ContentProps,
        action = props.action,
        onUndoClick = props.onUndoClick,
        anchorOrigin = props.anchorOrigin,
        bottom = props.bottom,
        edsContext = props.edsContext,
        autoHideDuration = props.autoHideDuration,
        rest = _objectWithoutProperties(props, ['classes', 'ContentProps', 'action', 'onUndoClick', 'anchorOrigin', 'bottom', 'edsContext', 'autoHideDuration']);

    var width = (0, _useWidth2.default)();
    var shouldAddMobileCustomStyles = function shouldAddMobileCustomStyles() {
        return (width === 'xs' || width === 'sm') && !anchorOrigin;
    };
    var getNewAutoHideDuration = function getNewAutoHideDuration() {
        var defatulDuration = onUndoClick || action ? ACTIONABLE_DEFAULT_DURATION : DEFAULT_DURATION;

        return autoHideDuration || defatulDuration;
    };

    var classOverrides = {
        root: classes.root,
        anchorOriginTopRight: classes.anchorOriginTopRight,
        anchorOriginTopCenter: classes.anchorOriginTopCenter,
        anchorOriginTopLeft: classes.anchorOriginTopLeft,
        anchorOriginBottomCenter: classes.anchorOriginBottomCenter,
        anchorOriginBottomRight: classes.anchorOriginBottomRight,
        anchorOriginBottomLeft: classes.anchorOriginBottomLeft
    };

    // This line will help screen read to read popper content.
    if (!ContentProps['aria-live']) {
        ContentProps['aria-live'] = 'assertive';
    }
    var contentProps = Object.assign({
        classes: {
            root: classes.contentPropsStyles,
            message: classes.message
        },
        style: shouldAddMobileCustomStyles() && bottom ? { marginBottom: bottom } : {}
    }, ContentProps);

    var propOverrides = {
        autoHideDuration: getNewAutoHideDuration(),
        // Custom actions need to be inserted in particular order which is done in `<CustomMessage/>`.
        action: null,
        anchorOrigin: anchorOrigin || { vertical: 'top', horizontal: 'right' }
    };

    if (shouldAddMobileCustomStyles()) {
        propOverrides.anchorOrigin = { vertical: 'bottom', horizontal: 'center' };
    }

    return _react2.default.createElement(_Snackbar2.default, Object.assign({}, rest, propOverrides, {
        classes: classOverrides,
        message: _react2.default.createElement(CustomMessage, Object.assign({ formatMessage: edsContext.formatMessage }, props, { ref: ref })),
        ContentProps: contentProps,
        ClickAwayListenerProps: {
            onClickAway: function onClickAway() {} // Prevent hiding on clickway
        },
        ref: ref
    }));
});

Snackbar.muiName = 'Snackbar';
Snackbar.displayName = 'Snackbar';

Snackbar.propTypes = {
    /**
     * The number of milliseconds to wait before `Snackbar` automatically closes.
     * Defaults to 5secs for nonactionable `Snackbar`s and 7secs for actionable `Snackbar`s.
     * This can be overridden by the users.
     */
    autoHideDuration: _propTypes2.default.number,
    /**
     * The custom action for Snackbar to display.
     * Undo action is built in to Snackbar but if you need more actions to perform, you can use `action` prop.
     */
    action: _propTypes2.default.node,
    /**
     * The anchor of the `Snackbar`. Defaults to top, right.
     */
    anchorOrigin: _propTypes2.default.shape({
        horizontal: _propTypes2.default.oneOf(['left', 'center', 'right']).isRequired,
        vertical: _propTypes2.default.oneOf(['top', 'bottom']).isRequired
    }),
    /**
     * If you wish the take control over the children of the component you can use this property.
     * When used, you replace the `SnackbarContent` component with the children.
     */
    children: _propTypes2.default.element,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * Properties applied to the `SnackbarContent` element.
     */
    ContentProps: _propTypes2.default.object,
    /**
     * If `true`, the `autoHideDuration` timer will expire even if the window is not focused.
     */
    disableWindowBlurListener: _propTypes2.default.bool,
    /**
     * When displaying multiple consecutive Snackbars from a parent rendering a single
     * <Snackbar/>, add the key property to ensure independent treatment of each message.
     * e.g. <Snackbar key={message} />, otherwise, the message may update-in-place and
     * features such as autoHideDuration may be canceled.
     */
    key: _propTypes2.default.any,
    /**
     * The message to display.
     */
    message: _propTypes2.default.node,
    /**
     * Callback fired when the component requests to be closed.
     * Typically `onClose` is used to set state in the parent component,
     * which is used to control the `Snackbar` `open` prop.
     * The `reason` parameter can optionally be used to control the response to `onClose`,
     * for example ignoring `clickaway`.
     *
     * @param {object} event The event source of the callback
     * @param {string} reason Can be:`"timeout"` (`autoHideDuration` expired) or: `"clickaway"`
     */
    onClose: _propTypes2.default.func,
    /**
     * Callback fired before the transition is entering.
     */
    onEnter: _propTypes2.default.func,
    /**
     * Callback fired when the transition has entered.
     */
    onEntered: _propTypes2.default.func,
    /**
     * Callback fired when the transition is entering.
     */
    onEntering: _propTypes2.default.func,
    /**
     * Callback fired before the transition is exiting.
     */
    onExit: _propTypes2.default.func,
    /**
     * Callback fired when the transition has exited.
     */
    onExited: _propTypes2.default.func,
    /**
     * Callback fired when the transition is exiting.
     */
    onExiting: _propTypes2.default.func,
    /**
     * @ignore
     */
    onMouseEnter: _propTypes2.default.func,
    /**
     * @ignore
     */
    onMouseLeave: _propTypes2.default.func,
    /**
     * If true, `Snackbar` is open.
     */
    open: _propTypes2.default.bool,
    /**
     * The number of milliseconds to wait before dismissing after user interaction.
     * If `autoHideDuration` property isn't specified, it does nothing.
     * If `autoHideDuration` property is specified but `resumeHideDuration` isn't,
     * we default to `autoHideDuration / 2` ms.
     */
    resumeHideDuration: _propTypes2.default.number,
    /**
     * Transition component.
     */
    TransitionComponent: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     */
    transitionDuration: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.shape({ enter: _propTypes2.default.number, exit: _propTypes2.default.number })]),
    /**
     * Properties applied to the `Transition` element.
     */
    TransitionProps: _propTypes2.default.object,
    /**
     * Variants of the Snackbar. Choose from these ['success', 'error', 'warning', 'info'].
     */
    variant: _propTypes2.default.oneOf(['success', 'error', 'warning', 'info']),
    /**
     * Callback fired when the undo action is clicked.
     * If this callback is present Snackbar will add Undo button automatically
     */
    onUndoClick: _propTypes2.default.func,
    /**
     * @ignore
     * Width based on the viewport size like xs, sm, md etc
     */
    width: _propTypes2.default.string,
    /**
     * Set the bottom in case of CTA bottom actions on mobile or floating button desktop.
     * Use the CSS units like `rem` or `px`
     */
    bottom: _propTypes2.default.string,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object
};

Snackbar.defaultProps = {
    ContentProps: {}
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(Snackbar));