'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _edsconfig = require('./edsconfig.js');

var _edsconfig2 = _interopRequireDefault(_edsconfig);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var cdnBaseUrlWithVersion = '' + _edsconfig2.default.cdnBaseUrl + _edsconfig2.default.appVersion;
var getLargeIllustrationsUrl = function getLargeIllustrationsUrl(assetName) {
    return cdnBaseUrlWithVersion + '/img/illustrations/large/' + assetName;
};

var getFontsUrl = function getFontsUrl() {
    return cdnBaseUrlWithVersion + '/fonts/fonts.css';
};

var getBackgroundImageUrl = function getBackgroundImageUrl(assetName) {
    return cdnBaseUrlWithVersion + '/img/bg/' + assetName;
};

var getFavIconUrl = function getFavIconUrl(assetName) {
    return assetName ? cdnBaseUrlWithVersion + '/img/favicons/' + assetName : cdnBaseUrlWithVersion + '/img/favicons/favicon.svg';
};

var cdn = {
    getLargeIllustrationsUrl: getLargeIllustrationsUrl,
    getFontsUrl: getFontsUrl,
    getBackgroundImageUrl: getBackgroundImageUrl,
    getFavIconUrl: getFavIconUrl
};

exports.default = cdn;