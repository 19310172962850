import React from 'react';
import PropTypes from 'prop-types';
import ErrorTemplateBody from './ErrorTemplateBody';

var BadRequest400Page = React.forwardRef(function (props, ref) {
    var edsContext = props.edsContext;

    var errorTitle = edsContext.formatMessage('component.ErrorPage.400');
    var errorMessage = edsContext.formatMessage('component.ErrorPage.400.message');

    return React.createElement(ErrorTemplateBody, Object.assign({
        largeIllustrationName: '400.png',
        errorTitle: errorTitle,
        errorMessage: errorMessage
    }, props, {
        ref: ref
    }));
});

BadRequest400Page.propTypes = {
    edsContext: PropTypes.object
};

export default BadRequest400Page;