function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import classNames from 'classnames';
import { fontFamilyDefault, fontSizeDefault, fontWeightStrong, borderRadiusSmall, borderWidthThick, colorBorderGlobalFocus, colorTextNeutral400 } from '../styles/tokens';
import isEmpty from 'lodash/isEmpty';

var styles = function styles(theme) {
    return {
        link: {
            color: theme.palette.ctaColor.base,
            textDecoration: 'underline',
            '&:hover': {
                color: theme.palette.ctaColor.hover,
                textDecoration: 'underline',
                cursor: 'pointer'
            },
            '&:active': {
                color: theme.palette.ctaColor.active,
                textDecoration: 'underline'
            },
            '&:focus': {
                outline: 'none',
                textDecoration: 'underline',
                borderRadius: borderRadiusSmall,
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBorderGlobalFocus
            }
        },
        disabled: {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            textDecoration: 'underline',
            color: colorTextNeutral400
        },
        standalone: {
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeDefault,
            fontWeight: fontWeightStrong
        },
        inline: {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit'
        }
    };
};

/**
 * Use `TextLink` to take the user to a new page.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  TextLinkExample
 *  CustomComponent
 */
var TextLink = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        className = props.className,
        Component = props.component,
        disabled = props.disabled,
        text = props.text,
        url = props.url,
        variant = props.variant,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'component', 'disabled', 'text', 'url', 'variant']);

    var classOverrides = {
        stateStyle: classNames((_classNames = {}, _defineProperty(_classNames, classes.disabled, disabled), _defineProperty(_classNames, classes.link, !disabled), _defineProperty(_classNames, classes.standalone, variant === 'standalone'), _defineProperty(_classNames, classes.inline, variant === 'inline'), _classNames), className)
    };

    useEffect(function () {
        if (props.component === 'a' && isEmpty(props.url)) {
            console.warn('url prop is required for TextLink component.');
        }
    }, [props.component, props.url]);

    if (disabled) {
        return React.createElement(
            Component,
            Object.assign({
                'aria-disabled': 'true',
                className: classOverrides.stateStyle
            }, rest),
            text
        );
    } else {
        return React.createElement(
            Component,
            Object.assign({
                href: url,
                className: classOverrides.stateStyle
            }, rest, {
                ref: ref
            }),
            text
        );
    }
});

TextLink.muiName = 'TextLink';
TextLink.displayName = 'TextLink';

TextLink.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * Custom className for additional styling
     */
    className: PropTypes.string,
    /**
     * The component used for the root node. We recommend using a component or element
     * that supports the `href` prop.
     */
    component: PropTypes.elementType,
    /**
     * Set to true to disable the link
     */
    disabled: PropTypes.bool,
    /**
     * The text that the link will display
     */
    text: PropTypes.string.isRequired,
    /**
     * The redirect URL. This prop is required if replacement `component` prop is not specified.
     */
    url: PropTypes.string,
    /**
     * Style variant of the TextLink to be displayed. <code>inherit</code> will inherit font weight, font size, and font family from surrounding text. <code>standalone</code> is to be used for a <code>TextLink</code> that is not surrounded by other text.
     */
    variant: PropTypes.oneOf(['standalone', 'inherit'])
};

TextLink.defaultProps = {
    component: 'a',
    disabled: false,
    variant: 'standalone'
};

export default withStyles(styles)(TextLink);