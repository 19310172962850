'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _expansionPanelStyles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles2 = require('../styles');

var _styles3 = _interopRequireDefault(_styles2);

var _Table = require('@material-ui/core/Table');

var _Table2 = _interopRequireDefault(_Table);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaultResponsiveStyles = {
    border: 0,
    position: 'relative',
    '& thead': {
        // `display: 'none'` will not be read by screen readers
        // hence hiding it through visibility property.
        border: 'none',
        clip: 'rect(0 0 0 0)',
        height: _tokens.borderWidthThin,
        margin: _tokens.borderWidthThin,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: _tokens.borderWidthThin
    },
    '& tbody tr': {
        display: 'flex',
        flexWrap: 'wrap',
        height: 'auto',
        marginBottom: _tokens.spacingMedium,
        borderBottom: _tokens.borderWidthThin + ' solid ' + _tokens.colorBrandNeutral300,
        paddingBottom: _tokens.spacingMedium,
        position: 'relative'
    },
    '& tbody td:before, & tbody th:before': {
        content: 'attr(data-label)',
        fontWeight: 'bold',
        whiteSpace: 'normal',
        wordBreak: 'break-all',
        left: 0,
        color: _tokens.colorBrandNeutral600,
        float: 'left',
        width: '6rem',
        minWidth: '6rem',
        marginRight: _tokens.spacingMedium
    },
    '& tbody td, & tbody th': {
        borderBottom: 'none',
        fontSize: _tokens.fontSizeDefault,
        textAlign: 'left',
        width: _tokens.widthFluid,
        padding: _tokens.spacingXSmall + ' 0',
        color: _tokens.colorBrandNeutral500,
        display: 'block'
    },
    '& tbody td > *, & tbody th > *': {
        display: 'flex'
    }
};

var expansionPanelStyles = (_expansionPanelStyles = {
    '& tbody tr': {
        padding: _tokens.spacingXSmall + ' !important',
        paddingRight: _tokens.spacingXxLarge + ' !important',
        marginBottom: '0 !important',
        borderColor: 'transparent'
    },
    '& tbody tr td, & tbody tr th': {
        wordBreak: 'break-all'
    }
}, _defineProperty(_expansionPanelStyles, '& thead th:last-child,\n        & tbody td:last-child,\n        & tbody th:last-child', {
    // On mobile should be always visible
    display: 'inherit !important'
}), _defineProperty(_expansionPanelStyles, '& tbody td:not(:first-child), & tbody th:not(:first-child)', {
    display: 'none'
}), _defineProperty(_expansionPanelStyles, '& tbody tr.table-row-expanded td:not(:first-child),\n        & tbody tr.table-row-expanded th:not(:first-child),\n        & tbody td.no-expanded-row-content span,\n        & tbody th.no-expanded-row-content span', {
    display: 'inherit'
}), _defineProperty(_expansionPanelStyles, '& tbody tr td.expand-row-button', {
    position: 'absolute',
    width: 'auto',
    top: 0,
    right: 0,
    paddingRight: _tokens.spacingSmall
}), _defineProperty(_expansionPanelStyles, '& tbody tr.table-row-expanded-content', {
    paddingRight: _tokens.spacingMedium
}), _defineProperty(_expansionPanelStyles, '& tbody tr.table-row-expanded-content td,\n        & tbody tr.table-row-expanded-content th', {
    wordBreak: 'break-word',
    paddingRight: 0
}), _defineProperty(_expansionPanelStyles, '& tbody tr.table-row-expanded-content td:before,\n        & tbody tr.table-row-expanded-content th:before', {
    display: 'none'
}), _defineProperty(_expansionPanelStyles, '& tbody tr.table-row-expanded-content td > *,\n        & tbody tr.table-row-expanded-content th > *', {
    display: 'inline-block'
}), _expansionPanelStyles);

var generateResponsiveBreakpoints = function generateResponsiveBreakpoints(breakpoints) {
    var styles = {};

    // Create responsive styles for each breakpoint
    // When responsive='xl', it will always show
    breakpoints.keys.forEach(function (breakpoint) {
        var _styles;

        styles['responsive_' + breakpoint] = (_styles = {
            width: 'calc(100% - 0.8rem)'
        }, _defineProperty(_styles, '& tbody td.no-expanded-row-content span,\n                & tbody th.no-expanded-row-content span', {
            display: 'none'
        }), _defineProperty(_styles, '&$responsive', _defineProperty({}, breakpoints.down(breakpoint), Object.assign({}, defaultResponsiveStyles))), _defineProperty(_styles, '&$expansionPanels', _defineProperty({}, breakpoints.down(breakpoint), Object.assign({}, expansionPanelStyles))), _styles);
    });

    return styles;
};

var styles = function styles(theme) {
    return Object.assign({
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: {},
        responsive: _defineProperty({
            width: 'calc(100% - 0.8rem)'
        }, '& tbody td.no-expanded-row-content span,\n            & tbody th.no-expanded-row-content span', {
            display: 'none'
        }),
        expansionPanels: {}
    }, generateResponsiveBreakpoints(theme.breakpoints));
};

/**
 * Use `Table` to display sets of data within a grid.
 * @done true
 * @updated true
 * @versionAdded v0.0.10
 * @examples
    TableExample
    TableWithPagination
    AnytimeEditableTableExample
    ResponsiveTableExample
    ResponsiveTableExpansionPanels
    ResponsiveTableExpansionAll
 */
var Table = _react2.default.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        onExpand = props.onExpand,
        onClick = props.onClick,
        layoutProp = props.layout,
        rest = _objectWithoutProperties(props, ['classes', 'onExpand', 'onClick', 'layout']);

    var layout = Object.assign({
        // declare default values to compare if consumer sent in a prop or not, but hide from prop API
        variant: '',
        breakpoint: ''
    }, layoutProp);

    var classOverrides = {
        root: (0, _classnames2.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.responsive, layoutProp), _defineProperty(_classNames, classes.responsive_xs, layout.breakpoint === 'xs'), _defineProperty(_classNames, classes.responsive_sm, layout.breakpoint === 'sm'), _defineProperty(_classNames, classes.responsive_md, layout.breakpoint === 'md'), _defineProperty(_classNames, classes.responsive_lg, layout.breakpoint === 'lg'), _defineProperty(_classNames, classes.responsive_xl, layout.variant !== '' && layout.breakpoint === ''), _defineProperty(_classNames, classes.expansionPanels, layout.variant === 'expansionPanels'), _classNames))
    };

    var callback = function callback(e) {
        var targetClassList = e.target.classList;
        var isTargetExpandButton = targetClassList ? targetClassList.contains('expand-icon-container') || targetClassList.contains('expand-icon') : false;

        if (isTargetExpandButton && onExpand) {
            var _target = e.currentTarget;

            // NOTE: The code coverage tool is not counting statements within `setTimeout()` for some reason.
            // Ignoring this for a while until we find a solution.
            // However, this block is always executed when expand all is clicked for which I have added UTs
            // TODO: Find how to get around `setTimeout()` for code coverage
            /* istanbul ignore next */
            // Break out of execution stack.
            setTimeout(function () {
                var rowsExpanded = _target.querySelectorAll('.table-row-expanded');
                var rowsNotExpanded = _target.querySelectorAll('.not-table-row-expanded');
                var rows = _target.querySelectorAll('.table-expandable-row');

                if (rowsExpanded.length === rows.length) {
                    onExpand(e, true);
                } else if (rowsNotExpanded.length === rows.length) {
                    onExpand(e, false);
                }
            });
        }

        onClick && onClick(e);
    };

    return _react2.default.createElement(_Table2.default, Object.assign({}, rest, { onClick: callback, classes: classOverrides, ref: ref }));
});

Table.muiName = 'Table';
Table.displayName = 'Table';

Table.propTypes = {
    /**
     * The content of the table, normally `TableHeader` and `TableBody`.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * Class name to be applied to the `Table` container.
     */
    className: _propTypes2.default.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
    /**
     * Specify the cell size
     */
    size: _propTypes2.default.oneOf(['small', 'medium']),

    /**
     * Set the responsive layout `variant` and the `breakpoint` to trigger the responsive layout of the table.
     *
     * The shape of this prop is `{ variant: ['card', 'expansionPanels'], breakpoint: ['xs', 'sm', 'md', 'lg'] }`.
     *
     * If you do not specify a `breakpoint`, then the table will always show the given responsive layout variant.
     *
     * e.g.
     *
     * - `{ variant: 'card', breakpoint: 'sm' }` will result in the table rendering in the default table layout for viewports
     * greater than or equal to `sm` (960px) and the 'card' responsive layout when the viewport is smaller than `sm`.
     * - `{ variant: 'expansionPanels' }` will result in the table always showing the responsive expansion panel layout
     */
    layout: _propTypes2.default.shape({
        variant: _propTypes2.default.string,
        breakpoint: _propTypes2.default.oneOf(['xs', 'sm', 'md', 'lg'])
    }),
    /**
     * Callback to toggle the boolean value
     */
    onExpand: _propTypes2.default.func,
    /**
     * Callback triggered when expand all is clicked.
     */
    onClick: _propTypes2.default.func
};

// default props
Table.defaultProps = {
    size: 'medium'
};

exports.default = (0, _styles3.default)(styles)(Table);