var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import { Icon } from '@eui/ds-icons/lib';
import classNames from 'classnames';
import { withEDSContext } from '../EDSContext/EDSContext';
import useEnforceFocusFlag from './internal/useEnforceFocusFlag';
import { borderRadiusMedium, borderRadiusReset, mqSmall, mqMedium, mqLarge, spacingSmall, spacingXSmall, spacingLarge, sizingXxLarge, sizingXSmall, widthFluid } from '../styles/tokens';

var styles = function styles(theme) {
    var _paper, _internalDialog;

    return {

        // Styles applied to the root element.
        root: {
            '&.fullScreen': {
                padding: 0,
                '& .dialog-close-button': {
                    top: spacingXSmall,
                    right: spacingXSmall
                }
            }
        },

        // Styles applied to the Paper component.
        paper: (_paper = {
            borderRadius: borderRadiusMedium,
            minHeight: '12.5rem',
            padding: spacingSmall,
            width: '21rem'
        }, _defineProperty(_paper, '@media ' + mqSmall, {
            padding: spacingLarge,
            width: '37.5rem'
        }), _defineProperty(_paper, '@media ' + mqMedium, {
            padding: spacingLarge,
            width: '39.625rem'
        }), _defineProperty(_paper, '@media ' + mqLarge, {
            padding: spacingLarge,
            width: '53rem'
        }), _paper),

        // Styles applied to the Paper component if fullWidth=true.
        paperFullWidth: {
            width: widthFluid + ' !important'
        },

        dialogXButton: {
            background: 'none',
            border: 'none',
            width: sizingXxLarge,
            height: sizingXxLarge,
            position: 'absolute',
            top: spacingSmall,
            right: spacingSmall,
            textAlign: 'center',
            '&:active': {
                color: theme.palette.ctaColor.active
            },
            '&:hover': {
                color: theme.palette.ctaColor.hover
            },
            '&:active:hover': {
                color: theme.palette.ctaColor.active
            }
        },
        paperFullScreen: {
            // TODO: Find a better way to increase the opacity.
            // internalDialog classes are overriding this class for now,
            // hence !important.
            width: widthFluid + ' !important',
            borderRadius: borderRadiusReset + ' !important'
        },

        internalDialog: (_internalDialog = {
            padding: '1.25rem',
            borderRadius: sizingXSmall,
            position: 'relative',
            minHeight: '12.5rem',
            width: '21.5rem'
        }, _defineProperty(_internalDialog, '@media ' + mqSmall, {
            width: '37.5rem'
        }), _defineProperty(_internalDialog, '@media ' + mqMedium, {
            width: '39.625rem'
        }), _defineProperty(_internalDialog, '@media ' + mqLarge, {
            width: '53rem'
        }), _internalDialog)
    };
};

var paperClassName = 'eds-dialog-paper';

/**
 * Use `Dialog` to display information without losing the context of the page.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  DialogA11y
 */
var Dialog = React.forwardRef(function (props, ref) {
    var _useEnforceFocusFlag = useEnforceFocusFlag(),
        _useEnforceFocusFlag2 = _slicedToArray(_useEnforceFocusFlag, 3),
        disableEnforceFocusFlag = _useEnforceFocusFlag2[0],
        onFocus = _useEnforceFocusFlag2[1],
        onBlur = _useEnforceFocusFlag2[2];

    var children = props.children,
        classesProp = props.classes,
        onClose = props.onClose,
        showTitleBarClose = props.showTitleBarClose,
        PaperPropsProp = props.PaperProps,
        edsContext = props.edsContext,
        open = props.open,
        fullScreen = props.fullScreen,
        rest = _objectWithoutProperties(props, ['children', 'classes', 'onClose', 'showTitleBarClose', 'PaperProps', 'edsContext', 'open', 'fullScreen']);

    var classes = {
        root: classesProp.root,
        paper: classesProp.paper,
        paperWidthXs: classesProp.paperWidthXs,
        paperWidthSm: classesProp.paperWidthSm,
        paperWidthMd: classesProp.paperWidthMd,
        paperFullWidth: classesProp.paperFullWidth,
        paperFullScreen: classesProp.paperFullScreen
    };

    // local overrides to the dialog's internal paper
    var internalPaperProps = {
        classes: {
            root: classesProp.internalDialog
        },
        className: paperClassName
    };

    // incorporate consumer's paper overrides as well (if any)
    var PaperProps2 = Object.assign(internalPaperProps, PaperPropsProp);

    // style the close 'x' in the title
    var buttonClasses = classNames(undefined !== props.className ? props.className : '', 'dialog-close-button', classesProp.dialogXButton);

    var onFocusCallback = function onFocusCallback(e) {
        var propsOnFocus = props.onFocus;

        onFocus(e);
        propsOnFocus && propsOnFocus(e);
    };

    var onBlurCallback = function onBlurCallback(e) {
        var propsOnBlur = props.onFocus;

        onBlur(e);
        propsOnBlur && propsOnBlur(e);
    };

    return React.createElement(
        MuiDialog,
        Object.assign({
            classes: classes,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            PaperProps: PaperProps2,
            onClose: onClose,
            className: 'eds-dialog',
            open: open,
            fullScreen: fullScreen,
            onFocus: onFocusCallback,
            onBlur: onBlurCallback
        }, rest, {
            ref: ref,
            disableEnforceFocus: disableEnforceFocusFlag
        }),
        showTitleBarClose && React.createElement(
            'button',
            {
                'aria-label': edsContext.formatMessage('component.Dialog.close'),
                title: edsContext.formatMessage('component.Dialog.close'),
                className: buttonClasses,
                onClick: onClose },
            React.createElement(Icon, { name: 'close', large: true })
        ),
        children
    );
});

Dialog.muiName = 'Dialog';
Dialog.displayName = 'Dialog';

Dialog.propTypes = {
    /**
     * @ignore
     */
    BackdropProps: PropTypes.object,
    /**
     * Dialog children, usually the included sub-components.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, clicking the backdrop will not fire the `onClose` callback.
     */
    disableBackdropClick: PropTypes.bool,
    /**
     * If `true`, hitting escape will not fire the `onClose` callback.
     */
    disableEscapeKeyDown: PropTypes.bool,
    /**
     * If `true`, the dialog will be full-screen
     */
    fullScreen: PropTypes.bool,
    /**
     * If `true`, the dialog stretches to `maxWidth`.
     */
    fullWidth: PropTypes.bool,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * Determine the max width of the dialog.
     *
     * The dialog width grows with the size of the screen, this property is useful
     * on the desktop where you might need some different widths across your
     * application.
     *
     * Valid values:
     *
     * * `xs`
     * * `sm`
     * * `md`
     * * `lg`
     * * `false` (disables max width)
     */
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', false]),
    /**
     * Callback fired when the backdrop is clicked.
     */
    onBackdropClick: PropTypes.func,
    /**
     * Callback fired when the component requests to be closed.
     *
     * @param {object} event The event source of the callback
     */
    onClose: PropTypes.func,
    /**
     * Callback fired before the dialog enters.
     */
    onEnter: PropTypes.func,
    /**
     * Callback fired when the dialog has entered.
     */
    onEntered: PropTypes.func,
    /**
     * Callback fired when the dialog is entering.
     */
    onEntering: PropTypes.func,
    /**
     * Callback fired when the escape key is pressed,
     * `disableKeyboard` is false and the modal is in focus.
     */
    onEscapeKeyDown: PropTypes.func,
    /**
     * Callback fired before the dialog exits.
     */
    onExit: PropTypes.func,
    /**
     * Callback fired when the dialog has exited.
     */
    onExited: PropTypes.func,
    /**
     * Callback fired when the dialog is exiting.
     */
    onExiting: PropTypes.func,
    /**
     * If `true`, the Dialog is open.
     */
    open: PropTypes.bool.isRequired,
    /**
     * Properties applied to the dialog's internal Paper element.
     */
    PaperProps: PropTypes.object,
    /**
     * Determine the container for scrolling the dialog.
     */
    scroll: PropTypes.oneOf(['body', 'paper']),
    /**
     * Whether to show a close 'x' in the title bar. Clicking this invokes the
     * `onClose` handler.
     */
    showTitleBarClose: PropTypes.bool,
    /**
     * Transition component.
     */
    TransitionComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     */
    transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number })]),
    /**
     * Properties applied to the `Transition` element.
     */
    TransitionProps: PropTypes.object
};

Dialog.defaultProps = {
    disableBackdropClick: false,
    disableEscapeKeyDown: false,
    fullScreen: false,
    fullWidth: false,
    maxWidth: 'sm',
    scroll: 'paper',
    showTitleBarClose: true
};

export default withEDSContext(withStyles(styles)(Dialog));