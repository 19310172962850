function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiExpansionPanelActions } from '@material-ui/core/ExpansionPanelActions';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
    // Necessary style overrides are set here
    // See Material UI documentation for available CSS classes to override
    root: {}
};

/**
 * A component that....
 */
var ExpansionPanelActions = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: classes.root
    };

    return React.createElement(MuiExpansionPanelActions, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

ExpansionPanelActions.muiName = 'ExpansionPanelActions';
ExpansionPanelActions.displayName = 'ExpansionPanelActions';

ExpansionPanelActions.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string
};

// If default props are needed, they must be set here
// ExpansionPanelActions.defaultProps = { };

export default withStyles(styles)(ExpansionPanelActions);