'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _EDSContext = require('../EDSContext/EDSContext');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

/**
 * A component that displays the current step of the total steps
 */
var MobileStepperCounter = _react2.default.forwardRef(function (props, ref) {
  var activeStep = props.activeStep,
      steps = props.steps,
      edsContext = props.edsContext,
      rest = _objectWithoutProperties(props, ['activeStep', 'steps', 'edsContext']);

  return _react2.default.createElement(
    _Typography2.default,
    Object.assign({ variant: 'body2' }, rest, { ref: ref }),
    edsContext.formatMessage('component.MobileStepper.step', {
      step: activeStep + 1 >= steps ? steps : activeStep + 1,
      count: steps
    })
  );
});

MobileStepperCounter.propTypes = {
  /**
   * Set the active step (zero based index). This should be the same value that is being sent to the <MobileStepper> component.
   */
  activeStep: _propTypes2.default.number,
  /**
   * The total steps. This should be the same value that is being sent to the <MobileStepper> component.
   */
  steps: _propTypes2.default.number.isRequired,
  /**
   * Props will be passed into the rendered `<Typography>` component.
   */
  props: _propTypes2.default.object,
  /**
   * @ignore
   */
  edsContext: _propTypes2.default.object
};

MobileStepperCounter.displayName = 'MobileStepperCounter';

exports.default = (0, _EDSContext.withEDSContext)(MobileStepperCounter);