"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgServer = function SvgServer(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", { fill: "currentColor", d: "M3 6h54v48H3z" }),
            _react2.default.createElement("path", { fill: "#FFF", opacity: 0.88, d: "M3 6h54v48H3z" }),
            _react2.default.createElement("path", {
                d: "M44.063 30a2.803 2.803 0 0 1 2.812-2.793A2.803 2.803 0 0 1 49.688 30a2.803 2.803 0 0 1-2.813 2.793A2.803 2.803 0 0 1 44.062 30zm-4.688 2.793A2.803 2.803 0 0 0 42.188 30a2.803 2.803 0 0 0-2.813-2.793A2.803 2.803 0 0 0 36.562 30a2.803 2.803 0 0 0 2.813 2.793zM60 17.897a5.543 5.543 0 0 1-1.433 3.724A5.543 5.543 0 0 1 60 25.345v9.31a5.543 5.543 0 0 1-1.433 3.724A5.543 5.543 0 0 1 60 42.103v9.31C60 54.5 57.482 57 54.375 57H5.625C2.518 57 0 54.499 0 51.414v-9.31c0-1.432.542-2.736 1.433-3.725A5.543 5.543 0 0 1 0 34.655v-9.31c0-1.431.542-2.736 1.433-3.724A5.543 5.543 0 0 1 0 17.897v-9.31C0 5.5 2.518 3 5.625 3h48.75C57.482 3 60 5.501 60 8.586v9.31zm-56.25 0a1.87 1.87 0 0 0 1.875 1.862h48.75a1.87 1.87 0 0 0 1.875-1.862v-9.31a1.87 1.87 0 0 0-1.875-1.863H5.625A1.87 1.87 0 0 0 3.75 8.586v9.31zm50.625 5.586H5.625a1.87 1.87 0 0 0-1.875 1.862v9.31a1.87 1.87 0 0 0 1.875 1.862h48.75a1.87 1.87 0 0 0 1.875-1.862v-9.31a1.87 1.87 0 0 0-1.875-1.862zm1.875 18.62a1.87 1.87 0 0 0-1.875-1.862H5.625a1.87 1.87 0 0 0-1.875 1.862v9.31a1.87 1.87 0 0 0 1.875 1.863h48.75a1.87 1.87 0 0 0 1.875-1.862v-9.31zm-9.375-26.069a2.803 2.803 0 0 0 2.813-2.793 2.803 2.803 0 0 0-2.813-2.793 2.803 2.803 0 0 0-2.813 2.793 2.803 2.803 0 0 0 2.813 2.793zm-7.5 0a2.803 2.803 0 0 0 2.813-2.793 2.803 2.803 0 0 0-2.813-2.793 2.803 2.803 0 0 0-2.813 2.793 2.803 2.803 0 0 0 2.813 2.793zm7.5 27.932a2.803 2.803 0 0 0-2.813 2.793 2.803 2.803 0 0 0 2.813 2.793 2.803 2.803 0 0 0 2.813-2.793 2.803 2.803 0 0 0-2.813-2.793zm-7.5 0a2.803 2.803 0 0 0-2.813 2.793 2.803 2.803 0 0 0 2.813 2.793 2.803 2.803 0 0 0 2.813-2.793 2.803 2.803 0 0 0-2.813-2.793z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgServer;