'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Chip = require('@material-ui/core/Chip');

var _Chip2 = _interopRequireDefault(_Chip);

var _Close = require('@eui/ds-icons/lib/Close');

var _Close2 = _interopRequireDefault(_Close);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            backgroundColor: theme.palette.grey[100],
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
            color: theme.palette.grey[500],
            fontSize: _tokens.fontSizeDefault
        },
        activatedRoot: {
            backgroundColor: theme.palette.ctaColor.tint,
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.ctaColor.base,
            color: theme.palette.ctaColor.base,
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.tint
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.ctaColor.tint
            }
        },
        label: {
            paddingLeft: _tokens.spacingSmall,
            paddingRight: _tokens.spacingSmall,
            paddingBottom: '0.125rem' // Push label up by 2px to perfectly center
        },
        labelWithIcon: {
            paddingRight: _tokens.spacingXSmall2
        },
        clickable: {
            WebkitTapHighlightColor: 'transparent', // Remove grey highlight
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.grey[100]
            },
            '&:focus': {
                backgroundColor: theme.palette.grey[100],
                boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + _tokens.colorBrandSecondary
            },
            '&:active': {
                backgroundColor: theme.palette.grey[300],
                boxShadow: 'none'
            },
            '&:active:focus': {
                boxShadow: 'none',
                backgroundColor: theme.palette.grey[300]
            },
            '&$activatedRoot': {
                backgroundColor: theme.palette.ctaColor.tint,
                border: _tokens.borderWidthThin + ' solid ' + theme.palette.ctaColor.base,
                color: theme.palette.ctaColor.base,
                '&:hover': {
                    backgroundColor: theme.palette.ctaColor.tint
                },
                '&:focus': {
                    backgroundColor: theme.palette.ctaColor.tint,
                    boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + _tokens.colorBrandSecondary
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: theme.palette.ctaColor.tint
                }
            }
        },
        deletable: {
            '&:focus': {
                backgroundColor: theme.palette.grey[100],
                boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + _tokens.colorBrandSecondary
            },
            '&$activatedRoot': {
                '&:focus': {
                    backgroundColor: theme.palette.ctaColor.tint,
                    boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + _tokens.colorBrandSecondary
                }
            }
        },
        deleteIcon: {
            boxSizing: 'initial', // required to fix stylistic differences between storybook and doc site
            height: _tokens.sizingSmall,
            width: _tokens.sizingSmall,
            color: theme.palette.grey[500],
            fill: theme.palette.grey[500],
            borderRadius: _tokens.borderRadiusCircle,
            padding: _tokens.spacingXxSmall,
            '&:hover': {
                backgroundColor: theme.palette.grey[300]
            },
            '&:active': {
                backgroundColor: theme.palette.grey[400]
            }
        }
    };
};

/**
 * Use `Chip` to represent a selected item or filter.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  ChipExamples
 *  ChipActions
 *  ChipGroup
 *  ListActivatedChips
 *  SelectedContentWithChips
 */
var Chip = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        activated = props.activated,
        rest = _objectWithoutProperties(props, ['classes', 'activated']);

    return _react2.default.createElement(_Chip2.default, Object.assign({
        classes: {
            root: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.activatedRoot, activated)),
            label: (0, _classnames2.default)(classes.label, _defineProperty({}, classes.labelWithIcon, props.onDelete)),
            clickable: classes.clickable,
            deletable: classes.deletable,
            deleteIcon: classes.deleteIcon
        },
        deleteIcon: _react2.default.createElement(_Close2.default, null)
    }, rest, {
        ref: ref
    }));
});

Chip.muiName = 'Chip';

Chip.propTypes = {
    /**
     * Set the chip to active, using the theme CTA color.
     */
    activated: _propTypes2.default.bool,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * If true, the chip will appear clickable, and will raise when pressed,
     * even if the onClick property is not defined. This can be used, for example,
     * along with the component property to indicate an anchor Chip is clickable.
     */
    clickable: _propTypes2.default.bool,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
    /**
     * The content of the `Chip` label.
     */
    label: _propTypes2.default.node,
    /**
     * Callback to fire when the `Chip` is clicked on or `space` or `enter` keys are pressed when the Chip has focus.
     */
    onClick: _propTypes2.default.func,
    /**
     * Callback function fired when the delete icon is clicked or `backspace` key is pressed when the `Chip` has focus.
     * If set, the delete icon will be shown.
     */
    onDelete: _propTypes2.default.func,
    /**
     * @ignore
     */
    onKeyDown: _propTypes2.default.func,
    /**
     * @ignore
     */
    tabIndex: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string])
};

Chip.displayName = 'Chip';

exports.default = (0, _styles2.default)(styles)(Chip);