'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _styles = require('@material-ui/core/styles');

var _lightTheme = require('./light/light-theme.js');

var _lightTheme2 = _interopRequireDefault(_lightTheme);

var _darkTheme = require('./dark/dark-theme.js');

var _darkTheme2 = _interopRequireDefault(_darkTheme);

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Creates an Ethos theme, based on one of our standard themes.
 *
 * @example <caption>Create a theme that overrides the large breakpoint value</caption>
 *
 * createEDSTheme('light', {
 *     breakpoints: {
 *         values: {
 *             lg: '1500',
 *         }
 *     }
 * })
 *
 * @since v0.0.13
 *
 * @param {theme} baseTheme    The theme to use as the basis of new new theme. Valid values: 'light', 'dark'.
 * @param {object} [overrides] Overrides to the base theme
 * @return {object}            The Ethos theme
 */
function createEDSTheme(baseTheme, overrides) {

    var theme = (0, _cloneDeep2.default)(_lightTheme2.default);
    var themeOptions = (0, _cloneDeep2.default)(_lightTheme.lightThemeOptions);

    // grab the baseline theme
    switch (baseTheme) {
        case 'light':
            break;
        case 'dark':
            theme = (0, _cloneDeep2.default)(_darkTheme2.default);
            themeOptions = (0, _cloneDeep2.default)(_darkTheme.darkThemeOptions);
            break;
        default:
            console.warn('createEDSTheme: Invalid base theme specified: ' + baseTheme + '. Defaulting to light theme.');
            break;

    }

    // apply any overrides
    if (overrides) {
        theme = (0, _styles.createMuiTheme)((0, _merge2.default)(themeOptions, overrides));
    }

    return theme;
}

exports.default = createEDSTheme;