import React from 'react';
import PropTypes from 'prop-types';
import ErrorTemplateBody from './ErrorTemplateBody';

var Unauthorized401Page = React.forwardRef(function (props, ref) {
    var edsContext = props.edsContext;

    var errorTitle = edsContext.formatMessage('component.ErrorPage.401');
    var errorMessage = edsContext.formatMessage('component.ErrorPage.401.message');
    var signInText = edsContext.formatMessage('component.ErrorPage.signInText');

    return React.createElement(ErrorTemplateBody, Object.assign({
        largeIllustrationName: '401.png',
        errorTitle: errorTitle,
        errorMessage: errorMessage,
        text: signInText,
        hasAction: true
    }, props, {
        ref: ref
    }));
});

Unauthorized401Page.propTypes = {
    edsContext: PropTypes.object
};

export default Unauthorized401Page;