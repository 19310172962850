import React from 'react';
import EdsTabs, { Tab as EdsTab } from '@hedtech/react-design-system/core/es/Tabs';
import Grid from '../Grid';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = ((theme) => createStyles({
    navTabsContainer: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    tabsStyle: {
        marginBottom: Tokens.spacingMedium
    }
}));
const NavTabs = (props) => {
    const { classes, className, id, options, valueSelected } = props;
    const onTabChange = (_event, value) => {
        window.location.assign(value);
    };
    let tabs;
    if (options && options.length > 0) {
        const tabItems = [];
        let urlSelected = '';
        options.forEach(item => {
            if (item.id === valueSelected) {
                urlSelected = item.url;
                tabItems.push((React.createElement(EdsTab, { key: `${id}_${item.id}`, label: item.text, value: item.url })));
            }
            else {
                tabItems.push((React.createElement(EdsTab, { key: `${id}_${item.id}`, label: item.text, value: item.url })));
            }
        });
        tabs = (React.createElement(Grid, { container: true, className: className },
            React.createElement(Grid, { item: true },
                React.createElement(EdsTabs, { className: classes.tabsStyle, id: id, value: urlSelected, onChange: onTabChange }, tabItems))));
    }
    return (React.createElement("div", { className: classes.navTabsContainer }, tabs));
};
export default withStyles(styles)(NavTabs);
