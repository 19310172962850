'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _capitalize = require('lodash/capitalize');

var _capitalize2 = _interopRequireDefault(_capitalize);

var _EDSContext = require('./../EDSContext/EDSContext');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _core = require('@material-ui/core');

var _MobileStepperCounter = require('./MobileStepperCounter');

var _MobileStepperCounter2 = _interopRequireDefault(_MobileStepperCounter);

var _constants = require('./internal/constants');

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            backgroundColor: theme.palette.grey[200],
            height: _tokens.sizingXxLarge1,
            padding: '0 ' + _tokens.spacingMedium
        },
        // StepCounter styles when position is bottom
        stepCounterBottom: {
            position: 'fixed',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            left: '50%',
            bottom: _tokens.spacingXSmall,
            zIndex: theme.zIndex.mobileStepper + 1 // MUI defined z-index + 1 to show on top of the MuiMobileStepper
        },
        // StepCounter styles when position is static
        stepCounterStatic: {
            position: 'relative',
            textAlign: 'center',
            top: 40
        }
    };
};

/**
 * Mobile step progress
 */
var MobileStepper = _react2.default.forwardRef(function (props, ref) {
    var _useState = (0, _react.useState)(''),
        _useState2 = _slicedToArray(_useState, 2),
        label = _useState2[0],
        setLabel = _useState2[1];

    var localRef = ref || (0, _react.useRef)();

    var classes = props.classes,
        activeStep = props.activeStep,
        steps = props.steps,
        StepCounterContent = props.StepCounterContent,
        position = props.position,
        children = props.children,
        edsContext = props.edsContext,
        rest = _objectWithoutProperties(props, ['classes', 'activeStep', 'steps', 'StepCounterContent', 'position', 'children', 'edsContext']);

    var classOverrides = {
        root: classes.root
    };

    var mobileStepperCounterClassName = (0, _classnames2.default)(classes['stepCounter' + (0, _capitalize2.default)(position)]);

    // This effect sets the div with current step content with error message if there is one.
    (0, _react.useEffect)(function () {
        var mobileStepLabel = localRef.current.querySelector('.' + _constants.MOBILE_STEPPER_LABEL_CLASSNAME);
        setLabel(props.activeStep + 1 > steps ? edsContext.formatMessage('component.MobileStepper.allStepsCompleted') : edsContext.formatMessage('component.MobileStepper.a11yLabel', {
            label: mobileStepLabel && mobileStepLabel.textContent
        }));
    }, [props.activeStep, steps, edsContext, localRef]);

    return _react2.default.createElement(
        'div',
        { ref: localRef },
        children,
        StepCounterContent ? _react2.default.createElement(
            'div',
            { className: mobileStepperCounterClassName },
            StepCounterContent
        ) : _react2.default.createElement(
            'div',
            { className: mobileStepperCounterClassName,
                'aria-atomic': 'false',
                'aria-live': 'assertive',
                'aria-label': label
            },
            _react2.default.createElement(_MobileStepperCounter2.default, { activeStep: activeStep, steps: steps, position: position })
        ),
        _react2.default.createElement(_core.MobileStepper, Object.assign({
            steps: steps,
            activeStep: activeStep,
            position: position,
            classes: classOverrides,
            variant: null
        }, rest))
    );
});

MobileStepper.muiName = 'MobileStepper';
MobileStepper.displayName = 'MobileStepper';

MobileStepper.propTypes = {
    /**
     * Set the active step (zero based index).
     */
    activeStep: _propTypes2.default.number,
    /**
     * A back button element. For instance, it can be be a `Button` or a `IconButton`.
     */
    backButton: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The children will be rendered before the `<MobileStepperCounter>` and the bottom control bar.
     */
    children: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object, _propTypes2.default.node]),
    /**
     * A next button element. For instance, it can be be a `Button` or a `IconButton`.
     */
    nextButton: _propTypes2.default.node,
    /**
     * Set the positioning type.
     */
    position: _propTypes2.default.oneOf(['bottom', 'static']),
    /**
     * The total steps.
     */
    steps: _propTypes2.default.number.isRequired,
    /**
     * Sent in a custom step counter.
     */
    StepCounterContent: _propTypes2.default.node
};

// default property values
MobileStepper.defaultProps = {
    activeStep: 0,
    position: 'bottom'
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(MobileStepper));