'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextField = require('@material-ui/core/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _omit = require('lodash/omit');

var _omit2 = _interopRequireDefault(_omit);

var _Close = require('@eui/ds-icons/lib/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Search = require('@eui/ds-icons/lib/Search');

var _Search2 = _interopRequireDefault(_Search);

var _InputAdornment = require('@material-ui/core/InputAdornment');

var _InputAdornment2 = _interopRequireDefault(_InputAdornment);

var _EDSContext = require('../EDSContext/EDSContext');

var _tokens = require('../styles/tokens');

var _v = require('uuid/v4');

var _v2 = _interopRequireDefault(_v);

var _isNumber = require('lodash/isNumber');

var _isNumber2 = _interopRequireDefault(_isNumber);

var _isObject = require('lodash/isObject');

var _isObject2 = _interopRequireDefault(_isObject);

var _isNil = require('lodash/isNil');

var _isNil2 = _interopRequireDefault(_isNil);

var _Tooltip = require('../Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _InputNumber = require('./internal/InputNumber');

var _InputNumber2 = _interopRequireDefault(_InputNumber);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// This object holds all of the props that are specific to our TextField and should not be included for the numeric TextField
var MUI_TEXTFIELD_PROPS = ['classes', 'ariaLabel', 'ariaLabelledBy', 'margin', 'maxCharacters', 'multiline', 'onAdornmentClick', 'onClearClick', 'onEnterPress', 'passwordToggle', 'rows', 'rowsMax', 'select', 'SelectProps', 'type'];

var adornmentStyles = {
    margin: 0,
    maxHeight: 'none',
    padding: _tokens.spacingSmall,
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '0 0.19rem 0.19rem 0',
    '&:focus': {
        color: _tokens.colorBorderGlobalFocus,
        boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + _tokens.colorBorderGlobalFocus,
        outline: 0,
        marginRight: '.1rem'
    }
};

var styles = function styles(theme) {
    var _inputSearch;

    return {
        root: {
            margin: 0
        },
        focused: {
            boxShadow: _tokens.boxShadowFocus
        },
        focusedError: {
            boxShadow: _tokens.boxShadowError,
            '-webkit-box-shadow': _tokens.boxShadowError,
            '-moz-box-shadow:': _tokens.boxShadowError
        },
        adornment: Object.assign({
            fill: theme.palette.grey[500],
            color: theme.palette.grey[500],

            cursor: 'pointer',
            height: 'auto'
        }, adornmentStyles),
        passwordAdornmentRoot: Object.assign({
            color: theme.palette.grey[500],
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.button.fontWeight,
            height: 'auto',
            textDecoration: 'none'
        }, adornmentStyles, {
            '&:hover': {
                color: theme.palette.ctaColor.hover,
                cursor: 'pointer'
            },
            '&:active': {
                color: theme.palette.ctaColor.active
            }
        }),
        adornmentDisabled: {
            fill: theme.palette.grey[400],
            color: theme.palette.grey[400],
            cursor: 'not-allowed',
            outline: 'none',
            '&:hover': {
                cursor: 'not-allowed',
                fill: theme.palette.grey[400],
                color: theme.palette.grey[400]
            },
            '&:active': {
                fill: theme.palette.grey[400],
                color: theme.palette.grey[400]
            }
        },
        adornmentButton: {
            '&:hover': {
                color: theme.palette.ctaColor.hover
            },
            '&:active': {
                color: theme.palette.ctaColor.active
            },
            '&:focus': {
                border: _tokens.borderWidthThick + ' solid ' + _tokens.colorBorderGlobalFocus,
                outline: 'none',
                top: '0.3125rem'
            },
            '&:focus:hover': {
                border: 'none',
                outline: 'none',
                top: '0.375rem'
            },
            '&:disabled': {
                color: theme.palette.ctaColor.disabled
            },
            '&:disabled:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.ctaColor.disabled
            }
        },
        inputRoot: {
            backgroundColor: theme.palette.grey[100],
            borderRadius: _tokens.borderRadiusMedium,
            height: 'auto',
            padding: 0,
            '&$disabled': {
                backgroundColor: theme.palette.grey[200],
                '&:hover': {
                    cursor: 'not-allowed'
                }
            },
            '& $input:disabled': {
                '&:hover': {
                    cursor: 'not-allowed'
                }
            }
        },
        rowsMaxRoot: {
            '& > div': {
                display: 'inline-flex'
            }
        },
        disabled: {},
        inputBorderDefault: {
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400]
        },
        inputBorderError: {
            border: _tokens.borderWidthThick + ' solid ' + theme.palette.status.error.fill
        },
        input: {
            color: theme.palette.grey[600],
            flex: 1,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            height: '1.25rem',
            lineHeight: _tokens.lineHeightHeader2Small,
            padding: _tokens.paddingTextField,
            width: 'auto',
            '&::placeholder': {
                color: theme.palette.grey[500],
                fontSize: theme.typography.body1.fontSize,
                opacity: '1'
            },
            '&::-ms-reveal': {
                display: 'none'
            },
            '&::-ms-input-placeholder': {
                color: theme.palette.grey[500],
                fontSize: theme.typography.body1.fontSize,
                opacity: '1'
            }
        },
        constrainedWidth: {
            width: _tokens.widthInputFields
        },
        inputMultilineRoot: {
            paddingTop: '1.375rem',
            paddingBottom: '.625rem'
        },
        inputMultiline: {
            height: 'auto',
            paddingRight: _tokens.spacingSmall,
            paddingLeft: _tokens.spacingSmall,
            paddingTop: 0
        },
        inputSearch: (_inputSearch = {
            padding: _tokens.spacingSmall
        }, _defineProperty(_inputSearch, '&[type="search"]::-webkit-search-decoration,\n        &[type="search"]::-webkit-search-cancel-button,\n        &[type="search"]::-webkit-search-results-button,\n        &[type="search"]::-webkit-search-results-decoration', {
            display: 'none'
        }), _defineProperty(_inputSearch, '&[type="search"]', {
            '-webkit-appearance': 'none'
        }), _defineProperty(_inputSearch, '&[type=search]::-ms-clear', {
            display: 'none',
            width: 0,
            height: 0
        }), _inputSearch),
        inputSearchWithLabel: {
            padding: _tokens.paddingTextField
        },
        inputLabel: {
            cursor: 'text',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeight,
            left: _tokens.spacingSmall,
            top: -6, // used to center label in middle of input
            zIndex: theme.zIndex.textFieldLabel,
            '&[data-shrink="true"]': {
                fontSize: theme.typography.body2.fontSize,
                lineHeight: 'normal',
                top: 6, // specific for inputs
                width: 'calc(100% - 1rem)', // needs to be assigned for ellipsis to work, 100% causes horizontal scrollbar to flash when opening
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        inputFormLabel: {
            color: theme.palette.status.error.text + ' !important'
        },
        svgSize: {
            height: _tokens.spacingSmall,
            width: _tokens.spacingSmall
        },
        small: {
            '& $inputRoot': {
                height: _tokens.heightInputFieldsSmall
            },
            '& $input, & $input:focus': {
                lineHeight: 'unset',
                padding: _tokens.spacingXSmall,
                fontSize: _tokens.fontSizeDefault
            },
            '& $inputLabel': {
                top: '-0.75rem',
                left: '0.625rem',
                fontSize: _tokens.fontSizeDefault
            },
            '& $inputLabel[data-shrink=true]': {
                display: 'none'
            }
        },
        helperTextContainer: {
            display: 'flex'
        },
        helperTextAlignment: {
            justifyContent: 'space-between'
        },
        counterTextAlignment: {
            justifyContent: 'flex-end'
        },
        charCounterContainer: {
            marginLeft: _tokens.spacingMedium,
            whiteSpace: 'nowrap'
        }
    };
};

/**
 * Use `TextField` to allow users to enter and edit data.
 * @done true
 * @updated false
 * @versionAdded v0.0.3
 * @examples
 *  Label
 *  LabelAndPlaceholder
 *  FullWidth
 *  Required
 *  Disabled
 *  Multiline
 *  MultilineLimit
 *  MultilineRequired
 *  Search
 *  Password
 *  Uncontrolled
 *  CharacterCounter
 *  Number
 */

var TextField = function (_Component) {
    _inherits(TextField, _Component);

    function TextField(props) {
        _classCallCheck(this, TextField);

        var _this = _possibleConstructorReturn(this, (TextField.__proto__ || Object.getPrototypeOf(TextField)).call(this, props));

        _this.cloneAdornment = function (element, classes) {
            var id = _this.props.id || '';
            var isButtonElement = element.props.component === 'button';
            return (0, _react.cloneElement)(element, {
                classes: {
                    root: (0, _classnames2.default)(classes.adornment, _defineProperty({}, classes.adornmentButton, isButtonElement))
                },
                id: id + '_Adornment',
                onClick: isButtonElement ? _this.handleAdornmentClick : null
            });
        };

        _this.handleAdornmentClick = function (event) {
            if (_this.props.onAdornmentClick) {
                // Prevents triggering onEnterPress event of TextField when user press enter key on search icon.
                event.preventDefault();
                _this.props.onAdornmentClick();
            }
        };

        _this.handleBlur = function (event) {
            if (_this.props.onBlur) {
                _this.props.onBlur(event);
            }
        };

        _this.handleChange = function (event) {
            if (event.target.value.length > 0) {
                _this.setState({
                    hasText: true,
                    count: event.target.value.length
                });
            } else {
                _this.setState({
                    hasText: false,
                    count: 0
                });
            }

            if (_this.props.onChange) {
                _this.props.onChange(event);
            }
        };

        _this.handleEnterPress = function (event) {
            var key = event.key;

            if (key === 'Enter') {
                if (_this.props.onEnterPress) {
                    _this.props.onEnterPress(event);
                }
            }
        };

        _this.handleFocus = function (event) {
            if (_this.props.onFocus) {
                _this.props.onFocus(event);
            }
        };

        _this.handleClearClick = function (event) {
            _this.setState({ hasText: false });
            if (_this.props.onClearClick) {
                _this.props.onClearClick(event);
            }
        };

        _this.handleSearchAdornment = function (event) {
            var onAdornmentClick = _this.props.onAdornmentClick;

            if (_this.state.hasText) {
                _this.handleClearClick(event);
            } else if (onAdornmentClick) {
                _this.handleAdornmentClick(event);
            }
        };

        _this.toggleVisibilityClick = function (e) {
            if (!_this.props.disabled) {
                _this.setState(function (prevState) {
                    return {
                        isPasswordVisible: !prevState.isPasswordVisible
                    };
                });
            }
        };

        _this.helperTextNode = function () {
            var _classNames3;

            var _this$props = _this.props,
                helperText = _this$props.helperText,
                maxCharacters = _this$props.maxCharacters,
                classes = _this$props.classes;


            var charCounterNode = void 0;

            var count = _this.state.count;

            // if we're dispalaying a character counter
            if ((0, _isObject2.default)(maxCharacters)) {

                // build the counter aria text
                var ariaLabel = _this.props.edsContext.formatMessage('component.TextField.characterCounter', {
                    count: count,
                    total: maxCharacters.max
                });

                var countErrorState = count > maxCharacters.max;

                // build the counter text node
                var nodeText = _react2.default.createElement(
                    'span',
                    {
                        'aria-label': ariaLabel,
                        className: (0, _classnames2.default)(classes.charCounterContainer, _defineProperty({}, classes.inputFormLabel, countErrorState)) },
                    count,
                    ' / ',
                    maxCharacters.max
                );

                // build the final counter node, optionally encasing it in a tooltip
                charCounterNode = maxCharacters.tooltipText ? _react2.default.createElement(
                    _Tooltip2.default,
                    {
                        title: maxCharacters.tooltipText,
                        placement: 'right' },
                    nodeText
                ) : nodeText;
            }

            return (helperText || charCounterNode) && _react2.default.createElement(
                'span',
                { className: (0, _classnames2.default)(classes.helperTextContainer, (_classNames3 = {}, _defineProperty(_classNames3, classes.helperTextAlignment, helperText), _defineProperty(_classNames3, classes.counterTextAlignment, !helperText), _classNames3)) },
                helperText && _react2.default.createElement(
                    'span',
                    null,
                    helperText
                ),
                charCounterNode !== undefined && charCounterNode
            );
        };

        _this.state = {
            isPasswordVisible: false,
            hasText: _this.props.value && _this.props.value.length > 0 || _this.props.defaultValue && _this.props.defaultValue.length > 0,
            count: _this.props.value && _this.props.value.length ? _this.props.value.length : 0
        };

        return _this;
    }

    _createClass(TextField, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {

            // if the value changed, determine whether to show the search/clear icon
            if (this.props.type === 'search' && prevProps.value !== this.props.value) {
                this.setState({
                    hasText: this.props.value && this.props.value.length > 0
                });
            }

            // if (1) this is a controlled component; (2) chracter counts are active; and (3) the value has changed,
            // update the character count
            if (!(0, _isNil2.default)(this.props.value) && (0, _isObject2.default)(this.props.maxCharacters) && prevProps.value !== this.props.value) {
                this.setState({
                    count: this.props.value ? this.props.value.length : 0
                });
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _classNames4,
                _this2 = this,
                _classNames7,
                _classNames8;

            var _props = this.props,
                ariaLabel = _props.ariaLabel,
                ariaLabelledBy = _props.ariaLabelledBy,
                classes = _props.classes,
                classNameProp = _props.className,
                disabled = _props.disabled,
                error = _props.error,
                edsContext = _props.edsContext,
                FormHelperTextPropsProp = _props.FormHelperTextProps,
                helperTextProp = _props.helperText,
                id = _props.id,
                InputLabelPropsProp = _props.InputLabelProps,
                InputPropsProp = _props.InputProps,
                inputPropsProp = _props.inputProps,
                label = _props.label,
                multiline = _props.multiline,
                maxCharacters = _props.maxCharacters,
                onAdornmentClick = _props.onAdornmentClick,
                onClearClick = _props.onClearClick,
                onEnterPress = _props.onEnterPress,
                onKeyDown = _props.onKeyDown,
                onKeyUp = _props.onKeyUp,
                passwordToggle = _props.passwordToggle,
                required = _props.required,
                type = _props.type,
                value = _props.value,
                size = _props.size,
                rest = _objectWithoutProperties(_props, ['ariaLabel', 'ariaLabelledBy', 'classes', 'className', 'disabled', 'error', 'edsContext', 'FormHelperTextProps', 'helperText', 'id', 'InputLabelProps', 'InputProps', 'inputProps', 'label', 'multiline', 'maxCharacters', 'onAdornmentClick', 'onClearClick', 'onEnterPress', 'onKeyDown', 'onKeyUp', 'passwordToggle', 'required', 'type', 'value', 'size']);

            var _state = this.state,
                isPasswordVisible = _state.isPasswordVisible,
                hasText = _state.hasText;

            var cleanedId = id || (0, _v2.default)();

            var numberAriaLabel = '';
            var numberAriaLabelledby = '';

            // HTML input element props
            var inputProps = Object.assign({}, inputPropsProp);

            // passing ARIA attributes through inputProps takes precedence over deprecated props
            if (inputProps && inputProps['aria-label']) {
                numberAriaLabel = inputProps['aria-label'];
            } else if (ariaLabel) {
                console.warn('DEPRECATED: The ariaLabel prop is deprecated. Use inputProps={{\'aria-label\': \'your label\'}} instead.');
                numberAriaLabel = ariaLabel;
            } else {
                numberAriaLabel = null;
            }

            if (inputProps && inputProps['aria-labelledby']) {
                numberAriaLabelledby = inputProps['aria-labelledby'];
            } else if (ariaLabelledBy) {
                console.warn('DEPRECATED: The ariaLabelledBy prop is deprecated. Use inputProps={{\'aria-labelledby\': \'your label\'s id\'}} instead.');
                numberAriaLabelledby = ariaLabelledBy;
            } else {
                numberAriaLabelledby = null;
            }

            // If type number, handle render in InputNumber
            if (type === 'number') {
                return _react2.default.createElement(_InputNumber2.default, Object.assign({}, (0, _omit2.default)(this.props, MUI_TEXTFIELD_PROPS), { // Send all props, except MUI TextField props
                    'aria-label': numberAriaLabel // Re-assign to correct React prop name
                    , 'aria-labelledby': numberAriaLabelledby // Re-assign to correct React prop name
                    , id: cleanedId // Assign id to new cleanedId
                }));
            }

            var rootClassName = (0, _classnames2.default)(classes.root, (_classNames4 = {}, _defineProperty(_classNames4, classes.small, size === 'small'), _defineProperty(_classNames4, classes.constrainedWidth, !this.props.fullWidth), _classNames4), classNameProp);

            // Search specific logic
            var searchAdornment = _react2.default.createElement(
                _InputAdornment2.default,
                {
                    component: 'button',
                    disabled: this.props.disabled,
                    className: (0, _classnames2.default)(_defineProperty({}, classes.adornmentDisabled, disabled)),
                    'aria-label': hasText ? edsContext.formatMessage('component.TextField.clear') : edsContext.formatMessage('component.TextField.search'),
                    classes: {
                        root: classes.adornment
                    },
                    id: id ? id + '_SearchIcon' : '',
                    onClick: function onClick(event) {
                        _this2.handleSearchAdornment(event);
                    },
                    tabIndex: '0'
                },
                !hasText || disabled ? _react2.default.createElement(
                    _Tooltip2.default,
                    {
                        disableFocusListener: true,
                        disableHoverListener: this.props.disabled,
                        title: edsContext.formatMessage('component.TextField.search') },
                    _react2.default.createElement(_Search2.default, { className: classes.svgSize })
                ) : _react2.default.createElement(
                    _Tooltip2.default,
                    {
                        disableFocusListener: true,
                        disableHoverListener: this.props.disabled,
                        title: edsContext.formatMessage('component.TextField.clear') },
                    _react2.default.createElement(_Close2.default, { className: classes.svgSize })
                )
            );

            // Password logic
            var passwordAdornment = _react2.default.createElement(
                _InputAdornment2.default,
                {
                    component: 'button',
                    disabled: this.props.disabled,
                    'aria-label': edsContext.formatMessage('component.TextField.toggleVisibility'),
                    classes: { root: classes.passwordAdornmentRoot },
                    className: (0, _classnames2.default)(_defineProperty({}, classes.adornmentDisabled, disabled)),
                    id: id ? id + '_PasswordToggle' : '',
                    onClick: function onClick(event) {
                        _this2.toggleVisibilityClick(event);
                    },
                    tabIndex: disabled ? '-1' : '0'
                },
                _react2.default.createElement(
                    'span',
                    null,
                    isPasswordVisible ? edsContext.formatMessage('component.TextField.hide') : edsContext.formatMessage('component.TextField.show')
                )
            );

            // MUI InputProps logic
            var InputProps = Object.assign({}, InputPropsProp);
            var endAdornment = InputProps.endAdornment;


            if (endAdornment) {
                InputProps.endAdornment = this.cloneAdornment(endAdornment, classes);
            }

            if (type === 'search') {
                InputProps.endAdornment = searchAdornment;
            }

            if (type === 'password' && passwordToggle) {
                InputProps.endAdornment = passwordAdornment;
            }

            InputProps.classes = {
                root: (0, _classnames2.default)(classes.inputRoot, (_classNames7 = {}, _defineProperty(_classNames7, classes.inputBorderDefault, !error), _defineProperty(_classNames7, classes.inputBorderError, error), _defineProperty(_classNames7, classes.inputMultilineRoot, multiline), _defineProperty(_classNames7, classes.rowsMaxRoot, multiline), _classNames7)),
                disabled: classes.disabled,
                focused: (0, _classnames2.default)((_classNames8 = {}, _defineProperty(_classNames8, classes.focused, !error), _defineProperty(_classNames8, classes.focusedError, error), _classNames8)),
                input: classes.input,
                inputMultiline: classes.inputMultiline,
                inputTypeSearch: (0, _classnames2.default)(classes.inputSearch, _defineProperty({}, classes.inputSearchWithLabel, !!label))
            };
            InputProps.disableUnderline = true;
            InputProps.onBlur = this.handleBlur;
            InputProps.onFocus = this.handleFocus;
            InputProps.onKeyDown = onKeyDown;
            InputProps.onKeyPress = this.handleEnterPress;
            InputProps.onKeyUp = onKeyUp;
            InputProps.onChange = this.handleChange;

            // if the caller asked for a max length, just set it
            if (maxCharacters && (0, _isNumber2.default)(maxCharacters)) {
                inputProps.maxLength = maxCharacters;
            }

            // if the caller asked for a character counter, only set the max length if overflow is disallowed
            else if (maxCharacters && (0, _isObject2.default)(maxCharacters) && !maxCharacters.allowOverflow) {
                    inputProps.maxLength = maxCharacters.max;
                }

            // Setting aria-label and aria-labelledby through inputProps is the correct
            // method and will take precedence if an aria-label is set both ways
            if (!inputProps['aria-label'] && ariaLabel) {
                inputProps['aria-label'] = ariaLabel;
            }
            if (!inputProps['aria-labelledby'] && ariaLabelledBy) {
                inputProps['aria-labelledby'] = ariaLabelledBy;
            }

            // MUI InputLabel props
            var InputLabelProps = Object.assign({}, InputLabelPropsProp);
            InputLabelProps.classes = {
                root: classes.inputLabel
            };
            InputLabelProps.disabled = disabled;

            // MUI FormHelper props
            var FormHelperTextProps = Object.assign({}, FormHelperTextPropsProp);
            FormHelperTextProps.classes = {
                error: classes.inputFormLabel
            };
            FormHelperTextProps.error = error;

            return _react2.default.createElement(_TextField2.default, Object.assign({
                className: rootClassName,
                disabled: disabled,
                error: error,
                FormHelperTextProps: FormHelperTextProps,
                helperText: this.helperTextNode(),
                id: cleanedId,
                InputProps: InputProps,
                InputLabelProps: InputLabelProps,
                inputProps: inputProps,
                label: label,
                margin: 'normal',
                multiline: multiline,
                onChange: this.handleChange,
                required: required,
                type: isPasswordVisible ? 'text' : type // use this to tell if you should hard render a 'text' or the props.type
                , value: value
            }, rest));
        }
    }]);

    return TextField;
}(_react.Component);

TextField.muiName = 'TextField';

TextField.propTypes = {
    /**
     * **DEPRECATED** Use inputProps={{'aria-label': 'your label'}} instead.
     *
     * A description of the current element.
     * Use in cases where a text label is not visible on the screen.
     * If there is a text label on the screen, use 'ariaLabelledBy' instead
     */
    ariaLabel: _propTypes2.default.string,
    /**
     * **DEPRECATED** Use inputProps={{'aria-labelledby': 'your label's id'}} instead.
     *
     * A description of the current element.
     * Use in cases where a text label is visible on the screen.
     * If there is no text label on the screen, use 'ariaLabel' instead
     */
    ariaLabelledBy: _propTypes2.default.string,
    /**
     * This property helps users to fill forms faster, especially on mobile devices.
     * The name can be confusing, as it's more like an autofill.
     * You can learn more about it here:
     * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
     */
    autoComplete: _propTypes2.default.string,
    /**
     * If `true`, the input will be focused during the first mount.
     */
    autoFocus: _propTypes2.default.bool,
    /**
     * @ignore
     */
    children: _propTypes2.default.node,
    /**
     * @ignore
     */
    classes: _propTypes2.default.object,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * The default value of the `Input` element.
     */
    defaultValue: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /**
     * If `true`, the input will be disabled.
     */
    disabled: _propTypes2.default.bool,
    /**
     * If `true`, the label will be displayed in an error state.
     */
    error: _propTypes2.default.bool,
    /**
     * Properties applied to the `FormHelperText` element.
     */
    FormHelperTextProps: _propTypes2.default.object,
    /**
     * If `true`, the input will take up the full width of its container.
     */
    fullWidth: _propTypes2.default.bool,
    /**
     * The helper text content.
     */
    helperText: _propTypes2.default.node,
    /**
     * The id of the `input` element.
     * Use that property to make `label` and `helperText` accessible for screen readers.
     */
    id: _propTypes2.default.string,
    /**
     * Properties applied to the `InputAdornment` element.
     */
    InputAdornment: _propTypes2.default.object,
    /**
     * Properties applied to the `InputLabel` element.
     */
    InputLabelProps: _propTypes2.default.object,
    /**
     * Properties applied to the `Input` element.
     */
    InputProps: _propTypes2.default.object,
    /**
     * Properties applied to the native `input` element.
     */
    inputProps: _propTypes2.default.object,
    /**
     * Use that property to pass a ref callback to the native input component.
     */
    inputRef: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.object]),
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,
    /**
     * The label content.
     */
    label: _propTypes2.default.node,
    /**
     * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
     */
    margin: _propTypes2.default.oneOf(['none', 'dense', 'normal']),
    /**
     * The maximum number of characters that can be entered into the TextField. There are two variants.
     *
     * With Counter: Textbox or Textarea can have counter at the bottom of the field.  allowOverflow: true will allow more that number of specified characters but counter text will be in red color.
     *
     * e.g.
     * - `{ max: number, allowOverflow: boolean, [tooltipText: string]  }`
     *
     * Without Counter: Will not display counter but number of character restriction will be applied.
     *
     * e.g.
     * - `{number}`
     *
     * **Does not apply to number fields (`type="number"`)**
     */
    maxCharacters: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.shape({
        max: _propTypes2.default.number.isRequired,
        allowOverflow: _propTypes2.default.bool
    })]),
    /**
     * If `true`, a textarea element will be rendered instead of an input.
     */
    multiline: _propTypes2.default.bool,
    /**
     * Name attribute of the `input` element.
     */
    name: _propTypes2.default.string,
    /**
     * Callback fired when an Adornment is clicked.
     * The value is passed into the provided function.
     */
    onAdornmentClick: _propTypes2.default.func,
    /**
     * @ignore
     */
    onBlur: _propTypes2.default.func,
    /**
     * Callback fired when the value is changed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value`.
     */
    onChange: _propTypes2.default.func,
    /**
     * Callback fired when the clear icon is clicked.
     * @param {object} event The event source of the callback.
     */
    onClearClick: _propTypes2.default.func,
    /**
     * Callback fired when the enter key is pressed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value`.
     */
    onEnterPress: _propTypes2.default.func,
    /**
     * Callback fired when the a key is pressed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the key by accessing `event.key`.
     */
    onKeyDown: _propTypes2.default.func,
    /**
     * Callback fired when the a key is pressed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the key by accessing `event.key`.
     */
    onKeyUp: _propTypes2.default.func,
    /**
     * @ignore
     */
    onFocus: _propTypes2.default.func,
    /**
     * Set to true to render the Show/Hide text for password TextFields. This will only affect TextField components with the type="password".
     */
    passwordToggle: _propTypes2.default.bool,
    /**
     * The short hint displayed in the input before the user enters a value.
     */
    placeholder: _propTypes2.default.string,
    /**
     * If `true`, the label is displayed as required.
     */
    required: _propTypes2.default.bool,
    /**
     * Number of rows to display when multiline option is set to true.
     */
    rows: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /**
     * Maximum number of rows to display when multiline option is set to true.
     */
    rowsMax: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /**
     * Render a `Select` element while passing the `Input` element to `Select` as `input` parameter.
     * If this option is set you must pass the options of the select as children.
     */
    select: _propTypes2.default.bool,
    /**
     * Properties applied to the `Select` element.
     */
    SelectProps: _propTypes2.default.object,
    /**
     * Type attribute of the `Input` element. It should be a valid HTML5 input type.
     */
    type: _propTypes2.default.string,
    /**
     * The value of the `Input` element, required for a controlled component.
     */
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.arrayOf(_propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]))]),
    /**
     * The size of the `TextField`. Small size is recommended to be used within the `TableEditableCell` component.
     */
    size: _propTypes2.default.oneOf(['small', 'medium']),

    // InputNumber specific
    /**
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the valid number intervals
     */
    step: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /**
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the minimum value. If a value less than the minimum is entered, the field will reset to the
     * minimum value.
     */
    min: _propTypes2.default.number,
    /**
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the maximum value. If a value larger than the maximum is entered, the field will reset to the
     * maximum value.
     */
    max: _propTypes2.default.number,
    /**
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the precision length of value e.g. `precision={2} => 0.00`
     */
    precision: _propTypes2.default.number,
    /**
     * @ignore
     * **Only valid for number fields (`type="number"`)**
     *
     * Whether focus input when click up or down button
     */
    focusOnUpDown: _propTypes2.default.bool,
    /**
     * @ignore
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the format of the value presented
     * `(@param: value: number|string, @param displayValue: string)`
     */
    formatter: _propTypes2.default.func,
    /**
     * @ignore
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies a regex pattern to be added to the input number element - useful for forcing iOS to open the number pad instead of the normal keyboard (supply a regex of "\d*" to do this) or form validation
     */
    pattern: _propTypes2.default.string,
    /**
     * @ignore
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the decimal separator
     */
    decimalSeparator: _propTypes2.default.string
};

TextField.displayName = 'TextField';

TextField.defaultProps = {
    required: false,
    type: 'text',
    rows: 4,
    size: 'medium',
    fullWidth: false,
    step: 1
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(TextField));