var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import React from 'react';
import { ButtonGroup as EdsButtonGroup } from '@hedtech/react-design-system/core/es/Button';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = (theme) => createStyles({
    root: {
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        },
        '& button': {
            [theme.breakpoints.only('xs')]: {
                marginLeft: 0,
                marginRight: 0
            }
        },
        width: 'auto'
    },
    toggleGroup: {
        '& > button:first-child': {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0
        },
        '& > button:last-child': {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0
        },
        '& > button:not(:first-child):not(:last-child)': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        },
        '& button': {
            marginLeft: 0,
            marginRight: 0
        },
        display: 'flex',
        flexWrap: 'nowrap'
    }
});
const ButtonGroup = (props) => {
    const { children, classes, toggle } = props, rest = __rest(props, ["children", "classes", "toggle"]);
    const classOverrides = omit(classes, ['toggleGroup']);
    if (toggle) {
        classOverrides.root = classnames(classes.root, classes.toggleGroup);
    }
    return (React.createElement(EdsButtonGroup, Object.assign({ classes: classOverrides }, rest), children));
};
export default withStyles(styles)(ButtonGroup);
