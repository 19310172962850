'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.INLINE_VARIANT = exports.PAGE_VARIANT = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _EDSContext = require('../EDSContext/EDSContext');

var _Snackbar = require('@material-ui/core/Snackbar');

var _Snackbar2 = _interopRequireDefault(_Snackbar);

var _IconButton = require('@material-ui/core/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _CheckFeedback = require('@eui/ds-icons/lib/CheckFeedback');

var _CheckFeedback2 = _interopRequireDefault(_CheckFeedback);

var _Warning = require('@eui/ds-icons/lib/Warning');

var _Warning2 = _interopRequireDefault(_Warning);

var _Error = require('@eui/ds-icons/lib/Error');

var _Error2 = _interopRequireDefault(_Error);

var _Info = require('@eui/ds-icons/lib/Info');

var _Info2 = _interopRequireDefault(_Info);

var _Close = require('@eui/ds-icons/lib/Close');

var _Close2 = _interopRequireDefault(_Close);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Icon type object
var alertTypeIcon = {
    success: _CheckFeedback2.default,
    warning: _Warning2.default,
    error: _Error2.default,
    info: _Info2.default
};

var styles = function styles(theme) {
    var _pageAlertSnackbarMes, _pageAlertMessageText;

    return {
        inlineTop: {
            left: 'auto',
            position: 'relative',
            right: 'auto',
            top: 'auto',
            transform: 'none',
            zIndex: theme.zIndex.inlineAlert,
            width: _tokens.widthFluid
        },
        inlineAlert: {
            minHeight: '4.5rem',
            display: 'flex',
            boxShadow: _tokens.boxShadowReset,
            maxWidth: 'none'
        },
        pageTop: {
            top: _tokens.sizingXxLarge1, // Push the page alerts down the height of the header bar
            width: _tokens.widthFluid,
            zIndex: theme.zIndex.pageAlert
        },
        pageAlert: {
            minHeight: '5.5rem',
            display: 'flex',
            boxShadow: _tokens.boxShadowReset,
            maxWidth: 'none'
        },
        contentRoot: {
            fontSize: theme.typography.body1.fontSize,
            boxShadow: _tokens.boxShadowReset,
            maxWidth: 'none',
            width: _tokens.widthFluid,
            minHeight: '5.5rem',
            padding: 0,
            borderRadius: '0'
        },
        inlineAlertSnackbarMessage: {
            padding: _tokens.spacingMedium + ' ' + _tokens.spacingSmall + ' ' + _tokens.spacingMedium + ' ' + _tokens.spacingLarge,
            width: _tokens.widthFluid
        },
        pageAlertSnackbarMessage: (_pageAlertSnackbarMes = {
            padding: _tokens.spacingLarge + ' ' + _tokens.spacingMedium,
            width: _tokens.widthFluid
        }, _defineProperty(_pageAlertSnackbarMes, '@media ' + _tokens.mqSmall, {
            padding: _tokens.spacingLarge + ' 5.25rem'
        }), _defineProperty(_pageAlertSnackbarMes, '@media ' + _tokens.mqMedium, {
            padding: _tokens.spacingLarge + ' 10rem'
        }), _defineProperty(_pageAlertSnackbarMes, '@media ' + _tokens.mqLarge, {
            padding: _tokens.spacingLarge + ' 5rem'
        }), _pageAlertSnackbarMes),
        // Background and text colors for alert variants
        success: {
            backgroundColor: theme.palette.status.success.background,
            color: theme.palette.status.success.text,
            fill: theme.palette.status.success.text
        },
        error: {
            backgroundColor: theme.palette.status.error.background,
            color: theme.palette.status.error.text,
            fill: theme.palette.status.error.text
        },
        info: {
            backgroundColor: theme.palette.status.neutral.background,
            color: theme.palette.status.neutral.text,
            fill: theme.palette.status.neutral.text
        },
        warning: {
            backgroundColor: theme.palette.status.warning.background,
            color: theme.palette.status.warning.text,
            fill: theme.palette.status.warning.text
        },
        // Border colors for inline alert variants
        successBorder: {
            borderTop: _tokens.borderWidthThick + ' solid ' + theme.palette.status.success.fill
        },
        errorBorder: {
            borderTop: _tokens.borderWidthThick + ' solid ' + theme.palette.status.error.fill
        },
        infoBorder: {
            borderTop: _tokens.borderWidthThick + ' solid ' + theme.palette.status.neutral.fill
        },
        warningBorder: {
            borderTop: _tokens.borderWidthThick + ' solid ' + theme.palette.status.warning.fill
        },
        // Page Alert Icon
        alertIcon: {
            marginRight: _tokens.spacingSmall
        },
        // Parent container for message prop in Snackbar
        message: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        // Target the text of the page alert
        inlineAlertMessageText: _defineProperty({
            flexGrow: 1,
            marginRight: _tokens.spacingLarge
        }, theme.breakpoints.down('sm'), {
            marginRight: 4
        }),
        pageAlertMessageText: (_pageAlertMessageText = {
            flexGrow: 1,
            marginRight: _tokens.spacingMedium
        }, _defineProperty(_pageAlertMessageText, '@media ' + _tokens.mqSmall, {
            marginRight: _tokens.spacingSmall
        }), _defineProperty(_pageAlertMessageText, '@media ' + _tokens.mqMedium, {
            marginRight: _tokens.spacingXLarge
        }), _defineProperty(_pageAlertMessageText, '@media ' + _tokens.mqLarge, {
            marginRight: _tokens.spacingLarge
        }), _pageAlertMessageText),
        // Close Icon
        closeIcon: {
            alignSelf: 'flex-start',
            height: 'auto',
            width: 'auto',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '&:focus': {
                outline: _tokens.borderWidthThin + ' solid'
            }
        },
        iconSvg: {
            width: _tokens.sizingMedium,
            height: _tokens.sizingMedium,
            verticalAlign: 'bottom'
        }
    };
};

// valid variant prop values
var PAGE_VARIANT = exports.PAGE_VARIANT = 'page';
var INLINE_VARIANT = exports.INLINE_VARIANT = 'inline';

/**
 * Use `Alert` to provide feedback on an operation through a message.
 * @done true
 * @updated false
 * @versionAdded v0.0.9
 * @examples
 *  AlertInlineExample
 *  AlertPageExample
 *  AlertTypesInlineExample
 *  AlertTypesPageExample
 *  AlertTimeout
 */
var Alert = _react2.default.forwardRef(function (props, ref) {
    var _classNames, _classNames3;

    var alertType = props.alertType,
        classes = props.classes,
        userDismissable = props.userDismissable,
        ContentPropsProp = props.ContentProps,
        id = props.id,
        onClose = props.onClose,
        text = props.text,
        variant = props.variant,
        edsContext = props.edsContext,
        rest = _objectWithoutProperties(props, ['alertType', 'classes', 'userDismissable', 'ContentProps', 'id', 'onClose', 'text', 'variant', 'edsContext']);

    var handleClose = function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        onClose(event);
    };

    // Assign the Icon to render according to the type sent via props.
    var AlertIcon = alertTypeIcon[alertType];

    // Determine alert variant before assigning classes
    var variantClasses = void 0;

    switch (variant) {
        case INLINE_VARIANT:
            variantClasses = classes.inlineTop;
            break;

        case PAGE_VARIANT:
            variantClasses = classes.pageTop;
            break;

        default:
            variantClasses = classes.inlineTop;
            break;
    }

    var snackbarClassOverrides = {
        anchorOriginTopCenter: variantClasses
    };

    /**
     * ContentProps will be passed to the SnackbarContent component that MUI internally calls when rendering the Snackbar component
     * We are sending in the stylistic changes and an aria tag for improved screen reader experience.
     */
    var ContentProps = Object.assign({}, ContentPropsProp);
    ContentProps.classes = {
        message: (0, _classnames2.default)((_classNames = {}, _defineProperty(_classNames, classes.inlineAlertSnackbarMessage, variant === INLINE_VARIANT), _defineProperty(_classNames, classes.pageAlertSnackbarMessage, variant === PAGE_VARIANT), _classNames)),
        root: (0, _classnames2.default)(classes.contentRoot, classes[alertType], _defineProperty({}, classes[alertType + 'Border'], variant === INLINE_VARIANT)),
        action: classes.close
    };
    ContentProps['aria-describedby'] = id;
    ContentProps['className'] = (0, _classnames2.default)(classes.alert);

    // warn about invalid prop values
    (0, _react.useEffect)(function () {

        if (!props.userDismissable && props.variant === PAGE_VARIANT) {
            console.warn('Page-level alerts must be user-dismissable. Ignoring userDismissable prop.');
        }

        if (!props.userDismissable && props.onClose) {
            console.warn('The onClose callback is never invoked for non-user-dismissable alerts. Ignoring onClose prop.');
        }
    }, [props.userDismissable, props.variant, props.onClose]);

    return _react2.default.createElement(_Snackbar2.default, Object.assign({}, rest, {
        ref: ref,
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
        },
        classes: snackbarClassOverrides,
        ContentProps: ContentProps,
        key: id + '_' + alertType + '_' + text,
        message: _react2.default.createElement(
            'div',
            { id: id, className: classes.message },
            _react2.default.createElement(
                'div',
                { className: classes.alertIcon },
                _react2.default.createElement(AlertIcon, { className: classes.iconSvg })
            ),
            _react2.default.createElement(
                'div',
                {
                    className: (0, _classnames2.default)((_classNames3 = {}, _defineProperty(_classNames3, classes.inlineAlertMessageText, variant === INLINE_VARIANT), _defineProperty(_classNames3, classes.pageAlertMessageText, variant === PAGE_VARIANT), _classNames3))
                },
                text
            ),
            (userDismissable || variant === PAGE_VARIANT) && _react2.default.createElement(
                _IconButton2.default,
                {
                    'aria-label': edsContext.formatMessage('component.Alert.close'),
                    title: edsContext.formatMessage('component.Alert.close'),
                    className: classes.closeIcon,
                    color: 'inherit',
                    disableRipple: true,
                    onClick: handleClose
                },
                _react2.default.createElement(_Close2.default, { className: classes.iconSvg })
            )
        )

        // only invoke onClose handler for user-dismissable alerts
        , onClose: userDismissable ? handleClose : null
    }));
});

Alert.muiName = 'Alert';

Alert.propTypes = {
    /**
     * Style type of the Alert to be displayed.
     */
    alertType: _propTypes2.default.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    /**
     * The number of milliseconds to wait before automatically calling the
     * `onClose` function. `onClose` should then set the state of the `open`
     * prop to hide the Alert. This behavior is disabled by default with
     * the `null` value.
     */
    autoHideDuration: _propTypes2.default.number,
    /**
     * If you wish the take control over the children of the component you can use this property.
     * When used, you replace the `SnackbarContent` component with the children.
     */
    children: _propTypes2.default.element,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * Class name to be applied to the Alert container.
     */
    className: _propTypes2.default.string,
    /**
     * Properties applied to the `SnackbarContent` element.
     */
    ContentProps: _propTypes2.default.object,
    /**
     * If `true`, the `autoHideDuration` timer will expire even if the window is not focused.
     */
    disableWindowBlurListener: _propTypes2.default.bool,
    /**
     * id of the text on the Alert. aria-describedby="message-id" is set automatically to link to this id.
     */
    id: _propTypes2.default.string,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,
    /**
     * When displaying multiple consecutive `Snackbars` from a parent rendering a single
     * `Snackbar`, add the `key` property to ensure independent treatment of each message.
     * e.g. `<Snackbar key={message} />`, otherwise, the message may update-in-place and
     * features such as `autoHideDuration` may be canceled.
     */
    key: _propTypes2.default.any,
    /**
     * Callback fired when the component requests to be closed.
     * Typically `onClose` is used to set state in the parent component,
     * which is used to control the `Alert` `open` prop.
     *
     * The `reason` parameter can optionally be used to control the response to `onClose`.
     *
     * This prop is only pertinent for user-dismissable alerts.
     *
     * @param {object} event The event source of the callback
     * @param {string} reason Can be:`"timeout"` (`autoHideDuration` expired)
     */
    onClose: _propTypes2.default.func,
    /**
     * Callback fired before the transition is entering.
     */
    onEnter: _propTypes2.default.func,
    /**
     * Callback fired when the transition has entered.
     */
    onEntered: _propTypes2.default.func,
    /**
     * Callback fired when the transition is entering.
     */
    onEntering: _propTypes2.default.func,
    /**
     * Callback fired before the transition is exiting.
     */
    onExit: _propTypes2.default.func,
    /**
     * Callback fired when the transition has exited.
     */
    onExited: _propTypes2.default.func,
    /**
     * Callback fired when the transition is exiting.
     */
    onExiting: _propTypes2.default.func,
    /**
     * Callback fired when the mouse enters the component.
     */
    onMouseEnter: _propTypes2.default.func,
    /**
     * Callback fired when the mouse leaves the component.
     */
    onMouseLeave: _propTypes2.default.func,
    /**
     * If true, `Alert` is open.
     */
    open: _propTypes2.default.bool,
    /**
     * The number of milliseconds to wait before dismissing after user interaction.
     * If `autoHideDuration` property isn't specified, it does nothing.
     * If `autoHideDuration` property is specified but `resumeHideDuration` isn't,
     * we default to `autoHideDuration / 2` ms.
     */
    resumeHideDuration: _propTypes2.default.number,
    /**
     * Text to be displayed on the Alert
     */
    text: _propTypes2.default.string,
    /**
     * Transition component.
     */
    TransitionComponent: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     */
    transitionDuration: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.shape({ enter: _propTypes2.default.number, exit: _propTypes2.default.number })]),
    /**
     * Properties applied to the `Transition` element.
     */
    TransitionProps: _propTypes2.default.object,
    /**
     * Whether the user can dismiss the Alert. If this is `false`, the close icon
     * will not appear in the alert, and the `onClose` handler will never be invoked.
     *
     * **Note**: Page alerts are _always_ user-dismissable. This prop is ignored for the `page` variant.
     */
    userDismissable: _propTypes2.default.bool,
    /**
     * Style variant of the Alert to be displayed.
     */
    variant: _propTypes2.default.oneOf(['inline', 'page']).isRequired
};

Alert.displayName = 'Alert';

Alert.defaultProps = {
    alertType: 'info',
    autoHideDuration: null,
    variant: INLINE_VARIANT,
    userDismissable: true
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(Alert));