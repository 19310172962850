"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgChartBars = function SvgChartBars(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", {
                d: "M44 18V8a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v35a1 1 0 0 1-1 1H45a.995.995 0 0 1-.5-.134.995.995 0 0 1-.5.134H34a.995.995 0 0 1-.5-.134.995.995 0 0 1-.5.134H23a.995.995 0 0 1-.5-.134.995.995 0 0 1-.5.134H12a1 1 0 0 1-1-1V27a1 1 0 0 1 1-1h10V12a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6h10z",
                fill: "currentColor"
            }),
            _react2.default.createElement("path", {
                d: "M44 18V7h12v37H11V26h11V11h12v7h10z",
                fill: "#FFF",
                opacity: 0.88
            }),
            _react2.default.createElement("path", {
                d: "M58.594 48.25A1.41 1.41 0 0 1 60 49.656v.938A1.41 1.41 0 0 1 58.594 52H1.406A1.41 1.41 0 0 1 0 50.594V8.406A1.41 1.41 0 0 1 1.406 7h.938A1.41 1.41 0 0 1 3.75 8.406V48.25h54.844zM18.75 40.047v-9.844a.705.705 0 0 0-.703-.703h-2.344a.705.705 0 0 0-.703.703v9.844c0 .387.316.703.703.703h2.344a.705.705 0 0 0 .703-.703zm33.75 0V11.453a.705.705 0 0 0-.703-.703h-2.344a.705.705 0 0 0-.703.703v28.594c0 .387.316.703.703.703h2.344a.705.705 0 0 0 .703-.703zm-11.25 0V22.703a.705.705 0 0 0-.703-.703h-2.344a.705.705 0 0 0-.703.703v17.344c0 .387.316.703.703.703h2.344a.705.705 0 0 0 .703-.703zm-11.25 0V15.203a.705.705 0 0 0-.703-.703h-2.344a.705.705 0 0 0-.703.703v24.844c0 .387.316.703.703.703h2.344a.705.705 0 0 0 .703-.703z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgChartBars;