import { createMuiTheme } from '@material-ui/core/styles';

// This is a copy of lightTheme -- needs to be changed
var darkThemeOptions = {
    props: {
        MuiButtonBase: {
            disableRipple: true // Disable ripple effect on components
        }
    },
    palette: {
        primary: {
            light: '#8a80ff',
            main: '#5353d1',
            dark: '#012a9f',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#5b97fc',
            main: '#026ab8',
            dark: '#004097',
            contrastText: '#ffffff'
        }
    },
    typography: {
        fontFamily: "'Open Sans', Helvetica, Arial, sans-serif"
    }
};

var darkTheme = createMuiTheme(darkThemeOptions);

export { darkTheme as default, darkThemeOptions };