import React from 'react';
import Footer from '../../core/Footer';
import Grid from '../../core/Grid';
import PageHeader from '../../core/PageHeader';
import Constants from '../../../helpers/Constants';
import Format from '../../../helpers/Format';
import Tokens from '../../core/styles/Tokens';
import { createStyles, withStyles } from '../../core/styles/withStyles';
import LayoutStore from '../../../flux/stores/LayoutStore';
const minWidthSS = '320px!important';
const styles = ((theme) => createStyles({
    '@global': {
        '#root > header': {
            '& > div > div:nth-child(3) > div > div > div > div > div > a': {
                '& > div': {
                    '& > div': {
                        [theme.breakpoints.up('md')]: {
                            maxWidth: '136px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }
                    },
                    [theme.breakpoints.up('md')]: {
                        overflow: 'hidden',
                        padding: '0rem!important'
                    }
                }
            },
            '& > div > div:nth-child(4)': {
                minWidth: minWidthSS
            },
            minWidth: minWidthSS,
            right: 'auto!important'
        },
        '.rbc-event-label': {
            display: 'none'
        },
        '.ss-app': {
            display: 'flex',
            flexDirection: 'column',
            height: 'inherit',
            minWidth: minWidthSS,
            overflowX: 'hidden',
            overflowY: 'hidden',
            WebkitOverflowScrolling: 'touch'
        },
        '.static-element': {
            position: 'sticky',
            top: '1px'
        },
        '@keyframes blinker': {
            '0%, 50%, 100%': { opacity: 0 },
            '25%, 75%': { opacity: 1 }
        },
        '@keyframes slidein': {
            from: {
                opacity: 0
            },
            to: {
                opacity: 1
            }
        }
    },
    containerContentPage: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: Tokens.sizingXxLarge,
            paddingRight: Tokens.sizingXxLarge
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: Tokens.spacingMedium,
            paddingRight: Tokens.spacingMedium
        },
        paddingLeft: '130px',
        paddingRight: '130px'
    },
    contentPage: {
        height: 'inherit',
        marginBottom: Tokens.spacingXLarge
    },
    main: {
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        overflowX: 'hidden',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch'
    },
    topMain: {
        minHeight: '4rem'
    }
}));
const ContentPage = (props) => {
    const { backgroundImageUrl, buildVersion, buildYear, classes, contentPage, headerTitle, navTabs, withBackgroundImage, withFooter } = props;
    let styleMain;
    if (withBackgroundImage) {
        const backgroundDefaultUrl = `${Constants.imagesCDN}/bg/PowerCampus-Background.png`;
        const gradient = 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.65) 100%), ';
        styleMain = {
            backgroundImage: `${backgroundImageUrl ? gradient : ''}url(${backgroundImageUrl ? backgroundImageUrl : backgroundDefaultUrl})`
        };
    }
    const layoutResources = LayoutStore.getResourcesLayout();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.topMain }),
        React.createElement("main", { id: "mainContent", className: classes.main, style: styleMain },
            React.createElement(Grid, { container: true, className: classes.containerContentPage, spacing: 0 },
                React.createElement(Grid, { item: true, className: classes.contentPage, id: "contentPage", xs: 12 },
                    headerTitle ? (React.createElement(PageHeader, { id: "lblPageHeader", text: headerTitle, white: withBackgroundImage && Boolean(backgroundImageUrl) })) : undefined,
                    navTabs,
                    React.createElement(Grid, { container: true, spacing: 0 },
                        React.createElement(Grid, { item: true, xs: true }, contentPage)))),
            withFooter
                && layoutResources
                && layoutResources.footer
                && buildVersion
                && buildYear ? (React.createElement(Footer, { id: "footerContent", text: Format.toString(layoutResources.footer.formatText, [buildVersion, buildYear]) })) : undefined)));
};
export default withStyles(styles)(ContentPage);
