function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiSnackbar } from '@material-ui/core/Snackbar';
import useWidth from './../useWidth';
import { Button } from '../';
import CheckFeedbackIcon from '@eui/ds-icons/lib/CheckFeedback';
import WarningIcon from '@eui/ds-icons/lib/Warning';
import ErrorIcon from '@eui/ds-icons/lib/Error';
import InfoIcon from '@eui/ds-icons/lib/Info';
import { withEDSContext } from '../EDSContext/EDSContext';

import { sizingXxLarge1, zIndexSnackbar, spacingXSmall, spacingSmall, spacingLarge, borderRadiusMedium, colorTextNeutral100, colorBrandNeutral500, fontSizeLarge, sizingMedium, widthFluid } from '../styles/tokens';

var commonStyles = {
    top: sizingXxLarge1,
    margin: spacingLarge
};

var commonMobileStyles = {
    margin: spacingSmall
};

var styles = function styles(theme) {
    return {
        root: {
            zIndex: zIndexSnackbar
        },
        anchorOriginTopRight: Object.assign({}, commonStyles, _defineProperty({
            right: 0
        }, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles))),
        anchorOriginTopCenter: Object.assign({}, commonStyles, _defineProperty({}, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles))),
        anchorOriginTopLeft: Object.assign({}, commonStyles, _defineProperty({}, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles))),
        anchorOriginBottomCenter: _defineProperty({
            margin: spacingLarge
        }, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles)),
        anchorOriginBottomLeft: _defineProperty({}, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles)),
        anchorOriginBottomRight: _defineProperty({}, theme.breakpoints.between('xs', 'sm'), Object.assign({}, commonMobileStyles)),
        contentPropsStyles: _defineProperty({
            minWidth: '21.5rem',
            backgroundColor: colorBrandNeutral500,
            color: colorTextNeutral100,
            fontSize: fontSizeLarge
        }, theme.breakpoints.between('xs', 'sm'), {
            borderRadius: borderRadiusMedium
        }),
        contentMessage: {
            marginRight: spacingLarge,
            flex: 1
        },
        message: {
            width: widthFluid
        },
        snackbarContent: {
            display: 'flex',
            alignItems: 'center'
        },
        undoAction: {
            '& a, a:hover': {
                color: colorTextNeutral100,
                textDecoration: 'underline'
            }
        },
        customAction: {
            marginRight: spacingXSmall
        },
        icon: {
            marginRight: spacingXSmall,
            height: sizingMedium,
            width: sizingMedium,
            fill: colorTextNeutral100
        }
    };
};

var snackbarVariant = {
    success: CheckFeedbackIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

var CustomMessage = React.forwardRef(function (props, ref) {
    var variant = props.variant,
        message = props.message,
        onUndoClick = props.onUndoClick,
        action = props.action,
        classes = props.classes,
        edsContext = props.edsContext;

    var SnackbarIcon = function SnackbarIcon() {
        return null;
    };
    var undoAction = null;

    if (variant) {
        SnackbarIcon = snackbarVariant[variant];
    }

    if (onUndoClick) {
        undoAction = React.createElement(
            Button,
            {
                variant: 'text',
                withDarkBackground: true,
                onClick: onUndoClick
            },
            edsContext.formatMessage('component.Snackbar.undo')
        );
    }

    return React.createElement(
        'div',
        { className: classes.snackbarContent },
        React.createElement(SnackbarIcon, { className: classes.icon, ref: ref }),
        React.createElement(
            'span',
            { className: classes.contentMessage },
            message
        ),
        action && React.createElement(
            'span',
            { className: classes.customAction },
            action
        ),
        undoAction && React.createElement(
            'span',
            { className: classes.undoAction },
            undoAction
        )
    );
});

CustomMessage.propTypes = {
    /**
     * The action to display.
     */
    action: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * The message to display.
     */
    message: PropTypes.node,
    /**
     * Variants of the Snackbar. Choose from these ['success', 'error', 'warning', 'info'].
     */
    variant: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
    /**
     * Callback fired when the undo action is clicked.
     * If this callback is present Snackbar will add Undo button automatically
     */
    onUndoClick: PropTypes.func,
    /**
     * @ignore
     */
    edsContext: PropTypes.object
};

var DEFAULT_DURATION = 5000;
var ACTIONABLE_DEFAULT_DURATION = 7000;

/**
 * Use `Snackbar` to deliver low-urgency communication to the user.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  SnackbarExamples
 */
var Snackbar = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        ContentProps = props.ContentProps,
        action = props.action,
        onUndoClick = props.onUndoClick,
        anchorOrigin = props.anchorOrigin,
        bottom = props.bottom,
        edsContext = props.edsContext,
        autoHideDuration = props.autoHideDuration,
        rest = _objectWithoutProperties(props, ['classes', 'ContentProps', 'action', 'onUndoClick', 'anchorOrigin', 'bottom', 'edsContext', 'autoHideDuration']);

    var width = useWidth();
    var shouldAddMobileCustomStyles = function shouldAddMobileCustomStyles() {
        return (width === 'xs' || width === 'sm') && !anchorOrigin;
    };
    var getNewAutoHideDuration = function getNewAutoHideDuration() {
        var defatulDuration = onUndoClick || action ? ACTIONABLE_DEFAULT_DURATION : DEFAULT_DURATION;

        return autoHideDuration || defatulDuration;
    };

    var classOverrides = {
        root: classes.root,
        anchorOriginTopRight: classes.anchorOriginTopRight,
        anchorOriginTopCenter: classes.anchorOriginTopCenter,
        anchorOriginTopLeft: classes.anchorOriginTopLeft,
        anchorOriginBottomCenter: classes.anchorOriginBottomCenter,
        anchorOriginBottomRight: classes.anchorOriginBottomRight,
        anchorOriginBottomLeft: classes.anchorOriginBottomLeft
    };

    // This line will help screen read to read popper content.
    if (!ContentProps['aria-live']) {
        ContentProps['aria-live'] = 'assertive';
    }
    var contentProps = Object.assign({
        classes: {
            root: classes.contentPropsStyles,
            message: classes.message
        },
        style: shouldAddMobileCustomStyles() && bottom ? { marginBottom: bottom } : {}
    }, ContentProps);

    var propOverrides = {
        autoHideDuration: getNewAutoHideDuration(),
        // Custom actions need to be inserted in particular order which is done in `<CustomMessage/>`.
        action: null,
        anchorOrigin: anchorOrigin || { vertical: 'top', horizontal: 'right' }
    };

    if (shouldAddMobileCustomStyles()) {
        propOverrides.anchorOrigin = { vertical: 'bottom', horizontal: 'center' };
    }

    return React.createElement(MuiSnackbar, Object.assign({}, rest, propOverrides, {
        classes: classOverrides,
        message: React.createElement(CustomMessage, Object.assign({ formatMessage: edsContext.formatMessage }, props, { ref: ref })),
        ContentProps: contentProps,
        ClickAwayListenerProps: {
            onClickAway: function onClickAway() {} // Prevent hiding on clickway
        },
        ref: ref
    }));
});

Snackbar.muiName = 'Snackbar';
Snackbar.displayName = 'Snackbar';

Snackbar.propTypes = {
    /**
     * The number of milliseconds to wait before `Snackbar` automatically closes.
     * Defaults to 5secs for nonactionable `Snackbar`s and 7secs for actionable `Snackbar`s.
     * This can be overridden by the users.
     */
    autoHideDuration: PropTypes.number,
    /**
     * The custom action for Snackbar to display.
     * Undo action is built in to Snackbar but if you need more actions to perform, you can use `action` prop.
     */
    action: PropTypes.node,
    /**
     * The anchor of the `Snackbar`. Defaults to top, right.
     */
    anchorOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
        vertical: PropTypes.oneOf(['top', 'bottom']).isRequired
    }),
    /**
     * If you wish the take control over the children of the component you can use this property.
     * When used, you replace the `SnackbarContent` component with the children.
     */
    children: PropTypes.element,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Properties applied to the `SnackbarContent` element.
     */
    ContentProps: PropTypes.object,
    /**
     * If `true`, the `autoHideDuration` timer will expire even if the window is not focused.
     */
    disableWindowBlurListener: PropTypes.bool,
    /**
     * When displaying multiple consecutive Snackbars from a parent rendering a single
     * <Snackbar/>, add the key property to ensure independent treatment of each message.
     * e.g. <Snackbar key={message} />, otherwise, the message may update-in-place and
     * features such as autoHideDuration may be canceled.
     */
    key: PropTypes.any,
    /**
     * The message to display.
     */
    message: PropTypes.node,
    /**
     * Callback fired when the component requests to be closed.
     * Typically `onClose` is used to set state in the parent component,
     * which is used to control the `Snackbar` `open` prop.
     * The `reason` parameter can optionally be used to control the response to `onClose`,
     * for example ignoring `clickaway`.
     *
     * @param {object} event The event source of the callback
     * @param {string} reason Can be:`"timeout"` (`autoHideDuration` expired) or: `"clickaway"`
     */
    onClose: PropTypes.func,
    /**
     * Callback fired before the transition is entering.
     */
    onEnter: PropTypes.func,
    /**
     * Callback fired when the transition has entered.
     */
    onEntered: PropTypes.func,
    /**
     * Callback fired when the transition is entering.
     */
    onEntering: PropTypes.func,
    /**
     * Callback fired before the transition is exiting.
     */
    onExit: PropTypes.func,
    /**
     * Callback fired when the transition has exited.
     */
    onExited: PropTypes.func,
    /**
     * Callback fired when the transition is exiting.
     */
    onExiting: PropTypes.func,
    /**
     * @ignore
     */
    onMouseEnter: PropTypes.func,
    /**
     * @ignore
     */
    onMouseLeave: PropTypes.func,
    /**
     * If true, `Snackbar` is open.
     */
    open: PropTypes.bool,
    /**
     * The number of milliseconds to wait before dismissing after user interaction.
     * If `autoHideDuration` property isn't specified, it does nothing.
     * If `autoHideDuration` property is specified but `resumeHideDuration` isn't,
     * we default to `autoHideDuration / 2` ms.
     */
    resumeHideDuration: PropTypes.number,
    /**
     * Transition component.
     */
    TransitionComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     */
    transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number })]),
    /**
     * Properties applied to the `Transition` element.
     */
    TransitionProps: PropTypes.object,
    /**
     * Variants of the Snackbar. Choose from these ['success', 'error', 'warning', 'info'].
     */
    variant: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
    /**
     * Callback fired when the undo action is clicked.
     * If this callback is present Snackbar will add Undo button automatically
     */
    onUndoClick: PropTypes.func,
    /**
     * @ignore
     * Width based on the viewport size like xs, sm, md etc
     */
    width: PropTypes.string,
    /**
     * Set the bottom in case of CTA bottom actions on mobile or floating button desktop.
     * Use the CSS units like `rem` or `px`
     */
    bottom: PropTypes.string,
    /**
     * @ignore
     */
    edsContext: PropTypes.object
};

Snackbar.defaultProps = {
    ContentProps: {}
};

export default withEDSContext(withStyles(styles)(Snackbar));