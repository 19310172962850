import EventEmitter from 'events';
import { CartItemType } from '../../types/ICartItem';
import { LayoutEventStatus } from '../../types/LayoutEventStatus';
import { LayoutStatus } from '../../types/LayoutStatus';
import AppDispatcher from '../AppDispatcher';
class LayoutStoreEmitter extends EventEmitter {
    constructor() {
        super();
        this.abortController = new AbortController();
        this.alert = undefined;
        this.authMode = undefined;
        this.countCart = 0;
        this.hasProfile = false;
        this.isAuthenticated = false;
        this.layoutReady = false;
        this.loading = false;
        this.logData = undefined;
        this.menuOptions = undefined;
        this.permissions = undefined;
        this.redirectCode = 0;
        this.resources = {};
        this.theme = undefined;
        this.cultures = undefined;
        this.error = false;
        this.errorMessage = undefined;
    }
    getSignal() {
        return this.abortController.signal;
    }
    abort() {
        this.abortController.abort();
        this.abortController = new AbortController();
    }
    addAlertListener(callback) {
        this.addListener(LayoutEventStatus.changeAlert, callback);
    }
    removeAlertListener(callback) {
        this.removeListener(LayoutEventStatus.changeAlert, callback);
    }
    emitAlert() {
        this.emit(LayoutEventStatus.changeAlert);
    }
    getAlert() {
        return this.alert;
    }
    setAlert(alert) {
        this.alert = alert;
    }
    getAuthMode() {
        return this.authMode;
    }
    setAuthMode(value) {
        this.authMode = value;
    }
    addCountCartListener(callback) {
        this.addListener(LayoutEventStatus.changeCountCart, callback);
    }
    removeCountCartListener(callback) {
        this.removeListener(LayoutEventStatus.changeCountCart, callback);
    }
    emitCountCart() {
        this.emit(LayoutEventStatus.changeCountCart);
    }
    getCountCart() {
        return this.countCart;
    }
    getCartItems() {
        return this.cartItems;
    }
    setCountCart(value, cartItems) {
        this.cartItems = cartItems;
        this.countCart = value;
    }
    getHasProfile() {
        return this.hasProfile;
    }
    setHasProfile(value) {
        this.hasProfile = value;
    }
    getIsAuthenticated() {
        const isAuthenticatedStorage = window.sessionStorage.getItem('pc_auth');
        if (isAuthenticatedStorage) {
            this.isAuthenticated = isAuthenticatedStorage === '1' ? true : false;
        }
        return this.isAuthenticated;
    }
    setIsAuthenticated(value) {
        this.isAuthenticated = value;
        window.sessionStorage.setItem('pc_auth', this.isAuthenticated ? '1' : '0');
    }
    addLogDataListener(callback) {
        this.addListener(LayoutEventStatus.changeLogData, callback);
    }
    removeLogDataListener(callback) {
        this.removeListener(LayoutEventStatus.changeLogData, callback);
    }
    emitLogData() {
        this.emit(LayoutEventStatus.changeLogData);
    }
    getLogData() {
        return this.logData;
    }
    setLogData(logData) {
        this.logData = logData;
    }
    addLayoutReadyListener(callback) {
        this.addListener(LayoutEventStatus.changeLayoutReady, callback);
    }
    removeLayoutReadyListener(callback) {
        this.removeListener(LayoutEventStatus.changeLayoutReady, callback);
    }
    emitLayoutReady() {
        this.emit(LayoutEventStatus.changeLayoutReady);
    }
    getLayoutReady() {
        return this.layoutReady;
    }
    setLayoutReady(value) {
        this.layoutReady = value;
    }
    addLoadingListener(callback) {
        this.addListener(LayoutEventStatus.changeLoading, callback);
    }
    removeLoadingListener(callback) {
        this.removeListener(LayoutEventStatus.changeLoading, callback);
    }
    emitLoading() {
        this.emit(LayoutEventStatus.changeLoading);
    }
    getLoading() {
        return this.loading;
    }
    setLoading(loading) {
        this.loading = loading;
    }
    getMenuOptions() {
        if (!this.menuOptions) {
            const menuStorage = window.sessionStorage.getItem('pc_menu');
            if (menuStorage) {
                this.menuOptions = JSON.parse(menuStorage);
            }
        }
        return this.menuOptions;
    }
    setMenuOptions(value) {
        this.menuOptions = value;
        if (this.menuOptions) {
            window.sessionStorage.setItem('pc_menu', JSON.stringify(this.menuOptions));
        }
        else {
            window.sessionStorage.removeItem('pc_menu');
        }
    }
    getPermissions() {
        return this.permissions;
    }
    setPermissions(value) {
        this.permissions = value;
    }
    addRedirectCodeListener(callback) {
        this.addListener(LayoutEventStatus.changeRedirectCode, callback);
    }
    removeRedirectCodeListener(callback) {
        this.removeListener(LayoutEventStatus.changeRedirectCode, callback);
    }
    emitRedirectCode() {
        this.emit(LayoutEventStatus.changeRedirectCode);
    }
    getRedirectCode() {
        return this.redirectCode;
    }
    setRedirectCode(redirectCode) {
        this.redirectCode = redirectCode;
    }
    addReloadThemeListener(callback) {
        this.addListener(LayoutEventStatus.changeReloadTheme, callback);
    }
    removeReloadThemeListener(callback) {
        this.removeListener(LayoutEventStatus.changeReloadTheme, callback);
    }
    emitReloadTheme() {
        this.emit(LayoutEventStatus.changeReloadTheme);
    }
    getResources() {
        return this.resources.View;
    }
    setResources(value) {
        this.resources.View = value;
    }
    getResourcesLayout() {
        if (!this.resources.Layout) {
            const layoutResourcesStorage = window.sessionStorage.getItem('pc_res_lay');
            if (layoutResourcesStorage) {
                this.resources.Layout = JSON.parse(layoutResourcesStorage);
            }
        }
        return this.resources.Layout;
    }
    setResourcesLayout(value) {
        this.resources.Layout = value;
        if (this.resources.Layout) {
            window.sessionStorage.setItem('pc_res_lay', JSON.stringify(this.resources.Layout));
        }
        else {
            window.sessionStorage.removeItem('pc_res_lay');
        }
    }
    getResourcesByKey(key) {
        return this.resources[key];
    }
    setResourcesByKey(key, value) {
        this.resources[key] = value;
    }
    getTheme() {
        if (!this.theme) {
            const themeStorage = window.sessionStorage.getItem('pc_theme');
            if (themeStorage) {
                this.theme = JSON.parse(themeStorage);
            }
        }
        return this.theme;
    }
    getDefaultTheme() {
        return {
            actionColor: '',
            backgroundUrl: '',
            faviconUrl: '',
            logoAlternateText: '',
            logoUrl: '',
            primaryColor: '',
            secondaryColor: ''
        };
    }
    setTheme(value) {
        this.theme = value;
        if (this.theme) {
            window.sessionStorage.setItem('pc_theme', JSON.stringify(this.theme));
        }
        else {
            window.sessionStorage.removeItem('pc_theme');
        }
    }
    getCultures() {
        if (!this.cultures) {
            const culturesStorage = window.sessionStorage.getItem('pc_cultures');
            if (culturesStorage) {
                this.cultures = JSON.parse(culturesStorage);
            }
        }
        if (this.cultures) {
            return this.cultures;
        }
        else {
            return this.getDefaultCutlure();
        }
    }
    getDefaultCutlure() {
        return {
            amDesignator: 'AM',
            currencyCulture: 'en-us',
            currencySymbol: '$',
            dateTimeCulture: 'en-us',
            firstDayOfWeek: 0,
            numberCulture: 'en-us',
            pmDesignator: 'PM',
            shortDatePattern: 'M/d/yyyy',
            shortTimePattern: 'hh:mm A',
            uiCulture: 'en-us'
        };
    }
    setCultures(value) {
        this.cultures = value;
        if (this.cultures) {
            window.sessionStorage.setItem('pc_cultures', JSON.stringify(this.cultures));
        }
        else {
            window.sessionStorage.removeItem('pc_cultures');
        }
    }
    addErrorListener(callback) {
        this.addListener(LayoutEventStatus.error, callback);
    }
    removeErrorListener(callback) {
        this.removeListener(LayoutEventStatus.error, callback);
    }
    emitError() {
        this.emit(LayoutEventStatus.error);
    }
    getError() {
        return this.error;
    }
    getErrorMessage() {
        return this.errorMessage;
    }
    setError(error, errorMessage) {
        this.error = error;
        this.errorMessage = errorMessage;
    }
}
const LayoutStore = new LayoutStoreEmitter();
AppDispatcher.register((callback) => {
    switch (callback.type) {
        case LayoutStatus.successGetCountCart:
            if (callback.payload.response) {
                const result = JSON.parse(callback.payload.response);
                if (result.status) {
                    let cartItemList = result.data;
                    const menuOptions = LayoutStore.getMenuOptions();
                    if (cartItemList && cartItemList.length > 0 && menuOptions) {
                        let registrationUrl;
                        let conEdRegistrationUrl;
                        menuOptions.forEach(optionMenu => {
                            if (optionMenu.options) {
                                optionMenu.options.forEach(option => {
                                    if (option.id === 'CoursesId') {
                                        registrationUrl = option.url;
                                    }
                                    if (option.id === 'ConEdCoursesId') {
                                        conEdRegistrationUrl = option.url;
                                    }
                                });
                            }
                        });
                        if (!registrationUrl) {
                            cartItemList = cartItemList.filter(cartItem => cartItem.type !== CartItemType.Traditional);
                        }
                        if (!conEdRegistrationUrl) {
                            cartItemList = cartItemList.filter(cartItem => cartItem.type !== CartItemType.ContinuingEducation);
                        }
                    }
                    else {
                        cartItemList = [];
                    }
                    let overallCountCart = 0;
                    if (cartItemList && cartItemList.length > 0) {
                        cartItemList.forEach(cartItem => {
                            overallCountCart += cartItem.count;
                        });
                    }
                    LayoutStore.setCountCart(overallCountCart, cartItemList);
                    LayoutStore.emitCountCart();
                }
            }
            break;
        case LayoutStatus.successSetAlert:
            LayoutStore.setAlert(callback.payload.value);
            LayoutStore.emitAlert();
            break;
        case LayoutStatus.successSetCountCart:
            LayoutStore.emitCountCart();
            break;
        case LayoutStatus.successSetLayoutReady:
            LayoutStore.setLayoutReady(callback.payload.value);
            LayoutStore.emitLayoutReady();
            break;
        case LayoutStatus.successHidePageLoader:
            LayoutStore.setLoading(false);
            LayoutStore.emitLoading();
            break;
        case LayoutStatus.successSetLoading:
            LayoutStore.setLoading(callback.payload.value);
            LayoutStore.emitLoading();
            break;
        case LayoutStatus.successShowPageLoader:
            LayoutStore.setLoading(true);
            LayoutStore.emitLoading();
            break;
        case LayoutStatus.successSetLogData:
            LayoutStore.setLogData(callback.payload.value);
            LayoutStore.emitLogData();
            break;
        case LayoutStatus.successSetRedirectCode:
            LayoutStore.setRedirectCode(callback.payload.value);
            LayoutStore.emitRedirectCode();
            break;
        case LayoutStatus.successSetReloadTheme:
            LayoutStore.setTheme(callback.payload.value);
            LayoutStore.emitReloadTheme();
            break;
        case LayoutStatus.errorGetCountCart:
            if (callback.payload.response) {
                LayoutStore.setError(true, callback.payload.response);
            }
            break;
        default:
            break;
    }
});
export default LayoutStore;
