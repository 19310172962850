"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Icon = require("./Icon");

Object.defineProperty(exports, "Icon", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Icon).default;
  }
});
Object.defineProperty(exports, "icons", {
  enumerable: true,
  get: function get() {
    return _Icon.icons;
  }
});

var _IconSprite = require("./IconSprite");

Object.defineProperty(exports, "IconSprite", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_IconSprite).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }