'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _ExpansionPanel = require('@material-ui/core/ExpansionPanel');

var _ExpansionPanel2 = _interopRequireDefault(_ExpansionPanel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
  card: {
    boxShadow: '0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.21)'
  },
  default: {
    boxShadow: '0 19px 20px 0 rgba(0, 0, 0, 0.03), 0 3px 8px 0 rgba(0, 0, 0, 0.06)'
  }
};

/**
 * Use `ExpansionPanel` to organize content into expandable and collapsible sections.
 * @done true
 * @updated true
 * @versionAdded v0.0.13
 * @examples
 *  ExpansionPanelSimple
 *  ExpansionPanelCard
 *  ExpansionPanelControlledAccordion
 *  ExpansionPanelLeftIcon
 *  ExpansionPanelSecondary
 */
var ExpansionPanel = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      variant = props.variant,
      rest = _objectWithoutProperties(props, ['classes', 'variant']);

  var classOverrides = {};
  classOverrides.root = variant === 'card' ? classes.card : classes.default;

  return _react2.default.createElement(_ExpansionPanel2.default, Object.assign({}, rest, {
    classes: classOverrides,
    ref: ref
  }));
});

ExpansionPanel.muiName = 'ExpansionPanel';
ExpansionPanel.displayName = 'ExpansionPanel';

ExpansionPanel.propTypes = {
  /**
   * The content of the expansion panel.
   */
  children: _propTypes2.default.node.isRequired,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * Properties applied to the `Collapse` element.
   */
  CollapseProps: _propTypes2.default.object,
  /**
   * If `true`, expands the panel by default.
   */
  defaultExpanded: _propTypes2.default.bool,
  /**
   * If `true`, the panel will be displayed in a disabled state.
   */
  disabled: _propTypes2.default.bool,
  /**
   * If `true`, expands the panel, otherwise collapse it.
   * Setting this prop enables control over the panel.
   */
  expanded: _propTypes2.default.bool,
  /**
   * Callback fired when the expand/collapse state is changed.
   *
   * @param {object} event The event source of the callback
   * @param {boolean} expanded The `expanded` state of the panel
   */
  onChange: _propTypes2.default.func,
  /**
   * Styles the ExpansionPanel for the context it is on, either 'default' or 'card'.
   */
  variant: _propTypes2.default.oneOf(['default', 'card'])
};

// If default props are needed, they must be set here
ExpansionPanel.defaultProps = {
  variant: 'default',
  defaultExpanded: false,
  disabled: false
};

exports.default = (0, _styles2.default)(styles)(ExpansionPanel);