import React from 'react';
import { i18n } from '@hedtech/react-design-system/core/i18n';
import hoistNonReactStatics from 'hoist-non-react-statics';
import isEmpty from 'lodash/isEmpty';
// context that tracks translated strings
var EDSContext = React.createContext({});
// Default locale
var DEFAULT_LOCALE = 'en_US';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * Wraps the given component in an HOC that provides global information to all EDS components.
 *
 * It injects an edsContextInfo property, which contains the follow attributs:
 *
 * * formatMessage: (function) Returns the text corresponding to a given id. Accepts two arguments:
 *     - id: The id of the message to include
 *     - substitutions: (optional) An object that specifies substitutions for templated resources.
 * * locale: (string) The locale specified at the EDSApplication level.
 *
 * @param {Component} WrappedComponent The component to wrap
 */
var withEDSContext = function withEDSContext(WrappedComponent) {

    var getFormatMessageFunc = function getFormatMessageFunc(_ref) {
        var locale = _ref.locale,
            labelOverrides = _ref.labelOverrides;

        // Replace hyphen ('-') with underscore ('_') to support hyphens in locale value.

        locale = locale && locale.replace('-', '_');
        var language = locale && locale.split('_')[0];

        var messages = {};
        /**
         * Example with respect to locale = `fr_CA`
         * If `fr_CA` locale is available, then use it to display labels.
         * If `fr_CA` locale is not available, then use `fr` language to display labels.
         * If `fr` language is not available, then use `en_US` locale (i.e. Default locale) to display labels.
         */
        if (i18n[locale]) {
            messages = i18n[locale].messages;
        } else if (i18n[language]) {
            messages = i18n[language].messages;
        } else {
            messages = i18n[DEFAULT_LOCALE].messages;
        }

        if (!isEmpty(labelOverrides)) {
            messages = Object.assign({}, messages, labelOverrides);
        }

        var f = function f(id, substitutions) {
            var msg = messages[id];

            // if we found a resource for this id, handle substitutions
            if (msg) {
                if (substitutions) {
                    Object.keys(substitutions).forEach(function (key) {
                        msg = msg.replace('{' + key + '}', substitutions[key]);
                    });
                }
            } else {
                // Display key as it is if value is not available in the locale file.
                msg = 'MISSING KEY: ' + id;
                console.error('MISSING KEY: ' + id);
            }

            return msg;
        };

        return f;
    };

    var wrap = React.forwardRef(function contextTranslation(props, ref) {
        return React.createElement(
            EDSContext.Consumer,
            null,
            function (value) {
                return React.createElement(WrappedComponent, Object.assign({
                    ref: ref
                }, props, {
                    edsContext: {
                        formatMessage: getFormatMessageFunc(value),
                        locale: value.locale && value.locale.replace('_', '-')
                    }
                }));
            }
        );
    });

    wrap.displayName = 'EDSContext(' + getDisplayName(WrappedComponent) + ')';

    return hoistNonReactStatics(wrap, WrappedComponent);
};

export { EDSContext, withEDSContext };