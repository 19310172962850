'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@material-ui/core');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    var _tabRoot, _cardLevelTabRoot;

    return {
        tabRoot: (_tabRoot = {
            textTransform: 'none',
            minWidth: 'auto',
            margin: '0 ' + _tokens.spacingSmall,
            padding: '0px ' + _tokens.spacingXxSmall,
            lineHeight: '' + _tokens.lineHeightParagraphSmall
        }, _defineProperty(_tabRoot, theme.breakpoints.up('md'), {
            minWidth: 'auto'
        }), _defineProperty(_tabRoot, '&:hover', {
            color: theme.palette.ctaColor.hover
        }), _defineProperty(_tabRoot, '&:focus', {
            boxShadow: _tokens.boxShadowFocusInset,
            color: theme.palette.ctaColor.active
        }), _defineProperty(_tabRoot, '&$disabled', {
            color: theme.palette.text.disabled
        }), _defineProperty(_tabRoot, 'fontFamily', theme.typography.fontFamily), _defineProperty(_tabRoot, 'fontWeight', theme.typography.button.fontWeight), _defineProperty(_tabRoot, 'fontSize', theme.typography.body1.fontSize), _tabRoot),
        cardLevelTabRoot: (_cardLevelTabRoot = {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.button.fontWeight,
            fontSize: theme.typography.body1.fontSize,
            textTransform: 'none',
            minWidth: 'auto',
            padding: '0px ' + _tokens.spacingSmall
        }, _defineProperty(_cardLevelTabRoot, theme.breakpoints.up('md'), {
            minWidth: 'auto'
        }), _defineProperty(_cardLevelTabRoot, '&:hover', {
            color: theme.palette.ctaColor.hover
        }), _defineProperty(_cardLevelTabRoot, '&:focus', {
            boxShadow: _tokens.boxShadowFocusInset,
            color: theme.palette.ctaColor.active
        }), _defineProperty(_cardLevelTabRoot, '&$disabled', {
            color: theme.palette.text.disabled
        }), _cardLevelTabRoot),
        selected: {
            color: theme.palette.ctaColor.base
        },
        // Inherit styles
        textColorInherit: {
            color: theme.palette.text.secondary,
            opacity: '1',
            '&$selected': {
                color: theme.palette.ctaColor.base
            },
            '&$disabled': {
                opacity: 1,
                color: theme.palette.grey[400]
            }
        },
        // Primary styles
        textColorPrimary: {
            color: theme.palette.text.secondary,
            opacity: '1',
            '&$selected': {
                color: theme.palette.ctaColor.base
            },
            '&$disabled': {
                opacity: '1'
            }
        },
        // Secondary styles
        textColorSecondary: {
            color: theme.palette.text.secondary,
            opacity: '1',
            '&$selected': {
                color: theme.palette.ctaColor.base
            },
            '&$disabled': {
                opacity: '1',
                '&:hover': {
                    color: theme.palette.grey[400]
                }
            }
        },
        // label: {
        //     fontFamily: theme.typography.fontFamily,
        //     fontWeight: theme.typography.button.fontWeight,
        //     fontSize: theme.typography.body1.fontSize,
        // },
        // labelContainer: {
        //     width: '100%', // Fix an IE 11 issue
        //     boxSizing: 'border-box',
        //     padding: `0 ${spacingXxSmall}`,
        // },
        cardLevel: {
            border: '1px solid transparent', // Fix text/tab shift with border
            '&$selected': {
                borderColor: theme.palette.grey[300] + ' ' + theme.palette.grey[300] + ' #fff',
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4
            }
        },
        disabled: {
            opacity: '1',
            color: theme.palette.grey[400]
        }
    };
};

var Tab = _react2.default.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        classNameProp = props.className,
        variant = props.variant,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'variant']);

    return _react2.default.createElement(_core.Tab, Object.assign({}, rest, {
        classes: {
            root: variant === 'card' ? classes.cardLevelTabRoot : classes.tabRoot,
            selected: classes.selected,
            textColorInherit: classes.textColorInherit,
            textColorPrimary: classes.textColorPrimary,
            textColorSecondary: classes.textColorSecondary,
            // label: classes.label,
            // labelContainer: classes.labelContainer,
            disabled: classes.disabled
        },
        className: (0, _classnames2.default)((_classNames = {}, _defineProperty(_classNames, classes.cardLevel, variant === 'card'), _defineProperty(_classNames, 'classNameProp', classNameProp), _classNames)),
        disableRipple: true,
        ref: ref
    }));
});

Tab.muiName = 'Tab';
Tab.displayName = 'Tab';

Tab.propTypes = {
    /**
     * Useful to extend the style applied to components.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * If `true`, the tab will be disabled.
     */
    disabled: _propTypes2.default.bool,
    /**
     * @ignore
     */
    fullWidth: _propTypes2.default.bool,
    /**
     * @ignore
     * For server side rendering consideration, we let the selected tab
     * render the indicator.
     */
    indicator: _propTypes2.default.node,
    /**
     * The label element.
     */
    label: _propTypes2.default.node,
    /**
     * @ignore
     */
    onChange: _propTypes2.default.func,
    /**
     * @ignore
     */
    onClick: _propTypes2.default.func,
    /**
     * @ignore
     */
    selected: _propTypes2.default.bool,
    /**
     * @ignore
     */
    textColor: _propTypes2.default.oneOf(['secondary', 'primary', 'inherit']),
    /**
     * You can provide your own value. Otherwise, we fallback to the child position index.
     */
    value: _propTypes2.default.any,
    /**
     * @ignore
     * Internal value to check if the Tab should have Card Level styling.
     */
    variant: _propTypes2.default.oneOf(['page', 'card'])
};

exports.default = (0, _styles2.default)(styles)(Tab);