function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import Typography from '../Typography';
import { spacingMedium, spacingLarge, fontSizeHeader1, fontSizeHeader1Small } from '../styles/tokens';

var styles = function styles(theme) {
    var _pageHeader;

    return {
        pageHeader: (_pageHeader = {
            paddingTop: spacingLarge,
            paddingBottom: spacingMedium,
            lineHeight: '2.625rem',
            color: theme.palette.grey[600]
        }, _defineProperty(_pageHeader, theme.breakpoints.up('lg'), {
            fontSize: fontSizeHeader1
        }), _defineProperty(_pageHeader, theme.breakpoints.down('md'), {
            fontSize: fontSizeHeader1Small
        }), _pageHeader)
    };
};

/**
 * Use `PageHeader` to display a page title at the top of a page.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  PageHeaderExample
 */
var PageHeader = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        text = props.text,
        rest = _objectWithoutProperties(props, ['classes', 'text']);

    var classOverrides = {
        h1: classes.pageHeader
    };

    return React.createElement(
        Typography,
        Object.assign({}, rest, { classes: classOverrides, variant: 'h1', ref: ref }),
        text
    );
});

PageHeader.muiName = 'PageHeader';
PageHeader.displayName = 'PageHeader';

PageHeader.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The content to be displayed as the page header.
     */
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default withStyles(styles)(PageHeader);