'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _TableCell = require('@material-ui/core/TableCell');

var _TableCell2 = _interopRequireDefault(_TableCell);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
  root: {
    paddingTop: _tokens.spacingXxSmall,
    paddingBottom: _tokens.spacingXxSmall
  },
  head: {},
  body: {}
};

/**
 * Table cell
 */
var TableCell = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      columnName = props.columnName,
      rest = _objectWithoutProperties(props, ['classes', 'columnName']);

  var classOverrides = {
    root: classes.root,
    head: classes.head,
    body: classes.body
  };

  return _react2.default.createElement(_TableCell2.default, Object.assign({}, rest, {
    'data-label': columnName || '',
    classes: classOverrides,
    ref: ref
  }));
});

TableCell.muiName = 'TableCell';
TableCell.displayName = 'TableCell';

TableCell.propTypes = {
  /**
   * Set the text-align on the table cell content. Monetary or generally number fields should
   * be right aligned as that allows you to add them up quickly in your head without having
   * to worry about decimals.
   */
  align: _propTypes2.default.oneOf(['inherit', 'left', 'right', 'center', 'justify']),
  /**
   * The table cell contents
   */
  children: _propTypes2.default.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * The component used for the root node. Either a string to use a DOM element or a component.
   */
  component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
  /**
   * Sets the padding applied to the cell.
   */
  padding: _propTypes2.default.oneOf(['default', 'checkbox', 'none']),
  /**
   * Specify the size of the cell. By default, inherits `size` from the parent `Table`.
   */
  size: _propTypes2.default.oneOf(['small', 'medium']),
  /**
   * Set scope attribute.
   */
  scope: _propTypes2.default.string,
  /**
   * Set aria-sort direction.
   */
  sortDirection: _propTypes2.default.oneOf(['asc', 'desc', false]),
  /**
   * Specify the cell type. By default, the `TableHead`, `TableBody` or `TableFooter` parent component set the value.
   */
  variant: _propTypes2.default.oneOf(['head', 'body', 'footer']),
  /**
   * Set this if you want to render a responsive Table
   */
  columnName: _propTypes2.default.string
};

// default props
TableCell.defaultProps = {
  align: 'inherit',
  padding: 'default'
};

exports.default = (0, _styles2.default)(styles)(TableCell);