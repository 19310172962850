'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _DialogContent = require('@material-ui/core/DialogContent');

var _DialogContent2 = _interopRequireDefault(_DialogContent);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            padding: '0 ' + _tokens.spacingMedium + ' ' + _tokens.spacingMedium
        }
    };
};

/**
 * DialogContent is the main content that lives within a Dialog.
 */
var DialogContent = _react2.default.forwardRef(function (props, ref) {
    var children = props.children,
        classesProp = props.classes,
        rest = _objectWithoutProperties(props, ['children', 'classes']);

    var classes = {
        root: classesProp.root
    };

    return _react2.default.createElement(
        _DialogContent2.default,
        Object.assign({ classes: classes }, rest, { ref: ref }),
        children
    );
});

DialogContent.muiName = 'DialogContent';
DialogContent.displayName = 'DialogContent';

DialogContent.propTypes = {
    /**
     * The content of the component.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string
};

exports.default = (0, _styles2.default)(styles)(DialogContent);