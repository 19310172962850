var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import React, { forwardRef } from 'react';
import EdsTypography from '@hedtech/react-design-system/core/es/Typography';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    baseline: {
        verticalAlign: 'baseline'
    },
    block: {
        display: 'block'
    },
    bottom: {
        verticalAlign: 'bottom'
    },
    inheritVerticalAlign: {
        verticalAlign: 'inherit'
    },
    inline: {
        display: 'inline'
    },
    inlineBlock: {
        display: 'inline-block'
    },
    light: {
        fontWeight: Tokens.fontWeightLight
    },
    middle: {
        verticalAlign: 'middle'
    },
    normal: {
        fontWeight: Tokens.fontWeightNormal
    },
    sizeInherit: {
        fontSize: 'inherit'
    },
    strong: {
        fontWeight: Tokens.fontWeightStrong
    },
    top: {
        verticalAlign: 'top'
    }
});
const Text = (props, ref) => {
    const { children, classes, className, component, display, size, verticalAlign, weight } = props, rest = __rest(props, ["children", "classes", "className", "component", "display", "size", "verticalAlign", "weight"]);
    let classText = '';
    let customComponent;
    let variant;
    switch (size) {
        case 'inherit':
            classText = classes.sizeInherit;
            variant = 'body2';
            break;
        case 'h1':
            variant = 'h1';
            break;
        case 'h2':
            variant = 'h2';
            break;
        case 'h3':
            variant = 'h3';
            break;
        case 'h4':
            variant = 'h4';
            break;
        case 'large':
            variant = 'body1';
            break;
        case 'small':
            variant = 'body3';
            break;
        default:
            variant = 'body2';
    }
    if (!Boolean(customComponent)) {
        customComponent = component;
    }
    switch (weight) {
        case 'light':
            classText = classnames(classText, classes.light);
            break;
        case 'strong':
            classText = classnames(classText, classes.strong);
            break;
        default:
            classText = classnames(classText, classes.normal);
    }
    switch (display) {
        case 'inline':
            classText = classnames(classText, classes.inline);
            break;
        case 'inline-block':
            classText = classnames(classText, classes.inlineBlock);
            break;
        default:
            classText = classnames(classText, classes.block);
    }
    switch (verticalAlign) {
        case 'inherit':
            classText = classnames(classText, classes.inheritVerticalAlign);
            break;
        case 'top':
            classText = classnames(classText, classes.top);
            break;
        case 'middle':
            classText = classnames(classText, classes.middle);
            break;
        case 'bottom':
            classText = classnames(classText, classes.bottom);
            break;
        case 'baseline':
            classText = classnames(classText, classes.baseline);
            break;
    }
    if (className) {
        classText = classnames(classText, className);
    }
    return (React.createElement(EdsTypography, Object.assign({ className: classText, component: customComponent, ref: ref, variant: variant }, rest), children));
};
const TextFRC = forwardRef(Text);
TextFRC.displayName = 'TextFRC';
TextFRC.defaultProps = {
    align: 'inherit',
    color: 'initial',
    display: 'block',
    gutterBottom: false,
    size: 'default',
    weight: 'normal'
};
export default withStyles(styles)(TextFRC);
