var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiRadio } from '@material-ui/core/Radio';
import { default as withStyles } from '../styles';
import { colorBrandSecondary, spacingSmall, sizingMedium } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            color: theme.palette.grey['500'],
            margin: spacingSmall,
            padding: 0,
            width: sizingMedium // size of the icon
        },
        focused: {
            boxShadow: '0 0 0 2px ' + colorBrandSecondary,
            color: theme.palette.grey['500'],
            margin: spacingSmall,
            padding: 0,
            width: sizingMedium // size of the icon
        },
        checked: {},
        disabled: {},
        colorPrimary: {
            '&$checked': {
                color: theme.palette.primary.main
            },
            '&$disabled': {
                color: theme.palette.disabled.background
            }
        },
        colorSecondary: {
            '&$checked': {
                color: theme.palette.ctaColor.base
            },
            '&$disabled': {
                color: theme.palette.disabled.background
            }
        }
    };
};

/**
 * Use `Radio` (radio buttons) to allow the user to select only one option from a group.
 * @done true
 * @updated false
 * @versionAdded v0.0.3
 * @examples
 *  LabeledRadios
 *  UnlabeledRadios
 */

var Radio = function (_React$Component) {
    _inherits(Radio, _React$Component);

    function Radio(props) {
        _classCallCheck(this, Radio);

        var _this = _possibleConstructorReturn(this, (Radio.__proto__ || Object.getPrototypeOf(Radio)).call(this, props));

        _this.state = {
            isFocused: false
        };
        _this.handleFocus = _this.handleFocus.bind(_this);
        _this.handleBlur = _this.handleBlur.bind(_this);
        return _this;
    }

    _createClass(Radio, [{
        key: 'handleFocus',
        value: function handleFocus(e) {
            if (this.props.onFocus) {
                this.props.onFocus(e);
            }
            this.setState(function (prevState) {
                return {
                    isFocused: true
                };
            });
        }
    }, {
        key: 'handleBlur',
        value: function handleBlur(e) {
            if (this.props.onBlur) {
                this.props.onBlur(e);
            }
            this.setState(function (prevState) {
                return {
                    isFocused: false
                };
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var isFocused = this.state.isFocused;

            var _props = this.props,
                classes = _props.classes,
                other = _objectWithoutProperties(_props, ['classes']);

            return React.createElement(MuiRadio, Object.assign({
                classes: {
                    root: isFocused ? classes.focused : classes.root,
                    checked: classes.checked,
                    disabled: classes.disabled,
                    colorPrimary: classes.colorPrimary,
                    colorSecondary: classes.colorSecondary
                },
                onFocus: this.handleFocus,
                onBlur: this.handleBlur,
                disableRipple: true
            }, other));
        }
    }]);

    return Radio;
}(React.Component);

;

Radio.muiName = 'Radio';

Radio.propTypes = {
    /**
     * If `true`, the component is checked.
     */
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /**
     * The icon to display when the component is checked.
     */
    checkedIcon: PropTypes.node,
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object.isRequired,
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color: PropTypes.oneOf(['primary', 'secondary', 'default']),
    /**
     * If `true`, the switch will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, the ripple effect will be disabled.
     */
    disableRipple: PropTypes.bool,
    /**
     * The icon to display when the component is unchecked.
     */
    icon: PropTypes.node,
    /**
     * The id of the `input` element.
     */
    id: PropTypes.string,
    /**
     * Properties applied to the `input` element.
     */
    inputProps: PropTypes.object,
    /**
     * Use that property to pass a ref callback to the native input component.
     */
    inputRef: PropTypes.func,
    /**
     * Callback fired when the state is changed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value`.
     * @param {boolean} checked The `checked` value of the switch
     */
    onChange: PropTypes.func,
    /**
     * @ignore
     */
    onBlur: PropTypes.func,
    /**
     * @ignore
     */
    onFocus: PropTypes.func,
    /**
     * The input component property `type`.
     */
    type: PropTypes.string,
    /**
     * The value of the component.
     */
    value: PropTypes.string
};

Radio.defaultProps = {
    color: 'secondary'
};

export default withStyles(styles)(Radio);