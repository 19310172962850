'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _tokens = require('../styles/tokens');

var _constants = require('./internal/constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        currentStepTitle: {
            order: 0,
            marginLeft: _tokens.spacingXSmall,
            flex: '1 1 auto',
            alignSelf: 'auto'
        }
    };
};

/**
 * A component that displays the current step of the total steps
 */
var MobileStepperLabel = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    return (
        /* These marker class names will be used by its parent component MobileStepper */
        _react2.default.createElement(
            _Typography2.default,
            Object.assign({ variant: 'h3',
                className: (0, _classnames2.default)(classes.currentStepTitle, _constants.MOBILE_STEPPER_LABEL_CLASSNAME),
                component: 'span'
            }, rest, {
                ref: ref
            }),
            props.children
        )
    );
});

MobileStepperLabel.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The step title component.
     */
    children: _propTypes2.default.node,
    /**
     * Props will be passed into the rendered `<Typography>` component.
     */
    props: _propTypes2.default.object
};

MobileStepperLabel.displayName = 'MobileStepperLabel';

exports.default = (0, _styles2.default)(styles)(MobileStepperLabel);