import React from 'react';

var SvgGraduationCap = function SvgGraduationCap(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M30.678 43.424l-9.348-.611-7.445-3.556 1.123-10.273L1.969 24.6v-3.183L30 13.233l28.453 8.868v2.5L44.992 29.33l1.646 9.447-7.131 4.036z"
            }),
            React.createElement("path", {
                fill: "#FFF",
                opacity: 0.88,
                d: "M30.678 43.424l-9.348-.611-7.445-3.556 1.123-10.273L1.969 24.6v-3.183L30 13.233l28.453 8.868v2.5L44.992 29.33l1.646 9.447-7.131 4.036z"
            }),
            React.createElement("path", {
                d: "M57.409 19.81l-24.584-7.412a10.222 10.222 0 0 0-5.65 0L2.593 19.81c-3.467 1.016-3.447 5.872 0 6.882l2.605.785c-.316 1-.438 2.058-.483 3.235a2.624 2.624 0 0 0-.022 4.565l-.94 11.915A.75.75 0 0 0 4.5 48h3a.75.75 0 0 0 .748-.81l-.94-11.914a2.623 2.623 0 0 0 .032-4.533c.04-.966.14-1.775.38-2.507l5.51 1.662C12.667 33.652 12 38.119 12 38.22 12 43.739 24.435 45 30 45c5.566 0 18-1.261 18-6.78 0-.1-.667-4.568-1.23-8.322L57.41 26.69c3.458-1.014 3.452-5.87 0-6.882zM44.992 38.28C44.25 40.5 37.5 42 30 42c-7.5 0-14.25-1.5-14.992-3.72l1.126-7.507 11.041 3.329c1.846.53 3.804.53 5.65 0l11.041-3.329 1.126 7.506zm11.561-14.468l-24.566 7.41a7.226 7.226 0 0 1-3.975 0l-24.55-7.405-.015-.005a.577.577 0 0 1 0-1.124l24.565-7.41a7.228 7.228 0 0 1 3.975 0l24.551 7.405.015.005a.577.577 0 0 1 0 1.124z",
                fill: "currentColor"
            })
        )
    );
};

export default SvgGraduationCap;