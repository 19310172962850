const Format = {
    toJson(json) {
        if (typeof json === 'string') {
            return JSON.parse(json);
        }
        return json;
    },
    toPhone(value, format, formatChar) {
        if (format && value && formatChar) {
            let formattedNumber = '';
            let iNumber = 0;
            for (const fChar of format) {
                if (fChar === formatChar) {
                    if (iNumber < value.length) {
                        formattedNumber = `${formattedNumber}${value[iNumber]}`;
                        iNumber++;
                    }
                }
                else {
                    formattedNumber = `${formattedNumber}${fChar}`;
                }
            }
            if (iNumber < value.length) {
                for (let iRest = iNumber; iRest < value.length; iRest++) {
                    formattedNumber = `${formattedNumber}${value[iRest]}`;
                }
            }
            return formattedNumber;
        }
        else {
            return value;
        }
    },
    toString(format, args) {
        return format.replace(/{(\d+)}/g, function (match, value) {
            return typeof args[value] !== 'undefined' ? args[value] : match;
        });
    }
};
export default Format;
