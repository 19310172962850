import React from 'react';

var SvgTasks = function SvgTasks(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                d: "M34,3 L34,10 L50,10 L50,58 L11,58 L11,10 L27,10 L27,3 L34,3 Z",
                id: "Theme", fill: "currentColor"
            }),
            React.createElement("path", {
                d: "M34,3 L34,10 L50,10 L50,58 L11,58 L11,10 L27,10 L27,3 L34,3 Z",
                id: "Lighten",
                fill: "#FFFFFF",
                opacity: "0.88"
            }),
            React.createElement("path", {
                d: "M47.375,7.5 L36.9921875,7.5 C37.0390625,7.1953125 37.0625,6.87890625 37.0625,6.5625 C37.0625,2.94140625 34.1210938,0 30.5,0 C26.8789062,0 23.9375,2.94140625 23.9375,6.5625 C23.9375,6.87890625 23.9609375,7.1953125 24.0078125,7.5 L13.625,7.5 C10.5195312,7.5 8,10.0195312 8,13.125 L8,54.375 C8,57.4804688 10.5195312,60 13.625,60 L47.375,60 C50.4804688,60 53,57.4804688 53,54.375 L53,13.125 C53,10.0195312 50.4804688,7.5 47.375,7.5 Z M30.5,3.75 C32.0585938,3.75 33.3125,5.00390625 33.3125,6.5625 C33.3125,8.12109375 32.0585938,9.375 30.5,9.375 C28.9414062,9.375 27.6875,8.12109375 27.6875,6.5625 C27.6875,5.00390625 28.9414062,3.75 30.5,3.75 Z M49.25,54.375 C49.25,55.40625 48.40625,56.25 47.375,56.25 L13.625,56.25 C12.59375,56.25 11.75,55.40625 11.75,54.375 L11.75,13.125 C11.75,12.09375 12.59375,11.25 13.625,11.25 L19.25,11.25 L19.25,13.59375 C19.25,14.3671875 19.8828125,15 20.65625,15 L40.34375,15 C41.1171875,15 41.75,14.3671875 41.75,13.59375 L41.75,11.25 L47.375,11.25 C48.40625,11.25 49.25,12.09375 49.25,13.125 L49.25,54.375 Z M42.3476563,26.671875 C41.796875,26.1210937 40.90625,26.1210937 40.3554688,26.6601562 L26.5625,40.3476562 L20.65625,34.4179687 C20.1054688,33.8671875 19.2148438,33.8671875 18.6640625,34.4179687 L17.6679688,35.4140625 C17.1171875,35.9648437 17.1171875,36.8554687 17.6679688,37.40625 L25.5664062,45.328125 C26.1171875,45.8789062 27.0078125,45.8789062 27.5585938,45.328125 L43.3554688,29.6601562 C43.90625,29.109375 43.90625,28.21875 43.3671875,27.6679687 L42.3476563,26.671875 L42.3476563,26.671875 Z",
                id: "Shape",
                fill: "currentColor"
            })
        )
    );
};

export default SvgTasks;