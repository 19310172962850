function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiTableCell } from '@material-ui/core/TableCell';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

import { spacingXxSmall } from '../styles/tokens';

var styles = {
  root: {
    paddingTop: spacingXxSmall,
    paddingBottom: spacingXxSmall
  },
  head: {},
  body: {}
};

/**
 * Table cell
 */
var TableCell = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      columnName = props.columnName,
      rest = _objectWithoutProperties(props, ['classes', 'columnName']);

  var classOverrides = {
    root: classes.root,
    head: classes.head,
    body: classes.body
  };

  return React.createElement(MuiTableCell, Object.assign({}, rest, {
    'data-label': columnName || '',
    classes: classOverrides,
    ref: ref
  }));
});

TableCell.muiName = 'TableCell';
TableCell.displayName = 'TableCell';

TableCell.propTypes = {
  /**
   * Set the text-align on the table cell content. Monetary or generally number fields should
   * be right aligned as that allows you to add them up quickly in your head without having
   * to worry about decimals.
   */
  align: PropTypes.oneOf(['inherit', 'left', 'right', 'center', 'justify']),
  /**
   * The table cell contents
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node. Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  /**
   * Sets the padding applied to the cell.
   */
  padding: PropTypes.oneOf(['default', 'checkbox', 'none']),
  /**
   * Specify the size of the cell. By default, inherits `size` from the parent `Table`.
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Set scope attribute.
   */
  scope: PropTypes.string,
  /**
   * Set aria-sort direction.
   */
  sortDirection: PropTypes.oneOf(['asc', 'desc', false]),
  /**
   * Specify the cell type. By default, the `TableHead`, `TableBody` or `TableFooter` parent component set the value.
   */
  variant: PropTypes.oneOf(['head', 'body', 'footer']),
  /**
   * Set this if you want to render a responsive Table
   */
  columnName: PropTypes.string
};

// default props
TableCell.defaultProps = {
  align: 'inherit',
  padding: 'default'
};

export default withStyles(styles)(TableCell);