'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.HeaderBarContext = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _v = require('uuid/v4');

var _v2 = _interopRequireDefault(_v);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _AppBar = require('@material-ui/core/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _Toolbar = require('@material-ui/core/Toolbar');

var _Toolbar2 = _interopRequireDefault(_Toolbar);

var _TextField = require('@material-ui/core/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _InputAdornment = require('@material-ui/core/InputAdornment');

var _InputAdornment2 = _interopRequireDefault(_InputAdornment);

var _ = require('../');

var _tokens = require('../styles/tokens');

var _EDSContext = require('../EDSContext/EDSContext');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _themes = require('@hedtech/react-design-system/core/themes');

var _Icon = require('@eui/ds-icons/lib/es6/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var colorBackgroundHeaderBarThemed = _tokens.colorBrandNeutral100;
var colorBackgroundSearchThemed = _tokens.colorBrandNeutral300;

// unique id for the 'page links' section
var pageSectionId = (0, _v2.default)();

// unique ids for standard utility items
var megaMenuItemKey = 'headerBarMegaMenuUtilityItem';
var searchItemKey = 'headerBarSearchUtilityItem';

var styles = function styles(theme) {
    var _searchInput;

    return _defineProperty({
        root: {
            borderBottom: _tokens.borderWidthThickest + ' solid ' + theme.palette.secondary.main,
            boxShadow: 'none',
            height: _tokens.sizingXxLarge1,
            zIndex: theme.zIndex.headerBar,
            backgroundColor: _tokens.colorBrandPrimary,
            '&.themed': {
                backgroundColor: colorBackgroundHeaderBarThemed
            }
        },
        overlay: {
            position: 'fixed',
            width: _tokens.widthFluid,
            height: _tokens.heightFluid,
            opacity: _tokens.opacity2,
            backgroundColor: theme.palette.grey[600],
            top: _tokens.spacingXxLarge1
        },
        menuBar: {
            display: 'flex',
            flex: '1'
        },
        utilityBar: {
            display: 'flex',
            height: '4rem',
            borderBottom: _tokens.sizingXxSmall + ' solid ' + theme.palette.secondary.main
        },
        menuItem: {
            display: 'block'
        },
        logoRoot: {
            backgroundColor: theme.palette.primary.main,
            borderBottom: _tokens.sizingXxSmall + ' solid ' + theme.palette.secondary.main,
            height: '4rem'
        },
        logo: {
            // TODO make Logo more configurable so that these important markers are no longer necessary
            padding: '0 !important',
            minWidth: '0 !important',

            '& svg ': {
                width: '5.625rem'
            }
        },

        // fix the toolbar at a specific minimum height, regardless of size class (Toolbar wants to float, height-wise,
        // but that makes positioning things a pain
        headerBarToolbar: {
            minHeight: _tokens.sizingXxLarge1
        },

        searchBar: {
            display: 'none',
            flex: '1'
        },
        searchRoot: {
            backgroundColor: '' + _tokens.colorBackgroundHeaderBarItemSelected,
            transform: 'translateY(-2px)',
            height: '60px',

            '& input': {
                color: '' + _tokens.colorTextNeutral100
            },

            '& .header-search-icon': {
                color: _tokens.colorTextNeutral100
            },

            '&.themed': {
                backgroundColor: '' + colorBackgroundSearchThemed
            },
            '&.themed input': {
                color: _tokens.colorTextNeutral500
            },
            '&.themed .header-search-icon': {
                color: _tokens.colorBrandNeutral500
            }

        },
        searchInput: (_searchInput = {
            padding: '1.2rem 0'

        }, _defineProperty(_searchInput, '&[type="text"]::-webkit-search-decoration,\n        &[type="text"]::-webkit-search-cancel-button,\n        &[type="text"]::-webkit-search-results-button,\n        &[type="text"]::-webkit-search-results-decoration', {
            display: 'none'
        }), _defineProperty(_searchInput, '&[type="text"]', {
            '-webkit-appearance': 'none'
        }), _defineProperty(_searchInput, '&[type=text]::-ms-clear', {
            display: 'none',
            width: 0,
            height: 0
        }), _searchInput),
        searchIconSize: {
            height: _tokens.spacingMedium + ' !important',
            width: _tokens.spacingMedium + ' !important'
        },
        searchAdornment: {
            backgroundColor: 'transparent',
            border: 'none',
            margin: 0,
            maxHeight: 'none',
            padding: _tokens.spacingSmall
        },

        // determines which elements of the headerbar are visible when the search is open
        searchOpen: _defineProperty({
            '& .headerbar-search-bar': {
                display: 'flex'
            },
            '& .menu-bar': {
                display: 'none'
            },
            '& .headerbar-logo': {
                display: 'none'
            },
            '& .headerbar-utility-bar': {
                paddingRight: 0
            }
        }, '& .headerbar-utility-bar div[data-key=' + megaMenuItemKey + ']', {
            display: 'none !important'
        }),

        logoDisabled: {
            pointerEvents: 'none'
        }

    }, '@media ' + _tokens.mqMedium, {
        searchOpen: _defineProperty({
            '& .headerbar-logo': {
                display: 'block'
            }
        }, '& .headerbar-utility-bar div[data-key=' + megaMenuItemKey + ']', {
            display: 'flex !important'
        })
    });
};

var HeaderBarContext = (0, _react.createContext)();

var lightTheme = (0, _themes.createEDSTheme)('light');

var HeaderBarProvider = function (_React$Component) {
    _inherits(HeaderBarProvider, _React$Component);

    function HeaderBarProvider() {
        var _ref2;

        var _temp, _this, _ret;

        _classCallCheck(this, HeaderBarProvider);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref2 = HeaderBarProvider.__proto__ || Object.getPrototypeOf(HeaderBarProvider)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
            activeHeaderBarItem: null,
            isMegaMenuOpen: false,
            isDropDownOpen: false,
            isThemed: false
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(HeaderBarProvider, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                children = _props.children,
                onActiveItemChanged = _props.onActiveItemChanged;


            return _react2.default.createElement(
                HeaderBarContext.Provider,
                {
                    value: {
                        state: this.state,
                        setActive: function setActive(item) {

                            if (onActiveItemChanged) {
                                onActiveItemChanged(item);
                            }
                        },
                        isActive: function isActive(itemRef) {
                            return _this2.state.activeHeaderBarItem === itemRef;
                        },
                        setThemed: function setThemed(isThemed) {
                            return _this2.setState({ isThemed: isThemed });
                        },
                        isThemed: function isThemed() {
                            return _this2.state.isThemed;
                        },
                        setOpen: function setOpen(open) {
                            return _this2.setState({ isDropDownOpen: open });
                        }
                    }
                },
                children
            );
        }
    }]);

    return HeaderBarProvider;
}(_react2.default.Component);

HeaderBarProvider.propTypes = {
    onActiveItemChanged: _propTypes2.default.func
};

/**
 * Use `HeaderBar` to display main navigation that enables the user to quickly access navigational items.
 * @done true
 * @updated false
 * @versionAdded v0.0.5
 * @examples
 *  ConsolidatedHeaderBarExample
 *  SimpleHeaderBarExample
 */

var HeaderBar = function (_React$Component2) {
    _inherits(HeaderBar, _React$Component2);

    function HeaderBar(props) {
        _classCallCheck(this, HeaderBar);

        var _this3 = _possibleConstructorReturn(this, (HeaderBar.__proto__ || Object.getPrototypeOf(HeaderBar)).call(this, props));

        _this3.state = {
            megaMenuOpen: false,
            searchOpen: false
        };

        _this3.onMenuItemClick = function (event, item) {

            switch (item.key) {
                case megaMenuItemKey:
                    _this3.toggleMegaMenu(!_this3.state.megaMenuOpen);
                    break;
                case searchItemKey:
                    // ignore the search click
                    break;
                default:
                    _this3.props.onItemSelected && _this3.props.onItemSelected(item);
                    break;
            }
        };

        _this3.itemSelected = function (event, item) {
            // handle selection if it's a valid keypress, or a click
            if (event.type === 'keypress' && (event.key === 'Enter' || event.key === ' ') || event.type === 'click') {
                _this3.onMenuItemClick(event, item);
            };
        };

        _this3.toggleMegaMenu = function (open) {
            _this3.setState({ megaMenuOpen: open });
            if (_this3.state.searchOpen) {
                _this3.toggleSearch(false);
            }
        };

        _this3.toggleSearch = function (open) {

            _this3.setState({ searchOpen: open }, function () {

                if (open) {
                    // focus the search field on open
                    _this3.searchRef.current.focus();
                } else {
                    // clear out search terms on close
                    _this3.searchRef.current.value = '';
                }
            });

            if (_this3.state.megaMenuOpen) {
                _this3.toggleMegaMenu(false);
            }
        };

        _this3.handleEnterPress = function (event) {
            if (event.key === 'Enter') {
                if (_this3.props.onSearch) {
                    _this3.props.onSearch(event.target.value);
                }
                _this3.toggleSearch(false);
            }
        };

        _this3.updateThemeMode = function () {
            // if the primary color was changed, go into 'themed' mode
            _this3.props.context.setThemed(_this3.props.theme.palette.primary.main !== lightTheme.palette.primary.main);
        };

        _this3.searchRef = _react2.default.createRef();
        return _this3;
    }

    /**
     * Invoked whenever a menu item is selected - from the menu bar, utility bar, or mega menu.
     *
     * @param {Event}   event The event
     * @param {object}  item  The selected item
     */


    /**
     * Handle header bar item selection.
     */


    _createClass(HeaderBar, [{
        key: 'neuterTabIndex',


        /**
         * Turn off tabbing to the given component.
         *
         * @param {Component} component Component to neuter
         * @return {Component}          Component with a neutered tab index
         */
        value: function neuterTabIndex(component) {
            return _react2.default.cloneElement(component, { tabIndex: -1 });
        }

        /**
         * Massages menu items until they meet our rigorous standards.
         *
         * @param  {Array.<object>} menuDefinition The menu definition to convert
         * @return {Array.<object>}                The same menu definition, with converted items
         */

    }, {
        key: 'convertItems',
        value: function convertItems(menuDefinition) {
            var _this4 = this;

            // ... and then strip the content of its tab indexes (so that *we* can fully control tabbing)
            menuDefinition.forEach(function (item) {
                if (item.items) {
                    item.items = item.items.map(function (subItem) {
                        return Object.assign({}, subItem, {
                            content: subItem.content.type ? _this4.neuterTabIndex(subItem.content) : subItem.content
                        });
                    });
                } else {
                    item.content = item.content.type ? _this4.neuterTabIndex(item.content) : item.content;
                }
            });

            return menuDefinition;
        }
    }, {
        key: 'createItems',
        value: function createItems(definition, responsiveType) {
            var _this5 = this;

            // create the menu bar
            var menuBar = definition.map(function (item) {

                // if the item has an explicit responsiveType, use that; otherwise use the provided
                // type (if any)
                var itemResponsiveType = item.responsiveType || responsiveType;

                // if this is a dropdown
                if (item.items) {
                    return _react2.default.createElement(
                        _.HeaderBarItem,
                        {
                            itemKey: item.key,
                            active: item.active,
                            key: item.key,
                            title: item.label,
                            disabled: _this5.state.megaMenuOpen,
                            responsiveType: itemResponsiveType
                        },
                        _react2.default.createElement(_.HeaderBarDropdown, {
                            onMenuItemClick: _this5.onMenuItemClick,
                            menuHeader: item.label,
                            menuItems: item.items.map(function (i) {
                                return Object.assign({
                                    value: i.key,
                                    label: i.content
                                }, i);
                            }),
                            dropdownMenuLabel: item.content,
                            secondaryDropdownMenuLabel: item.contentLabel,
                            disabled: _this5.state.megaMenuOpen
                        })
                    );

                    // otherwise it's a page link
                } else {
                    return _react2.default.createElement(
                        _.HeaderBarItem,
                        {
                            itemKey: item.key,
                            active: item.active,
                            key: item.key,
                            disabled: _this5.state.megaMenuOpen || item.disabled,
                            title: item.label,
                            'aria-label': item.label,
                            selected: item.selected,
                            responsiveType: itemResponsiveType,
                            className: 'headerbar-page-link',
                            alwaysShow: item.alwaysShow,
                            onClick: function onClick(event) {
                                _this5.onMenuItemClick(event, item);
                            },
                            onKeyUp: function onKeyUp(e) {
                                if (e.key === 'Enter') {
                                    _this5.onMenuItemClick(e, item);
                                }
                            }
                        },
                        item.content
                    );
                }
            });

            return menuBar;
        }

        /**
         * Opens/closes the mega menu.
         *
         * @param {boolean} open If true, opens the menu; otherwise closes it
         */


        /**
         * Opens/closes the search.
         *
         * @param {boolean} open If true, opens the search; otherwise closes it
         */


        /**
         * Handle key entry in the search field
         *
         * @param {Event} event The associated event
         */


        /**
         * Update the theme state of the HeaderBar.
         */

    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            this.updateThemeMode();
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            // update the theme mode if the primary theme color changed
            if (this.props.theme.palette.primary.main !== prevProps.theme.palette.primary.main) {
                this.updateThemeMode();
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this6 = this;

            var _props2 = this.props,
                classes = _props2.classes,
                children = _props2.children,
                edsContext = _props2.edsContext,
                position = _props2.position,
                menuDefinition = _props2.menuDefinition,
                utilityDefinition = _props2.utilityDefinition,
                megaMenuDefinition = _props2.megaMenuDefinition,
                onItemSelected = _props2.onItemSelected,
                megaMenuBadge = _props2.megaMenuBadge,
                megaMenuOnDemand = _props2.megaMenuOnDemand,
                responsiveType = _props2.responsiveType,
                showSearch = _props2.showSearch,
                UtilityBar = _props2.UtilityBar;
            var menuItemsLabel = this.props.menuItemsLabel;


            var classOverrides = {
                root: classes.root
            };

            var toolbar = void 0;

            // --- validate properties

            if (!this.props.autoGenerateMenus && megaMenuOnDemand) {
                console.warn('megaMenuOnDemand is only relevant if autoGenerateMenus === true');
            }

            if (utilityDefinition.length > 4) {
                console.warn('You should not have more than 4 utility items');
            }

            // header bar search item
            var SearchItem = _react2.default.createElement(
                _.HeaderBarItem,
                {
                    label: this.state.searchOpen ? edsContext.formatMessage('component.HeaderBar.closeSearch') : edsContext.formatMessage('component.HeaderBar.openSearch'),
                    title: this.state.searchOpen ? edsContext.formatMessage('component.HeaderBar.closeSearch') : edsContext.formatMessage('component.HeaderBar.openSearch'),
                    'aria-label': this.state.searchOpen ? edsContext.formatMessage('component.HeaderBar.closeSearch') : edsContext.formatMessage('component.HeaderBar.openSearch'),
                    isUtility: true,
                    itemKey: searchItemKey,
                    key: searchItemKey,
                    alwaysShow: true,
                    selected: this.state.searchOpen,
                    onClick: function onClick() {
                        return _this6.toggleSearch(!_this6.state.searchOpen);
                    }
                },
                _react2.default.createElement(_Icon2.default, {
                    name: this.state.searchOpen ? 'close' : 'search',
                    large: false
                })
            );

            // header bar search
            var searchBar = _react2.default.createElement(
                'div',
                { className: (0, _classnames2.default)(classes.searchBar, 'headerbar-search-bar') },
                _react2.default.createElement(_TextField2.default, {
                    fullWidth: true,
                    inputRef: this.searchRef,
                    InputProps: {
                        startAdornment: _react2.default.createElement(
                            _InputAdornment2.default,
                            { className: 'header-search-icon', classes: { root: classes.searchAdornment } },
                            _react2.default.createElement(_Icon2.default, { name: 'search', 'aria-hidden': 'true', role: 'img', className: classes.searchIconSize })
                        ),
                        onKeyPress: this.handleEnterPress.bind(this),
                        disableUnderline: true,
                        classes: {
                            root: classes.searchRoot,
                            input: classes.searchInput
                        },
                        className: (0, _classnames2.default)({ themed: this.props.context.isThemed() })
                    },
                    inputProps: {
                        'aria-label': edsContext.formatMessage('component.HeaderBar.search')
                    },
                    placeholder: edsContext.formatMessage('component.HeaderBar.search')
                })
            );

            var logoClassDef = (0, _classnames2.default)('headerbar-logo', classes.logoRoot, _defineProperty({}, classes.logoDisabled, this.state.megaMenuOpen));

            // if we've been told to auto-generate menus
            if (this.props.autoGenerateMenus) {

                // create the menu bar
                var menuBar = this.createItems(menuDefinition, responsiveType);

                var megaMenuIcon = _react2.default.createElement(_Icon2.default, {
                    name: this.state.megaMenuOpen ? 'close' : 'menu',
                    large: false
                });

                // add a badge to the megamenu item, if requested; simple notification if we
                // got a boolean, numeric if we got a number
                if (megaMenuBadge) {
                    if (typeof megaMenuBadge === 'boolean') {
                        megaMenuIcon = _react2.default.createElement(
                            _.NotificationBadge,
                            null,
                            megaMenuIcon
                        );
                    } else {
                        megaMenuIcon = _react2.default.createElement(
                            _.NotificationBadge,
                            { badgeContent: megaMenuBadge },
                            megaMenuIcon
                        );
                    }
                }

                // determine at what breakpoint the megamenu icon appears:

                // 1. if the megamenu is open, it always appears (because we want users to be able to close it in all circumstances)
                // 2. if megaMenuOnDemand is false, it always appears
                // 3. if megaMenuOnDemand is true, it appears only when menu items are hidden

                var megaMenuResponsiveType = null;

                if (megaMenuOnDemand && !this.state.megaMenuOpen) {

                    switch (responsiveType) {
                        case 'large':
                            megaMenuResponsiveType = 'medium-down';
                            break;
                        case 'medium':
                            megaMenuResponsiveType = 'small-down';
                            break;
                        case 'small':
                            megaMenuResponsiveType = 'xsmall-down';
                            break;
                        default:
                            break;

                    }
                }

                // always add megamenu to the utility bar
                var megaMenuUtilityDefinition = {
                    label: this.state.megaMenuOpen ? edsContext.formatMessage('component.HeaderBar.close') : edsContext.formatMessage('component.HeaderBar.megaMenu'),
                    key: megaMenuItemKey,
                    selected: this.state.megaMenuOpen,
                    alwaysShow: megaMenuResponsiveType === null,
                    content: megaMenuIcon,
                    responsiveType: megaMenuResponsiveType
                };

                var utilityItems = [].concat(_toConsumableArray(this.props.utilityDefinition));
                utilityItems.push(megaMenuUtilityDefinition);

                // create the utility bar (whose items hiden themselves at a specific responsiveType)
                var utilityBarItems = this.createItems(utilityItems, 'small');

                // grab all the menu items
                var menuItems = menuDefinition.filter(function (def) {
                    return def.items;
                });

                // grab all the page items; we'll be organizing them into a single block
                var pageItems = menuDefinition.filter(function (def) {
                    return !def.items;
                });

                var finalItemList = void 0;

                // if we need to add page items to the mega menu
                if (pageItems && pageItems.length > 0) {

                    // render all page items in a single block
                    var pageItemsSectionDefinition = [{
                        key: pageSectionId,
                        content: edsContext.formatMessage('component.HeaderBar.pageItems'),
                        items: pageItems
                    }];

                    // prepend the page items
                    finalItemList = menuItems.length > 0 ? pageItemsSectionDefinition.concat(menuItems) : pageItemsSectionDefinition;
                } else {
                    finalItemList = menuItems;
                }

                // put together everything that goes in the mega memu
                var fullMegaMenuDefinition = megaMenuDefinition && megaMenuDefinition.length > 0 ? finalItemList.concat(megaMenuDefinition) : finalItemList;

                // ... and massage them for use in the megamenu
                fullMegaMenuDefinition = this.convertItems(fullMegaMenuDefinition);

                toolbar = _react2.default.createElement(
                    _Toolbar2.default,
                    {
                        disableGutters: true,
                        classes: { root: classes.headerBarToolbar },
                        className: this.state.searchOpen ? classes.searchOpen : ''
                    },
                    _react2.default.createElement(
                        'div',
                        { className: logoClassDef },
                        this.props.logo ? this.props.logo : _react2.default.createElement(
                            _.HeaderBarLogo,
                            null,
                            _react2.default.createElement(_.Logo, { className: classes.logo })
                        )
                    ),
                    showSearch && searchBar,
                    _react2.default.createElement(
                        'div',
                        { className: (0, _classnames2.default)(classes.menuBar, 'menu-bar') },
                        menuBar
                    ),
                    _react2.default.createElement(
                        _.HeaderUtilityBar,
                        {
                            className: 'headerbar-utility-bar',
                            isThemed: this.props.context.isThemed(),
                            SearchItem: this.props.showSearch ? SearchItem : null
                        },
                        utilityBarItems
                    ),
                    _react2.default.createElement(_.MegaMenu, {
                        open: this.state.megaMenuOpen,
                        onClose: function onClose() {
                            return _this6.toggleMegaMenu(false);
                        },
                        menuDefinition: fullMegaMenuDefinition,
                        utilityDefinition: utilityDefinition,
                        onItemSelected: onItemSelected,
                        parentElementRef: this.props.parentElementRef,
                        useFluidTransition: true,
                        menuItemsHeader: menuItemsLabel
                    })
                );

                // no auto-build, just lay out the toolbar
            } else {

                // if a utility bar was provided, decorate it with internal props
                var utiltyBar = UtilityBar ? _react2.default.cloneElement(UtilityBar, {
                    isThemed: this.props.context.isThemed(),
                    className: 'headerbar-utility-bar',
                    SearchItem: showSearch ? SearchItem : null
                }) : null;

                // tell the headerbar's direct children (ie, menu bar items) what kind of responsive
                // they need to be
                var decoratedChildren = _react2.default.Children.map(children, function (child) {

                    // is this a headerbar item?
                    var isHeaderBarItem = false;
                    if (child && child.type.muiName) {
                        isHeaderBarItem = child && child.type.muiName.indexOf('HeaderBarItem') > -1;
                    }

                    // is the responsiveType prop already set on this item?
                    var hasResponsiveType = isHeaderBarItem && (0, _get2.default)(child, 'props.responsiveType', false);
                    if (isHeaderBarItem && !hasResponsiveType) {
                        return _react2.default.cloneElement(child, {
                            responsiveType: responsiveType
                        });
                    } else {
                        return child;
                    }
                });

                toolbar = _react2.default.createElement(
                    _Toolbar2.default,
                    {
                        classes: { root: classes.headerBarToolbar },
                        className: this.state.searchOpen ? classes.searchOpen : '',
                        disableGutters: true },
                    this.props.logo && _react2.default.createElement(
                        'div',
                        { className: logoClassDef },
                        this.props.logo
                    ),
                    showSearch && searchBar,
                    _react2.default.createElement(
                        'div',
                        { className: (0, _classnames2.default)(classes.menuBar, 'menu-bar') },
                        decoratedChildren
                    ),
                    utiltyBar
                );
            }

            return _react2.default.createElement(
                HeaderBarContext.Consumer,
                null,
                function (context) {
                    return _react2.default.createElement(
                        _AppBar2.default,
                        {
                            position: position,
                            className: (0, _classnames2.default)({ 'themed': _this6.props.context.isThemed() }),
                            classes: classOverrides
                        },
                        toolbar,
                        context.state.isDropDownOpen && _react2.default.createElement('div', { className: classes.overlay })
                    );
                }
            );
        }
    }]);

    return HeaderBar;
}(_react2.default.Component);

/**
 * Global Navigation Header Bar component foo.
 */


var HeaderBarConsumer = _react2.default.forwardRef(function (props, ref) {
    var onActiveItemChanged = props.onActiveItemChanged,
        remainingProps = _objectWithoutProperties(props, ['onActiveItemChanged']);

    return _react2.default.createElement(
        HeaderBarProvider,
        { onActiveItemChanged: onActiveItemChanged },
        _react2.default.createElement(
            HeaderBarContext.Consumer,
            null,
            function (context) {
                return _react2.default.createElement(HeaderBar, Object.assign({}, remainingProps, { context: context, ref: ref }));
            }
        )
    );
});

HeaderBarProvider.propTypes = {
    children: _propTypes2.default.object
};

HeaderBar.propTypes = {

    /** Useful to extend the style applied to components. */
    classes: _propTypes2.default.object.isRequired,

    /** The content of the component. Common children are HeaderBarItem, HeaderBarLogo or HeaderBarDropdown. */
    children: _propTypes2.default.node,

    /** The positioning type. Note: sticky is not universally supported and will fall back to static when unavailable. */
    position: _propTypes2.default.string,

    /**
     * If the megamenu should appear within a DOM element (and be constrained within its borders), you must
     * provide a [ref](https://reactjs.org/docs/refs-and-the-dom.html) representing the containing element.
     */
    parentElementRef: _propTypes2.default.object,

    /**
     * If true, the header bar will auto-generate menus, utility items, and the Mega Menu, based on the given set of
     * definitions.
     */
    autoGenerateMenus: _propTypes2.default.bool,

    /**
     * Items that should appear in the headerbar menu. See class description for definition format.
     */
    menuDefinition: _propTypes2.default.arrayOf(_propTypes2.default.object),

    /**
     * Items that should appear in the mega menu (but **not** in the main menu). See class description
     * for definition format.
     */
    megaMenuDefinition: _propTypes2.default.arrayOf(_propTypes2.default.object),

    /**
     * Items that should appear in the headerbar's utility area. See class description for definition format.
     */
    utilityDefinition: _propTypes2.default.arrayOf(_propTypes2.default.object),

    /**
     * Invoked when an item is selected. The function is passed the selected item.
     *
     * **Note**: This function is not called if the item content is a link (eg, an anchor tag, or
     * a `<NavLink>` component).
     */
    onItemSelected: _propTypes2.default.func,

    /**
     * Whether to display a badge on the megamenu. Accepts a boolean or a number.
     *
     * - `true` to display a simple notification badge;
     * - A number to show a numerical badge.
     */
    megaMenuBadge: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.number]),

    /**
     * The header label for menu items. Used in the MegaMenu.
     */
    menuItemsLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),

    /**
     * The logo to use in the header bar.
     */
    logo: _propTypes2.default.node,

    /**
     * At what breakpoint to hide all menu bar items on the header bar.
     *
     * Note that this **excludes** utility nav items, which will always hide at the 'small' breakpoint, by default. For
     * manual header bars, this can be overridden in the `HeaderUtilityBar` component.
     *
     * Valid values are:
     *
     * - `none` - Never hide anything
     * - `small` - Hide items at the small breakpoint (and above)
     * - `medium` - Hide items at the medium breakpoint (and above)
     * - `large` - Hide items at the large breakpoint (and above)
     */
    responsiveType: _propTypes2.default.oneOf(['none', 'small', 'medium', 'large']),

    /**
     * Whether to show the search utility (and associated search field) in the header bar
     */
    showSearch: _propTypes2.default.bool,

    /**
     * Function to call when a search is submitted. Is passed a single argument, the search term.
     */
    onSearch: _propTypes2.default.func,

    /**
     * A utility bar. Will be pinned to the right side of the `HeaderBar`.
     */
    UtilityBar: _propTypes2.default.node,

    /**
     * Fires when the active item changes. The callback is provided with a single parameter, the currently-active item.
     */
    onActiveItemChanged: _propTypes2.default.func,

    /**
     * If true, only shows the megaMenu icon when it's needed -- that is, when menu items are hidden.
     *
     * The breakpoint at which menu items are hidden is controlled by the `responsiveType` prop.
     *
     * **Note**: Only valid when `autoGenerateMenus` is `true`.
     */
    megaMenuOnDemand: _propTypes2.default.bool,

    /**
     * @ignore
     * Provided through wrapper.
     */
    context: _propTypes2.default.object.isRequired,

    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,

    /**
     * @ignore
     * Provided through wrapper.
     */
    theme: _propTypes2.default.object.isRequired

};

HeaderBarConsumer.propTypes = {
    classes: _propTypes2.default.object.isRequired,
    children: _propTypes2.default.node,
    position: _propTypes2.default.string,
    onActiveItemChanged: _propTypes2.default.func
};

HeaderBarConsumer.defaultProps = {
    position: 'static'
};

HeaderBar.defaultProps = {
    autoGenerateMenus: false,
    menuItemsLabel: 'Ellucian',
    megaMenuBadge: false,
    responsiveType: 'medium',
    showSearch: true,
    utilityDefinition: [],
    megaMenuOnDemand: false
};

exports.HeaderBarContext = HeaderBarContext;
exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles, { withTheme: true })(HeaderBarConsumer));