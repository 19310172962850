import React from 'react';

var SvgNoHistory = function SvgNoHistory(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                d: "M59.9999482,29.9433188 C60.0305009,46.4723801 46.5507259,59.9883716 30.0217222,60 C22.8828233,60.0050651 16.3254153,57.5165124 11.1723194,53.3575131 C9.83236201,52.2760596 9.7322009,50.2678706 10.9498601,49.0502072 L12.3128013,47.6872612 C13.3542108,46.6458481 15.0167885,46.5318963 16.1705769,47.4472608 C19.9671429,50.4599677 24.7718523,52.2581564 29.9999481,52.2581564 C42.302951,52.2581564 52.2579741,42.3011631 52.2579741,30.0000527 C52.2579741,17.6970069 42.3010155,7.74194909 29.9999481,7.74194909 C24.0950389,7.74194909 18.7319434,10.0365902 14.7498132,13.782121 L20.8893993,19.9217286 C22.1087521,21.1410855 21.2451648,23.2258473 19.5208936,23.2258473 L1.93548052,23.2258473 C0.866490436,23.2258473 0,22.3593538 0,21.29036 L0,3.70488554 C0,1.98060832 2.08475446,1.11701809 3.30410718,2.33625411 L9.2765162,8.30868396 C14.6623537,3.16161846 21.9618975,0 29.9999481,0 C46.5495162,0 59.9692914,13.40083 59.9999482,29.9433188 Z M38.1721376,39.8824346 L39.3788115,38.3592048 C40.3784972,37.0973241 40.1469406,35.2787349 38.861648,34.2973794 L33.8618683,30.4794154 L33.8618683,17.8944984 C33.8618683,16.295891 32.5419343,15 30.9136677,15 L28.9482006,15 C27.319934,15 26,16.295891 26,17.8944984 L26,34.2545649 L34.0349522,40.3901778 C35.3202449,41.3715334 37.1724519,41.1443153 38.1721376,39.8824346 L38.1721376,39.8824346 Z",
                id: "Theme",
                fill: "currentColor"
            }),
            React.createElement("path", {
                d: "M59.9999482,29.9433188 C60.0305009,46.4723801 46.5507259,59.9883716 30.0217222,60 C22.8828233,60.0050651 16.3254153,57.5165124 11.1723194,53.3575131 C9.83236201,52.2760596 9.7322009,50.2678706 10.9498601,49.0502072 L12.3128013,47.6872612 C13.3542108,46.6458481 15.0167885,46.5318963 16.1705769,47.4472608 C19.9671429,50.4599677 24.7718523,52.2581564 29.9999481,52.2581564 C42.302951,52.2581564 52.2579741,42.3011631 52.2579741,30.0000527 C52.2579741,17.6970069 42.3010155,7.74194909 29.9999481,7.74194909 C24.0950389,7.74194909 18.7319434,10.0365902 14.7498132,13.782121 L20.8893993,19.9217286 C22.1087521,21.1410855 21.2451648,23.2258473 19.5208936,23.2258473 L1.93548052,23.2258473 C0.866490436,23.2258473 0,22.3593538 0,21.29036 L0,3.70488554 C0,1.98060832 2.08475446,1.11701809 3.30410718,2.33625411 L9.2765162,8.30868396 C14.6623537,3.16161846 21.9618975,0 29.9999481,0 C46.5495162,0 59.9692914,13.40083 59.9999482,29.9433188 Z M38.1721376,39.8824346 L39.3788115,38.3592048 C40.3784972,37.0973241 40.1469406,35.2787349 38.861648,34.2973794 L33.8618683,30.4794154 L33.8618683,17.8944984 C33.8618683,16.295891 32.5419343,15 30.9136677,15 L28.9482006,15 C27.319934,15 26,16.295891 26,17.8944984 L26,34.2545649 L34.0349522,40.3901778 C35.3202449,41.3715334 37.1724519,41.1443153 38.1721376,39.8824346 L38.1721376,39.8824346 Z",
                id: "Lighten",
                fill: "#FFFFFF",
                opacity: "0.88"
            }),
            React.createElement("path", {
                d: "M1.44578313,2.04819277 L2.65060241,2.04819277 C3.44903614,2.04819277 4.09638554,2.69554217 4.09638554,3.4939759 L4.09638554,14.8945783 C9.30072289,6.02975904 18.9444578,0.0861445783 29.9750602,0.120453374 C46.4072289,0.172048193 59.7846988,13.6155422 59.759073,30.0477108 C59.733253,46.5278313 46.3655422,59.8795181 29.8795181,59.8795181 C22.1775904,59.8795181 15.1563855,56.9654217 9.85807229,52.179759 C9.24204819,51.6233735 9.21301205,50.6663855 9.8,50.0793976 L10.6516867,49.2277108 C11.1942169,48.6851807 12.0672289,48.659759 12.6374699,49.173012 C17.2026506,53.2816867 23.2451807,55.7831325 29.8795181,55.7831325 C44.0655422,55.7831325 55.6626506,44.2974699 55.6626506,30 C55.6626506,15.8139759 44.176988,4.21686747 29.8795181,4.21686747 C19.8961446,4.21686747 11.236506,9.88879518 6.95313253,18.1927711 L18.7951807,18.1927711 C19.5936145,18.1927711 20.2409639,18.8401205 20.2409639,19.6385542 L20.2409639,20.8433735 C20.2409639,21.6418072 19.5936145,22.2891566 18.7951807,22.2891566 L1.44578313,22.2891566 C0.647349398,22.2891566 0,21.6418072 0,20.8433735 L0,3.4939759 C0,2.69554217 0.647349398,2.04819277 1.44578313,2.04819277 Z M40.1984337,40.0283133 L40.7654217,39.2487952 C41.2350602,38.603012 41.0922892,37.6987952 40.446506,37.2291566 L31.8072289,30.9461446 L31.8072289,13.1325301 C31.8072289,12.3340964 31.1598795,11.686747 30.3614458,11.686747 L29.3975904,11.686747 C28.5991566,11.686747 27.9518072,12.3340964 27.9518072,13.1325301 L27.9518072,32.9092771 L38.1787952,40.3471084 C38.8245783,40.8168675 39.7287952,40.6740964 40.1984337,40.0283133 L40.1984337,40.0283133 Z",
                id: "Shape",
                fill: "currentColor"
            })
        )
    );
};

export default SvgNoHistory;