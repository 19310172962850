var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import React from 'react';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    anchor: {
        '&:focus': {
            '& *': {
                backgroundColor: Tokens.colorCtaBlueTint
            },
            outline: 'none'
        },
        '&:hover': {
            color: Tokens.colorBrandCtaHover,
            textDecoration: 'underline'
        },
        color: Tokens.colorBrandCtaBase,
        cursor: 'pointer',
        fontSize: 'inherit',
        textDecoration: 'underline'
    }
});
const Link = (props) => {
    const { classes, className, children, href } = props, rest = __rest(props, ["classes", "className", "children", "href"]);
    let hrefProp = '#';
    if (href) {
        hrefProp = href;
    }
    let classLink = classes.anchor;
    if (className) {
        classLink = classnames(className, classes.anchor);
    }
    return (React.createElement("a", Object.assign({ className: classLink, href: hrefProp }, rest), children));
};
export default withStyles(styles)(Link);
