'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _withMobileDialog = require('@material-ui/core/withMobileDialog');

var _withMobileDialog2 = _interopRequireDefault(_withMobileDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _withMobileDialog2.default;