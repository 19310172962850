'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextLink = require('./../../TextLink');

var _TextLink2 = _interopRequireDefault(_TextLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ErrorPageAction = function ErrorPageAction(_ref) {
    var action = _ref.action,
        text = _ref.text;

    var actionLink = _react2.default.createElement(_TextLink2.default, { text: text });

    if (action && typeof action === 'string') {
        actionLink = _react2.default.createElement(_TextLink2.default, { url: action, text: text });
    } else if (action && (typeof action === 'undefined' ? 'undefined' : _typeof(action)) === 'object') {
        actionLink = action;
    }

    return actionLink;
};

exports.default = ErrorPageAction;