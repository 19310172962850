'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _IconButton = require('../IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _TextField = require('../TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _TablePagination = require('@material-ui/core/TablePagination');

var _TablePagination2 = _interopRequireDefault(_TablePagination);

var _ChevronDown = require('@eui/ds-icons/lib/ChevronDown');

var _ChevronDown2 = _interopRequireDefault(_ChevronDown);

var _ArrowEndLeft = require('@eui/ds-icons/lib/ArrowEndLeft');

var _ArrowEndLeft2 = _interopRequireDefault(_ArrowEndLeft);

var _ArrowEndRight = require('@eui/ds-icons/lib/ArrowEndRight');

var _ArrowEndRight2 = _interopRequireDefault(_ArrowEndRight);

var _ChevronRight = require('@eui/ds-icons/lib/ChevronRight');

var _ChevronRight2 = _interopRequireDefault(_ChevronRight);

var _ChevronLeft = require('@eui/ds-icons/lib/ChevronLeft');

var _ChevronLeft2 = _interopRequireDefault(_ChevronLeft);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _EDSContext = require('../EDSContext/EDSContext');

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// PaginationAction Styles
var actionStyles = function actionStyles(theme) {
    var _root;

    return {
        root: (_root = {
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            order: 2
        }, _defineProperty(_root, theme.breakpoints.between('xs', 'sm'), {
            flex: '1 1 100%',
            textAlign: 'center',
            paddingBottom: _tokens.spacingXSmall,
            borderBottom: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[300],
            marginBottom: _tokens.spacingXSmall,
            justifyContent: 'space-between'
        }), _defineProperty(_root, '& $paginationButtons', {
            color: theme.palette.grey[500] + ' ',
            fill: theme.palette.grey[500] + ' ',
            backgroundColor: 'transparent ',
            '&:hover': {
                backgroundColor: 'transparent ',
                color: theme.palette.ctaColor.base + ' ',
                fill: theme.palette.ctaColor.base + ' '
            },
            '&:active': {
                backgroundColor: 'transparent ',
                color: theme.palette.ctaColor.hover + ' ',
                fill: theme.palette.ctaColor.hover + ' '
            },
            '&:disabled': {
                backgroundColor: 'transparent ',
                fill: theme.palette.grey[400] + ' '
            },
            // NOTE: Could create an 'icon' classes prop to handle this sizing
            '& svg': {
                width: 16,
                height: 16
            }
        }), _defineProperty(_root, '& $textField', {
            // NOTE: Remove when Textfield supports us overriding the classes prop
            width: '40px',
            marginLeft: '' + _tokens.spacingXSmall,
            marginRight: '' + _tokens.spacingXSmall,
            verticalAlign: 'baseline',
            '& input': {
                padding: '0.1rem',
                textAlign: 'center',
                fontSize: theme.typography.caption.fontSize
            }
        }), _root),
        typography: {
            display: 'flex',
            alignSelf: 'center'
        },
        // NOTE: This should be sent to the TextField classes.root
        // But our implementation doesn't allow this
        // textFieldRoot: {
        //     width: 32,
        //     marginLeft: 8,
        //     marginRight: 8,
        //     verticalAlign: 'baseline',
        // },
        // Remove if TextField can support classes override
        textField: {},
        // Remove after transparent IconButtons variant is created
        paginationButtons: {},
        pageNumberInput: _defineProperty({
            display: 'flex',
            margin: '0 ' + _tokens.spacingXxLarge
        }, theme.breakpoints.between('xs', 'sm'), {
            margin: 0
        }),
        buttonSpacer: _defineProperty({
            marginRight: _tokens.spacingMedium
        }, theme.breakpoints.between('xs', 'sm'), {
            marginRight: 0
        })
    };
};

var PaginationActions = function (_React$Component) {
    _inherits(PaginationActions, _React$Component);

    function PaginationActions() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, PaginationActions);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = PaginationActions.__proto__ || Object.getPrototypeOf(PaginationActions)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            pageNumberInput: _this.props.page + 1 || 1 // zero-based number
        }, _this.handleFirstPageButtonClick = function (event) {
            _this.setState({
                pageNumberInput: 1
            }, function () {
                return _this.props.onChangePage(event, 0);
            });
        }, _this.handleBackButtonClick = function (event) {
            var page = _this.props.page - 1;

            _this.setState({
                pageNumberInput: page + 1
            }, function () {
                return _this.props.onChangePage(event, page);
            });
        }, _this.handleNextButtonClick = function (event) {
            var page = _this.props.page + 1;
            _this.setState({
                pageNumberInput: page + 1
            }, function () {
                return _this.props.onChangePage(event, page);
            });
        }, _this.handleLastPageButtonClick = function (event) {
            // returns 0-based
            var page = Math.max(0, Math.ceil(_this.props.count / _this.props.rowsPerPage) - 1);

            // display value non-0 based
            _this.setState({
                pageNumberInput: page + 1
            }, function () {
                return _this.props.onChangePage(event, page);
            });
        }, _this.handleTextFieldOnChange = function (event) {
            var value = event.target.value;
            if (!isNaN(Number(value))) {
                _this.setState({
                    pageNumberInput: value
                });
            }
        }, _this.handleTextFieldOnBlur = function (event) {
            event.preventDefault();
            if (_this.paginationPageTextField.value - 1 !== _this.props.page) {
                _this.handlePageChange(event);
            }
        }, _this.handleTextFieldFocus = function () {
            _this.paginationPageTextField.select();
        }, _this.handleEnterKeyPress = function (event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (_this.paginationPageTextField.value - 1 !== _this.props.page) {
                    _this.handlePageChange(event);
                }
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(PaginationActions, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            var _this2 = this;

            if (prevProps.rowsPerPage !== this.props.rowsPerPage || prevProps.count !== this.props.count) {
                this.setState({
                    pageNumberInput: 1
                }, function () {
                    return _this2.props.onChangePage({}, 0);
                });
            }
        }
    }, {
        key: 'handlePageChange',


        // Shared function to change page or alter state
        // Receives `value` from the TextField
        value: function handlePageChange(event) {
            var _this3 = this;

            // 1-based
            var value = this.paginationPageTextField.value;

            // 0-based
            var totalNumberOfPages = Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1);

            if (value <= 0) {
                // If value is zero or negative
                // Go to first page
                this.setState({ pageNumberInput: 1 }, function () {
                    return _this3.props.onChangePage(event, 0);
                });
            } else if (value >= 1 && value <= totalNumberOfPages) {
                // If value is greater than 1
                // and value is less than or equal totalNumberOfPages
                this.setState({
                    pageNumberInput: value
                }, this.props.onChangePage(event, Number(value) - 1));
            } else if (value >= totalNumberOfPages + 1) {
                // If value is greater than total number of pages
                // Go to last page
                this.setState({
                    pageNumberInput: totalNumberOfPages + 1
                }, this.props.onChangePage(event, totalNumberOfPages));
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this4 = this;

            var _props = this.props,
                classes = _props.classes,
                count = _props.count,
                page = _props.page,
                rowsPerPage = _props.rowsPerPage,
                theme = _props.theme,
                edsContext = _props.edsContext;
            var pageNumberInput = this.state.pageNumberInput;


            var totalNumberOfPages = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
            var disablePreviousButtons = page === 0;
            var disableNextButtons = page >= Math.ceil(count / rowsPerPage) - 1;

            // TODO: Change IconButtons to different transparent variant
            return _react2.default.createElement(
                'div',
                { className: '' + classes.root },
                _react2.default.createElement(
                    _IconButton2.default,
                    {
                        onClick: this.handleFirstPageButtonClick,
                        disabled: disablePreviousButtons,
                        color: 'secondary',
                        'aria-label': edsContext.formatMessage('component.Pagination.firstPage'),
                        title: edsContext.formatMessage('component.Pagination.firstPage'),
                        className: classes.paginationButtons + ' ' + classes.buttonSpacer
                    },
                    theme.direction === 'rtl' ? _react2.default.createElement(_ArrowEndRight2.default, null) : _react2.default.createElement(_ArrowEndLeft2.default, null)
                ),
                _react2.default.createElement(
                    _IconButton2.default,
                    {
                        onClick: this.handleBackButtonClick,
                        disabled: disablePreviousButtons,
                        color: 'secondary',
                        'aria-label': edsContext.formatMessage('component.Pagination.previousPage'),
                        title: edsContext.formatMessage('component.Pagination.previousPage'),
                        className: classes.paginationButtons
                    },
                    theme.direction === 'rtl' ? _react2.default.createElement(_ChevronRight2.default, null) : _react2.default.createElement(_ChevronLeft2.default, null)
                ),
                _react2.default.createElement(
                    'span',
                    { className: classes.pageNumberInput + ' page-input' },
                    _react2.default.createElement(
                        _Typography2.default,
                        { variant: 'body3', className: classes.typography },
                        edsContext.formatMessage('component.Pagination.page')
                    ),
                    _react2.default.createElement(_TextField2.default, {
                        className: (0, _classnames2.default)(classes.textField),
                        inputRef: function inputRef(input) {
                            return _this4.paginationPageTextField = input;
                        },
                        value: pageNumberInput,
                        inputProps: { 'aria-label': '' + pageNumberInput },
                        onChange: this.handleTextFieldOnChange,
                        margin: 'normal',
                        onKeyPress: this.handleEnterKeyPress,
                        onFocus: this.handleTextFieldFocus,
                        onBlur: this.handleTextFieldOnBlur,
                        disabled: totalNumberOfPages === 0
                    }),
                    _react2.default.createElement(
                        _Typography2.default,
                        { variant: 'body3', className: classes.typography },
                        edsContext.formatMessage('component.Pagination.ofTotalCount', { count: totalNumberOfPages + 1 })
                    )
                ),
                _react2.default.createElement(
                    _IconButton2.default,
                    {
                        onClick: this.handleNextButtonClick,
                        disabled: disableNextButtons,
                        color: 'secondary',
                        'aria-label': edsContext.formatMessage('component.Pagination.nextPage'),
                        title: edsContext.formatMessage('component.Pagination.nextPage'),
                        className: classes.paginationButtons + ' ' + classes.buttonSpacer
                    },
                    theme.direction === 'rtl' ? _react2.default.createElement(_ChevronLeft2.default, null) : _react2.default.createElement(_ChevronRight2.default, null)
                ),
                _react2.default.createElement(
                    _IconButton2.default,
                    {
                        onClick: this.handleLastPageButtonClick,
                        disabled: disableNextButtons,
                        color: 'secondary',
                        'aria-label': edsContext.formatMessage('component.Pagination.lastPage'),
                        title: edsContext.formatMessage('component.Pagination.lastPage'),
                        className: classes.paginationButtons
                    },
                    theme.direction === 'rtl' ? _react2.default.createElement(_ArrowEndLeft2.default, null) : _react2.default.createElement(_ArrowEndRight2.default, null)
                )
            );
        }
    }]);

    return PaginationActions;
}(_react2.default.Component);

PaginationActions.propTypes = {
    classes: _propTypes2.default.object.isRequired,
    count: _propTypes2.default.number.isRequired,
    onChangePage: _propTypes2.default.func.isRequired,
    page: _propTypes2.default.number.isRequired,
    rowsPerPage: _propTypes2.default.number.isRequired,
    theme: _propTypes2.default.object.isRequired,
    edsContext: _propTypes2.default.object.isRequired
};

var PaginationActionsWrapped = (0, _EDSContext.withEDSContext)((0, _styles2.default)(actionStyles, { withTheme: true })(PaginationActions));

// Pagination Styles
var styles = function styles(theme) {
    return {
        root: _defineProperty({
            // Target the "Total results..." text
            '& $caption:nth-last-child(2)': _defineProperty({
                order: 4,
                marginRight: 0,
                marginLeft: 'auto'
            }, theme.breakpoints.between('xs', 'sm'), {
                marginRight: _tokens.spacingXSmall
            })
        }, theme.breakpoints.between('xs', 'sm'), {
            width: _tokens.widthFluid,
            paddingTop: _tokens.spacingXSmall,
            paddingBottom: _tokens.spacingLarge
            // TODO: To be used if it needs to be attached to bottom or made configurable
            // bottom: "0",
            // zIndex: "99",
            // background: "rgba(255, 255, 255, 1)",
            // position: "fixed",
            // left: 0,
            // boxShadow: "0px -3px 10px #ccc",
        }),
        toolbar: _defineProperty({
            padding: 0,
            margin: '0 ' + _tokens.spacingSmall + ' ' + _tokens.spacingSmall + ' ' + _tokens.spacingXSmall
        }, theme.breakpoints.between('xs', 'sm'), {
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            margin: '0 ' + _tokens.spacingXSmall + ' 0 ' + _tokens.spacingXSmall
        }),
        spacer: {
            flex: 'none',
            order: 1
        },
        caption: {
            color: theme.palette.grey[500],
            order: 3,
            marginRight: _tokens.spacingSmall,
            marginLeft: _tokens.spacingXSmall
        },
        selectRoot: _defineProperty({
            marginRight: _tokens.spacingXxLarge
        }, theme.breakpoints.between('xs', 'sm'), {
            marginRight: 0
        }),
        selectIcon: {
            top: _tokens.spacingXSmall,
            right: _tokens.spacingXxSmall,
            fill: theme.palette.grey[500]
        },
        select: {
            fontSize: theme.typography.caption.fontSize,
            paddingRight: _tokens.spacingLarge,
            '&:focus': {
                borderRadius: _tokens.borderRadiusSmall,
                boxShadow: _tokens.boxShadowFocusInset,
                backgroundColor: 'transparent'
            }
        },
        input: {
            order: 4
        },
        menuList: {
            border: theme.palette.ctaColor.base + ' ' + _tokens.borderWidthThin + ' solid',
            borderRadius: _tokens.borderRadiusMedium
        },
        menuListItem: {
            '& :first-child': {
                borderTopRightRadius: _tokens.borderRadiusSmall,
                borderTopLeftRadius: _tokens.borderRadiusSmall
            },
            '& :last-child': {
                borderBottomRightRadius: _tokens.borderRadiusSmall,
                borderBottomLeftRadius: _tokens.borderRadiusSmall
            }
        },
        menuItem: {
            padding: _tokens.spacingXSmall + ' ' + _tokens.spacingXSmall2,
            borderBottom: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[300],
            // Had to use `!important` below to override styles as MUI Pagination is adding inline styles.
            minWidth: _tokens.spacingLarge + ' !important',
            '&:last-child': {
                borderBottom: 'transparent ' + _tokens.borderWidthThin + ' solid'
            },
            fontSize: _tokens.fontSizeDefault,
            '&:hover': {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[250]
            },
            //Using aria-selected as a workaround as accessing the selected styles of menuItem was not possible
            '&[aria-selected=true]': {
                backgroundColor: theme.palette.ctaColor.base,
                color: theme.palette.grey[100],
                '&:hover': {
                    backgroundColor: theme.palette.ctaColor.active
                }
            }
        },

        menuPaper: {
            boxShadow: 'none'
        }
    };
};

/**
 * Use `Pagination` to allow users to navigate large data sets by breaking out the data into smaller, more consumable "pages."
 * @done true
 * @updated true
 * @versionAdded v0.0.8
 * @examples
 *  PaginationExamples
 *  PaginationWithListExamples
 *  PaginationWithTableExamples
 */

var Pagination = function (_React$Component2) {
    _inherits(Pagination, _React$Component2);

    function Pagination() {
        _classCallCheck(this, Pagination);

        return _possibleConstructorReturn(this, (Pagination.__proto__ || Object.getPrototypeOf(Pagination)).apply(this, arguments));
    }

    _createClass(Pagination, [{
        key: 'render',
        value: function render() {
            var _props2 = this.props,
                classes = _props2.classes,
                rowsPerPageOptions = _props2.rowsPerPageOptions,
                count = _props2.count,
                edsContext = _props2.edsContext,
                rowsPerPageShowAll = _props2.rowsPerPageShowAll,
                labelRowsPerPage = _props2.labelRowsPerPage,
                SelectProps = _props2.SelectProps,
                rest = _objectWithoutProperties(_props2, ['classes', 'rowsPerPageOptions', 'count', 'edsContext', 'rowsPerPageShowAll', 'labelRowsPerPage', 'SelectProps']);

            // Append `count` to the Select input


            var _rowsPerPageOption = rowsPerPageOptions;
            if (rowsPerPageShowAll) {
                _rowsPerPageOption = [].concat(_toConsumableArray(rowsPerPageOptions), [count]);
            }

            var classOverrides = {
                root: classes.root,
                toolbar: classes.toolbar,
                spacer: classes.spacer,
                caption: classes.caption,
                selectRoot: classes.selectRoot,
                select: classes.select,
                selectIcon: classes.selectIcon,
                input: classes.input,
                menuItem: classes.menuItem
            };

            var selectProps = Object.assign({
                IconComponent: _ChevronDown2.default,
                MenuProps: {
                    classes: {
                        paper: classes.menuPaper
                    },
                    MenuListProps: {
                        className: classes.menuList,
                        disablePadding: true,
                        classes: {
                            root: classes.menuListItem
                        }
                    }
                }
            }, SelectProps);

            return _react2.default.createElement(_TablePagination2.default, Object.assign({
                ActionsComponent: PaginationActionsWrapped,
                classes: classOverrides,
                className: classes.root,
                count: count,
                rowsPerPageOptions: _rowsPerPageOption,
                labelRowsPerPage: labelRowsPerPage === Pagination.defaultProps.labelRowsPerPage ? // translate unless a label was provided
                edsContext.formatMessage('component.Pagination.perPage') : labelRowsPerPage,
                labelDisplayedRows: function labelDisplayedRows(rows) {
                    return edsContext.formatMessage('component.Pagination.totalResults') + ' ' + rows.count;
                },
                SelectProps: selectProps
            }, rest));
        }
    }]);

    return Pagination;
}(_react2.default.Component);

;

Pagination.muiName = 'Pagination';

Pagination.propTypes = {
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     * EX: Use TableCell if used within Table
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * The total number of rows.
     */
    count: _propTypes2.default.number.isRequired,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,
    /**
     * Customize the rows per page label. Invoked with a `{ from, to, count, page }`
     * object.
     */
    labelRowsPerPage: _propTypes2.default.node,
    /**
     * Callback fired when the page is changed.
     *
     * @param {object} event The event source of the callback
     * @param {number} page The page selected
     */
    onChangePage: _propTypes2.default.func.isRequired,
    /**
     * Callback fired when the number of rows per page is changed.
     *
     * @param {object} event The event source of the callback
     */
    onChangeRowsPerPage: _propTypes2.default.func,
    /**
     * The zero-based index of the current page.
     */
    page: _propTypes2.default.number,
    /**
     * The number of rows per page.
     */
    rowsPerPage: _propTypes2.default.number.isRequired,
    /**
     * Customizes the options of the rows per page select field. If less than two options are
     * available, no select field will be displayed.
     */
    rowsPerPageOptions: _propTypes2.default.array,
    /**
     * Set to `true` to render a total count option in the `Select` in the last index.
     */
    rowsPerPageShowAll: _propTypes2.default.bool,
    /**
     * Properties applied to the rows per page `Select` element.
     */
    SelectProps: _propTypes2.default.object
};

// If default props are needed, they must be set here
Pagination.defaultProps = {
    labelRowsPerPage: 'Per page:',
    component: 'div',
    rowsPerPageOptions: [10, 20],
    rowsPerPageShowAll: false
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(Pagination));