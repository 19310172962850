var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { withEDSContext } from './../EDSContext/EDSContext';
import { default as withStyles } from '../styles';
import { MobileStepper as MuiMobileStepper } from '@material-ui/core';
import MobileStepperCounter from './MobileStepperCounter';
import { MOBILE_STEPPER_LABEL_CLASSNAME } from './internal/constants';
import { spacingXSmall, spacingMedium, sizingXxLarge1 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            backgroundColor: theme.palette.grey[200],
            height: sizingXxLarge1,
            padding: '0 ' + spacingMedium
        },
        // StepCounter styles when position is bottom
        stepCounterBottom: {
            position: 'fixed',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            left: '50%',
            bottom: spacingXSmall,
            zIndex: theme.zIndex.mobileStepper + 1 // MUI defined z-index + 1 to show on top of the MuiMobileStepper
        },
        // StepCounter styles when position is static
        stepCounterStatic: {
            position: 'relative',
            textAlign: 'center',
            top: 40
        }
    };
};

/**
 * Mobile step progress
 */
var MobileStepper = React.forwardRef(function (props, ref) {
    var _useState = useState(''),
        _useState2 = _slicedToArray(_useState, 2),
        label = _useState2[0],
        setLabel = _useState2[1];

    var localRef = ref || useRef();

    var classes = props.classes,
        activeStep = props.activeStep,
        steps = props.steps,
        StepCounterContent = props.StepCounterContent,
        position = props.position,
        children = props.children,
        edsContext = props.edsContext,
        rest = _objectWithoutProperties(props, ['classes', 'activeStep', 'steps', 'StepCounterContent', 'position', 'children', 'edsContext']);

    var classOverrides = {
        root: classes.root
    };

    var mobileStepperCounterClassName = classNames(classes['stepCounter' + capitalize(position)]);

    // This effect sets the div with current step content with error message if there is one.
    useEffect(function () {
        var mobileStepLabel = localRef.current.querySelector('.' + MOBILE_STEPPER_LABEL_CLASSNAME);
        setLabel(props.activeStep + 1 > steps ? edsContext.formatMessage('component.MobileStepper.allStepsCompleted') : edsContext.formatMessage('component.MobileStepper.a11yLabel', {
            label: mobileStepLabel && mobileStepLabel.textContent
        }));
    }, [props.activeStep, steps, edsContext, localRef]);

    return React.createElement(
        'div',
        { ref: localRef },
        children,
        StepCounterContent ? React.createElement(
            'div',
            { className: mobileStepperCounterClassName },
            StepCounterContent
        ) : React.createElement(
            'div',
            { className: mobileStepperCounterClassName,
                'aria-atomic': 'false',
                'aria-live': 'assertive',
                'aria-label': label
            },
            React.createElement(MobileStepperCounter, { activeStep: activeStep, steps: steps, position: position })
        ),
        React.createElement(MuiMobileStepper, Object.assign({
            steps: steps,
            activeStep: activeStep,
            position: position,
            classes: classOverrides,
            variant: null
        }, rest))
    );
});

MobileStepper.muiName = 'MobileStepper';
MobileStepper.displayName = 'MobileStepper';

MobileStepper.propTypes = {
    /**
     * Set the active step (zero based index).
     */
    activeStep: PropTypes.number,
    /**
     * A back button element. For instance, it can be be a `Button` or a `IconButton`.
     */
    backButton: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The children will be rendered before the `<MobileStepperCounter>` and the bottom control bar.
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object, PropTypes.node]),
    /**
     * A next button element. For instance, it can be be a `Button` or a `IconButton`.
     */
    nextButton: PropTypes.node,
    /**
     * Set the positioning type.
     */
    position: PropTypes.oneOf(['bottom', 'static']),
    /**
     * The total steps.
     */
    steps: PropTypes.number.isRequired,
    /**
     * Sent in a custom step counter.
     */
    StepCounterContent: PropTypes.node
};

// default property values
MobileStepper.defaultProps = {
    activeStep: 0,
    position: 'bottom'
};

export default withEDSContext(withStyles(styles)(MobileStepper));