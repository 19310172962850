'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
    return {
        currentStepContainer: {
            alignContent: 'stretch',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            marginBottom: _tokens.spacingMedium
        },
        currentStep: {
            alignSelf: 'auto',
            backgroundColor: theme.palette.ctaColor.base,
            borderRadius: '100%',
            flex: '0 1 auto',
            height: _tokens.spacingLarge,
            order: 0,
            textAlign: 'center',
            width: _tokens.spacingLarge
        },
        currentStepNumber: {
            fontSize: _tokens.fontSizeSmall,
            color: theme.palette.grey[100],
            position: 'relative',
            top: _tokens.spacingXSmall
        }
    };
};

/**
 * A component that displays the current step of the total steps
 */
var MobileStepperStep = _react2.default.forwardRef(function (props, ref) {
    var activeStep = props.activeStep,
        classes = props.classes;


    return _react2.default.createElement(
        'div',
        { className: classes.currentStepContainer, ref: ref },
        _react2.default.createElement(
            'div',
            { className: classes.currentStep },
            _react2.default.createElement(
                _Typography2.default,
                { className: classes.currentStepNumber },
                activeStep + 1
            )
        ),
        props.children && props.children
    );
});

MobileStepperStep.propTypes = {
    /**
     * Set the active step (zero based index). This should be the same value that is being sent to the <MobileStepper> component.
     */
    activeStep: _propTypes2.default.number,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The step title component node.
     */
    children: _propTypes2.default.node
};

MobileStepperStep.displayName = 'MobileStepperStep';

exports.default = (0, _styles2.default)(styles)(MobileStepperStep);