'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _withTheme = require('@material-ui/core/styles/withTheme');

var _withTheme2 = _interopRequireDefault(_withTheme);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EventWrapper = _react2.default.forwardRef(function (props, ref) {
    var event = props.event,
        theme = props.theme,
        children = props.children;
    // Check if a 'color' property is attched to the event,
    // if there is, and the color it non-empty then set the color to the value
    // else set to the current theme ctaColor base shade.

    var eventColor = event.hasOwnProperty('color') ? event.color.length > 0 && event.color : theme.palette.tertiary.fountain[600];

    var styles = {
        root: {
            '& > .rbc-event': {
                borderLeft: _tokens.borderWidthThickest + ' solid ' + eventColor
            }
        }
    };

    /* eslint-disable */
    var div = function div(_ref) {
        var classes = _ref.classes,
            children = _ref.children;
        return _react2.default.createElement(
            'div',
            { className: classes.root },
            children
        );
    };
    /* eslint-enable */

    var StyledEventWrapper = (0, _styles2.default)(styles)(div);

    return _react2.default.createElement(
        StyledEventWrapper,
        { ref: ref },
        children
    );
});

EventWrapper.propTypes = {
    /**
     * Event data sent to the WeekViewSchedule.
     */
    event: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     * Material-UI provided theme.
     */
    theme: _propTypes2.default.object,
    /**
     * @ignore
     * The children to render from react-big-calendar.
     */
    children: _propTypes2.default.any
};

EventWrapper.displayName = 'EventWrapper';

exports.default = (0, _withTheme2.default)(EventWrapper);