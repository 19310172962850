function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';

export var styles = function styles(theme) {
    return {
        root: {
            '& button': {
                margin: '0 8px',
                marginBottom: '8px'
            }
        }
    };
};

/**
 * Use a `ButtonGroup` for two or more buttons that are grouped together.
 */
var ButtonGroup = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        className = props.className,
        children = props.children,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'children']);

    // showcase all class overrides


    var buttonGroupClasses = classNames(classes.root, className);

    return React.createElement(
        'div',
        Object.assign({ className: buttonGroupClasses }, rest, { ref: ref }),
        children
    );
});

ButtonGroup.displayName = 'ButtonGroup';

ButtonGroup.propTypes = {
    /**
     * Class name to assign the component.
     */
    className: PropTypes.string,
    /**
     * Classes used to extend original style object.
     */
    classes: PropTypes.object,
    /**
     * The buttons that are passed in as children within ButtonGroups.
     */
    children: PropTypes.node.isRequired
};

export default withStyles(styles)(ButtonGroup);