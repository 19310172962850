function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiDialogContent } from '@material-ui/core/DialogContent';
import { spacingMedium } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            padding: '0 ' + spacingMedium + ' ' + spacingMedium
        }
    };
};

/**
 * DialogContent is the main content that lives within a Dialog.
 */
var DialogContent = React.forwardRef(function (props, ref) {
    var children = props.children,
        classesProp = props.classes,
        rest = _objectWithoutProperties(props, ['children', 'classes']);

    var classes = {
        root: classesProp.root
    };

    return React.createElement(
        MuiDialogContent,
        Object.assign({ classes: classes }, rest, { ref: ref }),
        children
    );
});

DialogContent.muiName = 'DialogContent';
DialogContent.displayName = 'DialogContent';

DialogContent.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string
};

export default withStyles(styles)(DialogContent);