var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import React from 'react';
import Media from 'react-media';
import EdsButton from '@hedtech/react-design-system/core/es/Button';
import Icon from '../Icon';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    centerAlign: {
        textAlign: 'center'
    },
    childrenContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    contained: {},
    containedSecondary: {},
    disabled: {},
    inheritAlign: {
        textAlign: 'inherit'
    },
    justifyAlign: {
        textAlign: 'justify'
    },
    leftAlign: {
        textAlign: 'left'
    },
    rightAlign: {
        textAlign: 'right'
    },
    root: {},
    sizeSmall: {},
    textInline: {},
    textStandalone: {}
});
const Button = (props) => {
    const { align, children, classes, disabled, fluid, IconProps, loading, variant } = props, rest = __rest(props, ["align", "children", "classes", "disabled", "fluid", "IconProps", "loading", "variant"]);
    const classOverrides = omit(classes, ['centerAlign', 'childrenContainer', 'inheritAlign', 'justifyAlign', 'leftAlign', 'rightAlign']);
    if (align) {
        switch (align) {
            case 'center':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.centerAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.centerAlign);
                break;
            case 'inherit':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.inheritAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.inheritAlign);
                break;
            case 'justify':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.justifyAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.justifyAlign);
                break;
            case 'left':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.leftAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.leftAlign);
                break;
            case 'right':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.rightAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.rightAlign);
                break;
        }
    }
    const contentButton = (React.createElement(React.Fragment, null, IconProps || loading ? (React.createElement("span", { className: classes.childrenContainer },
        loading ? (React.createElement(Icon, { marginRight: true, name: "spinner", spin: true })) : (IconProps && (React.createElement(Icon, Object.assign({ marginRight: true, name: IconProps.name }, IconProps)))),
        React.createElement("span", null, children))) : (React.createElement("span", null, children))));
    return (React.createElement(Media, { query: Tokens.mqXSmall }, (matches) => matches ? (React.createElement(EdsButton, Object.assign({ classes: classOverrides, disabled: loading ? true : disabled, fluid: fluid !== undefined || (variant && variant === 'text') ? fluid : true, variant: variant }, rest), contentButton)) :
        (React.createElement(EdsButton, Object.assign({ classes: classOverrides, disabled: loading ? true : disabled, fluid: fluid, variant: variant }, rest), contentButton))));
};
export default withStyles(styles)(Button);
