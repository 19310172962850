import React from 'react';
import Link from '../Link';
import Text from '../Text';
const Paragraph = (props) => {
    const { align, className, color, display, events, gutterBottom, id, size, text, weight } = props;
    const paragraph = [];
    const openBracket = text.split('[[');
    let countLinks = 0;
    let closeBracket;
    if (openBracket) {
        openBracket.forEach((openElement, i) => {
            closeBracket = openElement.split(']]');
            if (closeBracket) {
                if (closeBracket.length > 1) {
                    paragraph.push(React.createElement(Link, { id: "lnkCloseBracket", key: `${id}link_${countLinks}`, onClick: events ? events[countLinks] : undefined },
                        React.createElement("span", null, closeBracket[0])));
                    countLinks++;
                    for (let j = 1; j < closeBracket.length; j++) {
                        if (closeBracket[j] !== '') {
                            paragraph.push(React.createElement("span", { key: `${id}_${i}_${j}` }, closeBracket[j]));
                        }
                    }
                }
                else {
                    if (closeBracket[0] !== '') {
                        paragraph.push(React.createElement("span", { key: `${id}_0` }, closeBracket[0]));
                    }
                }
            }
        });
    }
    return (React.createElement(Text, { align: align, className: className, color: color, display: display, id: id, gutterBottom: gutterBottom, size: size, weight: weight }, paragraph));
};
export default Paragraph;
