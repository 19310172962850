"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgCrown = function SvgCrown(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", {
                fill: "currentColor",
                d: "M13.085 41.252L6.719 25.125l-4.57-.745v-4.464l3.101-2.762L9 19.916l-.169 4.464 9.11 4.823 3.765-4.823 6.747-11.526-2.305-3.604 2.305-4.212 3.797.697 1.735 4.223-1.735 2.896 4.427 9.275 4.548 6.329h3.155l6.798-4.078V19l4.413-1.846 3.022 4.096-2.363 3.875h-2.33l-7.073 16.127z"
            }),
            _react2.default.createElement("path", {
                fill: "#FFF",
                opacity: 0.88,
                d: "M13.085 41.252L6.719 25.125l-4.57-.745v-4.464l3.101-2.762L9 19.916l-.169 4.464 9.11 4.823 3.765-4.823 6.747-11.526-2.305-3.604 2.305-4.212 3.797.697 1.735 4.223-1.735 2.896 4.427 9.275 4.548 6.329h3.155l6.798-4.078V19l4.413-1.846 3.022 4.096-2.363 3.875h-2.33l-7.073 16.127z"
            }),
            _react2.default.createElement("path", {
                d: "M50.25 46H9.75a.752.752 0 0 0-.75.75v1.5c0 .413.338.75.75.75h40.5c.413 0 .75-.337.75-.75v-1.5a.752.752 0 0 0-.75-.75zm4.5-30a5.253 5.253 0 0 0-5.25 5.25c0 .712.14 1.387.403 2.006l-5.278 3.169a2.985 2.985 0 0 1-1.538.431 3.002 3.002 0 0 1-2.606-1.51l-6.975-12.205c1.06-.966 1.744-2.344 1.744-3.891A5.253 5.253 0 0 0 30 4a5.253 5.253 0 0 0-5.25 5.25 5.22 5.22 0 0 0 1.744 3.881l-6.975 12.206a2.982 2.982 0 0 1-2.606 1.51 2.96 2.96 0 0 1-1.538-.431l-5.278-3.17a5.284 5.284 0 0 0 .403-2.005 5.253 5.253 0 0 0-5.25-5.25 5.253 5.253 0 1 0 0 10.51c.188-.001.366-.038.544-.057L12 43h36l6.206-16.556c.178.018.356.056.544.056A5.253 5.253 0 0 0 60 21.25 5.253 5.253 0 0 0 54.75 16zM30 7a2.257 2.257 0 0 1 2.25 2.25A2.257 2.257 0 0 1 30 11.5a2.257 2.257 0 0 1-2.25-2.25A2.257 2.257 0 0 1 30 7zM5.25 23.5A2.257 2.257 0 0 1 3 21.25 2.257 2.257 0 0 1 5.25 19a2.257 2.257 0 0 1 2.25 2.25 2.257 2.257 0 0 1-2.25 2.25zM45.919 40H14.08l-5.25-14.006 5.007 3a5.973 5.973 0 0 0 3.084.853 6.026 6.026 0 0 0 5.212-3.028l7.088-12.403c.262.037.515.075.787.075s.525-.038.788-.075l7.078 12.412a6.017 6.017 0 0 0 5.212 3.019c1.088 0 2.147-.3 3.085-.853l5.006-3L45.918 40zm8.831-16.5a2.257 2.257 0 0 1-2.25-2.25A2.257 2.257 0 0 1 54.75 19 2.257 2.257 0 0 1 57 21.25a2.257 2.257 0 0 1-2.25 2.25z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgCrown;