"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgNoComments = function SvgNoComments(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 214, height: 183, viewBox: "0 0 214 183" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement(
                "g",
                { transform: "translate(-.56 61.346)" },
                _react2.default.createElement("rect", {
                    fill: "#FFF",
                    x: 2.56,
                    y: 2.654,
                    width: 211,
                    height: 56,
                    rx: 3.52
                }),
                _react2.default.createElement("path", {
                    d: "M5.579 3.654c-1.115 0-2.019.899-2.019 2.008v48.984c0 1.109.904 2.008 2.019 2.008h203.962c1.115 0 2.02-.9 2.02-2.008V5.662c0-1.109-.905-2.008-2.02-2.008H5.58zm-.002-3h203.966c2.77 0 5.017 2.236 5.017 4.994V54.66c0 2.758-2.246 4.994-5.017 4.994H5.577c-2.77 0-5.017-2.236-5.017-4.994V5.648C.56 2.89 2.806.654 5.577.654zm44.743 15H192.8c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H50.32a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zm0 14h96.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H50.32a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zm128 11h23.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76h-23.48a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zM36.56 25.476c0 4.877-5.598 8.822-12.499 8.822-2.096 0-4.075-.365-5.81-1.01-1.75 1.324-3.898 2.134-6.18 2.363a.466.466 0 0 1-.5-.327c-.056-.212.117-.343.286-.5.838-.78 1.854-1.391 2.253-4.012-1.597-1.48-2.548-3.33-2.548-5.336 0-4.873 5.599-8.822 12.5-8.822 6.9 0 12.498 3.949 12.498 8.822z",
                    fill: "currentColor"
                }),
                _react2.default.createElement("path", {
                    d: "M50.32 15.654H192.8c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H50.32a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zm0 14h96.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H50.32a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zm128 11h23.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76h-23.48a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zM36.56 25.476c0 4.877-5.598 8.822-12.499 8.822-2.096 0-4.075-.365-5.81-1.01-1.75 1.324-3.898 2.134-6.18 2.363a.466.466 0 0 1-.5-.327c-.056-.212.117-.343.286-.5.838-.78 1.854-1.391 2.253-4.012-1.597-1.48-2.548-3.33-2.548-5.336 0-4.873 5.599-8.822 12.5-8.822 6.9 0 12.498 3.949 12.498 8.822z",
                    fill: "#FFF",
                    opacity: 0.7
                })
            ),
            _react2.default.createElement("path", {
                d: "M4.52 0h203.96A3.52 3.52 0 0 1 212 3.52v48.96a3.52 3.52 0 0 1-3.52 3.52H4.52A3.52 3.52 0 0 1 1 52.48V3.52A3.52 3.52 0 0 1 4.52 0zm0 127h203.96a3.52 3.52 0 0 1 3.52 3.52v48.96a3.52 3.52 0 0 1-3.52 3.52H4.52A3.52 3.52 0 0 1 1 179.48v-48.96A3.52 3.52 0 0 1 4.52 127z",
                fill: "currentColor"
            }),
            _react2.default.createElement("path", {
                d: "M4.52 0h203.96A3.52 3.52 0 0 1 212 3.52v48.96a3.52 3.52 0 0 1-3.52 3.52H4.52A3.52 3.52 0 0 1 1 52.48V3.52A3.52 3.52 0 0 1 4.52 0zm0 127h203.96a3.52 3.52 0 0 1 3.52 3.52v48.96a3.52 3.52 0 0 1-3.52 3.52H4.52A3.52 3.52 0 0 1 1 179.48v-48.96A3.52 3.52 0 0 1 4.52 127z",
                fillOpacity: 0.92,
                fill: "#FFF"
            }),
            _react2.default.createElement("path", {
                d: "M49.76 16h142.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H49.76A1.76 1.76 0 0 1 48 21.24v-3.48c0-.972.788-1.76 1.76-1.76zm0 14h96.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H49.76A1.76 1.76 0 0 1 48 35.24v-3.48c0-.972.788-1.76 1.76-1.76zm128 12h23.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76h-23.48a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zM36 26c0 5.42-5.598 9.573-12 10-2.595-.427-4.574-.811-6-1-2.059.903-4.207 1.756-6 2-.716.024-.933-.128-1 0-.046-.57.128-.708 0-1 1.135-.695 2.15-1.338 3-4-2.047-1.655-2.998-3.602-3-6 .002-4.843 5.6-9 13-9 6.402 0 12 4.157 12 9zm13.76 117h142.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H49.76a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zm0 14h96.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H49.76a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zm128 12h23.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76h-23.48a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zM36 153c0 5.42-5.598 9.573-12 10-2.595-.427-4.574-.811-6-1-2.059.903-4.207 1.756-6 2-.716.024-.933-.128-1 0-.046-.57.128-.708 0-1 1.135-.695 2.15-1.338 3-4-2.047-1.655-2.998-3.602-3-6 .002-4.843 5.6-9 13-9 6.402 0 12 4.157 12 9z",
                fill: "currentColor"
            }),
            _react2.default.createElement("path", {
                d: "M49.76 16h142.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H49.76A1.76 1.76 0 0 1 48 21.24v-3.48c0-.972.788-1.76 1.76-1.76zm0 14h96.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H49.76A1.76 1.76 0 0 1 48 35.24v-3.48c0-.972.788-1.76 1.76-1.76zm128 12h23.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76h-23.48a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zM36 26c0 5.42-5.598 9.573-12 10-2.595-.427-4.574-.811-6-1-2.059.903-4.207 1.756-6 2-.716.024-.933-.128-1 0-.046-.57.128-.708 0-1 1.135-.695 2.15-1.338 3-4-2.047-1.655-2.998-3.602-3-6 .002-4.843 5.6-9 13-9 6.402 0 12 4.157 12 9zm13.76 117h142.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H49.76a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zm0 14h96.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76H49.76a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zm128 12h23.48c.972 0 1.76.788 1.76 1.76v3.48a1.76 1.76 0 0 1-1.76 1.76h-23.48a1.76 1.76 0 0 1-1.76-1.76v-3.48c0-.972.788-1.76 1.76-1.76zM36 153c0 5.42-5.598 9.573-12 10-2.595-.427-4.574-.811-6-1-2.059.903-4.207 1.756-6 2-.716.024-.933-.128-1 0-.046-.57.128-.708 0-1 1.135-.695 2.15-1.338 3-4-2.047-1.655-2.998-3.602-3-6 .002-4.843 5.6-9 13-9 6.402 0 12 4.157 12 9z",
                fillOpacity: 0.7,
                fill: "#FFF"
            })
        )
    );
};

exports.default = SvgNoComments;