"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var SvgChevronRight = function SvgChevronRight(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return _react2.default.createElement(
    "svg",
    Object.assign({ viewBox: "0 0 256 512", width: "1em", height: "1em", ref: svgRef }, props),
    _react2.default.createElement("path", { d: "M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z" })
  );
};

var ForwardRef = _react2.default.forwardRef(function (props, ref) {
  return _react2.default.createElement(SvgChevronRight, Object.assign({ svgRef: ref }, props));
});
exports.default = ForwardRef;