import React from 'react';

var SvgHeart = function SvgHeart(props) {
    return React.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M30 55.327L9.183 35.348 1.817 24.19v-9.403l5.8-7.139 10.155-2.61 9.143 5.122L30 12.7l6.845-5.054 6.62-1.838 6.79 1.838 6.109 3.858L58.603 20l-3.252 10.5-9.102 9.364z"
            }),
            React.createElement("path", {
                fill: "#FFF",
                opacity: 0.88,
                d: "M30 55.327L9.183 35.348 1.817 24.19v-9.403l5.8-7.139 10.155-2.61 9.143 5.122L30 12.7l6.845-5.054 6.62-1.838 6.79 1.838 6.109 3.858L58.603 20l-3.252 10.5-9.102 9.364z"
            }),
            React.createElement("path", {
                d: "M43.148 4C38.284 4 33.614 6.93 30 10.235 26.386 6.94 21.716 4 16.852 4 6.455 4 0 10.451 0 20.835c0 8.343 7.09 15.09 7.739 15.694L27.09 55.701c1.602 1.584 4.216 1.595 5.818 0L52.25 36.575l.057-.057C54.114 34.751 60 28.414 60 20.835 60 10.45 53.545 4 43.148 4zm6.557 29.976L30.364 53.091a.493.493 0 0 1-.716 0L10.26 33.908c-3.238-3.055-6.625-8.252-6.625-13.073 0-8.378 4.819-13.188 13.216-13.188 5.125 0 9.705 4.218 13.148 7.682 1.795-1.8 7.239-7.682 13.148-7.682 8.397 0 13.216 4.81 13.216 13.188 0 4.832-3.205 9.71-6.66 13.141z",
                fill: "currentColor"
            })
        )
    );
};

export default SvgHeart;