"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgContact = function SvgContact(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 253, height: 75, viewBox: "0 0 253 75" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", {
                d: "M.734 61.137a6.004 6.004 0 0 1 .486-4.472l28.409-53A6 6 0 0 1 34.917.5h177.646a6 6 0 0 1 5.053 2.765l33.926 53c.608.949.914 2.004.945 3.053h.03l-.027.201a6.03 6.03 0 0 1-.217 1.578l-1.2 8.72A6 6 0 0 1 245.13 75H7.477a6 6 0 0 1-5.975-5.454l-.768-8.409z",
                fill: "currentColor"
            }),
            _react2.default.createElement("path", {
                d: "M4.718 56.617l27.744-52A4 4 0 0 1 35.99 2.5h175.545a4 4 0 0 1 3.364 1.835l33.468 52a4 4 0 0 1-3.364 6.165H8.247a4 4 0 0 1-3.529-5.883z",
                fill: "#FFF"
            }),
            _react2.default.createElement("path", {
                d: "M30 26c0 1.105-1.567 2-3.5 2s-3.5-.895-3.5-2 1.567-2 3.5-2 3.5.895 3.5 2zM15.559 55.61L40.317 8.223A6 6 0 0 1 45.635 5H201.58a6 6 0 0 1 5.163 2.943l28.145 47.528a3 3 0 0 1-2.58 4.529H18.217a3 3 0 0 1-2.66-4.39zM218 18.5h.4a2 2 0 0 1 1.69.929l7.32 11.547a.825.825 0 0 1-.697 1.267h-.4a2 2 0 0 1-1.689-.93l-7.32-11.546A.825.825 0 0 1 218 18.5z",
                fill: "currentColor"
            }),
            _react2.default.createElement("path", {
                d: "M15.559 55.61L40.317 8.223A6 6 0 0 1 45.635 5H201.58a6 6 0 0 1 5.163 2.943l28.145 47.528a3 3 0 0 1-2.58 4.529H18.217a3 3 0 0 1-2.66-4.39z",
                fill: "#FFF",
                opacity: 0.9
            }),
            _react2.default.createElement("path", {
                d: "M120 30c0 3.314-6.268 6-14 6s-14-2.686-14-6 6.268-6 14-6 14 2.686 14 6zm39 0c0 3.314-6.268 6-14 6s-14-2.686-14-6 6.268-6 14-6 14 2.686 14 6zm41 0c0 3.314-6.268 6-14 6s-14-2.686-14-6 6.268-6 14-6 14 2.686 14 6zM79 30c0 3.314-6.268 6-14 6s-14-2.686-14-6 6.268-6 14-6 14 2.686 14 6zm40-16c0 2.761-4.925 5-11 5s-11-2.239-11-5 4.925-5 11-5 11 2.239 11 5zm35 0c0 2.761-4.925 5-11 5s-11-2.239-11-5 4.925-5 11-5 11 2.239 11 5zm34 0c0 2.761-4.925 5-11 5s-11-2.239-11-5 4.925-5 11-5 11 2.239 11 5zM84 14c0 2.761-4.925 5-11 5s-11-2.239-11-5 4.925-5 11-5 11 2.239 11 5zm36 34c0 4.418-7.611 8-17 8s-17-3.582-17-8 7.611-8 17-8 17 3.582 17 8zm44 0c0 4.418-7.611 8-17 8s-17-3.582-17-8 7.611-8 17-8 17 3.582 17 8zm48 0c0 4.418-7.611 8-17 8s-17-3.582-17-8 7.611-8 17-8 17 3.582 17 8zM73 48c0 4.418-7.611 8-17 8s-17-3.582-17-8 7.611-8 17-8 17 3.582 17 8z",
                fill: "currentColor"
            }),
            _react2.default.createElement("path", {
                d: "M120 30c0 3.314-6.268 6-14 6s-14-2.686-14-6 6.268-6 14-6 14 2.686 14 6zm39 0c0 3.314-6.268 6-14 6s-14-2.686-14-6 6.268-6 14-6 14 2.686 14 6zm41 0c0 3.314-6.268 6-14 6s-14-2.686-14-6 6.268-6 14-6 14 2.686 14 6zM79 30c0 3.314-6.268 6-14 6s-14-2.686-14-6 6.268-6 14-6 14 2.686 14 6zm40-16c0 2.761-4.925 5-11 5s-11-2.239-11-5 4.925-5 11-5 11 2.239 11 5zm35 0c0 2.761-4.925 5-11 5s-11-2.239-11-5 4.925-5 11-5 11 2.239 11 5zm34 0c0 2.761-4.925 5-11 5s-11-2.239-11-5 4.925-5 11-5 11 2.239 11 5zM84 14c0 2.761-4.925 5-11 5s-11-2.239-11-5 4.925-5 11-5 11 2.239 11 5zm36 34c0 4.418-7.611 8-17 8s-17-3.582-17-8 7.611-8 17-8 17 3.582 17 8zm44 0c0 4.418-7.611 8-17 8s-17-3.582-17-8 7.611-8 17-8 17 3.582 17 8zm48 0c0 4.418-7.611 8-17 8s-17-3.582-17-8 7.611-8 17-8 17 3.582 17 8zM73 48c0 4.418-7.611 8-17 8s-17-3.582-17-8 7.611-8 17-8 17 3.582 17 8z",
                fill: "#FFF",
                opacity: 0.75
            })
        )
    );
};

exports.default = SvgContact;