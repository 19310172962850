'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _CardContent = require('@material-ui/core/CardContent');

var _CardContent2 = _interopRequireDefault(_CardContent);

var _Card = require('./Card');

var _tokens = require('../styles/tokens');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    var _responsive;

    return {
        root: {},
        standard: {
            padding: _tokens.spacingSmall + ' ' + _tokens.spacingLarge,
            '&:last-child': {
                padding: _tokens.spacingSmall + ' ' + _tokens.spacingLarge
            }
        },
        dense: {
            padding: _tokens.spacingXSmall2 + ' ' + _tokens.spacingMedium,
            '&:last-child': {
                padding: _tokens.spacingXSmall2 + ' ' + _tokens.spacingMedium
            }
        },
        none: {
            padding: 0,
            '&:last-child': {
                padding: 0
            }
        },
        responsive: (_responsive = {}, _defineProperty(_responsive, theme.breakpoints.only('xs'), {
            padding: _tokens.spacingXSmall + ' ' + _tokens.spacingSmall
        }), _defineProperty(_responsive, '&:last-child', _defineProperty({}, theme.breakpoints.only('xs'), {
            padding: _tokens.spacingXSmall + ' ' + _tokens.spacingSmall
        })), _responsive)
    };
};

/**
 * CardContent component for displaying content on a Card component
 */
var CardContent = _react2.default.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var _useContext = (0, _react.useContext)(_Card.CardContext),
        padding = _useContext.padding,
        responsive = _useContext.responsive;

    var classOverrides = {
        root: (0, _classnames2.default)((_classNames = {}, _defineProperty(_classNames, classes.standard, padding === 'standard'), _defineProperty(_classNames, classes.dense, padding === 'dense'), _defineProperty(_classNames, classes.none, padding === 'none'), _defineProperty(_classNames, classes.responsive, responsive && ['standard', 'dense'].indexOf(padding) !== -1), _classNames), classes.root)
    };

    return _react2.default.createElement(_CardContent2.default, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

CardContent.muiName = 'CardContent';
CardContent.displayName = 'CardContent';

CardContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func])
};

// If default props are needed, they must be set here
CardContent.defaultProps = {
    component: 'div'
};

exports.default = (0, _styles2.default)(styles)(CardContent);