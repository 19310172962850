'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _EDSContext = require('../EDSContext/EDSContext');

var _TableCell = require('@material-ui/core/TableCell');

var _TableCell2 = _interopRequireDefault(_TableCell);

var _TableRow = require('@material-ui/core/TableRow');

var _TableRow2 = _interopRequireDefault(_TableRow);

var _ChevronDown = require('@eui/ds-icons/lib/ChevronDown');

var _ChevronDown2 = _interopRequireDefault(_ChevronDown);

var _classnames3 = require('classnames');

var _classnames4 = _interopRequireDefault(_classnames3);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            height: '3rem'
        }, '@media ' + _tokens.mqSmallDown, {
            '&.table-row-expanded': {
                backgroundColor: theme.palette.grey[200]
            }
        }),
        iconContainer: {
            cursor: 'pointer',
            padding: _tokens.spacingXSmall,
            '&:focus': {
                outline: _tokens.borderWidthThick + ' solid ' + _tokens.colorBrandSecondary
            }
        },
        expandIcon: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest
            }),
            verticalAlign: 'middle'
        },
        expandIconOpen: {
            transform: 'rotate(180deg)'
        },
        tableRowExpandedBackground: {
            backgroundColor: theme.palette.grey[200]
        },
        tableRowExpand: {
            padding: _tokens.spacingSmall + ' ' + _tokens.spacingMedium
        },
        muiTableCellRoot: {
            '&:last-child': {
                paddingRight: _tokens.spacingSmall,
                textAlign: 'right'
            }
        }
    };
};

var TableExpandableRow = function (_React$Component) {
    _inherits(TableExpandableRow, _React$Component);

    function TableExpandableRow(props) {
        _classCallCheck(this, TableExpandableRow);

        var _this = _possibleConstructorReturn(this, (TableExpandableRow.__proto__ || Object.getPrototypeOf(TableExpandableRow)).call(this, props));

        _this.toggleExpand = function (e) {
            if (e.type === 'keypress' && (e.key === 'Enter' || e.key === ' ')) {
                e.preventDefault();
                _this.updateState();
            } else if (e.type === 'click') {
                _this.updateState();
            }
        };

        _this.state = {
            expanded: false
        };
        return _this;
    }

    _createClass(TableExpandableRow, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
            var _this2 = this;

            if (this.props.expand !== prevProps.expand) {
                this.setState(function (state) {
                    return { expanded: _this2.props.expand };
                });
            }
        }
    }, {
        key: 'updateState',
        value: function updateState() {
            this.setState(function (state) {
                return {
                    expanded: !state.expanded
                };
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _classnames,
                _this3 = this;

            var _props = this.props,
                classes = _props.classes,
                children = _props.children,
                expandedRowClassName = _props.expandedRowClassName,
                expandedRowContent = _props.expandedRowContent,
                expandToggleAriaLabel = _props.expandToggleAriaLabel,
                TableRowProps = _props.TableRowProps,
                ExpandedTableRowProps = _props.ExpandedTableRowProps,
                edsContext = _props.edsContext,
                expand = _props.expand,
                rest = _objectWithoutProperties(_props, ['classes', 'children', 'expandedRowClassName', 'expandedRowContent', 'expandToggleAriaLabel', 'TableRowProps', 'ExpandedTableRowProps', 'edsContext', 'expand']);

            var expanded = this.state.expanded;


            var expandLabel = expandToggleAriaLabel === TableExpandableRow.defaultProps.expandToggleAriaLabel ? // translate unless a label was provided
            edsContext.formatMessage('component.Table.showMore') : expandToggleAriaLabel;

            return _react2.default.createElement(
                _react2.default.Fragment,
                null,
                _react2.default.createElement(
                    _TableRow2.default,
                    Object.assign({
                        className: (0, _classnames4.default)(classes.root, (_classnames = {}, _defineProperty(_classnames, classes.tableRowExpandedBackground, expanded && expandedRowContent), _defineProperty(_classnames, 'table-row-expanded', expanded), _defineProperty(_classnames, 'not-table-row-expanded', !expanded && expandedRowContent), _defineProperty(_classnames, 'table-expandable-row', expandedRowContent), _classnames))
                    }, TableRowProps, rest),
                    children,
                    _react2.default.createElement(
                        _TableCell2.default,
                        {
                            className: (0, _classnames4.default)('expand-row-button', { 'no-expanded-row-content': !expandedRowContent }),
                            classes: { root: classes.muiTableCellRoot }
                        },
                        _react2.default.createElement(
                            'span',
                            {
                                className: 'expand-icon-container ' + classes.iconContainer,
                                'aria-expanded': expanded,
                                'aria-label': expandLabel,
                                title: expandLabel,
                                onClick: function onClick(e) {
                                    return _this3.toggleExpand(e);
                                },
                                onKeyPress: function onKeyPress(e) {
                                    return _this3.toggleExpand(e);
                                },
                                role: 'button',
                                tabIndex: 0 },
                            _react2.default.createElement(_ChevronDown2.default, {
                                className: (0, _classnames4.default)(classes.expandIcon, 'expand-icon', _defineProperty({}, classes.expandIconOpen, expanded))
                            })
                        )
                    )
                ),
                expandedRowContent && expanded && _react2.default.createElement(
                    _TableRow2.default,
                    Object.assign({ className: (0, _classnames4.default)(classes.tableRowExpandedBackground, expandedRowClassName, 'table-row-expanded-content')
                    }, ExpandedTableRowProps),
                    _react2.default.createElement(
                        _TableCell2.default,
                        { className: classes.tableRowExpand, colSpan: children.length + 1 },
                        expandedRowContent
                    )
                )
            );
        }
    }]);

    return TableExpandableRow;
}(_react2.default.Component);

TableExpandableRow.propTypes = {
    /**
     * Common children are the TableCells with information you want to show on the TableRow.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * @ignore
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * This is the custom prop for developers to control the expand collapse.
     * Useful when you want to expand / collapse all section in one click.
     */
    expand: _propTypes2.default.bool,
    /**
     * This is the actual content that should be shown when the TableExpandableRow is expanded.
     * When used for responsive table, you can choose not to supply any content.
     */
    expandedRowContent: _propTypes2.default.node,
    /**
     * Custom string name to send to the TableRow that is shown when expanded.
     */
    expandedRowClassName: _propTypes2.default.string,
    /**
     * Override the aria-label for the toggle arrow button.
     */
    expandToggleAriaLabel: _propTypes2.default.string,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,
    /**
     * Object of props to send to the TableRow with the toggle arrow.
     */
    TableRowProps: _propTypes2.default.object,
    /**
     * Object of props to send to the TableRow that is shown when expanded.
     */
    ExpandedTableRowProps: _propTypes2.default.object
};

TableExpandableRow.defaultProps = {
    expandToggleAriaLabel: 'Show More'
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles.withStyles)(styles)(TableExpandableRow));