'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames3 = require('classnames');

var _classnames4 = _interopRequireDefault(_classnames3);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Toolbar = require('@material-ui/core/Toolbar');

var _Toolbar2 = _interopRequireDefault(_Toolbar);

var _ChevronDown = require('@eui/ds-icons/lib/ChevronDown');

var _ChevronDown2 = _interopRequireDefault(_ChevronDown);

var _Edit = require('@eui/ds-icons/lib/Edit');

var _Edit2 = _interopRequireDefault(_Edit);

var _ = require('../');

var _EDSContext = require('../EDSContext/EDSContext');

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            padding: _tokens.spacingSmall
        },
        flex: {
            flexGrow: 1,
            fontSize: _tokens.fontSizeHeader2Small,
            color: _tokens.colorTextNeutral500
        },
        iconContainer: {
            cursor: 'pointer',
            padding: _tokens.spacingXSmall,
            '&:not(:last-child)': {
                marginRight: _tokens.spacingMedium
            },
            '&:focus': {
                outline: _tokens.borderWidthThin + ' solid ' + _tokens.colorBrandSecondary
            }
        },
        expandIcon: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest
            }),
            verticalAlign: 'middle'
        },
        expandIconOpen: {
            transform: 'rotate(180deg)'
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: _tokens.widthFluid,
            alignItems: 'center'
        },
        footerLeft: {
            justifyContent: 'flex-start'
        }
    };
};

/**
 * TableToolbar
 */
var TableToolbar = _react2.default.forwardRef(function (props, ref) {
    var _classnames;

    var classes = props.classes,
        caption = props.caption,
        showExpandAllToggle = props.showExpandAllToggle,
        expandAllToggleAriaLabel = props.expandAllToggleAriaLabel,
        showEditToggle = props.showEditToggle,
        children = props.children,
        expanded = props.expanded,
        onExpand = props.onExpand,
        onEdit = props.onEdit,
        location = props.location,
        footerAlignItems = props.footerAlignItems,
        showFooterToolbarOnEdit = props.showFooterToolbarOnEdit,
        edsContext = props.edsContext,
        rest = _objectWithoutProperties(props, ['classes', 'caption', 'showExpandAllToggle', 'expandAllToggleAriaLabel', 'showEditToggle', 'children', 'expanded', 'onExpand', 'onEdit', 'location', 'footerAlignItems', 'showFooterToolbarOnEdit', 'edsContext']);

    var showToolbar = location === 'footer' && showFooterToolbarOnEdit || location === 'header';

    var toggleExpand = function toggleExpand(e) {
        if (e.type === 'keypress' && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            onExpand(e);
        } else if (e.type === 'click') {
            onExpand(e);
        }
    };

    var toggleEdit = function toggleEdit(e) {
        if (e.type === 'keypress' && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            onEdit(e);
        } else if (e.type === 'click') {
            onEdit(e);
        }
    };

    var expandLabel = expandAllToggleAriaLabel === TableToolbar.defaultProps.expandAllToggleAriaLabel ? // translate unless a label was provided
    edsContext.formatMessage('component.Table.showAll') : expandAllToggleAriaLabel;

    return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        showToolbar && _react2.default.createElement(
            _Toolbar2.default,
            Object.assign({ className: classes.root }, rest, { ref: ref }),
            caption && _react2.default.createElement(
                _.Typography,
                { variant: 'h1', className: classes.flex },
                caption
            ),
            _react2.default.createElement(
                'div',
                { className: (0, _classnames4.default)((_classnames = {}, _defineProperty(_classnames, classes.footer, location === 'footer'), _defineProperty(_classnames, classes.footerLeft, footerAlignItems === 'left'), _classnames)) },
                children,
                showEditToggle && _react2.default.createElement(
                    'span',
                    {
                        className: classes.iconContainer,
                        onClick: function onClick(e) {
                            return toggleEdit(e);
                        },
                        onKeyPress: function onKeyPress(e) {
                            return toggleEdit(e);
                        },
                        'aria-label': edsContext.formatMessage('component.Table.edit'),
                        title: edsContext.formatMessage('component.Table.edit'),
                        role: 'button',
                        tabIndex: 0 },
                    _react2.default.createElement(_Edit2.default, null)
                ),
                showExpandAllToggle && _react2.default.createElement(
                    'span',
                    {
                        className: classes.iconContainer,
                        'aria-expanded': expanded,
                        'aria-label': expandLabel,
                        title: expandLabel,
                        onClick: function onClick(e) {
                            return toggleExpand(e);
                        },
                        onKeyPress: function onKeyPress(e) {
                            return toggleExpand(e);
                        },
                        role: 'button',
                        tabIndex: 0 },
                    _react2.default.createElement(_ChevronDown2.default, {
                        className: (0, _classnames4.default)(classes.expandIcon, _defineProperty({}, classes.expandIconOpen, expanded))
                    })
                )
            )
        )
    );
});

TableToolbar.muiName = 'TableToolbar';
TableToolbar.displayName = 'TableToolbar';

TableToolbar.propTypes = {
    /**
     * The content of the table, normally `TableHeader` and `TableBody`.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * Class name to be applied to the Alert container.
     */
    className: _propTypes2.default.string,
    /**
     * Table caption or table title
     */
    caption: _propTypes2.default.string,
    /**
     * Show expand all or collapse all icon for expansion panels on mobile or expandable rows.
     */
    showExpandAllToggle: _propTypes2.default.bool,
    /**
     * Override the aria-label for the toggle all.
     */
    expandAllToggleAriaLabel: _propTypes2.default.string,
    /**
     * Prop to toggle expand all.
     */
    expanded: _propTypes2.default.bool,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,
    /**
     * Callback to toggle the boolean value
     */
    onExpand: _propTypes2.default.func,
    /**
     * Show editable toggle button to switch between edit and read-only table mode.
     */
    showEditToggle: _propTypes2.default.bool,
    /**
     * Callback when edit toggle button is clicked
     */
    onEdit: _propTypes2.default.func,
    /**
     * Align items for footer actions.
     */
    footerAlignItems: _propTypes2.default.oneOf(['left', 'right']),
    /**
     * If you need to use actions along with table caption,
     * use `header` location. For action with footer, use `footer` location.
     * NOTE: Captions actions are always right aligned.
     * For footer actions, you can choose left or right.
     */
    location: _propTypes2.default.oneOf(['header', 'footer']),
    /**
     * For footer, show the footer actions only when table is in edit mode.
     */
    showFooterToolbarOnEdit: _propTypes2.default.bool
};

TableToolbar.defaultProps = {
    showExpandAllToggle: false,
    showEditToggle: false,
    expandAllToggleAriaLabel: 'Show All',
    expanded: false,
    location: 'header',
    footerAlignItems: 'right'
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(TableToolbar));