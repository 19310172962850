"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgEyeHide = function SvgEyeHide(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", {
                fill: "currentColor",
                d: "M20 14.856l21.68 29.691 8.089-3.312 8.984-11.533-6.528-9.318-14.73-7.826L26.964 12z"
            }),
            _react2.default.createElement("path", {
                fill: "#FFF",
                opacity: 0.88,
                d: "M20 14.856l21.68 29.691 8.089-3.312 8.984-11.533-6.528-9.318-14.73-7.826L26.964 12z"
            }),
            _react2.default.createElement("path", {
                d: "M33.49 45.771l2.261 3.076c-1.9.323-3.824.484-5.751.484A33.9 33.9 0 0 1 .692 32.56a5.08 5.08 0 0 1 0-5.121 33.874 33.874 0 0 1 10.932-11.391l1.992 2.708a30.548 30.548 0 0 0-10.052 10.39 1.694 1.694 0 0 0 0 1.706C8.975 40.115 18.972 45.97 30 45.97a30.96 30.96 0 0 0 3.49-.198zm-18.701-25.42a16.12 16.12 0 0 0-1.456 6.707c0 9.017 7.42 16.39 16.667 16.39.58 0 1.152-.03 1.717-.086l-2.424-3.295c-7.035-.359-12.626-6.046-12.626-13.009 0-1.182.163-2.358.485-3.494l-2.363-3.213zm44.519 12.21a33.904 33.904 0 0 1-15.224 13.728l5.93 8.06c.417.567.3 1.367-.262 1.787l-.677.508a1.259 1.259 0 0 1-1.772-.265L9.985 5.65a1.284 1.284 0 0 1 .263-1.786l.678-.508a1.259 1.259 0 0 1 1.77.265l6.479 8.806A34.027 34.027 0 0 1 30 10.67a33.9 33.9 0 0 1 29.308 16.77 5.08 5.08 0 0 1 0 5.121zm-15.975-5.503c0-7.194-5.97-13.027-13.333-13.027-2.923 0-5.625.92-7.822 2.478l3.615 4.915a6.617 6.617 0 0 1 7.346-.719h-.002c-1.516 0-2.745 1.24-2.745 2.77 0 1.528 1.23 2.768 2.745 2.768 1.516 0 2.745-1.24 2.745-2.769v-.002c1.586 2.99.718 6.782-2.154 8.739l4.012 5.453c3.385-2.362 5.593-6.231 5.593-10.606zm13.103 2.089a30.556 30.556 0 0 0-13.298-12.175c5.771 7.252 4.344 17.903-3.41 23.395l2.317 3.149a30.507 30.507 0 0 0 14.39-12.663 1.694 1.694 0 0 0 0-1.706z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgEyeHide;