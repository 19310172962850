import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';

import { sizingXxLarge1, spacingSmall } from '../styles/tokens';

var styles = {
    root: {
        padding: '0 ' + spacingSmall,
        alignItems: 'center',
        margin: 'inherit',
        height: sizingXxLarge1,
        '& svg': {
            height: sizingXxLarge1
        }
    }
};

var HeaderBarLogo = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        children = props.children;


    return React.createElement(
        'div',
        { className: classes.root, ref: ref },
        children
    );
});

HeaderBarLogo.displayName = 'HeaderBarLogo';

HeaderBarLogo.propTypes = {
    /**  The content of the component. This component's children might be an image or brand name. */
    children: PropTypes.node.isRequired,

    /** Useful to extend the style applied to components. */
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HeaderBarLogo);