'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            width: 'auto',
            backgroundColor: theme.palette.grey[100],
            padding: _tokens.spacingSmall,
            boxShadow: '0px 0px 0.438rem 0.125rem rgba(0,0,0,0.12)',
            borderBottomLeftRadius: _tokens.borderRadiusMedium,
            borderBottomRightRadius: _tokens.borderRadiusMedium
        }
    };
};

/**
 * A wrapper component to handle the footer of the WeekView component.
 * @done false
 * @updated false
 * @versionAdded v0.2.1
 * @lab true
 */
var CalendarLegend = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        className = props.className,
        children = props.children,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'children']);

    return _react2.default.createElement(
        'div',
        Object.assign({ className: (0, _classnames2.default)(classes.root, className) }, rest, { ref: ref }),
        children
    );
});

CalendarLegend.propTypes = {
    /**
     * @ignore
     */
    classes: _propTypes2.default.object,
    /**
     * Custom class name to the outer CalendarLegend component.
     */
    className: _propTypes2.default.string,
    /**
     * The children to render within the CalendarLegend.
     * Common children are the CalendarLegendItem, Button, and IconButton.
     */
    children: _propTypes2.default.any
};

CalendarLegend.displayName = 'CalendarLegend';

exports.default = (0, _styles2.default)(styles)(CalendarLegend);