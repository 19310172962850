var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import uuid from 'uuid';
import { default as withStyles } from '../styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import NativeSelect from '@material-ui/core/NativeSelect';
import ChevronDown from '@eui/ds-icons/lib/ChevronDown';
import { spacingXSmall, boxShadowFocus, borderRadiusMedium, borderWidthThin, spacingMedium, spacingXxSmall, sizingLarge } from '../styles/tokens';

var ITEM_HEIGHT = 44;
var DEFAULT_SELECTED_INDEX = 0;

var styles = function styles(theme) {
    return {
        // SelectionMenu
        root: {
            display: 'inline-flex'
        },
        icon: {
            fill: theme.palette.grey[500]
        },
        listPadding: {
            paddingTop: '0.125rem',
            paddingBottom: '0.125rem'
        },

        listItemRoot: {
            paddingTop: '0.125rem',
            paddingBottom: '0.125rem',
            paddingLeft: spacingXSmall,
            paddingRight: spacingXSmall,

            '&:hover $icon': {
                fill: theme.palette.ctaColor.hover,
                color: theme.palette.ctaColor.hover
            },

            '&$listItemDisabled': {
                opacity: '1',
                '&:hover $icon': {
                    fill: theme.palette.grey[400]
                }
            }
        },
        listItemDisabled: {},

        listItemButton: {
            borderRadius: borderRadiusMedium,
            border: borderWidthThin + ' solid transparent',
            height: sizingLarge,
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '&:focus': {
                backgroundColor: 'transparent',
                borderColor: theme.palette.grey[300],
                boxShadow: boxShadowFocus
            }
        },

        disabled: {
            color: theme.palette.grey[500],
            fill: theme.palette.grey[400]
        },
        listItemTextRoot: {
            paddingRight: spacingXSmall
        },
        menuRoot: {
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            boxShadow: '0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12), 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14), 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.21)'
        },

        // Native styles
        nativeSelectRoot: {
            marginRight: spacingXxSmall,
            height: sizingLarge,
            '&:hover $nativeIcon': {
                fill: theme.palette.ctaColor.hover
            },
            '&:hover $nativeIconDisabled': {
                fill: theme.palette.grey[400]
            }
        },
        nativeSelect: {
            height: 'auto',
            borderRadius: borderRadiusMedium,
            border: borderWidthThin + ' solid transparent',
            width: 'calc(100% - ' + spacingMedium + ')',
            paddingLeft: spacingXSmall,
            paddingRight: spacingMedium,
            '&:focus': {
                backgroundColor: 'transparent',
                borderColor: theme.palette.grey[300],
                boxShadow: boxShadowFocus,
                borderRadius: borderRadiusMedium
            },
            '&:disabled': {
                color: theme.palette.grey[400]
            }
        },
        nativeIcon: {
            top: 'calc(50% - .5rem)',
            fill: theme.palette.grey[500]
        },
        nativeIconDisabled: {
            fill: theme.palette.grey[400]
        }
    };
};

/**
 * Use this smaller dropdown when space is limited and the dropdown does not need to be prominent.
 * @done true
 * @updated true
 * @versionAdded v2.6.0
 * @examples
 *  SelectionMenuExample
 *  SelectionMenuDisabled
 *  CardSelectionMenu
 *  CustomizedSelectionMenu
 *  SelectionMenuNative
 */

var SelectionMenu = function (_React$Component) {
    _inherits(SelectionMenu, _React$Component);

    function SelectionMenu(props) {
        _classCallCheck(this, SelectionMenu);

        var _this = _possibleConstructorReturn(this, (SelectionMenu.__proto__ || Object.getPrototypeOf(SelectionMenu)).call(this, props));

        _initialiseProps.call(_this);

        _this.state = {
            anchorEl: null,
            selectedIndex: _this.props.selectedIndex || DEFAULT_SELECTED_INDEX
        };
        return _this;
    }

    _createClass(SelectionMenu, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                idProp = _props.id,
                classes = _props.classes,
                disabled = _props.disabled,
                childrenProp = _props.children,
                ContainerProps = _props.ContainerProps,
                ListProps = _props.ListProps,
                ListItemProps = _props.ListItemProps,
                ListItemTextProps = _props.ListItemTextProps,
                MenuProps = _props.MenuProps,
                native = _props.native,
                selectedIndexProp = _props.selectedIndex,
                rest = _objectWithoutProperties(_props, ['id', 'classes', 'disabled', 'children', 'ContainerProps', 'ListProps', 'ListItemProps', 'ListItemTextProps', 'MenuProps', 'native', 'selectedIndex']);

            var _state = this.state,
                anchorEl = _state.anchorEl,
                selectedIndex = _state.selectedIndex;

            var id = idProp || uuid();

            var children = childrenProp;

            if (!native) {
                children = React.Children.map(childrenProp, function (child, index) {
                    return React.cloneElement(child, {
                        onClick: function onClick(event) {
                            _this2.handleMenuItemClick(event, index);
                            if (child.props.onClick) {
                                child.props.onClick(event);
                            }
                        },
                        selected: index === selectedIndex
                    });
                });
            }

            // Assume Array is sent in, else show empty label
            var selectedIndexLabel = Array.isArray(children) && children[selectedIndex].props.children || '';

            return native ? React.createElement(
                NativeSelect,
                Object.assign({
                    id: id,
                    disabled: disabled,
                    IconComponent: ChevronDown,
                    classes: {
                        root: classes.nativeSelectRoot,
                        select: classes.nativeSelect,
                        icon: cn(classes.nativeIcon, _defineProperty({}, classes.nativeIconDisabled, disabled))
                    },
                    disableUnderline: true
                }, rest),
                children
            ) : React.createElement(
                'div',
                Object.assign({ className: classes.root }, ContainerProps),
                React.createElement(
                    List,
                    Object.assign({
                        id: id + '-container',
                        component: 'nav',
                        disablePadding: true
                    }, ListProps),
                    React.createElement(
                        ListItem,
                        Object.assign({
                            button: true,
                            disabled: disabled,
                            disableGutters: true,
                            'aria-haspopup': 'true',
                            'aria-controls': id,
                            onClick: this.handleOpenMenu,
                            classes: {
                                root: classes.listItemRoot,
                                button: classes.listItemButton,
                                disabled: classes.listItemDisabled
                            }
                        }, ListItemProps),
                        React.createElement(ListItemText, Object.assign({
                            primary: selectedIndexLabel,
                            classes: {
                                root: classes.listItemTextRoot
                            },
                            primaryTypographyProps: {
                                className: cn(_defineProperty({}, classes.disabled, disabled))
                            }
                        }, ListItemTextProps)),
                        React.createElement(ChevronDown, {
                            className: cn(classes.icon, _defineProperty({}, classes.disabled, disabled))
                        })
                    )
                ),
                React.createElement(
                    Menu,
                    Object.assign({
                        id: id,
                        anchorEl: anchorEl,
                        open: Boolean(anchorEl),
                        onClose: this.handleClose,
                        MenuListProps: {
                            disablePadding: true
                        },
                        classes: {
                            paper: classes.menuRoot
                        },
                        PaperProps: {
                            style: {
                                maxHeight: ITEM_HEIGHT * 7
                            }
                        }
                    }, MenuProps),
                    children
                )
            );
        }
    }]);

    return SelectionMenu;
}(React.Component);

SelectionMenu.getDerivedStateFromProps = function (props, state) {
    var index = null;
    if (props.selectedIndex < 0 || props.selectedIndex > props.children.length - 1) {
        console.warn('The value ' + props.selectedIndex + ' passed to selectedIndex is invalid. Resetting selectedIndex to 0.');
        index = DEFAULT_SELECTED_INDEX;

        return {
            anchorEl: state.anchorEl,
            selectedIndex: index
        };
    }
    return null; // Indicates no change to the state
};

var _initialiseProps = function _initialiseProps() {
    var _this3 = this;

    this.handleOpenMenu = function (event) {
        _this3.setState({ anchorEl: event.currentTarget });
    };

    this.handleMenuItemClick = function (event, index) {
        _this3.setState({ selectedIndex: index, anchorEl: null });
    };

    this.handleClose = function () {
        _this3.setState({ anchorEl: null });
    };
};

SelectionMenu.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The unique HTML `id`. This is internally used to assist with accessibility of the nodes rendered.
     */
    id: PropTypes.string,
    /**
     * The items to select from, most commonly `SelectionMenuItem`.
     *
     * If `native` is `true`, `children` should be the HTML `option` element.
     */
    children: PropTypes.node,
    /**
     * If `true`, the `SelectionMenu` will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Properties applied to the wrapping `div` component. This prop should not be used if `native` is `true`.
     */
    ContainerProps: PropTypes.object,
    /**
     * Properties applied to the `Menu` component. Any other properties supplied
     * will be spread to the root element `Popover`. This prop should not be used if `native` is `true`.
     */
    MenuProps: PropTypes.object,
    /**
     * Properties applied to the [`List`](#/components/List) component. This prop should not be used if `native` is `true`.
     */
    ListProps: PropTypes.object,
    /**
     * Properties applied to the [`ListItem`](#/components/List) component. This prop should not be used if `native` is `true`.
     */
    ListItemProps: PropTypes.object,
    /**
     * Properties applied to the [`ListItemText`](#/components/List) component. This prop should not be used if `native` is `true`.
     */
    ListItemTextProps: PropTypes.object,
    /**
     * The (0-based) index of the child that will be marked as selected.
     */
    selectedIndex: PropTypes.number,
    /**
     * If `true`, render a native HTML `select` element.
     */
    native: PropTypes.bool,
    /**
     * Properties applied to the HTML `input` element, if `native` is `true`.
     */
    inputProps: PropTypes.object,
    /**
     * Callback function fired when a menu item is selected, if `native` is `true`.
     *
     * Signature:
     * `function(event: object) => void`
     *
     * `event`: The event source of the callback. You can pull out the new value by accessing `event.target.value`.
     */
    onChange: PropTypes.func,
    /**
     * The value of the HTML `input` element, if `native` is `true`.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
};

SelectionMenu.defaultProps = {
    selectedIndex: DEFAULT_SELECTED_INDEX
};

export default withStyles(styles)(SelectionMenu);