function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import defaultClassNames from 'react-day-picker/lib/src/classNames';
import { IconButton } from '@hedtech/react-design-system/core';
import { withStyles } from '@hedtech/react-design-system/core/styles';

import ChevronRight from '@eui/ds-icons/lib/ChevronRight';
import ChevronLeft from '@eui/ds-icons/lib/ChevronLeft';

import { sizingMedium, spacingSmall } from '../../styles/tokens';

var iconButtonAlignmentSpace = '0.375rem';

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0
        },
        left: {
            marginRight: 'auto',
            marginLeft: iconButtonAlignmentSpace,
            width: sizingMedium,
            height: sizingMedium,
            '& svg': {
                height: spacingSmall,
                width: spacingSmall
            }
        },
        right: {
            marginRight: iconButtonAlignmentSpace,
            marginLeft: 'auto',
            width: sizingMedium,
            height: sizingMedium,
            '& svg': {
                height: spacingSmall,
                width: spacingSmall
            }
        }
    };
};

function Navbar(props) {
    var _cn, _cn2;

    var onPreviousClick = props.onPreviousClick,
        onNextClick = props.onNextClick,
        className = props.className,
        dir = props.dir,
        labels = props.labels,
        classes = props.classes,
        showPreviousButton = props.showPreviousButton,
        showNextButton = props.showNextButton;


    var isRtl = dir === 'rtl';

    var previousButton = React.createElement(
        IconButton,
        {
            key: 'hedtech-datcepicker-prev-btn',
            color: 'gray',
            className: cn((_cn = {}, _defineProperty(_cn, classes.left, !isRtl), _defineProperty(_cn, classes.right, isRtl), _cn)),
            'aria-label': labels.previousMonth,
            onClick: function onClick() {
                return onPreviousClick();
            },
            disabled: !showPreviousButton
        },
        !isRtl ? React.createElement(ChevronLeft, null) : React.createElement(ChevronRight, null)
    );

    var nextButton = React.createElement(
        IconButton,
        {
            key: 'hedtech-datepicker-next-btn',
            color: 'gray',
            className: cn((_cn2 = {}, _defineProperty(_cn2, classes.right, !isRtl), _defineProperty(_cn2, classes.left, isRtl), _cn2)),
            'aria-label': labels.nextMonth,
            onClick: function onClick() {
                return onNextClick();
            },
            disabled: !showNextButton
        },
        !isRtl ? React.createElement(ChevronRight, null) : React.createElement(ChevronLeft, null)
    );

    return React.createElement(
        'div',
        { className: cn(className, classes.root) },
        isRtl ? [nextButton, previousButton] : [previousButton, nextButton]
    );
}

Navbar.propTypes = {
    classNames: PropTypes.shape({
        navBar: PropTypes.string.isRequired,
        navButtonPrev: PropTypes.string.isRequired,
        navButtonNext: PropTypes.string.isRequired
    }),
    className: PropTypes.string,
    showPreviousButton: PropTypes.bool,
    showNextButton: PropTypes.bool,
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    dir: PropTypes.string,
    labels: PropTypes.shape({
        previousMonth: PropTypes.string,
        nextMonth: PropTypes.string
    }),
    classes: PropTypes.object
};

Navbar.defaultProps = {
    classNames: defaultClassNames,
    dir: 'ltr',
    labels: {},
    showPreviousButton: true,
    showNextButton: true
};

export default withStyles(styles)(Navbar);