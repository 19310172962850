"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgCalendar = function SvgCalendar(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", { fill: "currentColor", d: "M6 10h48v47H6z" }),
            _react2.default.createElement("path", { fill: "#FFF", opacity: 0.88, d: "M7 11h46v46H7z" }),
            _react2.default.createElement("path", {
                d: "M50.429 7.5h-5.572V1.406C44.857.633 44.23 0 43.464 0h-.928c-.766 0-1.393.633-1.393 1.406V7.5H18.857V1.406C18.857.633 18.23 0 17.464 0h-.928c-.766 0-1.393.633-1.393 1.406V7.5H9.57C6.496 7.5 4 10.02 4 13.125v41.25C4 57.48 6.496 60 9.571 60H50.43C53.504 60 56 57.48 56 54.375v-41.25C56 10.02 53.504 7.5 50.429 7.5zM9.57 11.25H50.43c1.021 0 1.857.844 1.857 1.875v5.625H7.714v-5.625c0-1.031.836-1.875 1.857-1.875zm40.858 45H9.57a1.872 1.872 0 0 1-1.857-1.875V22.5h44.572v31.875a1.872 1.872 0 0 1-1.857 1.875zM21.179 37.5h-4.643a1.404 1.404 0 0 1-1.393-1.406v-4.688c0-.773.627-1.406 1.393-1.406h4.643c.766 0 1.392.633 1.392 1.406v4.688c0 .773-.626 1.406-1.392 1.406zm11.142 0H27.68a1.404 1.404 0 0 1-1.393-1.406v-4.688c0-.773.627-1.406 1.393-1.406h4.642c.767 0 1.393.633 1.393 1.406v4.688c0 .773-.627 1.406-1.393 1.406zm11.143 0h-4.643a1.404 1.404 0 0 1-1.392-1.406v-4.688c0-.773.626-1.406 1.392-1.406h4.643c.766 0 1.393.633 1.393 1.406v4.688c0 .773-.627 1.406-1.393 1.406zM32.321 48.75H27.68a1.404 1.404 0 0 1-1.393-1.406v-4.688c0-.773.627-1.406 1.393-1.406h4.642c.767 0 1.393.633 1.393 1.406v4.688c0 .773-.627 1.406-1.393 1.406zm-11.142 0h-4.643a1.404 1.404 0 0 1-1.393-1.406v-4.688c0-.773.627-1.406 1.393-1.406h4.643c.766 0 1.392.633 1.392 1.406v4.688c0 .773-.626 1.406-1.392 1.406zm22.285 0h-4.643a1.404 1.404 0 0 1-1.392-1.406v-4.688c0-.773.626-1.406 1.392-1.406h4.643c.766 0 1.393.633 1.393 1.406v4.688c0 .773-.627 1.406-1.393 1.406z",
                fill: "currentColor"
            })
        )
    );
};

exports.default = SvgCalendar;