var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid as MuiGrid } from '@material-ui/core';
import withWidth, { isWidthDown } from './../withWidth';
import { default as withStyles } from '../styles';

import times from 'lodash/times';
import reduce from 'lodash/reduce';
import assign from 'lodash/assign';

var GRID_SPACING_COEFFICIENT = 8;

var styles = function styles(theme) {

  // create a separate class for each available spacing class (spacing-xs-1 through
  // spacing-xs-10).

  // this is necessary because the each space increment uses
  // theme's "spacing" attribute as the multiplier for the value passed in; but we
  // want our general spacing multiplier to be a different value than our grid multipler

  // the following will produce class definitions that look like this (assuming a space
  // value of 4):
  //
  // // grid container class
  // .Grid-spacingXS4-863 {
  //     width: calc(100% + 32px);
  // }
  //
  // // grid item class
  // .Grid-spacingXS4-863 > div.grid-item {
  //     padding: 16px;
  // }
  var spacingClasses = reduce(times(10), function (accum, value) {
    var num = value + 1;
    assign(accum, _defineProperty({}, 'spacingXS' + num, {
      width: 'calc(100% + ' + GRID_SPACING_COEFFICIENT * num + 'px)',
      margin: '-' + GRID_SPACING_COEFFICIENT * num / 2 + 'px',
      '& > div.grid-item': {
        padding: GRID_SPACING_COEFFICIENT * num / 2 + 'px'
      }
    }));
    return accum;
  }, {});

  // we need to include entries for *all* classes that the underlying grid
  // component supports; otherwise we get console warnings when callers use
  // any of them

  // note that we're overriding the spacing-xs-* classes
  var gridClasses = {
    'container': {},
    'item': {},
    'zeroMinWidth': {},
    'direction-xs-column': {},
    'direction-xs-column-reverse': {},
    'direction-xs-row-reverse': {},
    'wrap-xs-nowrap': {},
    'wrap-xs-wrap-reverse': {},
    'align-items-xs-center': {},
    'align-items-xs-flex-start': {},
    'align-items-xs-flex-end': {},
    'align-items-xs-baseline': {},
    'align-content-xs-center': {},
    'align-content-xs-flex-start': {},
    'align-content-xs-flex-end': {},
    'align-content-xs-space-between': {},
    'align-content-xs-space-around': {},
    'justify-xs-center': {},
    'justify-xs-flex-end': {},
    'justify-xs-space-between': {},
    'justify-xs-space-around': {},
    'justify-xs-space-evenly': {},
    'spacing-xs-1': spacingClasses.spacingXS1,
    'spacing-xs-2': spacingClasses.spacingXS2,
    'spacing-xs-3': spacingClasses.spacingXS3,
    'spacing-xs-4': spacingClasses.spacingXS4,
    'spacing-xs-5': spacingClasses.spacingXS5,
    'spacing-xs-6': spacingClasses.spacingXS6,
    'spacing-xs-7': spacingClasses.spacingXS7,
    'spacing-xs-8': spacingClasses.spacingXS8,
    'spacing-xs-9': spacingClasses.spacingXS9,
    'spacing-xs-10': spacingClasses.spacingXS10,
    'grid-xs-auto': {},
    'grid-xs-true': {},
    'grid-xs-1': {},
    'grid-xs-2': {},
    'grid-xs-3': {},
    'grid-xs-4': {},
    'grid-xs-5': {},
    'grid-xs-6': {},
    'grid-xs-7': {},
    'grid-xs-8': {},
    'grid-xs-9': {},
    'grid-xs-10': {},
    'grid-xs-11': {},
    'grid-xs-12': {}
  };

  return gridClasses;
};

/**
 * Use `Grid` to create a responsive layout.
 * @done true
 * @updated false
 * @versionAdded v0.1.2
 * @examples
 *  GridExample
 *  InteractiveFlexBoxExample
 */

var Grid = function (_Component) {
  _inherits(Grid, _Component);

  function Grid() {
    _classCallCheck(this, Grid);

    return _possibleConstructorReturn(this, (Grid.__proto__ || Object.getPrototypeOf(Grid)).apply(this, arguments));
  }

  _createClass(Grid, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          width = _props.width,
          classes = _props.classes,
          className = _props.className,
          rest = _objectWithoutProperties(_props, ['width', 'classes', 'className']);

      var propOverrides = {};
      if (this.props.container) {
        propOverrides.spacing = isWidthDown('xs', width) ? 2 : 3;
      }

      var gridClassName = this.props.container ? 'grid-container' : 'grid-item';

      return React.createElement(MuiGrid, Object.assign({}, propOverrides, rest, {
        classes: classes,
        className: className ? className + ' ' + gridClassName : gridClassName
      }));
    }
  }]);

  return Grid;
}(Component);

Grid.muiName = 'Grid';

Grid.propTypes = {
  /**
   * Defines the `align-content` style property.
   * It's applied for all screen sizes.
   * Options include: "stretch", "center", "flex-start", "flex-end", "space-between", "space-around".
   */
  alignContent: PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
  /**
   * Defines the `align-items` style property.
   * It's applied for all screen sizes.
   * Options include: "flex-start", "center", "flex-end", "stretch", and "baseline".
   */
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element, an component, or a function that returns a component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  /**
   * If `true`, the component will have the flex *container* behavior.
   * You should be wrapping *items* with a *container*.
   */
  container: PropTypes.bool,
  /**
   * Defines the `flex-direction` style property.
   * It is applied for all screen sizes.
   * Options include: "row", "row-reverse", "column", "column-reverse".
   */
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  /**
   * If `true`, the component will have the flex *item* behavior.
   * You should be wrapping *items* with a *container*.
   */
  item: PropTypes.bool,
  /**
   * Defines the `justify-content` style property.
   * It is applied for all screen sizes.
   * Options include: "flex-start", "center", "flex-end", "space-between", "space-around", "space-evenly".
   */
  justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
  /**
   * Defines the number of columns the component is going to use.
   * It's applied for the `lg` breakpoint and wider screens if not overridden.
   * Options include: false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12.
   */
  lg: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /**
   * Defines the number of columns the component is going to use.
   * It's applied for the `md` breakpoint and wider screens if not overridden.
   * Options include: false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12.
   */
  md: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /**
   * Defines the number of columns the component is going to use.
   * It's applied for the `sm` breakpoint and wider screens if not overridden.
   * Options include: false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12.
   */
  sm: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /**
   * Defines the space between the type `item` component.
   * It can only be used on a type `container` component.
   * Options include: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10.
   */
  spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  /**
   * Defines the `flex-wrap` style property.
   * It's applied for all screen sizes.
   * Options include: "nowrap", "wrap", "wrap-reverse".
   */
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  /**
   * Defines the number of columns the component is going to use.
   * It's applied for the `xl` breakpoint and wider screens.
   * Options include: false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12.
   */
  xl: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /**
   * Defines the number of columns the component is going to use.
   * It's applied for all the screen sizes with the lowest priority.
   * Options include: false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12.
   */
  xs: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /**
   * @ignore
   */
  width: PropTypes.string.isRequired,
  /**
   * If `true`, it sets `min-width: 0` on the item.
   * Refer to the limitations section of the documentation to better understand the use case.
   * https://material-ui.com/layout/grid/#limitations
   */
  zeroMinWidth: PropTypes.bool
};

// explicitly put grid at the back of the JSS style queue, so that the classes that consumers
// provide (via the classes prop) override out own settings
export default withWidth()(withStyles(styles, { index: 0 })(Grid));