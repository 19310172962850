'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Popper = require('@material-ui/core/Popper');

var _Popper2 = _interopRequireDefault(_Popper);

var _Paper = require('../Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _tokens = require('@hedtech/react-design-system/core/styles/tokens');

var _styles = require('@hedtech/react-design-system/core/styles');

var _styles2 = _interopRequireDefault(_styles);

var _ClickAwayListener = require('@material-ui/core/ClickAwayListener');

var _ClickAwayListener2 = _interopRequireDefault(_ClickAwayListener);

var _Fade = require('@material-ui/core/Fade');

var _Fade2 = _interopRequireDefault(_Fade);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        paperRoot: {
            padding: _tokens.spacingSmall,
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
            borderRadius: _tokens.borderRadiusMedium
        },
        popper: {
            zIndex: theme.zIndex.pageAlert - 1,
            // Arrow styling:
            // https://github.com/twbs/bootstrap/blob/master/dist/css/bootstrap.css#L5655
            '& .hedtech-popper-arrow': {
                position: 'absolute',
                display: 'block',
                width: '1rem',
                height: '0.5rem',
                margin: '0 0.3rem',
                '&::before, &::after': {
                    position: 'absolute',
                    display: 'block',
                    content: '""',
                    borderColor: 'transparent',
                    borderStyle: 'solid'
                }
            },
            '&[x-placement*="top"] .hedtech-popper-arrow': {
                bottom: '-.5rem',
                '&::before': {
                    bottom: 0,
                    borderWidth: '0.5rem 0.5rem 0',
                    borderTopColor: theme.palette.grey[400]
                },
                '&::after': {
                    bottom: 1,
                    borderWidth: '0.5rem 0.5rem 0',
                    borderTopColor: theme.palette.grey[100]
                }
            },
            '&[x-placement*="right"] .hedtech-popper-arrow': {
                left: '-.5rem',
                width: '0.5rem',
                height: '1rem',
                margin: '0.3rem 0',
                '&::before': {
                    left: 0,
                    borderWidth: '0.5rem 0.5rem 0.5rem 0',
                    borderRightColor: theme.palette.grey[400]
                },
                '&::after': {
                    left: 1,
                    borderWidth: '0.5rem 0.5rem 0.5rem 0',
                    borderRightColor: theme.palette.grey[100]
                }
            },
            '&[x-placement*="bottom"] .hedtech-popper-arrow': {
                top: '-.5rem',
                '&::before': {
                    top: 0,
                    borderWidth: '0 0.5rem 0.5rem 0.5rem',
                    borderBottomColor: theme.palette.grey[400]
                },
                '&::after': {
                    top: 1,
                    borderWidth: '0 0.5rem 0.5rem 0.5rem',
                    borderBottomColor: theme.palette.grey[100]
                }
            },
            '&[x-placement*="left"] .hedtech-popper-arrow': {
                right: '-.5rem',
                width: '0.5rem',
                height: '1rem',
                margin: '0.3rem 0',
                '&::before': {
                    right: 0,
                    borderWidth: '0.5rem 0 0.5rem 0.5rem',
                    borderLeftColor: theme.palette.grey[400]
                },
                '&::after': {
                    right: 1,
                    borderWidth: '0.5rem 0 0.5rem 0.5rem',
                    borderLeftColor: theme.palette.grey[100]
                }
            }
        },
        popperText: {
            marginBottom: _tokens.spacingSmall
        },
        popperTitle: _defineProperty({ // h2 styles
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeHeader2,
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightHeader2Small
        }, '@media ' + _tokens.mqXSmall, {
            fontSize: _tokens.fontSizeHeader2Small,
            lineHeight: _tokens.lineHeightHeader2
        })
    };
};

/**
 * Use `Popper` on top of content to give additional context or information.
 * @done true
 * @updated false
 * @versionAdded v2.3.0
 * @examples
 *  MinimalPopper
 *  CustomContent
 *  CloseOnClickaway
 *  ScrollPlayground
 */

var Popper = _react2.default.forwardRef(function (props, ref) {
    var arrow = props.arrow,
        children = props.children,
        classes = props.classes,
        classNameProp = props.className,
        modifiersProp = props.modifiers,
        PaperPropsProp = props.PaperProps,
        text = props.text,
        title = props.title,
        TextTypographyProps = props.TextTypographyProps,
        TitleTypographyProps = props.TitleTypographyProps,
        onClickAway = props.onClickAway,
        ClickAwayListenerProps = props.ClickAwayListenerProps,
        transitionProps = props.transitionProps,
        rest = _objectWithoutProperties(props, ['arrow', 'children', 'classes', 'className', 'modifiers', 'PaperProps', 'text', 'title', 'TextTypographyProps', 'TitleTypographyProps', 'onClickAway', 'ClickAwayListenerProps', 'transitionProps']);

    var _useState = (0, _react.useState)(null),
        _useState2 = _slicedToArray(_useState, 2),
        arrowRef = _useState2[0],
        setArrowRef = _useState2[1];

    var handleArrowRef = function handleArrowRef(node) {
        return setArrowRef(node);
    };
    var PaperProps = Object.assign({}, PaperPropsProp);

    // This line will help screen read to read popper content.
    if (!PaperProps['aria-live']) {
        PaperProps['aria-live'] = 'assertive';
    }

    var addSpace = function addSpace(data) {
        var newData = data;

        // Add additional space to Popper element (16px)
        if (data.placement.indexOf('top') !== -1) {
            newData.offsets.popper.top = data.offsets.popper.top - 16;
        } else if (data.placement.indexOf('right') !== -1) {
            newData.offsets.popper.left = data.offsets.popper.left + 16;
        } else if (data.placement.indexOf('bottom') !== -1) {
            newData.offsets.popper.top = data.offsets.popper.top + 16;
        } else if (data.placement.indexOf('left') !== -1) {
            newData.offsets.popper.left = data.offsets.popper.left - 16;
        }
        return newData;
    };

    var modifiers = Object.assign({
        arrow: {
            enabled: arrow,
            element: arrowRef
        },
        flip: {
            enabled: true
        },
        offset: {
            fn: addSpace
        }
    }, modifiersProp);

    var className = (0, _classnames2.default)(classes.popper, classNameProp);

    // Build Popper content
    var PopperContent = _react2.default.createElement(
        'div',
        null,
        arrow && _react2.default.createElement('span', { className: 'hedtech-popper-arrow', ref: handleArrowRef }),
        _react2.default.createElement(
            _Paper2.default,
            Object.assign({
                elevation: 0,
                classes: {
                    root: classes.paperRoot
                }
            }, PaperProps, {
                ref: ref
            }),
            title && _react2.default.createElement(
                _Typography2.default,
                Object.assign({
                    classes: { root: classes.popperTitle },
                    gutterBottom: true
                }, TitleTypographyProps),
                title
            ),
            text && _react2.default.createElement(
                _Typography2.default,
                Object.assign({
                    gutterBottom: true,
                    className: (0, _classnames2.default)(_defineProperty({}, classes.popperText, children !== undefined))
                }, TextTypographyProps),
                text
            ),
            children
        )
    );

    var Content = onClickAway ? _react2.default.createElement(
        _ClickAwayListener2.default,
        Object.assign({ onClickAway: onClickAway }, ClickAwayListenerProps),
        PopperContent
    ) : PopperContent;

    // NOTE: Structure must be, Popper > Transition > ClickAway > Content

    return rest.transition ? _react2.default.createElement(
        _Popper2.default,
        Object.assign({ className: className, modifiers: modifiers }, rest),
        function (_ref) {
            var TransitionProps = _ref.TransitionProps;
            return _react2.default.createElement(
                _Fade2.default,
                Object.assign({}, TransitionProps, transitionProps),
                Content
            );
        }
    ) : _react2.default.createElement(
        _Popper2.default,
        Object.assign({ className: className, modifiers: modifiers }, rest),
        Content
    );
});

Popper.muiName = 'Popper';
Popper.displayName = 'Popper';

Popper.propTypes = {
    /**
     * This is the DOM element, or a function that returns the DOM element,
     * that may be used to set the position of the popover.
     * The return value will passed as the reference object of the `Popper`
     * instance.
     */
    anchorEl: _propTypes2.default.oneOfType([_propTypes2.default.object, _propTypes2.default.func]),
    /**
     * If `true`, popper will display the arrow.
     */
    arrow: _propTypes2.default.bool,
    /**
     * `Popper` render function or node.
     */
    children: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]),
    /**
     * @ignore
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * A node, component instance, or function that returns either.
     * The `container` will passed to the Modal component.
     * By default, it uses the body of the `anchorEl`'s top-level document object,
     * so it's simply `document.body` most of the time.
     */
    container: _propTypes2.default.oneOfType([_propTypes2.default.object, _propTypes2.default.func]),
    /**
     * Disable the portal behavior.
     * The children stay within its parent DOM hierarchy.
     */
    disablePortal: _propTypes2.default.bool,
    /**
     * Always keep the children in the DOM.
     */
    keepMounted: _propTypes2.default.bool,
    /**
     * Popper.js is based on a "plugin-like" architecture,
     * most of its features are fully encapsulated "modifiers".
     *
     * A modifier is a function that is called each time Popper.js needs to
     * compute the position of the `Popper`.
     * For this reason, modifiers should be very performant to avoid bottlenecks.
     * To learn how to create a modifier, [read the modifiers documentation](https://github.com/FezVrasta/popper.js/blob/master/docs/_includes/popper-documentation.md#modifiers--object).
     */
    modifiers: _propTypes2.default.object,
    /**
     * If `true`, the popper is visible.
     */
    open: _propTypes2.default.bool.isRequired,
    /**
     * Props provided to the `Paper` component
     */
    PaperProps: _propTypes2.default.object,
    /**
     * Props provided to the text `Typography` component
     */
    TextTypographyProps: _propTypes2.default.object,
    /**
     * Props provided to the title `Typography` component
     */
    TitleTypographyProps: _propTypes2.default.object,
    /**
     * `Popper` placement.
     */
    placement: _propTypes2.default.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
    /**
     * Options provided to the [`popper.js`](https://github.com/FezVrasta/popper.js) instance.
     */
    popperOptions: _propTypes2.default.object,
    /**
     * Paragraph text that will render below the title and above the content.
     */
    text: _propTypes2.default.string,
    /**
     * An `h2` element that renders above the paragraph text.
     */
    title: _propTypes2.default.string,
    /**
     * Help supporting a react-transition-group/Transition component.
     */
    transition: _propTypes2.default.bool,
    /**
     * Properties applied to the Transition component.
     */
    transitionProps: _propTypes2.default.object,
    // /**
    //  * The component used for the transition.
    //  */
    // TransitionComponent: PropTypes.oneOf([
    //     'Collapse', 'Fade', 'Grow', 'Slide', 'Zoom'
    // ]),
    /**
     * Function to perform when clicked outside of the `Popper`.
     */
    onClickAway: _propTypes2.default.func,
    /**
     * Props provided to the `ClickAwayListener` component
     */
    ClickAwayListenerProps: _propTypes2.default.object
};

Popper.defaultProps = {
    placement: 'bottom',
    transition: true
};

exports.default = (0, _styles2.default)(styles)(Popper);