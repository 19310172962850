var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import React, { forwardRef } from 'react';
import EdsIconButton from '@hedtech/react-design-system/core/es/IconButton';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    notSelectedStatus: {
        border: `${Tokens.borderWidthThin} solid ${Tokens.colorBrandNeutral300}`
    },
    root: {
        '& *': {
            pointerEvents: 'none'
        }
    },
    selectedStatus: {
        backgroundColor: Tokens.colorBrandNeutral300
    }
});
const IconButton = (props, ref) => {
    const { children, classes, selected, title } = props, rest = __rest(props, ["children", "classes", "selected", "title"]);
    const classOverrides = omit(classes, ['notSelectedStatus', 'selectedStatus']);
    if (selected === true) {
        classOverrides.root = classnames(classes.root, classes.selectedStatus);
    }
    else if (selected === false) {
        classOverrides.root = classnames(classes.root, classes.notSelectedStatus);
    }
    return (React.createElement(EdsIconButton, Object.assign({ "aria-label": title, classes: classOverrides, title: title, ref: ref }, rest), children));
};
const IconButtonFRC = forwardRef(IconButton);
IconButtonFRC.displayName = 'IconButtonFRC';
export default withStyles(styles, { name: 'IconButtonWS' })(IconButtonFRC);
