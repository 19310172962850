"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgUnderMaintenance = function SvgUnderMaintenance(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 176, height: 169, viewBox: "0 0 176 169" }, props),
        _react2.default.createElement(
            "g",
            { transform: "translate(0 -1)", fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("rect", {
                fill: "#F0F0F0",
                y: 28.632,
                width: 175.23,
                height: 140.552,
                rx: 4.277
            }),
            _react2.default.createElement("rect", {
                fill: "#FFF",
                x: 13.86,
                y: 44.469,
                width: 22.77,
                height: 9.898,
                rx: 2.851
            }),
            _react2.default.createElement("rect", {
                fill: "#FFF",
                x: 13.86,
                y: 61.295,
                width: 50.49,
                height: 95.021,
                rx: 2.851
            }),
            _react2.default.createElement("path", {
                d: "M80.731 60.295v2h-6.6v-2h6.6zm12.1 0v2h-6.6v-2h6.6zm12.1 0v2h-6.6v-2h6.6zm12.1 0v2h-6.6v-2h6.6zm12.1 0v2h-6.6v-2h6.6zm12.1 0v2h-6.6v-2h6.6zm12.1 0v2h-6.6v-2h6.6zm9.039 6.4h-2V64.13a1.852 1.852 0 0 0-1.647-1.825l.216-1.988a3.854 3.854 0 0 1 3.43 3.805l.001 2.572zm0 12.1h-2v-6.6h2v6.6zm0 12.1h-2v-6.6h2v6.6zm0 12.1h-2v-6.6h2v6.6zm0 12.1h-2v-6.6h2v6.6zm0 12.1h-2v-6.6h2v6.6zm0 12.1h-2v-6.6h2v6.6zm0 12.1h-2v-6.6h2v6.6zm-9.516 5.921v-2h5.702c.207-.004.407-.042.596-.11l.685 1.879a3.868 3.868 0 0 1-1.262.23l-5.721.001zm-12.1 0v-2h6.6v2h-6.6zm-12.1 0v-2h6.6v2h-6.6zm-12.1 0v-2h6.6v2h-6.6zm-12.1 0v-2h6.6v2h-6.6zm-12.1 0v-2h6.6v2h-6.6zm-12.1 0v-2h6.6v2h-6.6zm-9.974-5.464h2v1.613c0 1.023.829 1.851 1.851 1.851h.623v2h-.623a3.851 3.851 0 0 1-3.851-3.85v-1.614zm0-12.1h2v6.6h-2v-6.6zm0-12.1h2v6.6h-2v-6.6zm0-12.1h2v6.6h-2v-6.6zm0-12.1h2v6.6h-2v-6.6zm0-12.1h2v6.6h-2v-6.6zm0-12.1h2v6.6h-2v-6.6zm0-12.1h2v6.6h-2v-6.6zm3.851-6.857v2c-.421 0-.818.14-1.142.395l-1.236-1.573a3.838 3.838 0 0 1 2.378-.822z",
                fill: "currentColor"
            }),
            _react2.default.createElement("rect", {
                fill: "currentColor",
                x: 75.79,
                y: 65.092,
                width: 80.3,
                height: 86,
                rx: 4.4
            }),
            _react2.default.createElement("rect", {
                fill: "#FFF",
                opacity: 0.85,
                x: 75.79,
                y: 65.092,
                width: 80.3,
                height: 86,
                rx: 4.4
            }),
            _react2.default.createElement("rect", {
                fill: "#FFF",
                x: 42.57,
                y: 44.469,
                width: 121.77,
                height: 9.898,
                rx: 2.851
            }),
            _react2.default.createElement(
                "g",
                { transform: "rotate(-9 122.614 -147.565)" },
                _react2.default.createElement("rect", {
                    fill: "currentColor",
                    x: 0.99,
                    y: 0.99,
                    width: 109.89,
                    height: 122.735,
                    rx: 2
                }),
                _react2.default.createElement("rect", {
                    fill: "#FFF",
                    x: 3,
                    y: 3,
                    width: 106,
                    height: 118,
                    rx: 1.929
                }),
                _react2.default.createElement("rect", {
                    fill: "currentColor",
                    x: 6,
                    y: 6,
                    width: 100,
                    height: 111,
                    rx: 1.82
                }),
                _react2.default.createElement("rect", {
                    fill: "#FFF",
                    opacity: 0.85,
                    x: 6,
                    y: 6,
                    width: 100,
                    height: 111,
                    rx: 1.82
                }),
                _react2.default.createElement("path", {
                    fill: "#FFF",
                    d: "M22.397 22.397h67.789v7.136H22.397zM22.397 37.382h67.789v7.136H22.397zM22.397 52.367h67.789v7.136H22.397zM22.397 67.353h67.789v7.136H22.397zM22.397 82.338h67.789v7.136H22.397zM22.397 97.323h67.789v7.136H22.397z"
                })
            )
        )
    );
};

exports.default = SvgUnderMaintenance;