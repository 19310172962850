'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.styles = exports.TEXT_STYLING_INLINE = exports.TEXT_STYLING_STANDALONE = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// text styling variants
var TEXT_STYLING_STANDALONE = exports.TEXT_STYLING_STANDALONE = 'standalone';
var TEXT_STYLING_INLINE = exports.TEXT_STYLING_INLINE = 'inline';

/**
 * TODO: Need to implement the css for outlined buttons now that they are available.
 */
var styles = exports.styles = function styles(theme) {
    return {
        // Shared Button styles
        root: {
            // fontFamily is being updated from theme object for fontFamilies: { button: ... }
            lineHeight: 1.5,
            boxShadow: 'none',
            textTransform: 'none',
            color: theme.palette.text.white,
            borderRadius: _tokens.borderRadiusXLarge,
            padding: _tokens.spacingXxSmall + ' ' + _tokens.spacingMedium,
            fontSize: theme.typography.body1.fontSize,
            minHeight: _tokens.sizingXLarge,
            outline: 'none',
            '&$disabled': {
                color: theme.palette.text.white
            },
            '& svg': {
                flex: '1 0 auto'
            }
        },
        // Sizes
        sizeSmall: {
            fontSize: theme.typography.body2.fontSize,
            minHeight: '2.375rem',
            padding: _tokens.spacingXxSmall + ' ' + _tokens.spacingSmall
        },
        sizeLarge: {
            fontSize: theme.typography.body1.fontSize,
            minHeight: '3.125rem',
            padding: _tokens.spacingXxSmall + ' ' + _tokens.spacingLarge
        },
        // Shared Disabled Button styles
        disabled: {
            backgroundColor: theme.palette.disabled.background,
            color: theme.palette.grey[100]
        },
        // Primary Button
        contained: {
            backgroundColor: theme.palette.ctaColor.base,
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                borderColor: theme.palette.ctaColor.hover
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.ctaColor.active,
                borderColor: theme.palette.ctaColor.active
            },
            '&$disabled': {
                boxShadow: 'none',
                border: 'none',
                backgroundColor: theme.palette.disabled.background,
                color: theme.palette.text.white
            },
            '&:focus': {
                outline: 'none',
                boxShadow: _tokens.boxShadowFocus
            },
            '&:focus:hover': {
                backgroundColor: theme.palette.ctaColor.active,
                borderColor: theme.palette.ctaColor.active,
                boxShadow: _tokens.boxShadowFocus
            }
        },
        textPrimary: {
            backgroundColor: 'transparent',
            color: _tokens.colorCtaBlueBase, // text buttons do not respond to theme changes
            borderRadius: _tokens.borderRadiusSmall,
            minWidth: 'inherit',
            minHeight: 'inherit',
            height: 'auto',
            padding: '0 ' + _tokens.spacingXxSmall + ' 0 ' + _tokens.spacingXxSmall,
            textDecoration: 'underline',
            '&$disabled': {
                boxShadow: 'none',
                border: 'none',
                color: theme.palette.action.disabled,
                backgroundColor: 'transparent'
            },
            '&:focus:active': {
                color: theme.palette.ctaColor.active,
                boxShadow: 'none'
            },
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.ctaColor.hover,
                textDecoration: 'underline',
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none',
                boxShadow: _tokens.boxShadowFocus
            },
            '&:focus:hover': {
                boxShadow: 'none'
            }
        },
        textStandalone: {
            fontFamily: _tokens.fontFamilyDefault,
            fontWeight: _tokens.fontWeightStrong
        },
        textInline: {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            verticalAlign: 'inherit',
            padding: 0
        },
        textPrimaryDark: {
            color: theme.palette.grey[100],
            '&:hover': {
                color: theme.palette.grey[200]
            },
            '&:focus:active': {
                color: theme.palette.grey[300]
            }
        },
        // Secondary Button
        containedSecondary: {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.ctaColor.base,
            border: _tokens.borderWidthThin + ' solid',
            '&:hover': {
                backgroundColor: theme.palette.ctaColor.hover,
                borderColor: theme.palette.ctaColor.hover,
                color: theme.palette.grey[100]
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.ctaColor.active,
                borderColor: theme.palette.ctaColor.active,
                color: theme.palette.grey[100]
            },
            '&$disabled': {
                boxShadow: 'none',
                border: 'none',
                backgroundColor: theme.palette.disabled.background,
                color: theme.palette.text.white
            },
            '&:focus': {
                outline: 'none',
                boxShadow: _tokens.boxShadowFocus
            },
            '&:focus:hover': {
                backgroundColor: theme.palette.ctaColor.active,
                borderColor: theme.palette.ctaColor.active,
                boxShadow: _tokens.boxShadowFocus,
                color: theme.palette.text.white
            }
        }
        // Outlined => Inverted Buttons
        // outlined: {
        //     backgroundColor: "transparent",
        //     color: "#FFFFFF",
        //     border: "1px solid #FFFFFF",
        //     "&:hover": {
        //         backgroundColor: "#FFFFFF",
        //         color: "transparent" // TODO: verify with UXD about color fo rtext on hover, pressed, focused
        //     },
        //     "&:active": {
        //         boxShadow: "none",
        //         backgroundColor: "#0A4982",
        //         borderColor: "#0A4982",
        //         color: "#FFFFFF"
        //     },
        //     "&$disabled": {
        //         color: "#FFFFFF", // FIXME: not showing up properly, being overridden
        //         boxShadow: "none",
        //         backgroundColor: "#B2B3B7",
        //         border: "none"
        //     },
        //     "&:focus": {
        //         boxShadow: "0 0 0 2px #FFFFFF"
        //     },
        //     "&:focus:hover": {
        //         backgroundColor: "#FFFFFF",
        //         borderColor: "#FFFFFF",
        //         boxShadow: "0 0 0 2px #FFFFFF",
        //         color: "transparent"
        //     }
        // },
    };
};

/**
 * Use `Button` to show a call to action.
 * @done true
 * @updated false
 * @versionAdded v0.0.5
 * @examples
 *  SimpleExample
 *  SizesExample
 *  FluidExample
 *  TextWithIconExample
 *  ButtonGroupExample
 *  TextButtonExample
 *  TextOverflowExample
 */
var Button = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        fluid = props.fluid,
        textVariantStyling = props.textVariantStyling,
        variant = props.variant,
        withDarkBackground = props.withDarkBackground,
        rest = _objectWithoutProperties(props, ['classes', 'fluid', 'textVariantStyling', 'variant', 'withDarkBackground']);

    var textClass = classes.textPrimary + '\n        ' + (withDarkBackground ? classes.textPrimaryDark : '') + '\n        ' + (textVariantStyling === TEXT_STYLING_INLINE ? classes.textInline : classes.textStandalone) + '\n    ';

    var classOverrides = {
        root: classes.root,
        contained: classes.contained,
        text: textClass,
        containedSecondary: classes.containedSecondary,
        sizeSmall: classes.sizeSmall,
        sizeLarge: classes.sizeLarge,
        disabled: classes.disabled
    };

    var propOverrides = {
        disableRipple: true, // disable the click ripple by default
        disableFocusRipple: true // disable the default MUI focus ripple effect
    };

    if (variant === 'text') {
        // Need to override these props to prevent styling issues
        propOverrides.color = 'primary';
        propOverrides.fullWidth = false;
    };

    return _react2.default.createElement(
        _Button2.default,
        Object.assign({
            variant: variant,
            classes: classOverrides,
            fullWidth: fluid,
            ref: ref
        }, rest, propOverrides),
        props.children
    );
});

Button.propTypes = {
    /**
     * Class name to assign the component.
     */
    className: _propTypes2.default.string,
    /**
     * Classes used to extend original style object.
     */
    classes: _propTypes2.default.object,
    /**
     * The content of the button.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * The color of the component.
     */
    color: _propTypes2.default.oneOf(['primary', 'secondary']),
    /**
     * If `true`, the button will be disabled.
     */
    disabled: _propTypes2.default.bool,
    /**
     * If `true`, the button will take up the full width of its container.
     */
    fluid: _propTypes2.default.bool,
    /**
     * The URL to link to when the button is clicked.
     * If defined, an `a` element will be used as the root node.
     */
    href: _propTypes2.default.string,
    /**
     * The size of the button.
     *
     * **DEPRECATION NOTICE**
     *
     * In the next major version:
     *
     * 1. The `large` button size will be removed
     * 2. The `medium` button size will be renamed 'default'
     *
     */
    size: _propTypes2.default.oneOf(['small', 'medium', 'large']),
    /**
     * The font configuration of the `text` variant
     *
     * * `standalone` - Use standard text-button styling
     * * `inline` - Inherits styling from context. Used for text buttons placed inline with other text.
     */
    textVariantStyling: _propTypes2.default.oneOf(['standalone', 'inline']),
    /**
     * The HTML button type.
     */
    type: _propTypes2.default.string,
    /**
     * The type of button.
     */
    variant: _propTypes2.default.oneOf(['contained', 'text']),
    /**
     * With `variant="text"`, use this if you want to render a button on Snackbar or any dark background.
     */
    withDarkBackground: _propTypes2.default.bool
    // We should use "flat" for our "text" buttons, and "outlined" for our "inverted", and "fab" for our floating action button.
};

Button.defaultProps = {
    variant: 'contained',
    color: 'primary',
    size: 'medium',
    textVariantStyling: 'standalone',
    fluid: false,
    withDarkBackground: false
};

// necessary to add an explicit display name, since docgen can't seem to
// extract one from a forwardRef'd component
Button.displayName = 'Button';

/**
 * We order Button styles at index: 0 to avoid improper ordering
 * of Dropdown styles.
 */
exports.default = (0, _styles2.default)(styles, { name: 'Button', index: 0 })(Button);