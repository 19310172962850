function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { Step as MuiStep } from '@material-ui/core';
import { STEP_PROGRESS_STEP_CLASSNAME } from './internal/constants';

var styles = function styles(theme) {
    return {
        root: {
            borderColor: theme.palette.ctaColor.base
        }
    };
};

/**
 * A single step in a stepper
 */
var Step = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        root: classes.root
    };

    return (
        /* These marker class names will be used by its parent component StepProgress */
        React.createElement(MuiStep, Object.assign({}, rest, {
            className: STEP_PROGRESS_STEP_CLASSNAME,
            classes: classOverrides,
            ref: ref
        }))
    );
});

Step.muiName = 'Step';
Step.displayName = 'Step';

Step.propTypes = {
    /**
     * Sets the step as active. Is passed to child components.
     */
    active: PropTypes.bool,
    /**
     * Should be `Step` sub-components such as `StepLabel`, `StepContent`.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * Mark the step as disabled, will also disable the button if
     * `StepButton` is a child of `Step`. Is passed to child components.
     */
    disabled: PropTypes.bool
};

// NOTE: we are very intentionally NOT providing defaults for these props; if any
// value is assigned to the active, completed, or disabled props, those values will
// supercede the directives of the parent StepProgress; ie, if the stepper tries to
// set a Step to active, any defaults we declare here will override those settings

export default withStyles(styles)(Step);