import { LogType } from '../types/LogType';
import LogData from '../helpers/LogData';
import Constants from './Constants';
import LayoutStore from '../flux/stores/LayoutStore';
const onRejectRequest = (info) => {
    console.warn('The request was rejected');
    console.debug(info);
};
const onCatchRequest = (ex, functionName, resolveError) => {
    if (ex.code !== 20) {
        const logData = LogData.fromException(functionName, ex);
        logData.type = LogType.noConnection;
        if (resolveError) {
            resolveError(logData);
        }
        Constants.redirectToError500();
    }
};
const onResolveRequest = (response, functionName, resolver, resolveError) => {
    if (!(response && response.ok)) {
        if (resolveError) {
            resolveError(LogData.fromResponse(functionName, response))
                .then(() => Constants.redirectToError500());
        }
        else {
            Constants.redirectToError500();
        }
    }
    else if (response.redirected) {
        if (response.url.indexOf('LogIn?ReturnUrl') >= 0) {
            window.location.assign(window.location.href);
        }
        if (response.url.indexOf('Error403') >= 0) {
            Constants.redirectToError403();
        }
    }
    else {
        if (resolver) {
            response.json()
                .then(resolver)
                .catch(ex => {
                if (resolveError) {
                    resolveError(LogData.fromException(functionName, ex));
                }
            });
        }
    }
};
const Request = {
    get(functionName, url, resolver, resolveError, signal) {
        try {
            fetch(`${Constants.webUrl}${url}`, {
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: Constants.headersRequestsJson,
                method: 'get',
                signal: signal ? signal : LayoutStore.getSignal()
            })
                .then(response => onResolveRequest(response, functionName, resolver, resolveError), onRejectRequest)
                .catch(ex => onCatchRequest(ex, functionName, resolveError));
        }
        catch (e) {
            resolveError(LogData.fromException(functionName, e));
        }
    },
    post(functionName, url, data, resolver, resolveError, signal) {
        try {
            fetch(`${Constants.webUrl}${url}`, {
                body: JSON.stringify(data),
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: Constants.headersRequestsJson,
                method: 'post',
                signal: signal ? signal : LayoutStore.getSignal()
            })
                .then(response => onResolveRequest(response, functionName, resolver, resolver), onRejectRequest)
                .catch(ex => onCatchRequest(ex, functionName, resolveError));
        }
        catch (e) {
            resolveError(LogData.fromException(functionName, e));
        }
    }
};
export default Request;
