'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _CardHeader = require('@material-ui/core/CardHeader');

var _CardHeader2 = _interopRequireDefault(_CardHeader);

var _Card = require('./Card');

var _tokens = require('../styles/tokens');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: {},
        standard: {
            padding: _tokens.spacingLarge + ' ' + _tokens.spacingLarge + ' ' + _tokens.spacingSmall
        },
        dense: {
            padding: _tokens.spacingMedium + ' ' + _tokens.spacingMedium + ' ' + _tokens.spacingXSmall2
        },
        none: {
            padding: 0
        },
        action: {
            marginRight: 0,
            marginTop: 0
        },
        responsive: _defineProperty({}, theme.breakpoints.only('xs'), {
            padding: _tokens.spacingSmall + ' ' + _tokens.spacingSmall + ' ' + _tokens.spacingXSmall
        })
    };
};

/**
 * CardHeader component for displaying header on a Card component
 */
var CardHeader = _react2.default.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var _useContext = (0, _react.useContext)(_Card.CardContext),
        padding = _useContext.padding,
        responsive = _useContext.responsive;

    var classOverrides = {
        root: (0, _classnames2.default)((_classNames = {}, _defineProperty(_classNames, classes.standard, padding === 'standard'), _defineProperty(_classNames, classes.dense, padding === 'dense'), _defineProperty(_classNames, classes.none, padding === 'none'), _defineProperty(_classNames, classes.responsive, responsive && ['standard', 'dense'].indexOf(padding) !== -1), _classNames), classes.root),
        action: classes.action
    };

    return _react2.default.createElement(_CardHeader2.default, Object.assign({
        classes: classOverrides,
        titleTypographyProps: {
            variant: 'h2'
        }
    }, rest, {
        ref: ref
    }));
});

CardHeader.muiName = 'CardHeader';
CardHeader.displayName = 'CardHeader';

CardHeader.propTypes = {
    /**
     * The action to display in the card header.
     */
    action: _propTypes2.default.node,
    /**
     * The Avatar for the Card Header.
     */
    avatar: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
    /**
     * If true, the children won't be wrapped by a Typography component.
     * This can be useful to render an alternative Typography variant by
     * wrapping the title text, and optional subheader text with the
     * Typography component.
     */
    disableTypography: _propTypes2.default.bool,
    /**
     * The content of the component.
     */
    subheader: _propTypes2.default.node,
    /**
     * These props will be forwarded to the subheader (as
     * long as disableTypography is not true).
     */
    subheaderTypographyProps: _propTypes2.default.object,
    /**
     * The content of the Card Title.
     */
    title: _propTypes2.default.node,
    /**
     * These props will be forwarded to the title (as
     * long as disableTypography is not `true`).
     */
    titleTypographyProps: _propTypes2.default.object
};

// If default props are needed, they must be set here
CardHeader.defaultProps = {
    component: 'div',
    disableTypography: false
};

exports.default = (0, _styles2.default)(styles)(CardHeader);