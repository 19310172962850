'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.styles = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _core = require('@material-ui/core');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = exports.styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    row: {
        flexDirection: 'row'
    }
};

/**
 * Wraps controls such as Checkbox and Switch, with compact and changeable layout.
 */
var FormGroup = _react2.default.forwardRef(function (props, ref) {
    return _react2.default.createElement(_core.FormGroup, Object.assign({}, props, { ref: ref }));
});

FormGroup.muiName = 'FormGroup';
FormGroup.displayName = 'FormGroup';

FormGroup.propTypes = {
    /**
     * The content of the component.
     */
    children: _propTypes2.default.node,
    /**
     * Useful to extend the style applied to components.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * Display group of elements in a compact row.
     */
    row: _propTypes2.default.bool
};

FormGroup.defaultProps = {
    row: false
};

exports.default = (0, _styles2.default)(styles)(FormGroup);