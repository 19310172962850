'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Typography = require('./../../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _EDSApplication = require('./../../EDSApplication');

var _styles = require('./../../styles');

var _ErrorPageAction = require('./ErrorPageAction');

var _ErrorPageAction2 = _interopRequireDefault(_ErrorPageAction);

var _tokens = require('./../../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
    return {
        root: {},
        errorTemplate: {},
        noHeader: {},
        illustration: {
            width: '100%',
            objectFit: 'contain'
        },
        errorDetails: {
            textAlign: 'center',
            padding: _tokens.spacingSmall,
            '& h2': {
                marginBottom: _tokens.spacingMedium
            },
            '& a': {
                marginTop: _tokens.spacingSmall,
                display: 'inline-block'
            }
        }
    };
};

var ErrorTemplateBody = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        largeIllustrationName = props.largeIllustrationName,
        errorTitle = props.errorTitle,
        errorMessage = props.errorMessage,
        message = props.message,
        imageSrc = props.imageSrc,
        hasAction = props.hasAction,
        action = props.action,
        text = props.text;

    var illustrationUrl = _EDSApplication.cdn.getLargeIllustrationsUrl(largeIllustrationName);

    return _react2.default.createElement(
        'div',
        { ref: ref },
        _react2.default.createElement('img', { className: classes.illustration,
            src: imageSrc || illustrationUrl,
            alt: errorTitle
        }),
        _react2.default.createElement(
            'div',
            { className: classes.errorDetails },
            _react2.default.createElement(
                _Typography2.default,
                { variant: 'h2' },
                errorTitle
            ),
            _react2.default.createElement(
                _Typography2.default,
                null,
                message || errorMessage
            ),
            hasAction && _react2.default.createElement(_ErrorPageAction2.default, { action: action, text: text })
        )
    );
});

ErrorTemplateBody.propTypes = {
    largeIllustrationName: _propTypes2.default.string,
    classes: _propTypes2.default.object,
    errorTitle: _propTypes2.default.string,
    errorMessage: _propTypes2.default.string,
    message: _propTypes2.default.string,
    imageSrc: _propTypes2.default.string,
    text: _propTypes2.default.string,
    hasAction: _propTypes2.default.bool,
    action: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.string])
};

exports.default = (0, _styles.withStyles)(styles)(ErrorTemplateBody);