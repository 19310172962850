import React from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '../';

/**
 * Use `ConfirmationDialog` to require user action before closing a dialog.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  ConfirmationDialogExample
 *  FormDialogExample
 */
var ConfirmationDialog = React.forwardRef(function (props, ref) {
  var content = props.content,
      contentText = props.contentText,
      DialogProps = props.DialogProps,
      DialogActionsProps = props.DialogActionsProps,
      DialogContentProps = props.DialogContentProps,
      DialogContentTextProps = props.DialogContentTextProps,
      DialogTitleProps = props.DialogTitleProps,
      open = props.open,
      primaryActionOnClick = props.primaryActionOnClick,
      primaryActionProps = props.primaryActionProps,
      primaryActionText = props.primaryActionText,
      secondaryActionOnClick = props.secondaryActionOnClick,
      secondaryActionProps = props.secondaryActionProps,
      secondaryActionText = props.secondaryActionText,
      title = props.title;

  /**
   * Override `disableBackdropClick` and `disableEscapeKeyDown` to true
   * Pass `open` for convenience
   */

  DialogProps['disableBackdropClick'] = true;
  DialogProps['disableEscapeKeyDown'] = true;
  DialogProps['maxWidth'] = false;
  DialogProps['open'] = open;
  DialogProps['showTitleBarClose'] = false;

  // Override color for primary action
  primaryActionProps['color'] = 'primary';
  primaryActionProps['onClick'] = primaryActionOnClick;

  // Override color for secondary action
  secondaryActionProps['color'] = 'secondary';
  secondaryActionProps['onClick'] = secondaryActionOnClick;

  return React.createElement(
    Dialog,
    Object.assign({}, DialogProps, { ref: ref }),
    React.createElement(
      DialogTitle,
      DialogTitleProps,
      title
    ),
    React.createElement(
      DialogContent,
      DialogContentProps,
      React.createElement(
        DialogContentText,
        DialogContentTextProps,
        contentText
      ),
      content
    ),
    React.createElement(
      DialogActions,
      DialogActionsProps,
      React.createElement(
        Button,
        secondaryActionProps,
        secondaryActionText
      ),
      React.createElement(
        Button,
        primaryActionProps,
        primaryActionText
      )
    )
  );
});

ConfirmationDialog.muiName = 'ConfirmationDialog';
ConfirmationDialog.displayName = 'ConfirmationDialog';

ConfirmationDialog.propTypes = {
  /**
   * Content displayed by the `DialogContent` element.
   */
  content: PropTypes.node,
  /**
   * Text displayed by the `DialogContentText` element.
   */
  contentText: PropTypes.string,
  /**
   * Props that will be applied to the `Dialog` element.
   */
  DialogProps: PropTypes.object,
  /**
   * Props that will be applied to the `DialogActions` element.
   */
  DialogActionsProps: PropTypes.object,
  /**
   * Props that will be applied to the `DialogContent` element.
   */
  DialogContentProps: PropTypes.object,
  /**
   * Props that will be applied to the `DialogContentText` element.
   */
  DialogContentTextProps: PropTypes.object,
  /**
   * Props that will be applied to the `DialogTitle` element.
   */
  DialogTitleProps: PropTypes.object,
  /**
   * If `true`, the Dialog is open.
   */
  open: PropTypes.bool.isRequired,
  /**
   * onClick applied to the primary action Button element.
   */
  primaryActionOnClick: PropTypes.func.isRequired,
  /**
   * Props applied to the primary action Button element.
   */
  primaryActionProps: PropTypes.object.isRequired,
  /**
   * Text applied to the primary action Button element.
   */
  primaryActionText: PropTypes.string.isRequired,
  /**
   * onClick applied to the secondary action Button element.
   */
  secondaryActionOnClick: PropTypes.func.isRequired,
  /**
   * Props applied to the secondary action Button element.
   */
  secondaryActionProps: PropTypes.object.isRequired,
  /**
   * Text applied to the secondary action Button element.
   */
  secondaryActionText: PropTypes.string.isRequired,
  /**
   * Title displayed by the DialogTitle element.
   */
  title: PropTypes.string.isRequired
};

ConfirmationDialog.defaultProps = {
  DialogProps: {},
  DialogActionsProps: {},
  DialogContentProps: {},
  DialogContentTextProps: {},
  DialogTitleProps: {},
  primaryActionProps: {},
  secondaryActionProps: {}
};

export default ConfirmationDialog;