'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.styles = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _core = require('@material-ui/core');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = exports.styles = function styles(theme) {
    return {
        root: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.secondary,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1,
            padding: 0,
            '&:focused': {
                color: theme.palette.primary[theme.palette.type === 'light' ? 'dark' : 'light']
            },
            '&:disabled': {
                color: theme.palette.text.disabled
            },
            '&:error': {
                color: theme.palette.error.main
            }
        },
        focused: {},
        disabled: {},
        error: {},
        asterisk: {
            '&:error': {
                color: theme.palette.error.main
            }
        }
    };
};

/**
 *  A wrapper for a label in a form with standard disabled/error/filled/focused/required props,
 * each of which can be accompanied by standardized, customized styling.  It also supports passing
 * full children.
 */
var FormLabel = _react2.default.forwardRef(function (props, ref) {
    return _react2.default.createElement(_core.FormLabel, Object.assign({}, props, { ref: ref }));
});

FormLabel.muiName = 'FormLabel';
FormLabel.displayName = 'FormLabel';

FormLabel.propTypes = {
    /**
     * The content of the component.
     */
    children: _propTypes2.default.node,
    /**
     * Useful to extend the style applied to components.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
    /**
     * If `true`, the label should be displayed in a disabled state.
     */
    disabled: _propTypes2.default.bool,
    /**
     * If `true`, the label should be displayed in an error state.
     */
    error: _propTypes2.default.bool,
    /**
     * If `true`, the label should use filled classes key.
     */
    filled: _propTypes2.default.bool,
    /**
     * If `true`, the input of this label is focused (used by `FormGroup` components).
     */
    focused: _propTypes2.default.bool,
    /**
     * If `true`, the label will indicate that the input is required.
     */
    required: _propTypes2.default.bool
};

FormLabel.defaultProps = {
    component: 'label'
};

FormLabel.contextTypes = {
    muiFormLabel: _propTypes2.default.object
};

exports.default = (0, _styles2.default)(styles)(FormLabel);