"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SvgBookmark = function SvgBookmark(props) {
    return _react2.default.createElement(
        "svg",
        Object.assign({ width: 60, height: 60, viewBox: "0 0 60 60" }, props),
        _react2.default.createElement(
            "g",
            { id: "Small/Book", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            _react2.default.createElement(
                "g",
                { id: "Book", transform: "translate(4.000000, 0.000000)", fillRule: "nonzero" },
                _react2.default.createElement("path", {
                    d: "M51.1899785,56.25 L51.5803571,56.25 C52.3611145,56.25 53,56.8828583 53,57.65625 L53,58.59375 C53,59.3671417 52.3611145,60 51.5803571,60 L9.46428571,60 C4.23524475,60 0,55.8047104 0,50.625 L0,9.375 C0,4.19528961 4.23524475,0 9.46428571,0 L50.1607143,0 C51.7341287,0 53,1.25392914 53,2.8125 L53,45.9375 C53,47.109375 52.2664948,48.1171417 51.2372306,48.5390854 C50.8113839,50.4257584 50.7167642,53.8827896 51.1899785,56.25 Z",
                    id: "Path",
                    fill: "currentColor"
                }),
                _react2.default.createElement("polygon", { id: "Path", fill: "currentColor", points: "15 45 49 45 49 4 15 4" }),
                _react2.default.createElement("path", {
                    d: "M4,47 C5.67504883,45.8315217 7.75,45.1304348 10,45.1304348 L12,45.1304348 L12,4 L10,4 C6.6875,4 4,6.51222826 4,9.60869565 L4,47 Z",
                    id: "Path",
                    fill: "currentColor"
                }),
                _react2.default.createElement("path", {
                    d: "M48,56 C47.6336748,53.7687073 47.6573347,51.0562286 48,49 L9.67284746,49 C2.10905085,49 2.10905085,56 9.67284746,56 L48,56 Z",
                    id: "Path",
                    fill: "currentColor"
                }),
                _react2.default.createElement("polygon", { id: "Path-Copy-20", fillOpacity: "0.88", fill: "#FFFFFF", points: "15 45 49 45 49 4 15 4" }),
                _react2.default.createElement("path", {
                    d: "M4,47 C5.67504883,45.8315217 7.75,45.1304348 10,45.1304348 L12,45.1304348 L12,4 L10,4 C6.6875,4 4,6.51222826 4,9.60869565 L4,47 Z",
                    id: "Path-Copy-19",
                    fillOpacity: "0.88",
                    fill: "#FFFFFF"
                }),
                _react2.default.createElement("path", { d: "M48,56 C47.6336748,53.7687073 47.6573347,51.0562286 48,49 L9.67284746,49 C2.10905085,49 2.10905085,56 9.67284746,56 L48,56 Z",
                    id: "Path-Copy-18",
                    fillOpacity: "0.88",
                    fill: "#FFFFFF"
                }),
                _react2.default.createElement("path", {
                    d: "M42.5625,19 L22.4375,19 C21.6469218,19 21,18.3249432 21,17.5 L21,16.5 C21,15.6750472 21.6469218,15 22.4375,15 L42.5625,15 C43.3530782,15 44,15.6750472 44,16.5 L44,17.5 C44,18.3249432 43.3530782,19 42.5625,19 Z",
                    id: "Path",
                    fill: "currentColor"
                }),
                _react2.default.createElement("path", {
                    d: "M44,24.5 C44,25.3249432 43.3530782,26 42.5625,26 L22.4375,26 C21.6469218,26 21,25.3249432 21,24.5 L21,23.5 C21,22.6750472 21.6469218,22 22.4375,22 L42.5625,22 C43.3530782,22 44,22.6750472 44,23.5 L44,24.5 Z",
                    id: "Path",
                    fill: "currentColor"
                })
            )
        )
    );
};

exports.default = SvgBookmark;