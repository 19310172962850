var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Logo, Typography, List, ListItem, ListItemIcon, ListItemText } from '../';
import { withEDSContext } from '../EDSContext/EDSContext';
import Icon from '@eui/ds-icons/lib/Icon';
import { default as withStyles } from '../styles';
import classNames from 'classnames';
import withWidth, { isWidthDown } from './../withWidth';
import Grid from './../Grid';
import find from 'lodash/find';
import uuid from 'uuid/v4';

import { colorBrandNeutral600, colorTextDisabled, borderWidthThick, borderWidthThin, fontFamilyHeader, fontWeightStrong, spacingXSmall, spacingSmall, spacingMedium, spacingLarge, mqLarge } from '../styles/tokens';

var styles = function styles(theme) {
    var _ref;

    return _ref = {

        megaMenu: {
            position: 'fixed',
            color: colorBrandNeutral600,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 1,
            willChange: 'opacity',
            backgroundColor: '#FFF',
            zIndex: theme.zIndex.megaMenu,
            overflowX: 'hidden',

            '&.useFluidTransition': {
                transition: 'all 0.15s ease-in-out',
                transformOrigin: '0 0'
            }

        },

        megaMenuHidden: {
            maxHeight: '0'
        },

        wrapper: {
            padding: '6rem 5rem'
        }

    }, _defineProperty(_ref, '@media ' + mqLarge, {
        wrapper: {
            padding: '6rem 10rem'
        }
    }), _defineProperty(_ref, 'staticAreaStart', {
        borderLeft: '1px solid black'
    }), _defineProperty(_ref, 'footerArea', {
        paddingTop: '5rem',
        justifyContent: 'center',
        display: 'flex'
    }), _defineProperty(_ref, 'listItemRoot', {
        backgroundColor: 'white',
        outline: '1px solid blue'
    }), _defineProperty(_ref, 'section', {

        // remove top padding to ensure that the top of the dividing line between static and dynamic area lines up correctly
        '&.first-row': {
            paddingTop: '0 !important'
        },

        '&.last-row': {
            paddingBottom: '0 !important'
        },

        '& .section-header': {
            color: theme.palette.grey[600],
            paddingBottom: spacingSmall
        },
        '& .section-link': {
            color: theme.palette.action.selected,
            cursor: 'pointer',
            lineHeight: spacingMedium
        },
        '& .section-link:hover': {
            textDecoration: 'underline'
        },
        '& .section-link a': {
            color: theme.palette.action.selected,
            textDecoration: 'none'
        },
        '& .section-link.disabled': {
            color: colorTextDisabled,
            textDecoration: 'none',
            cursor: 'auto',
            pointerEvents: 'none'
        },
        '& .section-link.disabled a': {
            color: colorTextDisabled
        }
    }), _defineProperty(_ref, 'logo', {
        height: '0.875rem !important',
        marginTop: '0.125rem',
        minWidth: '0 !important',
        padding: '0 0 0 ' + spacingXSmall + ' !important',
        '& svg': {
            width: 'auto'
        }
    }), _defineProperty(_ref, 'topLevelMenuItem', {
        borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[300]
    }), _defineProperty(_ref, 'smallListItemOverrides', {
        padding: '1rem 0 !important', // TODO this class always appears below native list item classes in precedence, so need the !important
        backgroundColor: 'transparent !important'
    }), _defineProperty(_ref, 'smallMenuTopLevelItemText', {
        fontFamily: fontFamilyHeader + ' !important'
    }), _defineProperty(_ref, 'smallMenu', {
        display: 'flex',
        flexDirection: 'column'
    }), _defineProperty(_ref, 'smallMenuHeader', {

        display: 'flex',
        borderBottom: borderWidthThick + ' solid ' + theme.palette.grey[400],
        padding: '0.5rem 0',
        minHeight: '3.375rem', // necessary to maintain consistent height in iOS Safari

        marginLeft: spacingMedium,
        marginRight: spacingMedium,

        '& > .back-nav': {
            marginLeft: '-1rem'
        },

        '& > .back-icon': {
            color: theme.palette.grey[600]
        },

        '& > .back-icon:hover': {
            cursor: 'pointer'
        },

        '& > .header-title': {
            margin: 'auto'
        }

    }), _defineProperty(_ref, 'smallMenuSubHeader', {
        borderBottom: borderWidthThick + ' solid ' + theme.palette.grey[400],
        padding: '1rem 0',
        marginLeft: spacingMedium,
        marginRight: spacingMedium,

        '& > .back-icon': {
            color: theme.palette.grey[600]
        },

        '& > .back-icon:hover': {
            cursor: 'pointer'
        },
        '& > .submenu-header-title': {
            margin: 'auto'
        },

        '& > .header-title': {
            paddingTop: '1.5rem'
        }

    }), _defineProperty(_ref, 'smallMenuIcon', {
        marginRight: 0,
        color: theme.palette.grey[600]
    }), _defineProperty(_ref, 'smallMenuList', {
        flexGrow: 1,
        margin: '0 ' + spacingMedium,
        width: 'auto !important'
    }), _defineProperty(_ref, 'smallMenuItem', {
        color: theme.palette.action.selected,
        fontWeight: fontWeightStrong,

        '& a': {
            color: theme.palette.action.selected,
            textDecoration: 'none'
        },
        '& a:visited': {
            color: theme.palette.action.selected,
            textDecoration: 'none'
        },
        '& a:hover': {
            color: theme.palette.action.selected,
            textDecoration: 'none'
        }
    }), _defineProperty(_ref, 'smallMenuItemContents', {
        display: 'flex',
        '&> div': {
            paddingRight: spacingSmall
        }
    }), _defineProperty(_ref, 'smallMenuItemDisabled', {
        color: theme.palette.grey[400],
        cursor: 'default'
    }), _defineProperty(_ref, 'menuFooter', {
        display: 'flex',
        margin: 'auto',
        padding: spacingLarge + ' 0',

        '&.smallMenuFooter': {
            paddingBottom: '5rem'
        }
    }), _ref;
};

// detects when we've switched size classes that necessitate a change in the megamenu's form
var withMobileMegaMenu = function withMobileMegaMenu() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function (Component) {
        var _options$breakpoint = options.breakpoint,
            breakpoint = _options$breakpoint === undefined ? 'xs' : _options$breakpoint;


        function WithMobileMegaMenu(props) {
            return React.createElement(Component, Object.assign({ variant: isWidthDown(breakpoint, props.width) ? 'small' : 'large' }, props));
        }

        WithMobileMegaMenu.propTypes = {
            width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired
        };

        return withWidth()(WithMobileMegaMenu);
    };
};

/**
 * Use `MegaMenu` to display all menu and utility options.
 * @done true
 * @updated false
 * @versionAdded v0.0.15
*/

var MegaMenu = function (_React$Component) {
    _inherits(MegaMenu, _React$Component);

    function MegaMenu() {
        var _ref2;

        var _temp, _this, _ret;

        _classCallCheck(this, MegaMenu);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref2 = MegaMenu.__proto__ || Object.getPrototypeOf(MegaMenu)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
            selectedItemKey: null,
            previousBodyScroll: 'auto'

            /**
             * Calculates dynamic styles for the menu (based on its size, and it's positioning)
             *
             * @returns {object} Dynamic menu styles
             */
        }, _this.previousSelected = function (event) {
            if (event.type !== 'keydown' || event.key === 'Enter') {
                _this.setState({
                    selectedItemKey: null
                });
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(MegaMenu, [{
        key: 'getMenuStyles',
        value: function getMenuStyles() {
            var _props = this.props,
                classes = _props.classes,
                parentElementRef = _props.parentElementRef,
                open = _props.open,
                topOffset = _props.topOffset,
                variant = _props.variant;

            // figure out the height of the megamenu

            var height = 'calc(100vh - ' + topOffset + 'px';

            var menuStyles = {
                display: variant === 'small' ? 'flex' : 'block',
                top: topOffset + 'px'
            };

            // if we're provided with a parent element, place the menu within that element
            if (parentElementRef && parentElementRef.current) {

                var el = parentElementRef.current;
                var rect = el.getBoundingClientRect();

                height = rect.height + 'px';

                // position the menu within the element
                menuStyles = Object.assign(menuStyles, {
                    position: 'absolute',
                    top: topOffset + 'px',
                    left: 0,
                    width: rect.width - classes.megaMenu.top + 'px'
                });
            }

            var menuHeight = open ? height : 0;

            // set the height of the megamenu
            Object.assign(menuStyles, {
                maxHeight: menuHeight, // need to specify max height as well, because transitions don't work with just height
                height: menuHeight
            });

            return menuStyles;
        }

        /**
         * Handle item selection.
         *
         * @param {object} item The selected item
         */

    }, {
        key: 'selectItem',
        value: function selectItem(event, item) {

            // if this element contains an href somewhere in its hierarchy, invoke it
            var anchor = event.target && event.target.querySelector('a');

            if (anchor) {
                anchor.click();
            } else {

                // do whatever our caller tells us to do
                if (this.props.onItemSelected) {
                    this.props.onItemSelected(item);
                }

                // tell our caller that we're ready to close
                this.props.onClose();
            }
        }

        /**
         * Whether the item event should be handled.
         *
         * @param {Event} event Selection event
         * @param {object} item Selected item
         * @return {boolean}    True if event should be handled
         */

    }, {
        key: 'shouldHandleSelection',
        value: function shouldHandleSelection(event, item) {
            return !item.disabled && ((event.type === 'keydown' || event.type === 'keypress') && event.key === 'Enter' || event.type === 'click');
        }

        /**
         * Handle larege menu item selection.
         *
         * @param {Event}  event       Selection event
         * @param {object} sectionItem The selected item
         */

    }, {
        key: 'largeMenuItemSelected',
        value: function largeMenuItemSelected(event, sectionItem) {

            // check if the event is a keypress event, then check for the key pressed
            if (this.shouldHandleSelection(event, sectionItem)) {
                this.selectItem(event, sectionItem);
            }
        }

        /**
         * Handle small menu item selection.
         *
         * @param {Event}  event Selection event
         * @param {object} item  Selected item
         */

    }, {
        key: 'smallMenuItemSelected',
        value: function smallMenuItemSelected(event, item) {

            if (this.shouldHandleSelection(event, item)) {

                // item has submenus, navigate to them
                if (item.items) {
                    this.setState({
                        selectedItemKey: item.key
                    });

                    // leaf node, engage selection apparatus
                } else {
                    this.selectItem(event, item);
                }
            }
        }

        /**
         * We were asked to go up a level
         */

    }, {
        key: 'getSelectedItem',


        /**
         * Returns the item corresponding to the given key
         *
         * @param {string} key Item key
         * @return {object}    Corresponding item
         */
        value: function getSelectedItem(key) {
            return find(this.props.menuDefinition.concat(this.props.utilityDefinition), function (item) {
                return item.key === key;
            });
        }

        /**
         * Renders a single small megamenu items
         *
         * @param {object} item  Item to render
         * @return {Element}     The rendered element
         */

    }, {
        key: 'renderSmallItem',
        value: function renderSmallItem(item) {
            var _this2 = this;

            var classes = this.props.classes;

            // apply special header styling to text if we're at the top level of the menu
            var listItemClasses = this.state.selectedItemKey ? null : {
                primary: classes.smallMenuTopLevelItemText
            };

            return React.createElement(
                'div',
                { key: item.key },
                React.createElement(
                    ListItem,
                    {
                        classes: {
                            root: classes.smallListItemOverrides
                        },
                        button: true,
                        focusVisibleClassName: classes.listItemRoot,
                        onClick: function onClick(event) {
                            return _this2.smallMenuItemSelected(event, item);
                        },
                        onKeyPress: function onKeyPress(event) {
                            return _this2.smallMenuItemSelected(event, item);
                        },
                        className: this.state.selectedItemKey ? null : classes.topLevelMenuItem,
                        disabled: item.disabled,
                        tabIndex: this.props.open ? 0 : -1
                    },
                    React.createElement(
                        ListItemText,
                        {
                            classes: listItemClasses,
                            className: item.disabled ? 'disabled' : null,
                            primaryTypographyProps: this.getSmallListItemTypographyClass(item)
                        },
                        React.createElement(
                            'div',
                            { className: this.props.classes.smallMenuItemContents },
                            React.createElement(
                                'div',
                                null,
                                item.megaMenuContent || item.content
                            ),
                            item.label && React.createElement(
                                'div',
                                null,
                                item.label
                            )
                        )
                    ),
                    item.items && React.createElement(
                        ListItemIcon,
                        { className: classes.smallMenuIcon },
                        React.createElement(Icon, { name: 'chevron-right' })
                    )
                )
            );
        }

        /**
         * Renders the small version of the menu
         *
         * @param {Array.<object>} staticItems  Static menu items
         * @param {Array.<object>} dynamicItems Dynamic menu items
         *
         * @return {object} Rendered small menu
         */

    }, {
        key: 'renderSmallMenu',
        value: function renderSmallMenu(staticItems, dynamicItems, utilityItems) {
            var _this3 = this;

            var _props2 = this.props,
                classes = _props2.classes,
                edsContext = _props2.edsContext;


            var listItems = void 0;

            // we're displaying a submenu
            if (this.state.selectedItemKey) {
                listItems = this.getSelectedItem(this.state.selectedItemKey).items;

                // we're displaying the top-level menu
            } else {
                listItems = staticItems.concat(dynamicItems);
            }

            return React.createElement(
                'div',
                {
                    className: classNames(classes.megaMenu, classes.smallMenu, this.props.useFluidTransition ? 'useFluidTransition' : null, !this.props.open ? classes.megaMenuHidden : null),
                    'data-megamenu-type': 'small',
                    style: this.getMenuStyles() },
                !this.state.selectedItemKey && utilityItems.length > 0 && React.createElement(
                    'div',
                    null,
                    React.createElement(
                        'div',
                        { className: classes.smallMenuSubHeader },
                        React.createElement(
                            Typography,
                            { variant: 'h3', className: 'header-title' },
                            edsContext.formatMessage('component.MegaMenu.utilities')
                        )
                    ),
                    React.createElement(
                        List,
                        { className: classNames('megamenu-menu-list', classes.smallMenuList), component: 'div' },
                        utilityItems.filter(function (item) {
                            return !item.hidden;
                        }).map(function (item) {
                            return _this3.renderSmallItem(item);
                        })
                    )
                ),
                this.state.selectedItemKey && React.createElement(
                    'div',
                    { className: classes.smallMenuHeader },
                    React.createElement(
                        IconButton,
                        { color: 'gray', onClick: this.previousSelected, onKeyPress: this.previousSelected, className: 'back-nav' },
                        React.createElement(Icon, { name: 'chevron-left', className: 'back-icon' })
                    ),
                    React.createElement(
                        Typography,
                        { className: 'header-title' },
                        this.getSelectedItem(this.state.selectedItemKey).label ? this.getSelectedItem(this.state.selectedItemKey).label : this.getSelectedItem(this.state.selectedItemKey).content
                    )
                ),
                !this.state.selectedItemKey && React.createElement(
                    'div',
                    { className: classes.smallMenuSubHeader },
                    React.createElement(
                        Typography,
                        { variant: 'h3', className: 'header-title' },
                        this.props.menuItemsHeader
                    )
                ),
                React.createElement(
                    List,
                    { className: classNames('megamenu-menu-list', classes.smallMenuList), component: 'div' },
                    listItems.filter(function (item) {
                        return !item.hidden;
                    }).map(function (item) {
                        return _this3.renderSmallItem(item);
                    })
                ),
                React.createElement(
                    'div',
                    { className: classNames('megamenu-footer', classes.menuFooter, 'smallMenuFooter') },
                    React.createElement(
                        Typography,
                        { variant: 'body3', color: 'textSecondary' },
                        edsContext.formatMessage('component.MegaMenu.poweredBy')
                    ),
                    React.createElement(
                        'div',
                        null,
                        React.createElement(Logo, { logo: 'alternative', className: classes.logo })
                    )
                )
            );
        }

        /**
         * Construct list item typography override for small menu
         *
         * @param {object} item The menu item
         */

    }, {
        key: 'getSmallListItemTypographyClass',
        value: function getSmallListItemTypographyClass(item) {

            var classes = this.props.classes;

            var itemClasses = [];

            // grey out disabled items
            item.disabled && itemClasses.push(classes.smallMenuItemDisabled);

            // special formatting for items on the second level
            if (this.state.selectedItemKey) {
                itemClasses.push(classes.smallMenuItem);
            }

            return {
                classes: {
                    root: itemClasses.join(' ')
                }
            };
        }
    }, {
        key: 'buildSectionData',
        value: function buildSectionData(sectionData) {
            var _this4 = this;

            return React.createElement(
                'div',
                { className: 'megamenu-section' },
                React.createElement(
                    Typography,
                    { className: 'section-header', variant: 'h3' },
                    sectionData.content
                ),
                sectionData.items.filter(function (sectionItem) {
                    return !sectionItem.hidden;
                }).map(function (sectionItem) {

                    return React.createElement(
                        Typography,
                        {
                            className: classNames('section-link', { 'disabled': sectionItem.disabled }),
                            key: sectionItem.key,
                            variant: 'body2',
                            onClick: function onClick(event) {
                                _this4.largeMenuItemSelected(event, sectionItem);
                            },
                            onKeyPress: function onKeyPress(event) {
                                _this4.largeMenuItemSelected(event, sectionItem);
                            },
                            tabIndex: !_this4.props.open || sectionItem.disabled ? -1 : 0
                        },
                        sectionItem.content
                    );
                })
            );
        }

        /**
         * Build a single section in the mega menu. A section consists of a header and a series of child links.
         *
         * @param {object} sectionData  The section data
         * @param {boolean} isFirstRow  Whether this section appears on the first row
         * @param {boolean} isLastRow   Whether this section appears on the last row
         * @param {boolean} isStaticAreaStart  Whether this represents the first column of a static area
         * @param {number}  sectionsPerRow     The number of sections to display in a single row
         *
         * @return {object} Rendered large menu
         */

    }, {
        key: 'buildSection',
        value: function buildSection(sectionData, isFirstRow, isLastRow, isStaticAreaStart, sectionsPerRow) {

            return React.createElement(
                Grid,
                {
                    item: true,
                    xs: 12 / sectionsPerRow,
                    key: sectionData && sectionData.key || uuid(),
                    className: classNames(this.props.classes.section, isFirstRow ? 'first-row' : null, isLastRow ? 'last-row' : null, isStaticAreaStart ? this.props.classes.staticAreaStart : null, sectionData ? sectionData.dynamic ? 'megamenu-dynamic-section' : 'megamenu-static-section' : null)
                },
                sectionData ? this.buildSectionData(sectionData) : null
            );
        }
    }, {
        key: 'renderLargeMenu',


        /**
         * Renders the large version of the menu
         *
         * @param {Array.<object>} staticItems  Static menu items
         * @param {Array.<object>} dynamicItems Dynamic menu items
         */
        value: function renderLargeMenu(staticItems, dynamicItems) {
            var _props3 = this.props,
                classes = _props3.classes,
                edsContext = _props3.edsContext;


            var hasDynamicItems = dynamicItems.length > 0;
            var sectionsPerRow = hasDynamicItems ? 4 : 3;

            // figure out how many rows we're displahing on the megamnu (which will be the max of the number of static and
            // dynamic rows)
            var numStaticRows = Math.ceil(staticItems.length / 3);
            var numRows = Math.max(numStaticRows, dynamicItems.length);

            var allItems = [];

            // loop through all the items, building section for each and putting them in the right place
            for (var rowIndex = 0; rowIndex < numRows; rowIndex++) {

                var isFirstRow = rowIndex === 0;
                var isLastRow = rowIndex === numRows - 1;

                // first item in the row is always
                if (hasDynamicItems) {
                    // const dynamicItem = dynamicItems[rowIndex];
                    allItems.push(this.buildSection(dynamicItems[rowIndex], isFirstRow, isLastRow, false, sectionsPerRow));
                }

                for (var colIndex = 0; colIndex < 3; colIndex++) {
                    var staticItemsIndex = rowIndex * 3 + colIndex;
                    allItems.push(this.buildSection(staticItems[staticItemsIndex], isFirstRow, isLastRow, hasDynamicItems && colIndex === 0, sectionsPerRow));
                }
            }

            return React.createElement(
                'div',
                {
                    className: classNames(classes.megaMenu, !this.props.open ? classes.megaMenuHidden : null, this.props.useFluidTransition ? 'useFluidTransition' : null),
                    'data-megamenu-type': 'large',
                    style: this.getMenuStyles()
                },
                React.createElement(
                    'div',
                    { className: classes.wrapper },
                    React.createElement(
                        Grid,
                        { container: true, spacing: 4 },
                        allItems.map(function (item) {
                            return item;
                        }),
                        React.createElement(
                            Grid,
                            { item: true, xs: 12 },
                            React.createElement(
                                'div',
                                { className: classNames('megamenu-footer', classes.footerArea) },
                                React.createElement(
                                    'div',
                                    { className: classes.menuFooter },
                                    React.createElement(
                                        Typography,
                                        { variant: 'body3', color: 'textSecondary' },
                                        edsContext.formatMessage('component.MegaMenu.poweredBy')
                                    ),
                                    React.createElement(
                                        'div',
                                        null,
                                        React.createElement(Logo, { logo: 'alternative', className: classes.logo })
                                    )
                                )
                            )
                        )
                    )
                )
            );
        }

        /**
         * Toggle scrolling in the document <body className="">
         *
         * @param {boolean} enable Whether to enable body scrolling
         */

    }, {
        key: 'toggleBodyScroll',
        value: function toggleBodyScroll(enable) {
            document.body.style.overflow = enable ? this.state.previousBodyScroll : 'hidden';
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {

            if (this.props.open !== prevProps.open) {

                // remember current body overflow value when opening
                if (this.props.open) {
                    this.setState({
                        previousBodyScroll: document.body.style.overflow
                    });
                }

                // clear selected item when the menu closes
                else {
                        this.setState({
                            selectedItemKey: null
                        });
                    }

                // enable/disable body scrolling
                this.toggleBodyScroll(!this.props.open);
            }
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            // make sure body scroll is re-enabled
            this.toggleBodyScroll(true);
        }
    }, {
        key: 'render',
        value: function render() {
            var _props4 = this.props,
                menuDefinition = _props4.menuDefinition,
                utilityDefinition = _props4.utilityDefinition,
                variant = _props4.variant;
            // separate dynamic-area from static-area items

            var dynamicItems = menuDefinition.filter(function (def) {
                return def.dynamic;
            });
            var staticItems = menuDefinition.filter(function (def) {
                return !def.dynamic;
            });

            if (variant === 'small') {
                return this.renderSmallMenu(staticItems, dynamicItems, utilityDefinition);
            } else {
                return this.renderLargeMenu(staticItems, dynamicItems);
            }
        }
    }]);

    return MegaMenu;
}(React.Component);

;

MegaMenu.propTypes = {

    /**
     * @ignore
     */
    edsContext: PropTypes.object,

    /**
     * @ignore
     */
    classes: PropTypes.object,

    /**
     * Whether the menu is open
     */
    open: PropTypes.bool.isRequired,

    /**
     * The offset from the top at which the menu should be positioned (in pixels)
     */
    topOffset: PropTypes.number,

    /**
     * If the megamenu should appear within a DOM element (and be constrained within its borders), you must
     * provide a [ref](https://reactjs.org/docs/refs-and-the-dom.html) representing the containing element.
     */
    parentElementRef: PropTypes.object,

    /**
     * The menu items. An array of objects with the following format:
     *
     *   - `key*`: A unique key, identifying the item
     *   - `content`: Accepts straight text, or a component with some sort of visual representation - *Icon*, *NavLink*, etc.
     *   - `dynamic`: Whether the item should appear in the dynamic area of the mega menu
     *   - `items`: If the object has child items (eg, a menu), it can specify them in an array of objects
     *
     * The *items* objects take the following form:
     *
     *   - `key`: A unique key
     *   - `content`: Accepts straight text, or a component with some sort of visual representation - *Icon*, *NavLink*, etc.
     *   - `disabled`: A boolean indicating whether the item is disabled
     *   - `hidden`: A boolean indicating whether the item is hidden
     */
    menuDefinition: PropTypes.arrayOf(PropTypes.object),

    /**
     * The utility items definition (design TBD)
     */
    utilityDefinition: PropTypes.arrayOf(PropTypes.object),

    /**
     * Invoked when an item is selected. The function is passed the key of the selected item.
     */
    onItemSelected: PropTypes.func,

    /**
     * Invoked when the menu wants to close
     */
    onClose: PropTypes.func.isRequired,

    /**
     * Whether the megamenu should use a fluid transition when opening/closing
     */
    useFluidTransition: PropTypes.bool,

    /**
     * The header that appears above the menu items portion of the small menu header
     */
    menuItemsHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * @ignore
     *
     * Determines the layout of the mega menu's contents
     */
    variant: PropTypes.oneOf(['small', 'large'])

};

// default properties
MegaMenu.defaultProps = {
    menuDefinition: [],
    utilityDefinition: [],
    topOffset: 64,
    useFluidTransition: false,
    variant: 'large'
};

export default withEDSContext(withMobileMegaMenu()(withStyles(styles)(MegaMenu)));