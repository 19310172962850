'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _ListItem = require('@material-ui/core/ListItem');

var _ListItem2 = _interopRequireDefault(_ListItem);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var FOCUS_VISIBLE_CLASSNAME = 'eds-list-item-focus-visible';

var styles = function styles(theme) {
  return {
    root: _defineProperty({
      cursor: 'pointer',
      padding: _tokens.spacingSmall,
      '&:hover': {
        backgroundColor: theme.palette.grey['250']
      }
    }, '&.' + FOCUS_VISIBLE_CLASSNAME, {
      backgroundColor: '' + theme.palette.grey['250']
    })
  };
};

/**
 * An item in a list.
 */
var ListItem = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    root: classes.root
  };

  return _react2.default.createElement(_ListItem2.default, Object.assign({}, rest, {
    classes: classOverrides,
    focusVisibleClassName: FOCUS_VISIBLE_CLASSNAME,
    ref: ref
  }));
});

ListItem.muiName = 'ListItem';
ListItem.displayName = 'ListItem';

ListItem.propTypes = {
  /**
   * If `true`, the list item will be a button (using `ButtonBase`).
   */
  button: _propTypes2.default.bool,
  /**
   * The content of the component.
   */
  children: _propTypes2.default.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   * By default, it's a `li` when `button` is `false` and a `div` when `button` is `true`.
   */
  component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
  /**
   * The container component used when a `ListItemSecondaryAction` is rendered.
   */
  ContainerComponent: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
  /**
   * Properties applied to the container element when the component
   * is used to display a `ListItemSecondaryAction`.
   */
  ContainerProps: _propTypes2.default.object,
  /**
   * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense: _propTypes2.default.bool,
  /**
   * If `true`, the list item will be disabled.
   */
  disabled: _propTypes2.default.bool,
  /**
   * If `true`, the left and right padding is removed.
   */
  disableGutters: _propTypes2.default.bool,
  /**
   * If `true`, a 1px light border is added to the bottom of the list item.
   */
  divider: _propTypes2.default.bool
};

ListItem.defaultProps = {
  button: false,
  ContainerComponent: 'li',
  dense: false,
  disabled: false,
  disableGutters: false,
  divider: false
};

exports.default = (0, _styles2.default)(styles)(ListItem);