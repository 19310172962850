function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiPaper } from '@material-ui/core/Paper';

var styles = function styles(theme) {
  return {
    root: {}
  };
};

/**
 * A component that is the basis for many of other components.
 * @done true
 * @updated false
 * @versionAdded v0.0.11
 */
var Paper = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    root: classes.root
  };

  return React.createElement(MuiPaper, Object.assign({}, rest, {
    classes: classOverrides,
    ref: ref
  }));
});

Paper.muiName = 'Paper';
Paper.displayName = 'Paper';

Paper.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * Shadow depth, corresponds to `dp` in the spec.
   * It's accepting values between 0 and 24 inclusive.
   */
  elevation: PropTypes.number,
  /**
   * If `true`, rounded corners are disabled.
   */
  square: PropTypes.bool
};

Paper.defaultProps = {
  component: 'div',
  elevation: 2,
  square: false
};

export default withStyles(styles)(Paper);