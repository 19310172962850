'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.styles = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _v = require('uuid/v4');

var _v2 = _interopRequireDefault(_v);

var _reactPopper = require('react-popper');

var _debounce = require('lodash/debounce');

var _debounce2 = _interopRequireDefault(_debounce);

var _ClickAwayListener = require('@material-ui/core/ClickAwayListener');

var _ClickAwayListener2 = _interopRequireDefault(_ClickAwayListener);

var _Grow = require('@material-ui/core/Grow');

var _Grow2 = _interopRequireDefault(_Grow);

var _Paper = require('@material-ui/core/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _MenuList = require('@material-ui/core/MenuList');

var _MenuList2 = _interopRequireDefault(_MenuList);

var _EDSContext = require('../EDSContext/EDSContext');

var _IconButton = require('../IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _MoreVertical = require('@eui/ds-icons/lib/MoreVertical');

var _MoreVertical2 = _interopRequireDefault(_MoreVertical);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = exports.styles = function styles(theme) {
    return {
        root: {
            display: 'inline-block',
            '& .paper': {
                boxShadow: 'none'
            }
        },
        iconButton: {
            borderRadius: _tokens.borderRadiusSmall,
            fontSize: '1.2rem',
            '&:focus': {
                boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + theme.palette.focus
            },
            '&.color': {
                color: theme.palette.grey[500]
            },
            '&.color:hover': {
                background: 'none',
                color: theme.palette.ctaColor.base
            },
            '&.color:active': {
                background: 'none',
                color: theme.palette.ctaColor.base
            },
            '&.actionMenuOpen': {
                color: theme.palette.ctaColor.base
            },
            '&.actionMenuOpen:after': {
                position: 'absolute',
                width: '0.625rem',
                height: '0.625rem',
                top: 'auto',
                bottom: '-0.3rem',
                left: '1.05rem',
                content: '""',
                background: 'linear-gradient(-45deg, white 0%, white 54%, rgba(255,255,255,0) 75%, rgba(255,255,255,0) 100%)',
                borderRight: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
                borderBottom: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
                transform: 'rotate(-135deg)',
                borderBottomRightRadius: _tokens.borderRadiusXSmall,
                zIndex: theme.zIndex.actionMenu + 1
            },
            '&.actionMenuOpen.topPlacement:after': {
                bottom: 'auto',
                top: '-0.3rem',
                transform: 'rotate(45deg)'
            }
        },
        popperClose: {
            pointerEvents: 'none'
        },
        popperOpen: {
            zIndex: theme.zIndex.actionMenu
        },
        menuList: {
            maxWidth: '18.750rem',
            borderRadius: '' + _tokens.borderRadiusXSmall,
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[400],
            boxShadow: 'none'
        },
        menuListItem: {
            // Since menu list item is pulling from MUI, have to override to match recommended list
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightNormal,
            lineHeight: _tokens.lineHeightParagraphMedium,
            textOverflow: 'ellipsis',
            '&.padding': {
                padding: _tokens.spacingSmall + ' 1.25rem',
                display: 'block'
            },
            '&:hover': {
                color: '' + theme.palette.grey[600],
                backgroundColor: theme.palette.grey[250]
            },
            '&:focus': {
                color: '' + theme.palette.grey[100],
                backgroundColor: theme.palette.action.hover
            },
            '&:active': {
                backgroundColor: theme.palette.ctaColor.base
            }
        }
    };
};

/**
 * Use `ActionMenu` to give users access to additional actions related to a field.
 * @done true
 * @updated true
 * @versionAdded v0.0.8
 * @examples
 *  BasicActionMenuExamples
 *  PlacementActionMenuExamples
 */

var ActionMenu = function (_React$Component) {
    _inherits(ActionMenu, _React$Component);

    function ActionMenu(props) {
        _classCallCheck(this, ActionMenu);

        var _this = _possibleConstructorReturn(this, (ActionMenu.__proto__ || Object.getPrototypeOf(ActionMenu)).call(this, props));

        _this.handleToggle = function () {
            _this.setState({ open: !_this.state.open }, function () {
                if (_this.state.open) {
                    _this.registerEvents();
                } else {
                    _this.removeEvents();
                }

                // Queue it for execution from event queue as there is some timing issue.
                // TODO: Revisit this code later
                setTimeout(function () {
                    return _this.setInverted();
                });
            });
        };

        _this.handleClose = function (event, item) {
            if (_this.target.contains(event.target)) {
                return;
            }

            if (item) {
                item.callback(item);
            }

            _this.removeEvents();
            _this.setState({ open: false });
        };

        _this.popperRef = _react2.default.createRef();
        _this.findMenuPlacement = (0, _debounce2.default)(_this.findMenuPlacement.bind(_this), 0);
        _this.placements = ['top-start', 'top-end'];

        _this.state = {
            open: props.initialOpenOverride, // action menu is closed by default, unless an override is passed in
            invertedMenu: false
        };
        return _this;
    }

    _createClass(ActionMenu, [{
        key: 'registerEvents',
        value: function registerEvents() {
            window.addEventListener('resize', this.findMenuPlacement);
            window.addEventListener('scroll', this.findMenuPlacement, true);
        }
    }, {
        key: 'removeEvents',
        value: function removeEvents() {
            window.removeEventListener('resize', this.findMenuPlacement);
            window.removeEventListener('scroll', this.findMenuPlacement, true);
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            // Making sure to remove listeners
            this.removeEvents();
        }
    }, {
        key: 'findMenuPlacement',
        value: function findMenuPlacement() {
            if (this.state.open) {
                this.setInverted();
            }
        }
    }, {
        key: 'setInverted',
        value: function setInverted() {
            var placement = this.popperRef.current.state.data.placement;

            this.setState({
                invertedMenu: this.placements.indexOf(placement) !== -1
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this,
                _classNames2;

            var _props = this.props,
                classes = _props.classes,
                actions = _props.actions,
                placement = _props.placement,
                menuId = _props.menuId,
                edsContext = _props.edsContext;
            var _state = this.state,
                open = _state.open,
                invertedMenu = _state.invertedMenu;
            // const classOverrides = {
            //     root:
            //     selelcted: classes.selected
            // }

            var menuItems = actions.map(function (item, index) {
                return _react2.default.createElement(
                    _MenuItem2.default,
                    {
                        onClick: function onClick(e) {
                            return _this2.handleClose(e, item);
                        },
                        key: (0, _v2.default)(),
                        tabIndex: index === 0 ? 0 : -1,
                        className: classes.menuListItem + ' padding' // Not sure if this is the way to increase the Specificity of css
                    },
                    item.label
                );
            });

            return _react2.default.createElement(
                'div',
                { className: classes.root },
                _react2.default.createElement(
                    _reactPopper.Manager,
                    null,
                    _react2.default.createElement(
                        _reactPopper.Target,
                        { className: 'menuTarget' },
                        _react2.default.createElement(
                            'div',
                            {
                                ref: function ref(node) {
                                    _this2.target = node;
                                },
                                className: 'menuTargetInner'
                            },
                            _react2.default.createElement(
                                _IconButton2.default,
                                {
                                    disableRipple: true,
                                    title: edsContext.formatMessage('component.ActionMenu.more'),
                                    'aria-label': edsContext.formatMessage('component.ActionMenu.more'),
                                    'aria-expanded': open,
                                    'aria-haspopup': 'true',
                                    'aria-controls': menuId,
                                    onClick: this.handleToggle,
                                    color: 'gray',
                                    className: (0, _classnames2.default)(_defineProperty({}, classes.iconButton + ' color', true), { 'actionMenuOpen': open }, { 'topPlacement': invertedMenu }),
                                    tabIndex: 0
                                },
                                _react2.default.createElement(_MoreVertical2.default, null)
                            )
                        )
                    ),
                    _react2.default.createElement(
                        _reactPopper.Popper,
                        {
                            ref: this.popperRef,
                            placement: placement,
                            eventsEnabled: open,
                            className: (0, _classnames2.default)('eds-actionmenu-popper', (_classNames2 = {}, _defineProperty(_classNames2, classes.popperClose, !open), _defineProperty(_classNames2, classes.popperOpen, open), _classNames2))
                        },
                        _react2.default.createElement(
                            _ClickAwayListener2.default,
                            { onClickAway: this.handleClose },
                            _react2.default.createElement(
                                _Grow2.default,
                                { 'in': open, className: 'eds-action-menu', style: { transformOrigin: '0 0 0' } },
                                _react2.default.createElement(
                                    _Paper2.default,
                                    { className: classes.root + ' paper' },
                                    _react2.default.createElement(
                                        _MenuList2.default,
                                        { id: menuId, role: 'menu', className: classes.menuList },
                                        menuItems
                                    )
                                )
                            )
                        )
                    )
                )
            );
        }
    }]);

    return ActionMenu;
}(_react2.default.Component);

ActionMenu.propTypes = {
    /**
     * Classes used to extend original style object.
     */
    classes: _propTypes2.default.object,

    /**
     * Actions and label for the actions to be taken from the `ActionMenu`.
     */
    actions: _propTypes2.default.arrayOf(_propTypes2.default.object).isRequired,
    /**
     * @ignore
     */
    edsContext: _propTypes2.default.object,

    /**
     * Menu HTML ID for uniqueness.
     */
    menuId: _propTypes2.default.string,

    /**
     * Placement is used to place the menu at start or end of the container or screen.
     * Values include `bottom-start` or `bottom-end`.
     */
    placement: _propTypes2.default.string,

    /**
     * @ignore
     *
     * Externally set the initial open state of the action menu. For testing purposes only.
     */
    initialOpenOverride: _propTypes2.default.bool

};

ActionMenu.defaultProps = {
    placement: 'bottom-end',
    initialOpenOverride: false
};

exports.default = (0, _EDSContext.withEDSContext)((0, _styles2.default)(styles)(ActionMenu));