'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _TableRow = require('@material-ui/core/TableRow');

var _TableRow2 = _interopRequireDefault(_TableRow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: {
            height: '3rem',
            '&$hover:hover': {
                backgroundColor: theme.palette.grey[250]
            }
        },
        hover: {}
    };
};

/**
 * Table component for displaying a row
 */
var TableRow = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: classes.root,
        hover: classes.hover
    };

    return _react2.default.createElement(_TableRow2.default, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

TableRow.muiName = 'TableRow';
TableRow.displayName = 'TableRow';

TableRow.propTypes = {
    /**
     * Should be valid `<tr>`children such as `TableCell`.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
    /**
     * If `true`, the table row will shade on hover.
     */
    hover: _propTypes2.default.bool,
    /**
     * If `true`, the table row will have the selected shading.
     */
    selected: _propTypes2.default.bool

};

// If default props are needed, they must be set here
TableRow.defaultProps = {
    component: 'tr',
    hover: false,
    selected: false
};

exports.default = (0, _styles2.default)(styles)(TableRow);