'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _List = require('@material-ui/core/List');

var _List2 = _interopRequireDefault(_List);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
  return {
    root: {
      backgroundColor: theme.palette.common.white,
      width: _tokens.widthFluid
    }
  };
};

/**
 * Use `List` to display a continuous, vertical index of text and/or images.
 * @done true
 * @updated true
 * @versionAdded v0.0.15
 * @examples
 *  PrimaryTextExample
 *  SecondaryTextExample
 *  ControlsExample
 *  ListItemWithButtonExample
 *  IconsExample
 *  ComplexListExample
 *  MultilineExample
 *  NestedListExample
 */
var List = _react2.default.forwardRef(function (props, ref) {
  var classes = props.classes,
      rest = _objectWithoutProperties(props, ['classes']);

  var classOverrides = {
    // Style overrides
    root: classes.root
  };

  return _react2.default.createElement(_List2.default, Object.assign({}, rest, {
    classes: classOverrides,
    ref: ref
  }));
});

List.muiName = 'List';
List.displayName = 'List';

List.propTypes = {
  /**
   * The content of the component.
   */
  children: _propTypes2.default.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: _propTypes2.default.object.isRequired,
  /**
   * @ignore
   */
  className: _propTypes2.default.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
  /**
   * If `true`, compact vertical padding designed for keyboard and mouse input will be used for
   * the list and list items. The property is available to descendant components as the
   * `dense` context.
   */
  dense: _propTypes2.default.bool,
  /**
   * If `true`, vertical padding will be removed from the list.
   */
  disablePadding: _propTypes2.default.bool,
  /**
   * The content of the subheader, normally `ListSubheader`.
   */
  subheader: _propTypes2.default.node
};

List.defaultProps = {
  dense: false,
  disablePadding: true
};

exports.default = (0, _styles2.default)(styles)(List);