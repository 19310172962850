import React from 'react';
import PropTypes from 'prop-types';
// TODO: Update this after the update of material-ui itself
import { default as MuiRadioGroup } from '@material-ui/core/RadioGroup';

/**
 *  A component for grouping multiple radios
 */
var RadioGroup = React.forwardRef(function (props, ref) {
  return React.createElement(MuiRadioGroup, Object.assign({}, props, { ref: ref }));
});

RadioGroup.muiName = 'RadioGroup';
RadioGroup.displayName = 'RadioGroup';

RadioGroup.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The name used to reference the value of the control.
   */
  name: PropTypes.string,
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  /**
   * Callback fired when a radio button is selected.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`.
   * @param {string} value The `value` of the selected radio button
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onKeyDown: PropTypes.func,
  /**
   * Value of the selected radio button.
   */
  value: PropTypes.string
};

export default RadioGroup;