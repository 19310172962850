'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Badge = require('@material-ui/core/Badge');

var _Badge2 = _interopRequireDefault(_Badge);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        badge: {
            color: theme.palette.grey[200],
            background: theme.palette.status.error.fill,
            minWidth: '1.125rem',
            top: '-0.02rem',
            right: '0.05rem',
            width: 'auto',
            height: 'auto',
            fontSize: theme.typography.caption.fontSize,
            borderRadius: _tokens.borderRadiusLarge,
            padding: '0 ' + _tokens.spacingXxSmall,
            border: _tokens.borderWidthThin + ' solid ' + theme.palette.grey[200]
        },
        noBadgeContent: {
            '& $badge': {
                height: '0.625rem',
                width: '0.625rem',
                display: 'inline-block',
                minWidth: 'auto',
                padding: 0
            }
        }
    };
};

/**
 * Use a `NotificationBadge` to indicate a new notification, change, or update.
 */
var NotificationBadge = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        badgeContent = props.badgeContent,
        maxvalue = props.maxvalue,
        rest = _objectWithoutProperties(props, ['classes', 'badgeContent', 'maxvalue']);

    var classOverrides = {
        badge: classes.badge
    };

    return _react2.default.createElement(_Badge2.default, Object.assign({}, rest, {
        badgeContent: badgeContent || ' ',
        classes: classOverrides,
        invisible: false,
        max: maxvalue,
        className: (0, _classnames2.default)(rest.className, _defineProperty({}, classes.noBadgeContent, !badgeContent)),
        ref: ref
    }));
});

NotificationBadge.muiName = 'NotificationBadge';
NotificationBadge.displayName = 'NotificationBadge';

NotificationBadge.propTypes = {
    /**
     * The content rendered within the badge.
     */
    badgeContent: _propTypes2.default.number,
    /**
     * The badge will be added relative to this node.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * Max value of content rendered within the badge.
     */
    maxvalue: _propTypes2.default.number
};

NotificationBadge.defaultProps = {
    maxvalue: 999
};

exports.default = (0, _styles2.default)(styles)(NotificationBadge);