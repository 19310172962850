'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _core = require('@material-ui/core');

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            outline: 'none',
            '&:focus': {
                boxShadow: '0 0 0 ' + _tokens.borderWidthThick + ' ' + _tokens.colorBrandSecondary
            }
        }
    };
};

/**
 * A component that displays progress through numbered steps.
 */
var StepButton = _react2.default.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        root: classes.root
    };

    return _react2.default.createElement(_core.StepButton, Object.assign({}, rest, {
        classes: classOverrides,
        ref: ref
    }));
});

StepButton.muiName = 'StepButton';
StepButton.displayName = 'StepButton';

StepButton.propTypes = {
    /**
     * Can be a `StepLabel` or a node to place inside `StepLabel` as children.
     */
    children: _propTypes2.default.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * The icon displayed by the step label.
     */
    icon: _propTypes2.default.node,
    /**
     * The optional node to display.
     */
    optional: _propTypes2.default.node
};

exports.default = (0, _styles2.default)(styles)(StepButton);