'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.IMAGES = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('../styles');

var _Contact = require('./internal/medium/Contact');

var _Contact2 = _interopRequireDefault(_Contact);

var _EmptyCalendar = require('./internal/medium/EmptyCalendar');

var _EmptyCalendar2 = _interopRequireDefault(_EmptyCalendar);

var _NoComments = require('./internal/medium/NoComments');

var _NoComments2 = _interopRequireDefault(_NoComments);

var _NoMessages = require('./internal/medium/NoMessages');

var _NoMessages2 = _interopRequireDefault(_NoMessages);

var _NoSearchResults = require('./internal/medium/NoSearchResults');

var _NoSearchResults2 = _interopRequireDefault(_NoSearchResults);

var _UnderMaintenance = require('./internal/medium/UnderMaintenance');

var _UnderMaintenance2 = _interopRequireDefault(_UnderMaintenance);

var _Book = require('./internal/small/Book');

var _Book2 = _interopRequireDefault(_Book);

var _Bookmark = require('./internal/small/Bookmark');

var _Bookmark2 = _interopRequireDefault(_Bookmark);

var _Calendar = require('./internal/small/Calendar');

var _Calendar2 = _interopRequireDefault(_Calendar);

var _ChartBars = require('./internal/small/ChartBars');

var _ChartBars2 = _interopRequireDefault(_ChartBars);

var _Checkmark = require('./internal/small/Checkmark');

var _Checkmark2 = _interopRequireDefault(_Checkmark);

var _Crown = require('./internal/small/Crown');

var _Crown2 = _interopRequireDefault(_Crown);

var _EmptySet = require('./internal/small/EmptySet');

var _EmptySet2 = _interopRequireDefault(_EmptySet);

var _Error = require('./internal/small/Error');

var _Error2 = _interopRequireDefault(_Error);

var _EyeHide = require('./internal/small/EyeHide');

var _EyeHide2 = _interopRequireDefault(_EyeHide);

var _GraduationCap = require('./internal/small/GraduationCap');

var _GraduationCap2 = _interopRequireDefault(_GraduationCap);

var _Heart = require('./internal/small/Heart');

var _Heart2 = _interopRequireDefault(_Heart);

var _IdBadge = require('./internal/small/IdBadge');

var _IdBadge2 = _interopRequireDefault(_IdBadge);

var _Lightbulb = require('./internal/small/Lightbulb');

var _Lightbulb2 = _interopRequireDefault(_Lightbulb);

var _Link = require('./internal/small/Link');

var _Link2 = _interopRequireDefault(_Link);

var _News = require('./internal/small/News');

var _News2 = _interopRequireDefault(_News);

var _NoCourses = require('./internal/small/NoCourses');

var _NoCourses2 = _interopRequireDefault(_NoCourses);

var _NoHistory = require('./internal/small/NoHistory');

var _NoHistory2 = _interopRequireDefault(_NoHistory);

var _NoSearchResults3 = require('./internal/small/NoSearchResults');

var _NoSearchResults4 = _interopRequireDefault(_NoSearchResults3);

var _NoTasks = require('./internal/small/NoTasks');

var _NoTasks2 = _interopRequireDefault(_NoTasks);

var _Pencil = require('./internal/small/Pencil');

var _Pencil2 = _interopRequireDefault(_Pencil);

var _Search = require('./internal/small/Search');

var _Search2 = _interopRequireDefault(_Search);

var _Server = require('./internal/small/Server');

var _Server2 = _interopRequireDefault(_Server);

var _Tasks = require('./internal/small/Tasks');

var _Tasks2 = _interopRequireDefault(_Tasks);

var _UserGroup = require('./internal/small/UserGroup');

var _UserGroup2 = _interopRequireDefault(_UserGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// Medium


// Small


// Consumer focused const to help with auto-predict in text editors
// Consumer doesn't need the component, only the name of the illustration
var IMAGES = exports.IMAGES = {
    // Medium
    CONTACT: 'contact',
    EMPTY_CALENDAR: 'empty-calendar',
    NO_COMMENTS: 'no-comments',
    NO_MESSAGES: 'no-messages',
    NO_SEARCH_RESULTS: 'no-search-results',
    UNDER_MAINTENANCE: 'under-maintenance',

    // Small
    BOOK: 'book',
    BOOKMARK: 'bookmark',
    CALENDAR: 'calendar',
    CHART_BARS: 'chart-bars',
    CHECKMARK: 'checkmark',
    CROWN: 'crown',
    EMPTY_SET: 'empty-set',
    ERROR: 'error',
    EYE_HIDE: 'eye-hide',
    GRADUATION_CAP: 'graduation-cap',
    HEART: 'heart',
    ID_BADGE: 'id-badge',
    LIGHTBULB: 'lightbulb',
    LINK: 'link',
    NEWS: 'news',
    NO_COURSES: 'no-courses',
    NO_HISTROY: 'no-history',
    NO_SEARCH_RESULTS_SM: 'no-search-results-sm',
    NO_TASKS: 'no-tasks',
    PENCIL: 'pencil',
    SEARCH: 'search',
    SERVER: 'server',
    TASKS: 'tasks',
    USER_GROUP: 'user-group'
};
// Internal object to return the correct illustration-component to render
var ILLUSTRATIONS = {
    // Medium
    contact: _Contact2.default,
    'empty-calendar': _EmptyCalendar2.default,
    'no-comments': _NoComments2.default,
    'no-messages': _NoMessages2.default,
    'no-search-results': _NoSearchResults2.default,
    'under-maintenance': _UnderMaintenance2.default,

    // Small
    book: _Book2.default,
    bookmark: _Bookmark2.default,
    calendar: _Calendar2.default,
    'chart-bars': _ChartBars2.default,
    checkmark: _Checkmark2.default,
    crown: _Crown2.default,
    'empty-set': _EmptySet2.default,
    'error': _Error2.default,
    'eye-hide': _EyeHide2.default,
    'graduation-cap': _GraduationCap2.default,
    heart: _Heart2.default,
    'id-badge': _IdBadge2.default,
    lightbulb: _Lightbulb2.default,
    link: _Link2.default,
    news: _News2.default,
    'no-courses': _NoCourses2.default,
    'no-history': _NoHistory2.default,
    'no-search-results-sm': _NoSearchResults4.default,
    'no-tasks': _NoTasks2.default,
    pencil: _Pencil2.default,
    search: _Search2.default,
    server: _Server2.default,
    tasks: _Tasks2.default,
    'user-group': _UserGroup2.default
};

var styles = function styles(theme) {
    return {
        root: {},
        primary: { color: theme.palette.primary.main },
        secondary: { color: theme.palette.secondary.main }
    };
};

/**
 * Use `Illustration` to soften content-heavy pages and to communicate important moments in a user's journey.
 * @done true
 * @updated false
 * @versionAdded v2.4.0
 * @examples
 *  IllustrationExample
 *  ThemedIllustration
 *  EmptyState
 */
var Illustration = _react2.default.forwardRef(function (props, ref) {
    var _cn;

    var classes = props.classes,
        name = props.name,
        color = props.color,
        className = props.className,
        SvgProps = props.SvgProps,
        rest = _objectWithoutProperties(props, ['classes', 'name', 'color', 'className', 'SvgProps']);

    var IllustrationComponent = ILLUSTRATIONS[name];

    return _react2.default.createElement(
        'span',
        Object.assign({
            className: (0, _classnames2.default)(classes.root, (_cn = {}, _defineProperty(_cn, classes.primary, color === 'primary'), _defineProperty(_cn, classes.secondary, color === 'secondary'), _cn), className)
        }, rest, {
            ref: ref
        }),
        _react2.default.createElement(IllustrationComponent, SvgProps)
    );
});

Illustration.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * The name of the illustration to render.
     *
     * Use the const `IMAGES` located in `@hedtech/react-design-system/core/Illustration`.
     */
    name: _propTypes2.default.oneOf(['contact', 'empty-calendar', 'no-comments', 'no-messages', 'no-search-results', 'under-maintenance', 'book', 'bookmark', 'calendar', 'chart-bars', 'checkmark', 'crown', 'empty-set', 'error', 'eye-hide', 'graduation-cap', 'heart', 'id-badge', 'lightbulb', 'link', 'news', 'no-courses', 'no-history', 'no-search-results-sm', 'no-tasks', 'pencil', 'search', 'server', 'tasks', 'user-group']).isRequired,
    /**
     * The color the illustration will render in.
     */
    color: _propTypes2.default.oneOf(['primary', 'secondary']),
    /**
     * Prop object to pass to the Illustration SVG component.
     */
    SvgProps: _propTypes2.default.object
};

Illustration.displayName = 'Illustration';

// If default props are needed, they must be set here
Illustration.defaultProps = {
    color: 'primary'
};

exports.default = (0, _styles.withStyles)(styles)(Illustration);