function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiDivider } from '@material-ui/core/Divider';
import classNames from 'classnames';
import { spacingSmall } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            backgroundColor: theme.palette.grey[400],
            marginTop: spacingSmall,
            marginBottom: spacingSmall
        },
        middle: {
            marginLeft: spacingSmall,
            marginRight: spacingSmall
        }
    };
};

/**
 * Use `Divider` to group and separate content within lists and page layouts.
 * @done true
 * @updated false
 * @versionAdded v0.0.11
 * @examples
 *  FullBleedDividerExample
 *  MiddleDividerExample
 */
var Divider = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        variant = props.variant,
        rest = _objectWithoutProperties(props, ['classes', 'variant']);

    var classOverrides = {
        root: classNames(classes.root, _defineProperty({}, classes.middle, variant === 'middle'))
    };

    return React.createElement(MuiDivider, Object.assign({}, rest, { classes: classOverrides, ref: ref }));
});

Divider.muiName = 'Divider';
Divider.displayName = 'Divider';

Divider.propTypes = {
    absolute: PropTypes.bool,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * Specifies fullbleed or middle Divider styling. Default is fullbleed.
     */
    variant: PropTypes.oneOf(['fullbleed', 'middle'])
};

Divider.defaultProps = {
    variant: 'fullbleed'
};

export default withStyles(styles)(Divider);