'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ = require('../');

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _DialogTitle = require('@material-ui/core/DialogTitle');

var _DialogTitle2 = _interopRequireDefault(_DialogTitle);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = function styles(theme) {
    return {
        root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h2.fontSize,
            fontWeight: theme.typography.fontWeight,
            lineHeight: theme.typography.h2.lineHeight,
            margin: 0,
            padding: _tokens.spacingXSmall2 + ' ' + _tokens.spacingXSmall2 + ' ' + _tokens.spacingSmall + ' ' + _tokens.spacingXSmall2
        }
    };
};

/**
 * DialogTitle is the title within a Dialog.
 */
var DialogTitle = _react2.default.forwardRef(function (props, ref) {
    var children = props.children,
        disableTypography = props.disableTypography,
        classesProp = props.classes,
        rest = _objectWithoutProperties(props, ['children', 'disableTypography', 'classes']);

    var classes = {
        root: classesProp.root
    };

    // use our own typography element (if the user hasn't disabled typography for titles),
    // so that we can control the variant
    var titleContents = disableTypography ? children : _react2.default.createElement(
        _.Typography,
        { variant: 'h2' },
        children
    );

    return _react2.default.createElement(
        _DialogTitle2.default,
        Object.assign({ disableTypography: true, classes: classes }, rest, { ref: ref }),
        titleContents
    );
});

DialogTitle.muiName = 'DialogTitle';
DialogTitle.displayName = 'DialogTitle';

DialogTitle.propTypes = {
    /**
     * The content of the component.
     */
    children: _propTypes2.default.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: _propTypes2.default.object.isRequired,
    /**
     * @ignore
     */
    className: _propTypes2.default.string,
    /**
     * If `true`, the children won't be wrapped by a typography component.
     * For instance, this can be useful to render an h4 instead of the default h2.
     */
    disableTypography: _propTypes2.default.bool
};

DialogTitle.defaultProps = {
    disableTypography: false
};

exports.default = (0, _styles2.default)(styles)(DialogTitle);