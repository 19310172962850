var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import uuid from 'uuid/v4';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withEDSContext } from '../EDSContext/EDSContext';

import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import { withStyles } from '@hedtech/react-design-system/core/styles';
import withWidth from '../withWidth';
import styles from './style/styles';
import ArrowRight from '@eui/ds-icons/lib/ArrowRight';
import ArrowLeft from '@eui/ds-icons/lib/ArrowLeft';

import Navbar from './internal/Navbar';
import Caption from './internal/Caption';
import { getDefaultMaxDate, getDefaultMinDate } from './internal/DateUtils';

var Boundary = {
    START: 'start',
    END: 'end'
};

/**
 * Use `DateRangePicker` for short periods of time.
 * @done true
 * @updated false
 * @versionAdded v3.0.0
 * @examples
 *  DateRangePickerExample
 *  Required
 *  Locale
 *  InDialog
 *  InCard
 */

var DateRangePicker = function (_React$Component) {
    _inherits(DateRangePicker, _React$Component);

    function DateRangePicker(props, context) {
        _classCallCheck(this, DateRangePicker);

        var _this = _possibleConstructorReturn(this, (DateRangePicker.__proto__ || Object.getPrototypeOf(DateRangePicker)).call(this, props, context));

        _initialiseProps.call(_this);

        _this.handleDayChange = _this.handleDayChange.bind(_this);
        _this.handleDayMouseEnter = _this.handleDayMouseEnter.bind(_this);
        _this.handleDayMouseLeave = _this.handleDayMouseLeave.bind(_this);
        _this.handleInputFocus = _this.handleInputFocus.bind(_this);
        _this.openDateRangePicker = _this.openDateRangePicker.bind(_this);
        _this.closeDateRangePicker = _this.closeDateRangePicker.bind(_this);

        // Refs
        _this.startInputContainerRef = React.createRef();
        _this.startInputRef = React.createRef();
        _this.endInputRef = React.createRef();
        _this.daypicker = React.createRef();

        _this.state = _this.initialState(props);
        return _this;
    }

    // ========
    // Component & Lifecycle methods
    // ========

    _createClass(DateRangePicker, [{
        key: 'initialState',
        value: function initialState(props) {
            var _getInitialRange = this.getInitialRange(),
                _getInitialRange2 = _slicedToArray(_getInitialRange, 2),
                selectedStart = _getInitialRange2[0],
                selectedEnd = _getInitialRange2[1];

            var isOpen = this.getInitialOpenState();

            return {
                isOpen: isOpen,

                // Internal error variables
                startInputError: false,
                endInputError: false,
                errorHelperText: null,

                // Helper util variables to keep track of focus states
                lastFocusedField: null,
                boundaryToModify: null,
                isStartInputFocused: false,
                isEndInputFocused: false,

                // Used to show the input value string when user is typing in the input field
                startInputString: null,
                endInputString: null,

                // Used to show the user the date they are hovering over when calendar UI is open
                startHoverString: null,
                endHoverString: null,

                // Keep track of the last day for mouseEnter
                hoverTo: null,

                // These are the internal start and end dates
                // They should be updated internally if the component is
                // uncontrolled.
                // They should NOT be updated if the component IS controlled.
                selectedStart: selectedStart,
                selectedEnd: selectedEnd,

                // Util variables used for checking how the user selects a date
                wasLastFocusChangeDueToHover: false,
                wasLastFocusChangeDueToSelectingDate: false,

                // Used to see if date is out of range
                prevMinDate: props.minDate,
                prevMaxDate: props.maxDate,
                prevValue: [selectedStart, selectedEnd]
            };
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
            // Check focus helper variables to make sure the correct input is focused
            var _state = this.state,
                isStartInputFocused = _state.isStartInputFocused,
                isEndInputFocused = _state.isEndInputFocused;


            var shouldFocusStartInput = this.shouldFocusInputRef(isStartInputFocused, this.startInputRef);
            var shouldFocusEndInput = this.shouldFocusInputRef(isEndInputFocused, this.endInputRef);

            if (shouldFocusStartInput || isStartInputFocused) {
                this.startInputRef.current.focus();
            } else if (shouldFocusEndInput || isEndInputFocused) {
                this.endInputRef.current.focus();
            }

            // Check if date values changed
            var nextState = {};

            // NOTE: Uncomment this if the minDate and maxDate checks are required
            // const shouldUpdateState = false;

            // If values change for controlled implementations, update internal component with new date values
            if (this.props.value !== prevProps.value) {
                var _getInitialRange3 = this.getInitialRange(this.props),
                    _getInitialRange4 = _slicedToArray(_getInitialRange3, 2),
                    selectedStart = _getInitialRange4[0],
                    selectedEnd = _getInitialRange4[1];

                nextState = Object.assign({}, nextState, { selectedStart: selectedStart, selectedEnd: selectedEnd });
                this.setState(function (prevState) {
                    return Object.assign({}, prevState, nextState);
                });
            }

            // NOTE: Commenting this out right now, but we might need to check minDate or maxDate changes
            // cache the formatted date strings to avoid computing on each render.
            // if (this.props.minDate !== prevProps.minDate) {
            //     const formattedMinDateString = this.getFormattedMinMaxDateString(this.props, 'minDate');
            //     nextState = { ...nextState, formattedMinDateString };
            // }
            // if (this.props.maxDate !== prevProps.maxDate) {
            //     const formattedMaxDateString = this.getFormattedMinMaxDateString(this.props, 'maxDate');
            //     nextState = { ...nextState, formattedMaxDateString };
            // }

            // NOTE: Uncomment this if the minDate and maxDate checks are required
            // if (shouldUpdateState) {
            //     this.setState(nextState);
            // }
        }

        // Helper function to check if a specific input should get focus

    }, {
        key: 'shouldFocusInputRef',
        value: function shouldFocusInputRef(isFocused, inputRef) {
            return isFocused && inputRef !== undefined && document.activeElement !== inputRef;
        }

        // This method is used to close the calendar UI when the input is focused


        // ========
        // DayPicker functions
        // ========

    }, {
        key: 'handleDayChange',
        value: function handleDayChange(day) {
            var modifiers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            // Disable users being able to click and enter day
            if (modifiers.disabled) {
                return;
            }

            var _state2 = this.state,
                selectedStart = _state2.selectedStart,
                selectedEnd = _state2.selectedEnd;


            var wasStartFieldFocused = this.state.lastFocusedField === Boundary.START;
            var wasEndFieldFocused = this.state.lastFocusedField === Boundary.END;

            var nextState = {};

            var controlledSelectedStart = selectedStart;
            var controlledSelectedEnd = selectedEnd;

            if (wasStartFieldFocused) {
                // if user selects a date after the end date then assign day to
                // start date and clear end date
                if (selectedEnd != null && DateUtils.isDayAfter(day, selectedEnd)) {
                    nextState = Object.assign({}, nextState, {
                        selectedStart: day,
                        selectedEnd: null,
                        isStartInputFocused: false,
                        isEndInputFocused: true
                    });
                    controlledSelectedStart = day;
                    controlledSelectedEnd = null;
                } else {
                    nextState = Object.assign({}, nextState, {
                        selectedStart: day,
                        isStartInputFocused: false,
                        isEndInputFocused: true,
                        isOpen: false
                    });
                    controlledSelectedStart = day;
                }
            } else if (wasEndFieldFocused) {
                if (selectedStart == null) {
                    // if end date is picked, and start date is empty
                    // assign day to end date, and focus start date
                    nextState = Object.assign({}, nextState, {
                        selectedEnd: day,
                        isStartInputFocused: true,
                        isEndInputFocused: false
                    });
                    controlledSelectedEnd = day;
                } else if (selectedStart != null && DateUtils.isDayBefore(day, selectedStart)) {
                    // if there is a selectedStart date and the picked day is before
                    // the selectedStart, then set selectedStart to null
                    nextState = Object.assign({}, nextState, {
                        selectedStart: null,
                        selectedEnd: day,
                        isStartInputFocused: true,
                        isEndInputFocused: false
                    });
                    controlledSelectedStart = null;
                    controlledSelectedEnd = day;
                } else {
                    nextState = Object.assign({}, nextState, {
                        selectedEnd: day,
                        isStartInputFocused: false,
                        isEndInputFocused: true,
                        isOpen: false
                    });
                    controlledSelectedEnd = day;
                }
            }

            // set state helper variable to close DayPicker if complete range exists
            if (controlledSelectedEnd != null && controlledSelectedStart) {
                nextState.wasLastFocusChangeDueToSelectingDate = true;
                // if start date was picked last, return focus to start
                if (wasStartFieldFocused) {
                    nextState.isStartInputFocused = true;
                    nextState.isEndInputFocused = false;
                }
            }

            // set internal state
            this.setState(nextState);

            // send controlled values to onChange method
            if (this.isControlled() && this.props.onChange) {
                this.props.onChange([controlledSelectedStart, controlledSelectedEnd]);
            }
        }
    }, {
        key: 'handleDayMouseEnter',
        value: function handleDayMouseEnter(day, locale) {
            // If the mouse enters a day (or the calendar),
            // show the 'potential' range styling and update hover strings
            var _state3 = this.state,
                isStartInputFocused = _state3.isStartInputFocused,
                isEndInputFocused = _state3.isEndInputFocused,
                lastFocusedField = _state3.lastFocusedField;


            var wasStartFieldFocused = lastFocusedField === Boundary.START;
            var wasEndFieldFocused = lastFocusedField === Boundary.END;

            if (isStartInputFocused || wasStartFieldFocused) {
                this.setState({
                    startHoverString: formatDate(day, this.props.format, locale),
                    hoverTo: day
                });
            } else if (isEndInputFocused || wasEndFieldFocused) {
                this.setState({
                    endHoverString: formatDate(day, this.props.format, locale),
                    hoverTo: day
                });
            }
        }
    }, {
        key: 'handleDayMouseLeave',
        value: function handleDayMouseLeave(day) {
            // If the mouse leaves a day (or the calendar),
            // reset the 'potential' range styling and hover strings
            this.setState({
                hoverTo: null,
                endHoverString: null,
                startHoverString: null
            });
        }

        // ========
        // Input Functions
        // ========

    }, {
        key: 'handleInputFocus',
        value: function handleInputFocus(e, boundary, locale) {
            var _setState;

            var _getStateKeysAndValue = this.getStateKeysAndValuesForBoundary(boundary),
                keys = _getStateKeysAndValue.keys,
                values = _getStateKeysAndValue.values;

            var inputString = this.getFormattedDateString(values.selectedValue, this.props, locale, false);

            var isOpen = true;

            // Close the DayPicker UI if both dates are selected
            // This implementation keeps focus on the last focused boundary
            if (this.state.wasLastFocusChangeDueToSelectingDate) {
                isOpen = false;
            }

            // Change the boundary only if the user explicitly focused in the field.
            // Focus changes from hovering don't count; they're just temporary.
            var boundaryToModify = this.state.wasLastFocusChangeDueToHover ? this.state.boundaryToModify : boundary;

            this.setState((_setState = {}, _defineProperty(_setState, keys.inputString, inputString), _defineProperty(_setState, keys.isInputFocused, true), _defineProperty(_setState, 'boundaryToModify', boundaryToModify), _defineProperty(_setState, 'isOpen', isOpen), _defineProperty(_setState, 'lastFocusedField', boundary), _defineProperty(_setState, 'wasLastFocusChangeDueToHover', false), _defineProperty(_setState, 'wasLastFocusChangeDueToSelectingDate', false), _setState));
        }
    }, {
        key: 'handleInputBlur',
        value: function handleInputBlur(e, boundary, locale) {
            var _nextState;

            var _getStateKeysAndValue2 = this.getStateKeysAndValuesForBoundary(boundary),
                keys = _getStateKeysAndValue2.keys,
                values = _getStateKeysAndValue2.values;

            var _getStateKeysAndValue3 = this.getStateKeysAndValuesForBoundary(this.getOtherBoundary(boundary)),
                otherBoundaryValues = _getStateKeysAndValue3.values;

            var maybeNextDate = this.parseDate(values.inputString);
            var isValueControlled = this.isControlled();

            var edsContext = this.props.edsContext;


            var nextState = (_nextState = {}, _defineProperty(_nextState, keys.isInputFocused, false), _defineProperty(_nextState, 'wasLastFocusChangeDueToSelectingDate', false), _nextState);

            // if input is empty, then update Boundary's inputString value to ""
            if (this.isInputEmpty(values.inputString)) {
                // reset error, no string means no error
                if (isValueControlled) {
                    var _Object$assign;

                    // This should clear the date out for the consumer
                    nextState = Object.assign({}, nextState, (_Object$assign = {}, _defineProperty(_Object$assign, keys.inputString, this.getFormattedDateString(values.controlledValue, this.props, locale, false)), _defineProperty(_Object$assign, keys.error, false), _defineProperty(_Object$assign, 'errorHelperText', null), _Object$assign));
                } else {
                    var _Object$assign2;

                    // Uncontrolled
                    // should clear the input string if the input is empty
                    nextState = Object.assign({}, nextState, (_Object$assign2 = {}, _defineProperty(_Object$assign2, keys.inputString, null), _defineProperty(_Object$assign2, keys.error, false), _defineProperty(_Object$assign2, 'errorHelperText', null), _Object$assign2));
                }
            } else if (!DateUtils.isDate(maybeNextDate)) {
                var _Object$assign3;

                nextState = Object.assign({}, nextState, (_Object$assign3 = {
                    errorHelperText: edsContext.formatMessage('component.DateRangePicker.error.invalidDate')
                }, _defineProperty(_Object$assign3, keys.error, true), _defineProperty(_Object$assign3, keys.selectedValue, null), _Object$assign3));
            } else {
                var _Object$assign4;

                nextState = Object.assign({}, nextState, (_Object$assign4 = {}, _defineProperty(_Object$assign4, keys.inputString, null), _defineProperty(_Object$assign4, keys.selectedValue, maybeNextDate), _Object$assign4));
            }

            if (otherBoundaryValues.inputString || otherBoundaryValues.selectedValue) {
                if (otherBoundaryValues.selectedValue && !DateUtils.isDayInRange(otherBoundaryValues.selectedValue, {
                    from: this.props.minDate,
                    to: this.props.maxDate
                })) {
                    nextState = Object.assign({}, nextState, {
                        errorHelperText: edsContext.formatMessage('component.DateRangePicker.error.outOfRange')
                    });
                }
                if (otherBoundaryValues.inputString && !DateUtils.isDate(otherBoundaryValues.inputString)) {
                    nextState = Object.assign({}, nextState, {
                        errorHelperText: edsContext.formatMessage('component.DateRangePicker.error.invalidDate')
                    });
                }
            }

            // Need to be able to account for consumer's onBlur methods for error checking
            if (boundary === Boundary.START && this.props.StartInputProps.onBlur) {
                this.props.StartInputProps.onBlur([this.state.selectedStart, this.state.selectedEnd]);
            } else if (boundary === Boundary.END && this.props.EndInputProps.onBlur) {
                this.props.EndInputProps.onBlur([this.state.selectedStart, this.state.selectedEnd]);
            }

            this.setState(nextState);
        }
    }, {
        key: 'handleInputChange',
        value: function handleInputChange(e, boundary) {
            var inputString = e.target.value;

            var _getStateKeysAndValue4 = this.getStateKeysAndValuesForBoundary(boundary),
                keys = _getStateKeysAndValue4.keys;

            var maybeNextDate = this.parseDate(inputString);
            var isValueControlled = this.isControlled();

            var otherBoundary = this.getOtherBoundary(boundary);

            var _getStateKeysAndValue5 = this.getStateKeysAndValuesForBoundary(otherBoundary),
                otherBoundaryKeys = _getStateKeysAndValue5.keys,
                otherBoundaryValues = _getStateKeysAndValue5.values;

            var nextState = _defineProperty({}, keys.error, false);
            var edsContext = this.props.edsContext;


            if (inputString.length === 0) {
                // this case will be relevant when we start showing the hovered range in the input
                // fields. goal is to show an empty field for clarity until the mouse moves over a
                // different date.
                var baseState = Object.assign({}, nextState, _defineProperty({
                    errorHelperText: null
                }, keys.inputString, ''));
                if (isValueControlled) {
                    nextState = baseState;
                    // nextState = { ...baseState, [keys.selectedValue]: null };

                    if (this.props.onChange) {
                        // reset respective input field's value
                        this.props.onChange(this.getDateRangeForCallback(null, boundary));
                    }
                } else {
                    nextState = Object.assign({}, baseState, _defineProperty({}, keys.selectedValue, null));
                }
            } else if (DateUtils.isDate(maybeNextDate)) {
                var _Object$assign7;

                // If the date typed in is valid,
                // note that error cases that depend on both fields (e.g. overlapping dates) should fall
                // through into this block so that the UI can update immediately, possibly with an error
                // message on the other field.
                // also, clear the hover string to ensure the most recent keystroke appears.
                var _baseState = Object.assign({}, nextState, (_Object$assign7 = {}, _defineProperty(_Object$assign7, keys.hoverString, null), _defineProperty(_Object$assign7, keys.inputString, inputString), _Object$assign7));
                if (isValueControlled) {
                    nextState = _baseState;
                    if (this.props.onChange) {
                        this.props.onChange(this.getDateRangeForCallback(maybeNextDate, boundary));
                    }
                } else {
                    nextState = Object.assign({}, _baseState, _defineProperty({}, keys.selectedValue, maybeNextDate));
                }
            } else {
                var _Object$assign9;

                // again, clear the hover string to ensure the most recent keystroke appears
                nextState = Object.assign({}, nextState, (_Object$assign9 = {}, _defineProperty(_Object$assign9, keys.inputString, inputString), _defineProperty(_Object$assign9, keys.hoverString, null), _Object$assign9));
            }

            // Internal error states
            if (DateUtils.isDate(maybeNextDate) && this.doBoundaryDatesOverlap(maybeNextDate, boundary)) {
                var _Object$assign10;

                // If typed dates are overlapping, trigger error state and message
                nextState = Object.assign({}, nextState, (_Object$assign10 = {}, _defineProperty(_Object$assign10, keys.error, true), _defineProperty(_Object$assign10, 'errorHelperText', edsContext.formatMessage('component.DateRangePicker.error.overlappingDates')), _Object$assign10));
            } else if (DateUtils.isDate(maybeNextDate) && !DateUtils.isDayInRange(maybeNextDate, {
                from: this.props.minDate,
                to: this.props.maxDate
            })) {
                var _Object$assign11;

                // if typed dates are out of range, trigger error state and message
                nextState = Object.assign({}, nextState, (_Object$assign11 = {}, _defineProperty(_Object$assign11, keys.error, true), _defineProperty(_Object$assign11, 'errorHelperText', edsContext.formatMessage('component.DateRangePicker.error.outOfRange')), _Object$assign11));
            } else {
                var _Object$assign12;

                nextState = Object.assign({}, nextState, (_Object$assign12 = {}, _defineProperty(_Object$assign12, keys.error, false), _defineProperty(_Object$assign12, 'errorHelperText', null), _Object$assign12));
            }

            // This will check the other boundary and determine if the error should persist
            // i.e. overlapping dates, 10/20/2019 - 10/19/2019
            // You can change either date to resolve an internal error, this means that each input
            // needs to check the other boundary to see if the error should be cleared or persist.
            if (!this.doBoundaryDatesOverlap(maybeNextDate, boundary) && DateUtils.isDate(maybeNextDate) && DateUtils.isDayInRange(maybeNextDate, {
                from: this.props.minDate,
                to: this.props.maxDate
            }) && otherBoundaryValues.selectedValue && DateUtils.isDayInRange(otherBoundaryValues.selectedValue, {
                from: this.props.minDate,
                to: this.props.maxDate
            })) {
                var _Object$assign13;

                nextState = Object.assign({}, nextState, (_Object$assign13 = {}, _defineProperty(_Object$assign13, keys.error, false), _defineProperty(_Object$assign13, otherBoundaryKeys.error, false), _defineProperty(_Object$assign13, 'errorHelperText', null), _Object$assign13));
            }
            if (otherBoundaryValues.selectedValue && !this.isDateValidAndInRange(otherBoundaryValues.selectedValue)) {
                // check check if the date range is valid
                nextState = Object.assign({}, nextState, {
                    errorHelperText: edsContext.formatMessage('component.DateRangePicker.error.outOfRange')
                });
            }
            if (otherBoundaryValues.inputString && !DateUtils.isDate(otherBoundaryValues.inputString)) {
                // inputString should only be non-null if the input has focus or if the date is invalid
                nextState = Object.assign({}, nextState, {
                    errorHelperText: edsContext.formatMessage('component.DateRangePicker.error.invalidDate')
                });
            }

            // Update state
            this.setState(nextState);
        }
    }, {
        key: 'handleOnKeyDown',
        value: function handleOnKeyDown(e, input) {
            var dir = this.props.dir;

            var isTabPressed = e.which === 9; // Tab keycode
            var isDownArrowPressed = e.which === 40; // Down arrow keycode
            var isShiftPressed = e.shiftKey;

            if (this.state.isOpen && isDownArrowPressed) {
                // prevent the default behavior of scrolling down
                e.preventDefault();
                // get first selectable day
                document.querySelector('.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)').focus();
            }

            // order of JS events is our enemy here. when tabbing between fields,
            // this handler will fire in the middle of a focus exchange when no
            // field is currently focused. we work around this by referring to the
            // most recently focused field, rather than the currently focused field.
            var wasStartFieldFocused = this.state.lastFocusedField === Boundary.START;
            var wasEndFieldFocused = this.state.lastFocusedField === Boundary.END;

            if (isTabPressed) {
                var isEndInputFocused = void 0;
                var isStartInputFocused = void 0;
                var isOpen = true;

                // account for both ltr and rtl tab order
                if (dir === 'ltr') {
                    // if start field focused and shift is not pressed
                    // move to end field
                    if (wasStartFieldFocused && !isShiftPressed) {
                        isStartInputFocused = false;
                        isEndInputFocused = true;
                        // prevent the default focus-change behavior to avoid race conditions;
                        // we'll handle the focus change ourselves in componentDidUpdate.
                        e.preventDefault();
                    } else if (wasEndFieldFocused && isShiftPressed) {
                        // if end field is focused and shift is pressed
                        // move to start input
                        isStartInputFocused = true;
                        isEndInputFocused = false;
                        e.preventDefault();
                    } else {
                        // don't prevent default here, otherwise Tab won't do anything.
                        isStartInputFocused = false;
                        isEndInputFocused = false;
                        isOpen = false;
                    }
                } else {
                    // right-to-left
                    // if start field focused and shift is not pressed
                    // move to end field
                    if (wasEndFieldFocused && !isShiftPressed) {
                        isStartInputFocused = true;
                        isEndInputFocused = false;

                        // prevent the default focus-change behavior to avoid race conditions;
                        // we'll handle the focus change ourselves in componentDidUpdate.
                        e.preventDefault();
                    } else if (wasStartFieldFocused && isShiftPressed) {
                        // if end field is focused and shift+tab is pressed
                        // move to start input
                        isStartInputFocused = false;
                        isEndInputFocused = true;
                        e.preventDefault();
                    } else {
                        // don't prevent default here, otherwise Tab won't do anything.
                        isStartInputFocused = false;
                        isEndInputFocused = false;
                        isOpen = false;
                    }
                }
                this.setState({
                    isEndInputFocused: isEndInputFocused,
                    isOpen: isOpen,
                    isStartInputFocused: isStartInputFocused,
                    wasLastFocusChangeDueToHover: false
                });
            }

            // else the default keystroke happen without side effects
        }

        // Date Util Functions

    }, {
        key: 'parseDate',
        value: function parseDate(dateString) {
            if (dateString === this.props.edsContext.formatMessage('component.DateRangePicker.error.outOfRange') || dateString === this.props.edsContext.formatMessage('component.DateRangePicker.error.invalidDate')) {
                return null;
            }

            var _props = this.props,
                locale = _props.locale,
                format = _props.format,
                parseDateProp = _props.parseDate;
            // Accounts for ISO formats and format prop
            // YYYY-MM-DD and MM/DD/YYYY and the consumer provided prop

            var newDate = parseDateProp(dateString, format, locale) || parseDateProp(dateString, 'MM/DD/YYYY', locale) || parseDateProp(dateString, 'YYYY-MM-DD', locale);

            return newDate === false ? new Date(undefined) : newDate;
        }
    }, {
        key: 'getFormattedDateString',
        value: function getFormattedDateString(date, props, locale) {
            var ignoreRange = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

            if (date == null) {
                return '';
            } else if (!DateUtils.isDate(date)) {
                return props.edsContext.formatMessage('component.DateRangePicker.error.invalidDate');
            } else if (ignoreRange || DateUtils.isDayInRange(date, {
                from: props.minDate,
                to: props.maxDate
            })) {
                // good date
                return formatDate(date, props.format, locale);
            } else {
                return formatDate(date, props.format, locale);
            }
        }

        // ========
        // Helper Functions
        // ========

        /**
         * Returns true if the provided boundary is an END boundary overlapping the
         * selected start date. (If the boundaries overlap, we consider the END
         * boundary to be erroneous.)
         */


        // NOTE: This should also get uncommented if the check functions in componentDidUpdate are required
        // getFormattedMinMaxDateString(props, propName) {
        //     const date = props[propName];
        //     const defaultDate = DateRangePicker.defaultProps[propName];
        //     return this.getFormattedDateString(
        //         date === undefined ? defaultDate : date,
        //         this.props
        //     );
        // }

    }, {
        key: 'isNextDateRangeValid',
        value: function isNextDateRangeValid(nextDate, boundary) {
            return this.isDateValidAndInRange(nextDate) && !this.doBoundaryDatesOverlap(nextDate, boundary);
        }
    }, {
        key: 'getInputDisplayString',
        value: function getInputDisplayString(boundary, locale) {
            var _getStateKeysAndValue6 = this.getStateKeysAndValuesForBoundary(boundary),
                values = _getStateKeysAndValue6.values;

            var isInputFocused = values.isInputFocused,
                inputString = values.inputString,
                selectedValue = values.selectedValue,
                hoverString = values.hoverString,
                error = values.error;

            // Show hover string as priority

            if (hoverString != null) {
                return hoverString;
            } else if (error && inputString) {
                return inputString == null ? '' : inputString;
            } else if (isInputFocused) {
                // If Input is focused, then show inputString being typed in
                // If the input loses focus, then show the hoverString (if hovering over the DatePicker),
                return inputString == null ? '' : inputString;
            } else if (selectedValue == null) {
                return '';
            } else {
                // if no issues, get formatted date string and display
                return this.getFormattedDateString(selectedValue, this.props, locale, false);
            }
        }
    }, {
        key: 'getDayPickerMonth',
        value: function getDayPickerMonth() {
            // 1. no start date, no end date (clicking either start/end input) => current month
            // 2. yes start date, no end date, clicking on end date input => start month
            // 3. yes start date, no end date, clicking on start date input => start month
            // 4. no start date, yes end date, clicking start date input => end month
            // 5. no start date, yes end date, clicking end date input => end month
            // 6. yes start date, yes end date, clicking start date input => start month?
            // 7. yes start date, yes end date, clicking end date input => end month?
            if (this.state.selectedStart && !this.state.selectedEnd) {
                // show selectedStart month if only start or both dates exist
                return new Date(this.state.selectedStart);
            } else if (!this.state.selectedStart && this.state.selectedEnd) {
                // show end date if only end date exists
                return new Date(this.state.selectedEnd);
            } else if (this.state.selectedStart && this.state.selectedEnd) {
                if (this.state.isStartInputFocused || this.state.lastFocusedField === 'start') {
                    return new Date(this.state.selectedStart);
                } else if (this.state.isEndInputFocused || this.state.lastFocusedField === 'end') {
                    return new Date(this.state.selectedEnd);
                }
            } else if (this.props.initialMonth) {
                // return consumer's initialMonth to show
                return new Date(this.props.initialMonth);
            } else {
                // else return today's month
                return new Date();
            }
        }

        // Utility function to update the months shown according to viewport size

    }, {
        key: 'getNumberOfMonths',
        value: function getNumberOfMonths() {
            var viewportNumberOfMonths = this.props.width === 'xs' || this.props.width === 'sm' ? 1 : 2;
            return this.props.numberOfMonths || viewportNumberOfMonths;
        }
    }, {
        key: 'getDayPickerModifiers',
        value: function getDayPickerModifiers() {
            var _state4 = this.state,
                selectedStart = _state4.selectedStart,
                selectedEnd = _state4.selectedEnd,
                hoverTo = _state4.hoverTo,
                lastFocusedField = _state4.lastFocusedField;


            var modifiers = {
                start: selectedStart,
                end: selectedEnd,
                hover: { from: selectedStart, to: hoverTo }
            };

            // we are assuming that hoverTo is a date, since it is an internal variable and only set as a valid date
            if (DateUtils.isDate(selectedEnd)) {
                if (lastFocusedField === Boundary.START && DateUtils.isDayAfter(new Date(hoverTo), selectedEnd)) {
                    delete modifiers.hover;
                }
            }
            if (DateUtils.isDate(selectedStart)) {
                if (lastFocusedField === Boundary.END && DateUtils.isDayBefore(new Date(hoverTo), selectedStart)) {
                    delete modifiers.hover;
                }
            }
            // flip the modifiers if we are picking the start date
            if (lastFocusedField === Boundary.START && !DateUtils.isDate(selectedStart)) {
                modifiers.hover = {
                    from: hoverTo,
                    to: selectedEnd
                };
            }
            if (selectedEnd != null && hoverTo !== null) {
                if (DateUtils.isDayAfter(hoverTo, selectedEnd)) {
                    delete modifiers.hover;
                }
            }

            return modifiers;
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this,
                _cn4,
                _cn8,
                _cn11;

            var _props2 = this.props,
                edsContext = _props2.edsContext,
                classes = _props2.classes,
                idProp = _props2.id,
                ContainerProps = _props2.ContainerProps,
                format = _props2.format,
                localeProp = _props2.locale,
                labels = _props2.labels,
                dir = _props2.dir,
                disabled = _props2.disabled,
                error = _props2.error,
                required = _props2.required,
                fullWidth = _props2.fullWidth,
                size = _props2.size,
                startInputLabelProp = _props2.startInputLabel,
                endInputLabelProp = _props2.endInputLabel,
                startInputPlaceholder = _props2.startInputPlaceholder,
                endInputPlaceholder = _props2.endInputPlaceholder,
                StartInputProps = _props2.StartInputProps,
                EndInputProps = _props2.EndInputProps,
                StartFormControlProps = _props2.StartFormControlProps,
                EndFormControlProps = _props2.EndFormControlProps,
                StartInputLabelProps = _props2.StartInputLabelProps,
                EndInputLabelProps = _props2.EndInputLabelProps,
                FormHelperTextProps = _props2.FormHelperTextProps,
                helperText = _props2.helperText,
                PopperProps = _props2.PopperProps,
                ClickAwayListenerProps = _props2.ClickAwayListenerProps,
                minDate = _props2.minDate,
                maxDate = _props2.maxDate,
                props = _objectWithoutProperties(_props2, ['edsContext', 'classes', 'id', 'ContainerProps', 'format', 'locale', 'labels', 'dir', 'disabled', 'error', 'required', 'fullWidth', 'size', 'startInputLabel', 'endInputLabel', 'startInputPlaceholder', 'endInputPlaceholder', 'StartInputProps', 'EndInputProps', 'StartFormControlProps', 'EndFormControlProps', 'StartInputLabelProps', 'EndInputLabelProps', 'FormHelperTextProps', 'helperText', 'PopperProps', 'ClickAwayListenerProps', 'minDate', 'maxDate']);

            var _state5 = this.state,
                isOpen = _state5.isOpen,
                selectedStart = _state5.selectedStart,
                selectedEnd = _state5.selectedEnd,
                isStartInputFocused = _state5.isStartInputFocused,
                isEndInputFocused = _state5.isEndInputFocused,
                lastFocusedField = _state5.lastFocusedField,
                startInputError = _state5.startInputError,
                endInputError = _state5.endInputError,
                errorHelperText = _state5.errorHelperText;


            var internalError = startInputError || endInputError;

            var id = idProp || uuid();

            // figure out which locale to use -- in order: the locale prop, or the EDSApplication-level locale
            var locale = localeProp || edsContext.locale;

            var startInputLabel = startInputLabelProp || edsContext.formatMessage('component.DateRangePicker.startDate');
            var endInputLabel = endInputLabelProp || edsContext.formatMessage('component.DateRangePicker.endDate');

            var anchorEl = this.startInputContainerRef.current;

            var modifiers = this.getDayPickerModifiers();
            var numberOfMonths = this.getNumberOfMonths();

            var selectedDays = [selectedStart, { from: selectedStart, to: selectedEnd }, selectedEnd];

            var buttonLabels = Object.assign({
                previousMonth: edsContext.formatMessage('component.DateRangePicker.previousMonth'),
                nextMonth: edsContext.formatMessage('component.DateRangePicker.nextMonth')
            }, labels);

            var defaultProps = {
                navbarElement: React.createElement(Navbar, null),
                labels: buttonLabels,
                captionElement: function captionElement(_ref) {
                    var date = _ref.date,
                        localeUtils = _ref.localeUtils,
                        localeCaptionProp = _ref.locale,
                        rest = _objectWithoutProperties(_ref, ['date', 'localeUtils', 'locale']);

                    return React.createElement(Caption, Object.assign({
                        onChange: _this2.handleYearMonthChange,
                        date: date,
                        localeUtils: MomentLocaleUtils,
                        locale: localeCaptionProp
                    }, rest));
                },
                renderDay: function renderDay(day) {
                    return (
                        // We use a new div so we can apply our custom date range picker styles via class name
                        React.createElement(
                            'div',
                            { className: 'hedtech-day' },
                            day.toLocaleDateString(locale, {
                                day: 'numeric'
                            })
                        )
                    );
                }
            };

            var shouldStartInputShrink = isStartInputFocused || isEndInputFocused || this.getInputDisplayString(Boundary.START, locale).length !== 0;
            var shouldEndInputShrink = isStartInputFocused || isEndInputFocused || this.getInputDisplayString(Boundary.END, locale).length !== 0;

            var shouldStartInputFakeFocus = isOpen && lastFocusedField === Boundary.START || isStartInputFocused;
            var shouldEndInputFakeFocus = isOpen && lastFocusedField === Boundary.END || isEndInputFocused;

            var startInput = React.createElement(
                FormControl,
                Object.assign({
                    id: id + '-start-form-control',
                    disabled: disabled,
                    error: internalError || error,
                    fullWidth: fullWidth,
                    key: id + '-start-input',
                    ref: this.startInputContainerRef,
                    className: cn(classes.container, _defineProperty({}, classes.smallDateRangePickerInput, size === 'small'))
                }, StartFormControlProps),
                React.createElement(
                    InputLabel,
                    Object.assign({
                        id: id + '-start-input-label',
                        htmlFor: id + '-start-input',
                        classes: {
                            formControl: classes.inputLabelFormControl,
                            shrink: classes.inputLabelShrink
                        },
                        className: cn(_defineProperty({}, classes.inputLabelSmall, size === 'small')),
                        shrink: shouldStartInputShrink,
                        disabled: disabled,
                        error: internalError || error,
                        required: required
                    }, StartInputLabelProps),
                    startInputLabel
                ),
                React.createElement(Input, Object.assign({
                    autoComplete: 'off',
                    inputRef: this.startInputRef,
                    id: id + '-start-input',
                    classes: {
                        root: cn(classes.inputRoot, _defineProperty({}, classes.smallDateRangePickerInput, size === 'small')),
                        input: cn(classes.input, (_cn4 = {}, _defineProperty(_cn4, classes.startInput, dir === 'ltr'), _defineProperty(_cn4, classes.startInputRtl, dir === 'rtl'), _defineProperty(_cn4, classes.inputFocusedError, internalError || error), _defineProperty(_cn4, classes.inputFocused, shouldStartInputFakeFocus), _defineProperty(_cn4, classes.smallDateRangePickerInputRootinput, size === 'small'), _cn4)),
                        disabled: classes.inputDisabled
                    },
                    disableUnderline: true,
                    value: this.getInputDisplayString(Boundary.START, locale),
                    placeholder: startInputPlaceholder
                }, StartInputProps, {
                    onClick: function onClick(e) {
                        return _this2.handleInputClick(e);
                    },
                    onChange: function onChange(e) {
                        return _this2.handleInputChange(e, Boundary.START);
                    },
                    onFocus: function onFocus(e) {
                        return _this2.handleInputFocus(e, Boundary.START, locale);
                    },
                    onBlur: function onBlur(e) {
                        return _this2.handleInputBlur(e, Boundary.START, locale);
                    },
                    onKeyDown: function onKeyDown(e) {
                        return _this2.handleOnKeyDown(e);
                    },
                    onMouseDown: function onMouseDown() {
                        return _this2.handleInputMouseDown();
                    }
                }))
            );

            var endInput = React.createElement(
                FormControl,
                Object.assign({
                    id: id + '-end-form-control',
                    disabled: disabled,
                    error: internalError || error,
                    fullWidth: fullWidth,
                    key: id + '-end-input',
                    className: cn(classes.container, _defineProperty({}, classes.smallDateRangePickerInput, size === 'small'))
                }, EndFormControlProps),
                React.createElement(
                    InputLabel,
                    Object.assign({
                        id: id + '-end-input-label',
                        htmlFor: id + '-end-input',
                        classes: {
                            formControl: classes.inputLabelFormControl,
                            shrink: classes.inputLabelShrink
                        },
                        className: cn(_defineProperty({}, classes.inputLabelSmall, size === 'small')),
                        shrink: shouldEndInputShrink,
                        disabled: disabled,
                        error: internalError || error,
                        required: required
                    }, EndInputLabelProps),
                    endInputLabel
                ),
                React.createElement(Input, Object.assign({
                    autoComplete: 'off',
                    inputRef: this.endInputRef,
                    id: id + '-end-input',
                    classes: {
                        root: cn(classes.inputRoot, _defineProperty({}, classes.smallDateRangePickerInput, size === 'small')),
                        input: cn(classes.input, (_cn8 = {}, _defineProperty(_cn8, classes.endInput, dir === 'ltr'), _defineProperty(_cn8, classes.endInputRtl, dir === 'rtl'), _defineProperty(_cn8, classes.inputFocusedError, internalError || error), _defineProperty(_cn8, classes.inputFocused, shouldEndInputFakeFocus), _cn8)),
                        disabled: classes.inputDisabled
                    },
                    disableUnderline: true,
                    value: this.getInputDisplayString(Boundary.END, locale),
                    placeholder: endInputPlaceholder
                }, EndInputProps, {
                    onClick: function onClick(e) {
                        return _this2.handleInputClick(e);
                    },
                    onChange: function onChange(e) {
                        return _this2.handleInputChange(e, Boundary.END);
                    },
                    onFocus: function onFocus(e) {
                        return _this2.handleInputFocus(e, Boundary.END, locale);
                    },
                    onBlur: function onBlur(e) {
                        return _this2.handleInputBlur(e, Boundary.END, locale);
                    },
                    onKeyDown: function onKeyDown(e) {
                        return _this2.handleOnKeyDown(e);
                    },
                    onMouseDown: function onMouseDown() {
                        return _this2.handleInputMouseDown();
                    }
                }))
            );

            var inputArrowIcon = React.createElement(
                'span',
                {
                    id: id + '-arrow-icon',
                    className: cn(classes.iconContainer, _defineProperty({}, classes.iconContainerDisabled, disabled)),
                    key: id + '-icon'
                },
                dir === 'ltr' ? React.createElement(ArrowRight, null) : React.createElement(ArrowLeft, null)
            );

            return React.createElement(
                ClickAwayListener,
                Object.assign({
                    onClickAway: function onClickAway() {
                        return _this2.closeDateRangePicker();
                    }
                }, ClickAwayListenerProps),
                React.createElement(
                    'div',
                    Object.assign({
                        className: cn(classes.container, _defineProperty({}, classes.inputContainerFullWidth, fullWidth))
                    }, ContainerProps),
                    React.createElement(
                        'div',
                        {
                            className: cn(classes.inputContainer, (_cn11 = {}, _defineProperty(_cn11, classes.inputContainerDisabled, disabled), _defineProperty(_cn11, classes.inputContainerError, internalError || error), _defineProperty(_cn11, classes.inputContainerFullWidth, fullWidth), _defineProperty(_cn11, classes.smallDateRangePicker, size === 'small'), _cn11)),
                            role: 'presentation',
                            onKeyDown: function onKeyDown(e) {
                                return _this2.handleContainerKeyDown(e);
                            }
                        },
                        dir === 'ltr' ? [startInput, inputArrowIcon, endInput] : [endInput, inputArrowIcon, startInput]
                    ),
                    (errorHelperText || helperText) && React.createElement(
                        FormHelperText,
                        Object.assign({
                            disabled: disabled,
                            error: internalError || error,
                            classes: {
                                root: classes.formHelperTextRoot,
                                error: classes.formHelperTextError
                            }
                        }, FormHelperTextProps),
                        errorHelperText || helperText
                    ),
                    React.createElement(
                        Popper,
                        Object.assign({
                            id: id + '-popper',
                            open: isOpen,
                            anchorEl: anchorEl,
                            placement: dir === 'ltr' ? 'bottom-start' : 'bottom-end',
                            disablePortal: true,
                            onKeyDown: function onKeyDown(e) {
                                return _this2.handleContainerKeyDown(e);
                            },
                            className: classes.popper
                        }, PopperProps),
                        React.createElement(
                            'div',
                            {
                                className: cn(classes.root, _defineProperty({}, classes.hideOutsideDays, !this.props.showOutsideDays))
                            },
                            React.createElement(DayPicker, Object.assign({
                                id: id,
                                className: cn('hedtech-DateRangePicker', classes.dateRangePickerRoot, _defineProperty({}, classes.singleMonth, numberOfMonths === 1))
                            }, defaultProps, {
                                parse: parseDate,
                                numberOfMonths: numberOfMonths,
                                selectedDays: selectedDays,
                                modifiers: modifiers,
                                month: this.getDayPickerMonth(),
                                format: format,
                                locale: locale,
                                dir: dir,
                                localeUtils: MomentLocaleUtils,
                                fromMonth: minDate,
                                toMonth: maxDate
                            }, props, {
                                onDayClick: this.handleDayChange,
                                onDayMouseEnter: function onDayMouseEnter(day) {
                                    return _this2.handleDayMouseEnter(day, locale);
                                },
                                onDayMouseLeave: this.handleDayMouseLeave,
                                onBlur: function onBlur(e) {
                                    // Following condition would validate whether focus is still on the calendar or not.  If not, it will close the calendar.
                                    // e.relatedTarget : This would help us to get the current focus item from the DOM.
                                    if (!e.currentTarget.contains(e.relatedTarget)) {
                                        _this2.closeDateRangePicker();
                                    }
                                }
                            }))
                        )
                    )
                )
            );
        }
    }]);

    return DateRangePicker;
}(React.Component);

var _initialiseProps = function _initialiseProps() {
    var _this3 = this;

    this.getInitialRange = function () {
        var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _this3.props;
        var defaultValue = props.defaultValue,
            value = props.value;

        if (value != null) {
            return value;
        } else if (defaultValue != null) {
            return defaultValue;
        } else {
            return [null, null];
        }
    };

    this.getInitialOpenState = function () {
        var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _this3.props;
        var isOpen = props.isOpen;

        if (isOpen != null) {
            return isOpen;
        } else {
            return false;
        }
    };

    this.handleContainerKeyDown = function (event) {
        if (event.key !== 'Escape') {
            return;
        }

        // Swallow the event, in case someone is listening for the escape key on the body.
        event.stopPropagation();

        _this3.closeDateRangePicker();
    };

    this.openDateRangePicker = function () {
        // If calendar ui is already open, exit
        if (_this3.state.isOpen) {
            return;
        }

        _this3.setState({
            isOpen: true
        });
    };

    this.closeDateRangePicker = function () {
        // If calendar ui is already closed, exit
        if (!_this3.state.isOpen) {
            return;
        }
        _this3.setState({
            isOpen: false,

            // Clear out these variables
            startHoverString: null,
            endHoverString: null,
            hoverTo: null
        });
    };

    this.handleInputClick = function (e) {
        if (!_this3.props.disabled && !_this3.state.isOpen) {
            _this3.openDateRangePicker();
        }
        e.stopPropagation();
    };

    this.handleInputMouseDown = function (e) {
        // clicking in the field constitutes an explicit focus change. we update
        // the flag on "mousedown" instead of on "click", because it needs to be
        // set before onFocus is called ("click" triggers after "focus").
        _this3.setState({ wasLastFocusChangeDueToHover: false });
    };

    this.doesEndBoundaryOverlapStartBoundary = function (boundaryDate, boundary) {
        return boundary === Boundary.START ? false : _this3.doBoundaryDatesOverlap(boundaryDate, boundary);
    };

    this.isControlled = function () {
        return _this3.props.value !== undefined;
    };

    this.isInputEmpty = function (inputString) {
        return !inputString || inputString == null || inputString.length === 0;
    };

    this.isDateValidAndInRange = function (date) {
        return DateUtils.isDate(date) && DateUtils.isDayInRange(date, {
            from: _this3.props.minDate,
            to: _this3.props.maxDate
        });
    };

    this.getDateRangeForCallback = function (currDate, currBoundary) {
        var otherBoundary = _this3.getOtherBoundary(currBoundary);
        var otherDate = _this3.getStateKeysAndValuesForBoundary(otherBoundary).values.selectedValue;

        return currBoundary === Boundary.START ? [currDate, otherDate] : [otherDate, currDate];
    };

    this.getOtherBoundary = function (boundary) {
        return boundary === Boundary.START ? Boundary.END : Boundary.START;
    };

    this.doBoundaryDatesOverlap = function (date, boundary) {
        var otherBoundary = _this3.getOtherBoundary(boundary);
        var otherBoundaryDate = _this3.getStateKeysAndValuesForBoundary(otherBoundary).values.selectedValue;

        if (date == null || otherBoundaryDate == null) {
            return false;
        }

        if (boundary === Boundary.START) {
            var isAfter = DateUtils.isDayAfter(date, otherBoundaryDate);
            return isAfter;
        } else {
            var isBefore = DateUtils.isDayBefore(date, otherBoundaryDate);
            return isBefore;
        }
    };

    this.getStateKeysAndValuesForBoundary = function (boundary) {
        var controlledRange = _this3.props.value;

        if (boundary === Boundary.START) {
            return {
                keys: {
                    hoverString: 'startHoverString',
                    inputString: 'startInputString',
                    isInputFocused: 'isStartInputFocused',
                    selectedValue: 'selectedStart',
                    error: 'startInputError'
                },
                values: {
                    controlledValue: controlledRange != null ? controlledRange[0] : undefined,
                    hoverString: _this3.state.startHoverString,
                    inputString: _this3.state.startInputString,
                    isInputFocused: _this3.state.isStartInputFocused,
                    selectedValue: _this3.state.selectedStart,
                    error: _this3.state.startInputError
                }
            };
        } else {
            return {
                keys: {
                    hoverString: 'endHoverString',
                    inputString: 'endInputString',
                    isInputFocused: 'isEndInputFocused',
                    selectedValue: 'selectedEnd',
                    error: 'endInputError'
                },
                values: {
                    controlledValue: controlledRange != null ? controlledRange[1] : undefined,
                    hoverString: _this3.state.endHoverString,
                    inputString: _this3.state.endInputString,
                    isInputFocused: _this3.state.isEndInputFocused,
                    selectedValue: _this3.state.selectedEnd,
                    error: _this3.state.endInputError
                }
            };
        }
    };
};

DateRangePicker.propTypes = {
    /**
     * @ignore
     * This is the width of the viewport coming from the withWidth HOC
     */
    width: PropTypes.string,
    /**
     * @ignore
     * This function fetches resource string translations. It comes from the withEDSContext HOC.
     */
    edsContext: PropTypes.object,
    /**
     * @ignore
     * Helper variable for testing.
     */
    isOpen: PropTypes.bool,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Properties applied to the top-level `div` element.
     */
    ContainerProps: PropTypes.object,

    // Generic props
    /**
     * The id of the `input` element. This is also used to create id's for internal components such as the the `FormControl`, `InputLabel` and `DayPicker`.
     */
    id: PropTypes.string,
    /**
     * The format of the date to accept and display.
     *
     * e.g. `M/D/YYYY`, `MM-D-YY`, `l`, `LL`, `ll`.
     */
    format: PropTypes.string,
    /**
     * If `fullWidth` is true, then render the input field full width.
     */
    fullWidth: PropTypes.bool,
    /**
     * Callback fired when the value is changed. This function sends back an `Array` of `Date`: ([startDate, endDate]) => setDates([startDate, endDate])
     * If no days are selected, it will pass `[null, null]`. If a start date is selected but not an end date, it will pass `[selectedDate, null]`. If both a start and end date are selected, it will pass `[startDate, endDate]`.
     */
    onChange: PropTypes.func,
    /**
     * The date range value. If you are controlling the state and want to initialize with empty dates, set the value to `[null, null]`. If a single boundary is known the array could look like either `[someDate, null]` or `[null, someDate]`. If both boundaries are known the array would look like, `[someDate, someOtherDate]`.
     *
     * **Note:** Sending in a completely empty array (`[]`) will cause an the DateRangePicker to throw an error.
     */
    value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),

    /**
     * The date range value for uncontrolled implementations.
     */
    defaultValue: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    /**
     * If true, the labels are displayed as required.
     */
    required: PropTypes.bool,
    /**
     * The maximum date possible to select. The selected date range must fall in between the `minDate` and `maxDate`. Default is December 31st, 10 years in the future.
     */
    maxDate: PropTypes.any,
    /**
     * The minimum date possible to select. The selected date range must fall in between the `minDate` and `maxDate`. Default is January 1st, 20 years in the past.
     */
    minDate: PropTypes.any,
    /**
     * Custom date function that parses the date. This is used to display date to the user in the input field.
     *
     * e.g.
     *
     * `(dateString: string, format: string, locale: string) => string`
     */
    parseDate: PropTypes.func,

    // DayPicker props
    /**
     * The month to display in the calendar at first render. This differs from the `month` prop, as it won’t re-render the calendar if its value changes.
     */
    initialMonth: PropTypes.instanceOf(Date),
    /**
     * Labels to use as `aria-label` HTML attributes for the previous and next month navigation buttons.
     */
    labels: PropTypes.object,
    /**
     * Locale to use for date formatting and calendar labels.  Defaults to the locale specified in [`EDSApplication`](#/components/EDSApplication).
     */
    locale: PropTypes.string,
    /**
     * Render the `DateRangePicker` right-to-left.
     */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    /**
     * Object of functions to format dates and to get the first day of the week. You can pass your own object for advanced [localization](http://react-day-picker.js.org/docs/localization/).
     */
    localeUtils: PropTypes.object,
    /**
     * An object of *day modifiers*. This will add a new CSS class name on the days that match the modifier. [View react-day-picker documentation](http://react-day-picker.js.org/docs/matching-days/).
     *
     * e.g.
     * `const modifiers = { birthday: new Date(2018, 8, 19) } => // Creates a day component with the class name: .DayPicker-Day--birthday`
     */
    modifiers: PropTypes.object,
    /**
     * An object of inline styles added to the day cells when a modifier is matched. Use this prop to style day cells inline instead of using CSS classes.
     */
    modifiersStyles: PropTypes.object,
    /**
     * @ignore
     *
     * The month to display in the calendar. This differs from the `initialMonth` prop, as it causes the calendar to re-render when its value changes.
     */
    month: PropTypes.instanceOf(Date),
    /**
     * An array containing the long month names to use in the month’s header. Defaults to the English month names.
     */
    months: PropTypes.arrayOf(PropTypes.string),

    /**
     * @ignore
     *
     * A React Element or React Component to render the navigation bar. It will receive the following props:
     *
     * - month: Date The currently displayed month
     * - previousMonth: Date
     * - nextMonth: Date
     * - onPreviousClick: () ⇒ void
     * - onNextClick: () ⇒ void
     */
    navbarElement: PropTypes.arrayOf(PropTypes.string),

    /**
     * The number of months to render.
     *
     * We recommend this prop to be using sparingly. In some situations,
     * where only one month should be shown to conserve screen space or issues arise with the visual layout
     * of the DateRangePicker calendar interface. Most common situations are when the `DateRangePicker` is
     * placed in a `Dialog` or `Table`.
     */
    numberOfMonths: PropTypes.number,

    /**
     * @ignore
     *
     * When displaying multiple months, navigation will be paginated displaying the `numberOfMonths` at time instead of one.
     */
    pagedNavigation: PropTypes.bool,

    /**
     * @ignore
     *
     * Returns the content of a day cell. As default it returns day’s current date.
     */
    renderDay: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]),
    /**
     * @ignore
     *
     * Returns the content of the week element when showWeekNumbers is set. As default it returns the week number.
     */
    renderWeek: PropTypes.func,

    /**
     * Render the months in reversed order. Useful when wanting to display the most recent month first.
     * This prop should also be used when `dir="rtl"`.
     */
    reverseMonths: PropTypes.bool,

    /**
     * Day(s) that should appear as selected.
     */
    selectedDays: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.object, PropTypes.arrayOf(PropTypes.instanceOf(Date)), PropTypes.func]),
    /**
     * Day(s) that should appear as disabled.
     */
    disabledDays: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.object, PropTypes.arrayOf(PropTypes.instanceOf(Date)), PropTypes.func]),

    /**
     * @ignore
     * Display the days falling outside the current month.
     */
    showOutsideDays: PropTypes.bool,
    /**
     * @ignore
     * Display the weekday names in the calendar header.
     */
    showWeekDays: PropTypes.bool,
    /**
     * @ignore
     * Display the year's week number next to each week.
     */
    showWeekNumbers: PropTypes.bool,
    /**
     * @ignore
     * Display a button to switch to the current month using the provided string as a label.
     */
    todayButton: PropTypes.bool,
    /**
     * @ignore
     *
     * A React Element or React Component to render the weekday cells in the header. It will receive the following props:
     *
     * - weekday `number`
     * - className `string`
     * - localeUtils `object`
     * - locale `string`
     */
    weekdayElement: PropTypes.instanceOf(Date),
    /**
     * @ignore
     *
     * An array containing the long weekdays names to use in the month's header Defaults to the English weekdays names. Must start from Sunday.
     */
    weekdaysLong: PropTypes.arrayOf(PropTypes.string),
    /**
     * @ignore
     *
     * An array containing the short weekdays names to use in the month's header. Defaults to the English weekdays names. Must start from Sunday.
     */
    weekdaysShort: PropTypes.arrayOf(PropTypes.string),

    // DayPicker EventHandlers
    /**
     * @ignore
     */
    onCaptionClick: PropTypes.func,

    /**
     * Event handler when the calendar gets the `blur` event.
     *
     * e.g.
     * (e: SyntheticEvent) ⇒ void
     */
    onBlur: PropTypes.func,
    /**
     * Event handler when the calendar gets the `focus` event.
     *
     * e.g.
     * `(e: SyntheticEvent) ⇒ void`
     */
    onFocus: PropTypes.func,
    /**
     * Event handler when the calendar gets the `keydown` event.
     *
     * e.g.
     * `(e: SyntheticEvent) ⇒ void`
     */
    onKeyDown: PropTypes.func,

    /**
     * @ignore
     *
     * Event handler when the user clicks on a day cell.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayClick: PropTypes.func,
    /**
     * Event handler when the day cell gets the `keydown` event.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayKeyDown: PropTypes.func,
    /**
     * Event handler when the day cell gets the `focus` event.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayFocus: PropTypes.func,
    /**
     * Event handler when the mouse button is pressed on a day cell.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayMouseDown: PropTypes.func,
    /**
     * @ignore
     * Event handler when the mouse enters a day cell.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayMouseEnter: PropTypes.func,
    /**
     * @ignore
     * Event handler when the mouse leave a day cell.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayMouseLeave: PropTypes.func,
    /**
     * Event handler when the mouse button is released on a day cell.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayMouseUp: PropTypes.func,

    /**
     * Event handler when the day cell gets the `touchStart` event.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayTouchStart: PropTypes.func,
    /**
     * Event handler when the day cell gets the `touchEnd` event.
     *
     * e.g.
     * `(day: date, modifiers: Object, e: SyntheticEvent) ⇒ void`
     */
    onDayTouchEnd: PropTypes.func,

    /**
     * @ignore
     *
     * Event handler when the month is changed, i.e. clicking the navigation buttons or using the keyboard.
     *
     * e.g.
     * `(month: date) ⇒ void`
     */
    onMonthChange: PropTypes.func,

    /**
     * @ignore
     */
    onWeekClick: PropTypes.func,
    /**
     * @ignore
     */
    onTodayButtonClick: PropTypes.func,

    // Public Methods
    /**
     * @ignore
     */
    showMonth: PropTypes.func,
    /**
     * @ignore
     */
    showPreviousMonth: PropTypes.func,
    /**
     * @ignore
     */
    showNextMonth: PropTypes.func,
    /**
     * @ignore
     */
    showPreviousYear: PropTypes.func,
    /**
     * @ignore
     */
    showNextYear: PropTypes.func,

    // Input/FormControl props
    /**
     * If `true`, the input field will be displayed in an error state.
     */
    error: PropTypes.bool,
    /**
     * If true, the input will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * The label for the start input. Defaults to 'Start date'.
     */
    startInputLabel: PropTypes.string,
    /**
     * The label for the end input. Defaults to 'End date'.
     */
    endInputLabel: PropTypes.string,
    /**
     * The placeholder text for the start input.
     */
    startInputPlaceholder: PropTypes.string,
    /**
     * The placeholder text for the end input.
     */
    endInputPlaceholder: PropTypes.string,

    /**
     * Properties applied to the start [`Input`](https://material-ui.com/api/input/#input-api) element.
     * If you re-assign the `placeholder` property, it will override the `startInputPlaceholder` prop.
     */
    StartInputProps: PropTypes.object,
    /**
     * Properties applied to the start [`Input`](https://material-ui.com/api/input/#input-api) element.
     * If you re-assign the `placeholder` property, it will override the `endInputPlaceholder` prop.
     */
    EndInputProps: PropTypes.object,

    /**
     * Properties applied to the start [`FormControl`](#/components/FormControl) element.
     */
    StartFormControlProps: PropTypes.object,
    /**
     * Properties applied to the end [`FormControl`](#/components/FormControl) element.
     */
    EndFormControlProps: PropTypes.object,
    /**
     * Properties applied to the start [`InputLabel`](#/components/FormControl) element.
     */
    StartInputLabelProps: PropTypes.object,
    /**
     * Properties applied to the end [`InputLabel`](#/components/FormControl) element.
     */
    EndInputLabelProps: PropTypes.object,

    // Popper props
    /**
     * Properties applied to the [`Poppper`](#/components/Popper) element.
     */
    PopperProps: PropTypes.object,

    /**
     * Properties applied to the [`ClickAwaysListener`](https://material-ui.com/api/click-away-listener/) element.
     */
    ClickAwayListenerProps: PropTypes.object,

    // FormHelperText props
    /**
     * The helper text content.
     */
    helperText: PropTypes.string,
    /**
     * Properties applied to the [`FormHelperText`](#/components/FormControl) element.
     */
    FormHelperTextProps: PropTypes.object,
    /**
     * The size of the `DateRangePicker`. Small size is recommended to be used within the `TableEditableCell` component.
     */
    size: PropTypes.oneOf(['small', 'medium'])
};

DateRangePicker.defaultProps = {
    initialMonth: new Date(),
    dir: 'ltr',
    format: 'L',
    showOutsideDays: false,
    maxDate: getDefaultMaxDate(),
    minDate: getDefaultMinDate(),
    StartInputProps: {},
    EndInputProps: {},
    parseDate: parseDate,
    size: 'medium'
};

export default withWidth()(withEDSContext(withStyles(styles)(DateRangePicker)));