import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '../styles';
import Typography from '../Typography';
import { spacingXSmall, spacingMedium, spacingLarge, fontSizeSmall } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        currentStepContainer: {
            alignContent: 'stretch',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            marginBottom: spacingMedium
        },
        currentStep: {
            alignSelf: 'auto',
            backgroundColor: theme.palette.ctaColor.base,
            borderRadius: '100%',
            flex: '0 1 auto',
            height: spacingLarge,
            order: 0,
            textAlign: 'center',
            width: spacingLarge
        },
        currentStepNumber: {
            fontSize: fontSizeSmall,
            color: theme.palette.grey[100],
            position: 'relative',
            top: spacingXSmall
        }
    };
};

/**
 * A component that displays the current step of the total steps
 */
var MobileStepperStep = React.forwardRef(function (props, ref) {
    var activeStep = props.activeStep,
        classes = props.classes;


    return React.createElement(
        'div',
        { className: classes.currentStepContainer, ref: ref },
        React.createElement(
            'div',
            { className: classes.currentStep },
            React.createElement(
                Typography,
                { className: classes.currentStepNumber },
                activeStep + 1
            )
        ),
        props.children && props.children
    );
});

MobileStepperStep.propTypes = {
    /**
     * Set the active step (zero based index). This should be the same value that is being sent to the <MobileStepper> component.
     */
    activeStep: PropTypes.number,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The step title component node.
     */
    children: PropTypes.node
};

MobileStepperStep.displayName = 'MobileStepperStep';

export default withStyles(styles)(MobileStepperStep);