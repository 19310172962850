'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _HeaderBar = require('./HeaderBar');

var _ = require('./');

var _Typography = require('../Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _tokens = require('../styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var colorBackgroundIconSelectedThemed = _tokens.colorBrandNeutral300;
var colorBackgroundIconHoverThemed = '#E9E9E9';

var styles = function styles(theme) {
    var _root;

    return {
        root: (_root = {
            display: 'none',
            '& *': {
                outline: 'none'
            },
            '&.themed, &.themed a': {
                color: _tokens.colorTextNeutral500
            },

            fontFamily: theme.typography.h2.fontFamily,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.button.fontWeight,
            color: theme.palette.grey[100],
            textTransform: 'uppercase',
            textDecoration: 'none',
            position: 'relative',
            height: _tokens.heightFluid,
            justifyContent: 'center',
            outline: 'none',
            whiteSpace: 'nowrap',
            '& > *': {
                padding: _tokens.spacingMedium + ' ' + _tokens.spacingSmall
            },
            '& > .ds-icon': {
                padding: _tokens.spacingMedium + ' ' + _tokens.spacingSmall
            },
            '& a': {
                textDecoration: 'none',
                color: theme.palette.grey[100]
            }
        }, _defineProperty(_root, '&:focus:not(.utility-item):after,\n                &:hover:not(.utility-item):after,\n                &.active:not(.utility-item):not(.avoid-clicks):after,\n                &.focused:not(.utility-item):after,\n                & .activeLink:not(.utility-item):after', {
            width: '1.25rem',
            height: '.625rem',
            borderTopLeftRadius: _tokens.spacingXLarge,
            borderTopRightRadius: _tokens.spacingXLarge,
            backgroundColor: theme.palette.secondary.main,
            content: "''",
            position: 'absolute',
            left: 'calc(50% - 0.625rem)',
            bottom: '0',
            pointerEvents: 'none'
        }), _defineProperty(_root, '&.disabled:not(.always-show)', {
            opacity: _tokens.opacity2,
            pointerEvents: 'none'
        }), _defineProperty(_root, '&:focus', {
            outline: 'none'
        }), _defineProperty(_root, '&.no-hide', {
            display: 'flex'
        }), _defineProperty(_root, '&.hide-small', _defineProperty({}, '@media ' + _tokens.mqSmall, {
            display: 'flex'
        })), _defineProperty(_root, '&.hide-medium', _defineProperty({}, '@media ' + _tokens.mqMedium, {
            display: 'flex'
        })), _defineProperty(_root, '&.hide-large', _defineProperty({}, '@media ' + _tokens.mqLarge, {
            display: 'flex'
        })), _defineProperty(_root, '&.hide-xsmall-down', _defineProperty({}, '@media ' + _tokens.mqXSmall, {
            display: 'flex'
        })), _defineProperty(_root, '&.hide-small-down', _defineProperty({}, '@media ' + _tokens.mqSmallDown, {
            display: 'flex'
        })), _defineProperty(_root, '&.hide-medium-down', _defineProperty({}, '@media ' + _tokens.mqMediumDown, {
            display: 'flex'
        })), _defineProperty(_root, 'cursor', 'pointer'), _root),

        alwaysShow: {
            display: 'flex !important'
        },
        itemIcon: {
            textTransform: 'none !important',
            color: theme.palette.grey[100],
            zIndex: 1,
            '& svg': {
                width: '24px',
                height: '24px',
                transform: 'translateY(1px)'
            },
            '& > *': {
                padding: '0'
            },
            '& > div': {
                padding: '1rem 0'
            },
            '& > span': {
                padding: '1rem 1rem'
            },
            '&.focused': {
                boxShadow: _tokens.boxShadowFocusInset,
                outline: 'none'
            },
            '&:hover': {
                backgroundColor: _tokens.colorBackgroundHeaderBarItemHover
            },
            '&.themed:hover': {
                backgroundColor: colorBackgroundIconHoverThemed
            },
            '&.selected': {
                backgroundColor: _tokens.colorBackgroundHeaderBarItemSelected
            },
            '&.themed.selected': {
                backgroundColor: colorBackgroundIconSelectedThemed
            },
            height: 'auto'
        }
    };
};

var HeaderBarItemConsumer = function (_React$Component) {
    _inherits(HeaderBarItemConsumer, _React$Component);

    function HeaderBarItemConsumer(props) {
        _classCallCheck(this, HeaderBarItemConsumer);

        var _this = _possibleConstructorReturn(this, (HeaderBarItemConsumer.__proto__ || Object.getPrototypeOf(HeaderBarItemConsumer)).call(this, props));

        _this.state = { focused: false };

        _this.isActive = function () {
            var active = _this.props.active;

            return active;
        };

        _this.itemRef = Math.floor(Math.random() * Math.floor(1000));
        return _this;
    }

    _createClass(HeaderBarItemConsumer, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _props = this.props,
                context = _props.context,
                active = _props.active;


            if (active) {
                context.setActive(this);
            }
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState, snapshot) {

            // if the 'active' property was changed to true, update active component
            if (this.props.active !== prevProps.active && this.props.active) {
                this.props.context.setActive(this);
            }
        }

        /**
         * Set the current item to active
         */

    }, {
        key: 'setItemActive',
        value: function setItemActive() {
            this.props.context.setActive(this);
        }

        /**
         * Handle an event to set the item active.
         */

    }, {
        key: 'setActive',
        value: function setActive(event) {
            event.preventDefault();
            if (event.target !== event.currentTarget) {
                this.setItemActive();
            }
        }

        /**
         * Determine whether given item represents a single header bar dropdown.
         *
         * @param {*} children  The children passed into this component
         * @return {boolean}    True if it's a dropdown
         */

    }, {
        key: 'isChildDropdown',
        value: function isChildDropdown(children) {
            return children && children.type && children.type === _.HeaderBarDropdown;
        }

        /**
         * Deteremine whether the current header bar item is active.
         *
         * @return {boolean} True if the current menu item is active.
         */

    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props2 = this.props,
                classes = _props2.classes,
                children = _props2.children,
                isUtility = _props2.isUtility,
                responsiveType = _props2.responsiveType,
                role = _props2.role,
                itemKey = _props2.itemKey,
                selected = _props2.selected;

            var isItemDropdown = this.isChildDropdown(children);

            var isSimpleText = typeof children === 'string';

            // if this is a dropdown, add a callback that allows it to inform us when one
            // of its items have been selected
            var decoratedChildren = isItemDropdown ? _react2.default.cloneElement(children, {
                itemSelected: this.setItemActive.bind(this),
                isUtility: this.props.isUtility
            }) : children;

            // unadorned text items and non-dropdown utility items need to be wrapped in a span, to make them tab stops
            if ((isSimpleText || isUtility) && !isItemDropdown) {
                decoratedChildren = _react2.default.createElement(
                    'span',
                    { role: 'link', tabIndex: 0, 'aria-label': this.props['aria-label'] },
                    decoratedChildren
                );
            }

            // determine at what size class (if any) the item should hide itself
            var responsiveClass = responsiveType === 'none' ? null : 'hide-' + responsiveType;

            return _react2.default.createElement(
                _Typography2.default,
                {
                    'data-key': itemKey,
                    variant: 'h2',
                    color: 'inherit',
                    title: this.props.title,
                    className: (0, _classnames2.default)(classes.root, { 'selected': selected }, _defineProperty({}, classes.itemIcon, this.props.isUtility), responsiveClass, { 'no-hide': responsiveType === 'none' }, { 'utility-item': this.props.isUtility }, { 'active': this.isActive() }, { 'focused': this.state.focused }, { 'disabled': this.props.disabled }, _defineProperty({}, classes.alwaysShow, this.props.alwaysShow), { 'always-show': this.props.alwaysShow }, { 'themed': this.props.context.isThemed() }, this.props.className),
                    role: role,
                    component: 'div',
                    onClick: function onClick(e) {
                        if (!isItemDropdown && !isUtility) {
                            _this2.setActive(e);
                        }
                        // eslint-disable-next-line
                        if (_this2.props.onClick) {
                            _this2.props.onClick(e);
                        }
                    },
                    onKeyUp: function onKeyUp(e) {
                        if (e.key === 'Enter' && !isItemDropdown) {
                            _this2.setItemActive(_this2.itemRef);
                        }
                        // eslint-disable-next-line
                        if (_this2.props.onKeyUp) {
                            _this2.props.onKeyUp(e);
                        }
                    },
                    onFocus: function onFocus(e) {
                        return _this2.setState({ focused: true });
                    },
                    onMouseLeave: function onMouseLeave(e) {
                        return _this2.setState({ focused: false });
                    },
                    onBlur: function onBlur(e) {
                        return _this2.setState({ focused: false });
                    }
                },
                decoratedChildren
            );
        }
    }]);

    return HeaderBarItemConsumer;
}(_react2.default.Component);

var HeaderBarItem = function HeaderBarItem(props) {
    return _react2.default.createElement(
        _HeaderBar.HeaderBarContext.Consumer,
        null,
        function (context) {
            return _react2.default.createElement(HeaderBarItemConsumer, Object.assign({}, props, { context: context }));
        }
    );
};

HeaderBarItem.muiName = 'HeaderBarItem';

HeaderBarItemConsumer.propTypes = {
    'aria-label': _propTypes2.default.string,
    children: _propTypes2.default.node,
    classes: _propTypes2.default.object,
    active: _propTypes2.default.bool,
    context: _propTypes2.default.object.isRequired,
    alwaysShow: _propTypes2.default.bool,
    disabled: _propTypes2.default.bool,
    role: _propTypes2.default.string,
    className: _propTypes2.default.string,
    title: _propTypes2.default.string,
    responsiveType: _propTypes2.default.string,
    isUtility: _propTypes2.default.bool,
    itemKey: _propTypes2.default.string,
    selected: _propTypes2.default.bool
};

HeaderBarItem.propTypes = {

    /**  The content of the component. Common children are links to send users to different pages within an application. */
    children: _propTypes2.default.node.isRequired,

    /** Useful to extend the style applied to components. */
    classes: _propTypes2.default.object.isRequired,

    /** Set default active header item on load */
    active: _propTypes2.default.bool,

    /** Whether to always show this item, regardless of the current size class */
    alwaysShow: _propTypes2.default.bool,

    /** Whether this item is disabled */
    disabled: _propTypes2.default.bool,

    /** Aria role if applicable */
    role: _propTypes2.default.string,

    /** Additional class to apply to the item */
    className: _propTypes2.default.string,

    /**
     * Whether the item should be responsive
     *
     * This property is no longer used.
     *
     * @deprecated
     *
     */
    responsive: _propTypes2.default.bool,

    /**
     * HTML title to apply to the item
     */
    title: _propTypes2.default.string,

    /**
     * Whether to render this menu as a utility item
     *
     * @ignore
     */
    isUtility: _propTypes2.default.bool,

    /**
     * At what breakpoint to hide all non-essential items on the header bar. Valid values are:
     *
     * - `none` - Never hide anything
     * - `xsmall-down` - Hide items at x-small breapoint, and below
     * - `small-down` - Hide items at small breapoint, and below
     * - `small` - Hide items at the small breakpoint
     * - `medium` - Hide items at the medium breakpoint
     * - `large` - Hide items at the large breakpoint
     */
    responsiveType: _propTypes2.default.string,

    /**
     * A key that uniquely identifies this header bar item
     */
    itemKey: _propTypes2.default.string,

    /**
     * @ignore
     *
     * If true, the item is also rendered selected
     */
    selected: _propTypes2.default.bool

};

HeaderBarItem.defaultProps = {
    alwaysShow: false,
    disabled: false,
    isUtility: false,
    responsiveType: 'medium'
};

exports.default = (0, _styles2.default)(styles)(HeaderBarItem);